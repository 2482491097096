import React from "react";
import {UIText} from "../../../components/ui/text.js";
import {UIButton} from '../../../components/ui/button/button.js';
import {useStateContext} from '../../../helpers/state-context.js';



const ViewAuthConfirmSignUp = function (props) {
    const [,dispatch] = useStateContext()
    
    return <div style={{display: 'flex', alignItems: 'center', height: '100%', paddingTop: '71px'}}>
        <div style={{maxWidth: 850, padding: 10, margin: 'auto'}}>
            <UIText type={'head-1'} color={'darkColor'} style={{marginBottom: 10}}>
                Thank you for signing up!
            </UIText>
            <UIText type={'body-L'} color={'mediumColor'}>
                Once your account is verified you will recieve an email and you will be able to login.
            </UIText>
            <UIButton className="primary" style={{ margin: '15px 0'}}
                    onClick={()=>dispatch({type: 'page', value: 'sign-in'})}>
                Return to home
            </UIButton>
        </div>
    </div>
}

export {ViewAuthConfirmSignUp}