import React from 'react';
import thankYouImage from '../../../assets/images/thank_you.png';
import {UIButton} from '../../../components/ui/button/button.js';
import {useModelFeedback} from '../../../helpers/model/use_model_feedback.js';
import {ModelParagraph} from '../../../model/admin/paragraph.js';

const containerStyle = {
    backgroundColor: '#FFF',
    width: '100%',
    padding: 25,
    textAlign: 'center',

};

const innerStyle = {
    margin: 'auto',
    maxWidth: 800,
    padding: 25,
    textAlign: 'center'
};

const AdminAuditFinished = (props) => {
    const audit1Text = useModelFeedback(ModelParagraph, {type: 'audit_thanks_1'});
    const audit2Text = useModelFeedback(ModelParagraph, {type: 'audit_thanks_2'});

    if(audit1Text.feedback){
        return audit1Text.feedback
    }
    if(audit2Text.feedback){
        return audit2Text.feedback
    }

    return <div style={containerStyle}>
        <div style={innerStyle}>
            <img style={{width: '100%'}} alt={'thank-you'} src={thankYouImage}/>
            <h1>{audit1Text.data[0].text}</h1>
            <p>
               {audit2Text.data[0].text}
            </p>
            <UIButton onClick={()=>props.onClick()}>Go Back</UIButton>
        </div>
    </div>
};

export {AdminAuditFinished}