import React, {useState} from "react";
import {PropTypes} from 'prop-types';
import {UIInputSelect} from "../../../components/ui/input/select.js";
import {Card} from "../../../components/card/card.js";
import {CardHeader} from "../../../components/card/header.js";
import {useModelFeedback} from "../../../helpers/model/use_model_feedback.js";
import styles from  "../../../assets/variables.scss";
import {useStateContext} from "../../../helpers/state-context.js";
import {ModelAuditIndicatorRatio} from '../../../model/audit/indicator-ratio.js';
import {ModelOrgHierarchy} from '../../../model/org-hierarchy.js';
import {VerticalBarIcon} from '../../../components/chart/vertical-bar/icon.js';
import {VerticalBarComponentCircle} from '../../../components/chart/vertical-bar/component/circle.js';


const InvestmentVariationTitle = function (props){
    // TODO: national mean and recommendation should be configurable from admin
    return <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <div>
            Average Investment per Patient 
            <p style={{color:styles.brandPurpleColor}}>{`£ ${props.average}`}</p>
        </div>
        <div style={{margin: '0 15px'}}>
            National mean
            <p style={{color:styles.brandRedColor}}>£ 7080</p>
        </div>
        <div>
            NHS England recommends
            <p style={{color:styles.brandYellowColor}}>£ 8250</p>
        </div>
    </div>
}
InvestmentVariationTitle.propTypes = {
    average: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
}


const getTotal = function (data, key='value'){
    return data.reduce((total, item) => { return total += parseFloat(item[key]); }, 0);
}

const DashboardCardInvestmentVariation = function (props) {
    const [state] = useStateContext(['period', 'group', 'isExportingPDF']);
    const [group, setGroup] = useState('teams');
    const groupOptions = ['teams', 'providers'];
    let averageInvestment = 0;
    const data = [];

    const select = group === 'teams'? 
        'team_id as group_id, team as group_title':
        'provider_id as group_id, provider as group_title';
    const selectionCriteria = {
        id: 17,  // investment per patient Q3 / Q4b,
        period: state.period,
        select: select + ', sum(numerator) as budget, sum(denominator) as caseload'
    }
    const resultSetHierarchy = useModelFeedback(ModelOrgHierarchy, 
            {select: 'provider, provider_id, team, team_id'}, (m) => (m.getRawData()));
    const resultSetInvestment = useModelFeedback(ModelAuditIndicatorRatio, selectionCriteria);

    if(resultSetHierarchy.feedback){
        return resultSetHierarchy.feedback
    }
    
    if(resultSetInvestment.status === 'success'){
        const result = resultSetInvestment.data.map((item) => ({
            ...item,
            value: item.caseload? item.budget / item.caseload: 0
        })).sort((a, b) => (a.value - b.value));
        const totalCaseload = getTotal(result, 'caseload');
        const totalBudget = getTotal(result, 'budget');
        averageInvestment = totalCaseload? (totalBudget / totalCaseload).toFixed(2): 0;
        const activeIds = resultSetHierarchy.data.reduce((array, item) =>{
            let newArray = [...array]
            if(item.provider_id === state.group || item.team_id === state.group){
                newArray.push(...[item.provider_id, item.team_id])
            }
            return newArray
        }, []);
        for(const item of result){
            const value = parseFloat(item.value).toFixed(2);
            const color = activeIds.includes(item.group_id) ? 
                            '#7300f2' : 
                            value < 7080 ? styles.brandRedColor :
                            value < 8250 ? styles.brandYellowColor :
                            styles.brandGreenColor;
            data.push({
                group_id: item.group_id,
                title: item.group_title,
                value: value,
                header: item.group_title,
                tooltip: `£ `+ value,
                color: color
            });
        }
    }

    const getGroupSelect = () => {
        return <div style={{display: 'inline-flex', width: '100%', alignItems: 'center'}}>
            <p style={{marginRight:'10px'}}>Compare across </p>
            <UIInputSelect
                    options={groupOptions}
                    name={'period-select'}
                    placeholder={props.placeholder}
                    onChange={(value)=>{setGroup(value)}}
                    value={group}/>
            </div>
    };
    
    return <Card id={props.id} cardSize={props.cardSize}>
        
        <CardHeader id={props.id} 
                title={<InvestmentVariationTitle average={averageInvestment} />} 
                rightColumn={getGroupSelect()}/>

        <div style={{height: 300, width: '100%'}}>
            {resultSetInvestment.feedback ?
                resultSetInvestment.feedback:
                <VerticalBarIcon axis={{x: 'title', y:['value']} }
                        data={data.map(({group_id, ...item }) => ({
                        ...item,
                        icon: (state.isExportingPDF) ?
                            <VerticalBarComponentCircle>
                                {group_id}
                            </VerticalBarComponentCircle>:
                            null,
                        }))}
                        formatAxis={{
                            y: (value)=>`£${value}`
                        }}
                        type='grouped'
                        tooltipHeader={true}
                        hideAxisX={true}
                        lines={[{value: '8250', color: styles.brandYellowColor},
                            {value: '7080', color:styles.brandRedColor}]}/>
            }
        </div>
    </Card>
};

export {DashboardCardInvestmentVariation}

