import React, {useEffect} from 'react';
import {FormElement} from '../../../../helpers/form/element.js';
import {UIButton} from '../../../../components/ui/button/button.js';
import {useForm} from '../../../../helpers/form/use_form.js';
import {UIInputTextarea} from '../../../../components/ui/input/textarea.js';
import {UIInput} from '../../../../components/ui/input/input.js';
import {ModelParagraph} from '../../../../model/admin/paragraph.js';

const formConfig = [
    {
        title: 'Type',
        name: 'type',
        element: UIInput,
        type: 'wide',
        //KC: Do we allow users to edit types?
        disabled: true
    },
    {
        title: 'Text',
        name: 'text',
        element: UIInputTextarea,
        type: 'wide'
    },
];

const ParagraphEdit = function (props) {
    const Form = useForm(formConfig);

    useEffect(()=>{
        Form.setValues(props.paragraph[0])
    }, [Form, props.paragraph])

    const onSubmit = () => {
        ModelParagraph.updateItem(props.paragraph[0].id, Form.getValues(), props.close);
    }

    return <div style={{backgroundColor: 'white', padding: '40px', margin: '20px 0'}}>
        <p>
            {`NOTE: Please do not change the text between the curly brackets or remove the curly brackets,
            e.g. {name}. We use these to insert dynamic data like the name/email of the users`}
        </p>
        <Form.Component>

            {formConfig.map(element => (
                <FormElement  key={element.name} {...element} />
            ))}

            <UIButton className="primary" style={{marginRight: '20px'}} onClick={onSubmit}>
                Save
            </UIButton>

            <UIButton className="off-white" style={{}}
                      onClick={() => { props.close() }}>
                Cancel
            </UIButton>

        </Form.Component>

    </div>;
};

export {ParagraphEdit}