import React, {useState} from "react";
import {PropTypes} from 'prop-types';
import {UITable} from "../../../components/ui/table/table.js";
import {ReactComponent as ViewSvg} from "../../../assets/icons/view.svg";
import {ReactComponent as MailSvg} from "../../../assets/icons/mail.svg";
import {ReactComponent as CheckSvg} from "../../../assets/icons/check.svg";
import styles from '../../../assets/variables.scss';
import { useStateContext } from "../../../helpers/state-context.js";
import {KPIEmoticon} from '../../../components/kpi/emoticon.js';
import {UIInputSelect} from "../../../components/ui/input/select.js";
import {UIButton} from "../../../components/ui/button/button.js";
import {ModelAuditIndicator} from '../../../model/audit/indicator.js';
import {ModelDashboardExpectedCaseload} from "../../../model/dashboard/expected-caseload.js";
import {useModelFeedback} from '../../../helpers/model/use_model_feedback.js';
import {getNICEIndicators, getIndicatorPercentage, getCaseloadIndicators, getOutcomeIndicators, getThresholds}
        from "./component/admin-indicators.js";
import './audit.scss';
import { ModelAuthUser } from "../../../model/auth/user.js";
import {AuditComponentEmailTeam} from './component/email-team.js';
import {UIModal} from "../../../components/ui/modal/modal.js";

function getButton(value){
    return (value==='Completed')?
        <CheckSvg style={{float: 'right', marginRight: '50px', width: "30px"}}/> :
        <UIButton style={{float: 'right'}}>Complete audit</UIButton>
}

const AuditTable = function (props) { 
    const [emailModal, setEmailModal] = useState(false);
    const resultSetIndicator = useModelFeedback(ModelAuditIndicator);
    const resultSetExpected = useModelFeedback(ModelDashboardExpectedCaseload, {byTeam: true});
    const resultSetIsAdmin = useModelFeedback(ModelAuthUser, {id: 'me'}, (model) => ( model.getIsAdmin() ));
    const isAdmin = resultSetIsAdmin.isAdmin;

    const [state, dispatch] = useStateContext(
            ['view', 'id', 'auditListIndicator1', 'auditListIndicator2', 'auditListIndicator3']);
    
    const openAudit = (audit) => {
        dispatch({type: 'setValues', value: {view: 'edit', id: audit.id}});
    };

    const indicator1 = state.auditListIndicator1 || 'Caseload VS Prevalence';
    const indicator2 = state.auditListIndicator2 || 'Delivery of CBT for Psychosis';
    const indicator3 = state.auditListIndicator3 || 'Employment/Education';
    const setIndicator = (listId, value) => {
        dispatch({type: 'auditListIndicator', listId: listId, value: value});
    }

    if(resultSetExpected.feedback || resultSetIndicator.feedback){
        return resultSetExpected.feedback || resultSetIndicator.feedback;
    }
    const expectedData = resultSetExpected.data;
    const indicatorData = resultSetIndicator.data;

    const caseloadIndicatorOptions = getCaseloadIndicators(indicatorData, expectedData);
    const caseloadOptions = caseloadIndicatorOptions.map((o)=>o.title);

    const NICEIndicatorOptions = getNICEIndicators(indicatorData);
    const NICEOptions = NICEIndicatorOptions.map((o)=>o.title);

    const outcomeIndicatorOptions = getOutcomeIndicators(indicatorData);
    const outcomeOptions = outcomeIndicatorOptions.map((o)=>o.title);
    
    const tableConfig = {
        team: {
            title: 'Team',
            value: (row) => ( row.group.name ),
            width: 2
        },
        period: {
            title: 'Period',
            value: (row) => ( row.period.name ),
            width: 1,
        },
        // TODO: see if selected title can run on 2 lines and correct width such that the side bar is always 
        // next to table (not overlapping)
        // TODO: store settings of the user in localStorage
        indicatordropdown1:{
            title: <UIInputSelect
                options={caseloadOptions}
                name={'period-select'}
                onChange={(value)=>{setIndicator(1, value)}}
                value={indicator1} />, 
            width: 2,
            value: caseloadIndicatorOptions.find((row)=> row.title === indicator1)?.value || (() => ('-')),
            renderCell: caseloadIndicatorOptions.find((row)=> row.title === indicator1)?.renderCell || (() => ('-'))
        },
        indicatordropdown2:{
            title: <UIInputSelect
                options={NICEOptions}
                name={'period-select'}
                onChange={(value)=>{setIndicator(2, value)}}
                value={indicator2} />,
            width: 2,
            value: NICEIndicatorOptions.find((row)=> row.title === indicator2)?.value || (() => ('')),
            renderCell: NICEIndicatorOptions.find((row)=> row.title === indicator2)?.renderCell || (() => ('-'))
        },
        indicatordropdown3:{
            title: <UIInputSelect
                options={outcomeOptions}
                name={'period-select'}
                onChange={(value)=>{setIndicator(3, value)}}
                value={indicator3} />, // n1 - 25
            width: 2,
            value: outcomeIndicatorOptions.find((row)=> row.title === indicator3)?.value || (() => ('-')),
            renderCell: outcomeIndicatorOptions.find((row)=> row.title === indicator3)?.renderCell || (() => ('-'))
        },
        indicator1:{
            title: 'Delivery of CBT for Psychosis', // indicator: 1, question: n1a - 26
            width: 1,
            value: (row) => (
                getIndicatorPercentage(row.answers, resultSetIndicator.data, 1) 
            ),
            renderCell: (value) => ( 
                <KPIEmoticon value={value} thresholds={getThresholds(resultSetIndicator.data, 1)} /> 
            )
        },
        indicator2:{
            title: 'Delivery of Family Interventions', // indicator 5: question: n2b - 29
            width: 1,
            value: (row) => ( 
                getIndicatorPercentage(row.answers, resultSetIndicator.data, 5) 
            ),
            renderCell: (value) => ( 
                <KPIEmoticon value={value} thresholds={getThresholds(resultSetIndicator.data, 5)} /> 
            )
        },
        indicator3:{
            title: 'Antipsychotics', // indicator 6, question N3 - 30
            width: 1,
            value: (row) => ( 
                getIndicatorPercentage(row.answers, resultSetIndicator.data, 6) 
            ),
            renderCell: (value) => ( 
                <KPIEmoticon value={value} thresholds={getThresholds(resultSetIndicator.data, 6)} /> 
            )
        },
        indicator4:{
            title: 'IPS specialist', // indicator 10, question Na4 - 35
            width: 1,
            value: (row) => ( 
                getIndicatorPercentage(row.answers, resultSetIndicator.data, 10) 
            ),
            renderCell: (value) => ( 
                <KPIEmoticon value={value} thresholds={getThresholds(resultSetIndicator.data, 10)} /> 
            )
        },
        indicator5:{
            title: 'Health checks', // indicator 12, question N5 - 37
            width: 1,
            value: (row) => ( 
                getIndicatorPercentage(row.answers, resultSetIndicator.data, 12) 
            ),
            renderCell: (value) => ( 
                <KPIEmoticon value={value} thresholds={getThresholds(resultSetIndicator.data, 12)} /> 
            )
        },
        progress: {
            title: 'Progress',
            width: 1,
            value: (row) => {
                if(row.step === 0){
                    return 'Not started';
                }else if(row.step <= 8){
                    return Math.round(100 * (row.step-1) / 8) + '%';
                }
                return 'Completed';
            }
        },
        button: {
            title: '',
            width: 1,
            value: (row)=> {
                if(row.step > 8){
                    return 'Completed'
                }
            },
            renderCell: (value)=> getButton(value)
        },
        view: {
            width: 1,
            renderCell: (value, row) => (
                // No click here, this action is already handled by the row click
                <ViewSvg onClick={()=> { /*openAudit(row);*/ }} title="View answers"
                        style={{width: 25, height: 25,cursor: 'pointer', color: styles.primaryColor}}/>
            )
        },
        mail: {
            width: 1,
            renderCell: (value, row) => {
                const emailDetails = {group: row.group.name, respondents: row.respondents};
                return isAdmin?
                    <MailSvg onClick={(e) => { e.stopPropagation(); setEmailModal(emailDetails) }} 
                            style={{width: 25, height: 25,cursor: 'pointer', color: styles.primaryColor}}
                            title={'Send an e-mail to this team'}/>:
                    null;
            }
        }
    };
    
    const config = props.columns.map((column)=>{
        return {
            id: column,
            value: (row) => ( row[column] ),
            renderCell: (value)=> value,
            ...tableConfig[column]
        }
    });

    return <div className="zol-audit-table">
        <UITable inAdmin={props.inAdmin} columns={config} data={props.data} onRowClick={openAudit} />

        {emailModal? 
            <UIModal visible={true} onClose={()=>{setEmailModal(false)}}> 
                <AuditComponentEmailTeam closeModal={()=>setEmailModal(false)} 
                        group={emailModal.group} respondents={emailModal.respondents} />
            </UIModal> :
            null
        }
    </div>;
};

AuditTable.defaultProps = {
    columns: ['team', 'period', 'indicatordropdown1', 'indicatordropdown2', 'indicatordropdown3',
            'progress', 'view', 'mail']
}

AuditTable.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.string),
    data: PropTypes.arrayOf(PropTypes.object).isRequired
}

export {AuditTable}