import React from 'react';
import {UIText} from "../../../../components/ui/text.js";
import {settingsConfig} from "../settings-config.js";
import {UIButtonPaper} from "../../../../components/ui/button/paper.js";
import {useStateContext} from "../../../../helpers/state-context/use_state_context.js";

const ViewSettingsPagesDefault = function (props) {
    const [state] = useStateContext(['renderSize'])
    const size = ['sm', 'md'].includes(state.renderSize) ? 'small' : 'large'
    return <>
        <UIText type={'head-2'} color={'darkColor'}>System Administration</UIText>
        <div style={{display: 'flex', flexWrap: 'wrap'}}>
            {settingsConfig.map((setting, key)=>{
                const width = (size === 'small') ? '100%' : '30%'
                const height = (size === 'small') ? 100 : 200
                return <UIButtonPaper key={key}
                        {...setting}
                        titleProps ={{margin: '0 20px 0 20px'}}
                        descProps = {{margin: '5px 20px 20px 20px'}}
                        onClick={()=>props.setActiveSetting(setting.id)}
                        cardStyle={{width: width,margin: 15, height: height,  display: 'flex'}}
                        titleType={'head-5'}
                />
            })}
        </div>
    </>
}

export {ViewSettingsPagesDefault}