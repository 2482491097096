import React from "react";
import PropTypes from 'prop-types';
import {UIButton} from "../button/button.js";

const UIProgressStepsFooter = function (props) {
    const {onPrevClick, onNextClick, nextText} = props;
    
    return <div className={'zol-stepper-footer'}>
        {(onPrevClick) ?
            <UIButton className={'off-white'} onClick={onPrevClick} style={{marginRight: '20px'}}>
                Previous
            </UIButton> :
            null
        }
        {/* you can always click next, whether we allow the user to continue depends on the outcome of
            props.onNextStep */ }
        <UIButton className="primary" onClick={onNextClick}>
            {nextText}
        </UIButton>
    </div>
};

UIProgressStepsFooter.propTypes = {
    onPrevClick: PropTypes.func,
    onNextClick: PropTypes.func,
    nextText: PropTypes.string
};

export {UIProgressStepsFooter}