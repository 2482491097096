import React from 'react';
import SvgArc from '../../../helpers/svg/arc.js';
import Tooltip from "../../../helpers/elements/tooltip.js";
import {getElementPagePosition} from "../../../helpers/functions/get_element_page_position.js";

const DonutCircleSegment = function (props) {
    const {style, name} = props;

    const onClick = () => {
        if(props.onClick){
            props.onClick(name);
        }
    };

    const getActiveAngle = (percentage)=>{
        if(percentage === 1 && props.totalAngle === 360){
            // 360 is considered equal to 0, which means drawing a segment
            // from 0 to 0 degree and nothing is visible
            return 359.9;
        }
        return percentage * props.totalAngle;
    };

    const onMouseOver = (event)=>{
        props.callback(props.title, true);

        if(props.tooltip){
            const angle = getActiveAngle(props.startAt + 0.5*props.percentage);
            const node = event.currentTarget.parentNode;
            const pos = getElementPagePosition(node);
            const radius = props.center.x;
            pos.left += props.center.x + radius * Math.sin(angle/180*Math.PI);
            pos.top += props.center.y - radius * Math.cos(angle/180*Math.PI);
            let anchor = 'right';
            if(angle < 45 || angle > 315){
                anchor = 'bottom';
            }else if(angle < 135){
                anchor = 'left';
            }else if(angle < 225){
                anchor = 'top';
            }
            const width = node.getBoundingClientRect().width; // get the bounding rectangle
            const top = (pos.top<0)? 10 : pos.top; //to make sure the tooltip stays on the screen
            Tooltip.getInstance().setState({
                position: [pos.left + 0.5*width, top],
                header: props.tooltip.header,
                text: props.tooltip.text,
                anchor: anchor
            });
        }
    };

    const onMouseOut = (event)=>{
        props.callback(props.title, false);
        if(props.tooltip){
            Tooltip.getInstance().setState({});
        }
    };

    let startAngle = props.startAt;
    let endAngle = props.startAt + props.percentage;
    if(props.percentage < 0){
        startAngle = endAngle;
        endAngle = props.startAt;
    }

    const path = SvgArc.getPath({
        x: props.x,
        y: props.y,
        radius: props.radius,
        innerRadius: props.innerRadius,
        a1: getActiveAngle(startAngle),
        a2: getActiveAngle(endAngle)
    });

    let titleElm = '';
    if(props.showTitle){
        titleElm = <title>{props.title}</title>;
    }

    return <path d={path} onClick={onClick} onMouseOver={onMouseOver} onMouseOut={onMouseOut} style={style}>
        {titleElm}
    </path>
};

DonutCircleSegment.defaultProps = {
    totalAngle: 360,
    x: 0,
    y: 0,
    radius: 1,
    innerRadius: 0.55,
    callback: function(){},
    onClick: false,
    title: '',
    name: '',
    tooltip: false,
    showTitle: false,
    style : {
        fill: '#6b91cb',
        stroke: 'white',
        strokeWidth: '0.01px',
        cursor: 'default'
    },
};

export default DonutCircleSegment;
