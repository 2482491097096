import React, {useState} from "react";
import {useStateContext} from "../../../helpers/state-context.js";
import {UIInputSelect} from "../../../components/ui/input/select.js";
import {Card} from "../../../components/card/card.js";
import {CardHeader} from "../../../components/card/header.js";
import {useModelFeedback} from "../../../helpers/model/use_model_feedback.js";
import {ModelDashboardAnswerDistribution} from "../../../model/dashboard/answer-distribution.js";
import {ChartTrend} from "../../../components/chart/trend/trend.js";
import {denominators} from "../../../helpers/functions/denominators.js";
import {NICEIcons} from "../../../helpers/elements/nice-icons.js";
import {ModelAuditIndicator} from "../../../model/audit/indicator.js";
import styles from "../../../assets/variables.scss";

const questionIds= [39, 37, 40, 33, 26, 29, 36];

const DashboardCardNiceProgress = function (props) {
    const [state] = useStateContext(['group']);
    const [questionId, setQuestionId] = useState(39);
    const resultSetIndicator = useModelFeedback(ModelAuditIndicator);
    const resultSetAnswer = useModelFeedback(ModelDashboardAnswerDistribution,
        {
            question_id: questionId,
            periods: '',
            group_id: state.group

        });
    
    const questionOptions = resultSetIndicator.feedback? [] :
        questionIds.map((id)=>({
                id: id,
                title: resultSetIndicator.data.find((row)=> row.fk_field1 === id)?.title,
                denominator: resultSetIndicator.data.find((row)=> row.fk_field1 === id)?.fk_field2 || 0
            })
        );
    
    const resultSetDenominator = useModelFeedback(ModelDashboardAnswerDistribution,
        {
            question_id: questionOptions.find((row)=> row.id === questionId)?.denominator || 0,
            periods: '',
            group_id: state.group
        });
    
    if(resultSetIndicator.feedback ||resultSetAnswer.feedback||resultSetDenominator.feedback){
        return resultSetIndicator.feedback || resultSetAnswer.feedback || resultSetDenominator.feedback;
    }

    let data = [];
    const toPercentage = (period_id) =>{
        const item1 = resultSetAnswer.data.find(row => row.period_id === period_id);
        const value1 = (item1 === undefined) ? 0 : item1.value;
        const item2 = resultSetDenominator.data.find(row => row.period_id === period_id);
        const value2 = (item2 === undefined) ? 0 : item2.value;
        const result = (value1/value2 *100).toFixed()
        return isNaN(result) ? 0 : result
    }
    
    for(let i=0; i<resultSetAnswer.data.length; i++){
        data.push({
            x: resultSetAnswer.data[i].period,
            dot: denominators.find(row => row.id === resultSetAnswer.data[i].id).title,
            description: denominators.find(row => row.id === resultSetAnswer.data[i].id).description,
            y: toPercentage(resultSetAnswer.data[i].period_id)
        })
    }
    
    data.sort((a, b) => (a.x > b.x) ? 1 : -1 );
    
    function getLines(){
        const item = resultSetIndicator.data.find((item)=> item.fk_field1 === questionId);
        if(item?.threshold1){
            return [
                {value: item.threshold1*100, color: styles.brandRedColor},
                {value: item.threshold2*100, color: styles.brandYellowColor},
                {value: item.threshold3*100, color:styles.brandGreenColor}]
        }
        
    }

    const lines = resultSetIndicator.status === 'success'? getLines(): [];

    const getQuestionSelect = () => {
        const options = {}
        questionOptions.forEach((o)=>{
            options[o.id]=o.title;
        });
        
        return <UIInputSelect
            options={options}
            name={'period-select'}
            onChange={(value)=>{setQuestionId(parseInt(value))}}
            value={questionId} />
    };

    function getIcon(){
        const foundIcon = NICEIcons.find((row)=>
            row.ids.includes(questionId)
            );
        if(foundIcon === undefined) {
            return <p>x</p>
            //default icon
        }else {
            return foundIcon.icon
        }
    } 

    const color = () =>{
        const icon = NICEIcons.find((row)=>row.ids.includes(questionId));
        return icon.iconColor;
    }

    const max = Math.max(...data.map((i)=>parseFloat(i.y)));

    const getTitle = function (title){
        return <div style={{width: '100%', display:'inline-flex', alignItems: 'center'}}>
            {getIcon()}
            <p>{title}</p>
            <p>{getQuestionSelect()}</p>
        </div>
    };
    return <Card cardSize={props.cardSize} id={props.id}>
        <CardHeader id={props.id} title={getTitle} style={{height: 35}}/>
        <div style={{height: '100%'}}>
            <div style={{height: 'calc(100% - 30px)'}}>
                <ChartTrend data={data} color={color()} domain={[0, max + ((max / 100) *10)]} lines={lines}/>
            </div>
        </div>
    </Card>
};


DashboardCardNiceProgress.defaultProps = {
    id: 14
};

export {DashboardCardNiceProgress}

