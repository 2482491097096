import React from "react";
import {useStateContext} from "../../../helpers/state-context.js";
import "./export.scss";
import {UIButton} from "../../../components/ui/button/button.js";
import {Card} from "../../../components/card/card.js";
import {FilterGroup} from "../../../components/filter/group.js";

const ViewAdminExportManagerReport = function (props) {
    const [state, dispatch] = useStateContext(['group', 'managerReport']);

    // TODO: name suggests we get a boolean return value. A better name would be openReportWhenPossible, or
    // just openReport
    const isReportPossible = (team) => {
        if(team){
            //open page
            dispatch({type: 'managerReport', value: true});
        }
    };
    
    return <div>
        <h1>Team manager report</h1>
        <Card cardSize={'large'} style={{height: 'auto'}}>
            <h4>Choose a team</h4>
            <div style={{float:'right'}}> 
                <UIButton style={{marginRight: '10px'}} onClick={()=>{isReportPossible(state.group)}}>
                    Open report
                </UIButton>
            </div>
            <FilterGroup onlyTeams={true} optionStyle={{fontSize: '130%'}} style={{marginBottom:'20px'}}/>
        </Card>
            

    </div>
}

export {ViewAdminExportManagerReport}
