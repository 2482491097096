export let getHash = function(input) {
    let hash = 0;
    if (input.length === 0) return hash;
    for (let i = 0; i < input.length; i++) {
        let char = input.charCodeAt(i);
        hash = ((hash << 5) - hash) + char;
        hash = hash & hash; // Convert to 32bit integer
    }

    return ((hash < 0)? 'a': 'b') + Math.abs(hash).toString(36);
}

export let stringify = function(input){
    let result = '';
    if(typeof input == 'undefined' || input == null){
        return result;
    }
    if(typeof input == 'function'){
        result = input.toString();
    }else if(typeof input == 'object'){
        if(input instanceof Array){
            for(let k = 0; k < input.length; k++){
                result += stringify(input[k]);
            }
        }else if(input instanceof Map){
            for(let [k, v] of input){
                result += stringify(k) + ': ' + stringify(v) + ',';
            }
        }else if(input instanceof Set){
            for(let v of input){
                result += stringify(v) + ',';
            }
        }else if(input.constructor && input.constructor !== Object) {
            // do not stringify class instances, but try to get the unique id
            let uid = (input.uid) ? input.uid: '-';
            result += input.constructor.name +'[' + uid + ']';            
        }else{
            for(let k in input){
                result += k + ': ' + stringify(input[k]);
            }
        }
    }else{
        result = input.toString();
    }
    return result;
}

export let getUniqueId = function(length){
    let chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';
    for (let i = length; i > 0; i--){
        result += chars[Math.floor(Math.random() * chars.length)];
    }
    return result;
}