import React, {useState} from "react";
import {PropTypes} from 'prop-types';
import {UIText} from "../../../components/ui/text.js";
import {AuditTable} from './table.js';
import { ModelAuditList } from "../../../model/audit/list.js";
import {useModelFeedback} from "../../../helpers/model/use_model_feedback.js";
import {useModel} from "../../../helpers/model/use_model.js";
import {AuditComponentPeriodSwitch} from './component/period-switch.js';
import {AuditComponentSideBar} from './component/side-bar.js'
import {ModelAuthUser} from '../../../model/auth/user.js';
import {StateContext} from '../../../helpers/state-context.js';

const auditListIndicatorReducer = (state, action) => {
    if(action.type === 'auditListIndicator'){
        if(! action.listId){
            console.error('No list id specified when dispatching action auditListIndicator');
        }
        // this sets multiple values at once
        const nextState = {};
        nextState['auditListIndicator' + action.listId] = action.value;
        localStorage.setItem('auditListIndicator' + action.listId, action.value);
        return {...state, ...nextState};
    }
    // by default return the same object: nothing changes
    return state;
}

const AuditList = function (props) {
    const [switchValue, setSwitchValue] = useState('current');
    const [filter, setFilter] = useState({});
    const groups = filter.groups || [];
    const selectionCriteria = switchValue === 'current'? 
            {period: 'current'}:
            {period: filter.period || 'history', group_id: groups};
    const resultSet = useModelFeedback(ModelAuditList, selectionCriteria);

    const resultAdmin = useModel(ModelAuthUser, {id: 'me'}, (m) => ( m.getIsAdmin() ));
    const isAdmin = resultAdmin.isAdmin;
    if(resultSet.feedback){
        return resultSet.feedback;
    }
    
    const listIncluded = switchValue === 'current'? ['not_started', 'started', 'completed']: ['all'];
    const styleContainer = {
        float: 'left', 
        width: isAdmin? 'calc(75% - 30px)':'calc(100% - 30px)' , 
        paddingRight: 30
    };

    const initialState = {
        auditListIndicator1: localStorage.getItem('auditListIndicator1'),
        auditListIndicator2: localStorage.getItem('auditListIndicator2'),
        auditListIndicator3: localStorage.getItem('auditListIndicator3')
    };
    
    return <div style={{padding: '0 15px'}}>
        <div style={styleContainer}>

            <StateContext initialState={initialState} reducer={auditListIndicatorReducer}>

                <AuditComponentPeriodSwitch value={switchValue} onChange={setSwitchValue} />

                <div style={{width: '100%', marginTop: '30px'}}>

                    {listIncluded.map((type) => {
                        const data = resultSet.data.filter((row) => {
                            if(type === 'all'){
                                return true;
                            }else if(type === 'not_started'){
                                return row.step === 0;
                            }else if(type === 'started'){
                                return row.step > 0 && row.step <= 8;
                            }else if(type === 'completed'){
                                return row.step > 8;
                            }
                            return false;
                        });
                        if(data.length === 0){
                            return null;
                        }
                        
                        return <div style={{width: '100%'}} key={type}>
                            <UIText type={'body-L-bold'} color={'darkColor'}>
                                {type==='not_started'? 'Not started (' + data.length + ')': null}
                                {type==='started'? 'Started (' + data.length + ')': null}
                                {type==='completed'? 'Completed (' + data.length + ')': null}
                                {type==='all'? 'Audits (' + data.length + ')': null}
                            </UIText>
                            <AuditTable data={data} inAdmin={isAdmin}/>
                        </div>;
                    })}

                    {resultSet.data.length === 0? 
                        <UIText type={'body-L-bold'} color={'darkColor'}>
                            There are no audits
                        </UIText>:
                        null
                    }
                
                </div>
            </StateContext>
        </div>
        {isAdmin?
            <AuditComponentSideBar onFilterChange={setFilter} 
                filter={filter}
                showProgress={switchValue==='current'} 
                showFilters={switchValue==='history'} />:
            null
        }
    </div>;
};

AuditList.defaultProps = {
    period: 'all'
}

AuditList.propTypes = {
    period: PropTypes.oneOf(['current', 'history', 'all'])
}

export {AuditList}