
const getSimpleReducer = (keys) => (
    (state, action) => {
        if(action.type === 'setValues'){
            // this sets multiple values at once
            const nextState = {...state};
            for(const key of Object.keys(action.value)){
                if(keys.includes(key)){
                    nextState[key] = action.value[key];
                }
            }
            return nextState;
        }
        if(keys.indexOf(action.type) !== -1){
            // if something changes, always create a new object
            const nextState = {};
            nextState[action.type] = action.value;
            return {
                ...state, 
                ...nextState
            };
        }
        // by default return the same object: nothing changes
        return state;
    }
)

export {getSimpleReducer};