import {config} from '../../config.js';
import {ModelXhr} from '../../helpers/model/xhr.js';

class ModelAuditAnswer extends ModelXhr {

    static modelName = "ModelAuditAnswer";

    static currentValues = null;

    static getSelect(input) {
        return {};
    }

    static updateItem(id, data, onSuccess=()=>{}){
        this.saveData(ModelAuditAnswer, config.api_endpoint + 'audit_answer/' + id, 'PATCH', 
            data, 
            (response) => {
                if(response.success){
                    const answer = response.result? (response.result[0] || null): null;
                    onSuccess(answer);
                }else{
                    // todo: post status to global message channel
                    console.error(response.error || "Unknow error");
                }
            }
        );
    }
    
    static getCurrentValues(){
        if(! ModelAuditAnswer.currentValues){
            return null;
        }
        const currentValues = {};
        for(const id of Object.keys(ModelAuditAnswer.currentValues)){
            currentValues[id] = ModelAuditAnswer.currentValues[id].answer;
        }
        return currentValues;
    }

    static saveAnswers(changedValues){
        if(ModelAuditAnswer.currentValues === null || this.data === null){
            console.error("ModelAuditAnswer: can't save values if the audit answer data is not yet loaded");
            return false;
        }
        for(const id of Object.keys(changedValues)){
            const q = ModelAuditAnswer.currentValues[id];

            // Saving without providing feedback to the user
            ModelAuditAnswer.updateItem(q.answer_id, {answer: changedValues[id]});
        }
    }

    constructor(selectData) {
        super(selectData, {
            endpoint: config.api_endpoint + 'audit_answer',
            method: 'GET'
        });

        this.cacheEnabled = true;
    }

   
    getSelect(input) {
        return ModelAuditAnswer.getSelect(input);
    }

}

export {ModelAuditAnswer};