import React from "react";
import {PropTypes} from 'prop-types';
import styles from "../../../../assets/variables.scss";
import {ReactComponent as IconEdit} from "../../../../assets/icons/edit.svg";
import {ReactComponent as IconDelete} from "../../../../assets/icons/delete.svg";
import {ReactComponent as IconPlus} from "../../../../assets/icons/plus.svg";
import { useModelFeedback } from "../../../../helpers/model/use_model_feedback.js";
import {UITable} from '../../../../components/ui/table/table.js';
import { useStateContext } from "../../../../helpers/state-context";
import {UIButton} from '../../../../components/ui/button/button.js';
import { ModelAuditIndicator } from "../../../../model/audit/indicator.js";
import {ViewSettingsPageHeader} from "../page/header.js";

const styleIconEdit = {
    cssFloat: 'right', 
    width: 25, 
    height: 25,
    cursor: 'pointer', 
    color: styles.primaryColor
};
const styleIconDelete = {
    ...styleIconEdit,
    marginLeft: '20px'
};

const styleBtnNew = {
    display: 'flex',
    alignItems: 'center',
    padding: '15px 15px',
    backgroundColor: '#e2e7fc',
    color: styles.primaryColor,
    cssFloat: 'right'
};

const IndicatorList = function (props) {
    const resultSet = useModelFeedback(ModelAuditIndicator, {category: props.section.toLowerCase()});
    const [, dispatch] = useStateContext(['view', 'id']);

    const editItem = (indicatorId) => {
        if(! indicatorId){
            dispatch({type: 'setValues', value: {view: 'new', id: null}});    
        }else{
            dispatch({type: 'setValues', value: {view: 'edit', id: indicatorId}});
        }
    };

    const deleteItem = (event, indicatorId) => {
        if(window.confirm("You are about to permenantly delete an indicator. The data stored in the audit " +
                "is not impacted by this action, but visualizations that use this indicator will brake. " +
                "Do you really want to delete this indicator?")){
            ModelAuditIndicator.deleteItem(indicatorId);
        }
        event.stopPropagation();
    }
    
    const tableConfig = {
        title: {
            title: 'Indicator',
            value: (row) => ( row.title ),
            width: 3
        },
        type: {
            title: 'Type',
            value: (row) => ( row.type ),
            width: 1
        },
        edit: {
            width: 1,
            renderCell: (value, row) => ( <>
                <IconDelete onClick={(e)=> { deleteItem(e, row.id); }} 
                        style={styleIconDelete}/>
                <IconEdit onClick={()=> { editItem(row.id); }} 
                        style={styleIconEdit}/>
            </>)
        }
    };

    const config = Object.keys(tableConfig).map((column)=>{
        return {
            id: column,
            value: (row) => ( row[column] ),
            renderCell: (value)=> value,
            ...tableConfig[column],
        };
    });



    return <div style={{float: 'left', width: 'calc(75% - 30px)', paddingRight: 30}}>
        <ViewSettingsPageHeader {...props}/>
        <UIButton style={styleBtnNew} onClick={() => {editItem() }}>
            <IconPlus style={{marginRight: 5}}/>
            New indicator
        </UIButton>

        {resultSet.feedback?
            resultSet.feedback:
            <UITable columns={config} data={resultSet.data} 
                    onRowClick={(row) => {editItem(row.id)}} />
        }

    </div>;
}

IndicatorList.propTypes = {
    section: PropTypes.string.isRequired
};

export {IndicatorList};