import React from 'react';
import {getColorPalette, getSectionPalette} from "../../../helpers/color.js";
import {extend} from "../../../helpers/functions/extend.js";

//TODO: legend sometimes 'falls off' the card when the donut is too big or the card is too small

const styleLegendHeader = {
    fontSize: '1.2em',
    borderBottom: '1px solid #c4c4c4',
    margin: '0px 0px 10px 0px',
    
};

const styleLegendItem = {
    padding: '4px 0px'
};


const styleLegendBox = {
    display: 'inline-block',
    verticalAlign: 'top',
    width: '15px',
    height: '15px',
    backgroundColor: 'gray',
    marginRight: '5px'
};

const styleLegendText = {
    display: 'inline-block',
    width: 'calc(100% - 20px)'
};

const DonutLegend = function (props) {

    const showTitle = () => {
        if (props.metrics.length === 1) {
            return <h3 style={styleLegendHeader}>{getMetricTitle(props.metrics)}</h3>
        } else {
            const menu = <select value={props.state.metric} style={{width: '80%'}}
                                 onChange={(e) => props.setMetric(e.target.value.toLowerCase())}>
                {props.metrics.map((item) => <option key={item} value={getMetricTitle(item)}>{item}</option>)}
            </select>;
            return <span style={{fontSize: 16, marginBottom: 15}}>By {menu}</span>
        }

    };

    const getMetricTitle =(metric) =>{
        return props.metricTitles[metric] ? props.metricTitles[metric] : metric;
    }
    const items = [];
    let count = 0;
    const characters = props.showWholeLegend? 100 : Math.floor(38 * props.state.widthFact * props.state.width / 200);
    const {paletteColor} = props;
    const colors = (paletteColor) ? getSectionPalette(paletteColor, props.data.length) : getColorPalette(props.data.length);

    for (const item of props.data) {
        count++;
        const opacity = props.state.hover && props.state.hover !== item.description ? 0.5 : 1;
        const title = (item.description && item.description.length > 40) ? {title: item.description} : {};
        items.push(<div key={item.description}
                        style={extend({
                            opacity: opacity,
                            cursor: props.onSegmentSelect ? 'pointer' : 'default'
                        }, styleLegendItem)}
                        onClick={() => {
                            if(props.onSegmentSelect){
                                if(props.hoverFixed){
                                    props.onSegmentSelect({disableFilter: true});
                                }else{
                                    props.onSegmentSelect(item.id || item.description);
                                }
                                props.onSegmentHover(item.description, true)
                            }
                        }}
                        onMouseOver={(event) => props.onSegmentHover(item.description, true)}
                        onMouseOut={(event) => props.onSegmentHover(item.description, false)}>
                <span
                    style={extend({backgroundColor: item.color || (paletteColor) ? colors[count - 1] : 'rgb(' + colors[count - 1].join(',') + ')'}, styleLegendBox)}/>
            <span style={styleLegendText} {...title}>
                    {(item.description) ? item.description.length > (characters + 4) ? item.description.substring(0, characters) + '...' : item.description : 'Item not found'}
                </span>
        </div>);
        if (count === 8 && props.data.length > 8) {
            items.push(<p key="more" style={{margin: '3px 0px'}}>And {props.data.length - count} more...</p>);
            break;
        }
    }

    return <div className={'legend-container'}>
        {showTitle()}
        {items.map((i) => i)}
    </div>;
};

export {DonutLegend}