import React from "react";
import {UIText} from "../../../components/ui/text.js";
import {FormElement} from "../../../helpers/form/element.js";
import {useStateContext} from "../../../helpers/state-context.js";
import {UIButton} from "../../../components/ui/button/button.js";
import {UILinkInternal} from "../../../components/ui/link/internal.js";
import { ModelAuthToken } from "../../../model/auth/token.js";
import { useForm } from '../../../helpers/form/use_form.js';
import { UIInput } from '../../../components/ui/input/input.js';
import { UIInputPassword } from '../../../components/ui/input/password.js';

const ViewAuthSignIn = function (props) {

    const Form = useForm(props.loginForm);
    const [, dispatch] = useStateContext([]);

    const submitForm = () => {
        if(Form.validate()){
            const onSuccess = () => {
                dispatch({type: 'page', value: 'dashboard'});
            }   
            const onFailure = (error) => {
                Form.setError('password', error);
            }
            ModelAuthToken.createItem(
                    Form.getValue('email'), Form.getValue('password'), onSuccess, onFailure);
        }
    }

    // TODO: maybe we can introduce a decorator like UIContentSmall, UIContentFull, etc
    return <div style={{display: 'flex', alignItems: 'center', height: '100%', paddingTop: '71px'}}>
        
        <div style={{maxWidth: 400, margin: 'auto', width: 400, padding: '0 15px'}}>
            
            <UIText type={'head-1'} color={'darkColor'} style={{marginBottom: 10}}>
                Sign in
            </UIText>
            
            <UIText type={'body-L'} color={'mediumColor'}>
                Please enter your credentials to proceed
            </UIText>

            <div style={{margin: '25px 0'}}>

                <Form.Component>
                    {props.loginForm.map((item) => (
                        <FormElement key={item.name} {...item} onEnter={submitForm} />
                    ))}
                </Form.Component>

                <UILinkInternal page="forgot-password">Forgot password?</UILinkInternal>
                
            </div>

            <UIButton className="primary" style={{width: '100%'}} onClick={submitForm}>
                Sign in
            </UIButton>

            <UIText type={'body-L'} color={'mediumColor'} style={{padding: 10, textAlign: 'center'}}>
                Don't have an account yet?&nbsp;
                <UILinkInternal page="sign-up">Sign up &gt;</UILinkInternal>
            </UIText>

        </div>
    </div>
};

ViewAuthSignIn.defaultProps = {
    loginForm: [{
        title: 'Email address',
        name: 'email',
        element: UIInput,
        type: 'wide'
    },
    {
        title: 'Password',
        name: 'password',
        element: UIInputPassword,
        type: 'wide'
    }]
}

export {ViewAuthSignIn}