import React, {useEffect} from "react";
import {Axis, axisPropsFromTickScale, BOTTOM, LEFT} from "react-d3-axis";
import PropTypes from "prop-types";

const TrendAxis = function (props) {
    const {data, axis, width, height} = props;

    useEffect(()=>{
        // Add horizontal lines to axis over full width
        const horizontalLines = document.querySelectorAll('.zol-chart-trend .y-axis g line');
        for(const line of horizontalLines){
            line.setAttribute('x1',width + 6)
        }
        // Position horizontal texts to axis
        const horizontalTexts = document.querySelectorAll('.zol-chart-trend .y-axis g text');
        for(const text of horizontalTexts){
            text.setAttribute('x','-25')
        }

        // Add vertical lines to axis over full height
        const verticalLines = document.querySelectorAll('.zol-chart-trend .x-axis g line');
        for(const line of verticalLines){
            line.setAttribute('y2',`${-height}`)
        }
    }, [width, height, data, axis])

    const xAxisData = data.map(i=>i.x);
    return <>
        <g className={'y-axis'} transform={`translate(-5, 0)`}>
            <Axis {...axisPropsFromTickScale(axis.y, 6)} format={(d) => `${d}%`}
                  style={{orient: LEFT, tickFontSize: 16}}/>
        </g>
        <g className={'x-axis'} transform={`translate(0, ${height})`}>
            <Axis values={xAxisData}
                  position={(d)=>{
                          const percentage  = (xAxisData.indexOf(d)) / (xAxisData.length -1)
                          return percentage * width;
                  }}
                  range={[0, width]}
                  format={(d)=>d}
                  style={{orient: BOTTOM, tickFontSize: 16}}/>
        </g>
    </>
};

TrendAxis.propTypes = {
    data: PropTypes.array,
    axis:  PropTypes.object,
    width: PropTypes.number,
    height: PropTypes.number,
};

export {TrendAxis}