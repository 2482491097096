import './side-bar.scss';
import React, {useState} from "react";
import {useStateContext} from "../../helpers/state-context/use_state_context.js";
import {ReactComponent as SettingsSvg } from '../../assets/icons/settings.svg'
import {ReactComponent as UsersSvg } from '../../assets/icons/users.svg'
import {ReactComponent as OrganizationSvg } from '../../assets/icons/organization.svg';
import {ReactComponent as AuditSvg } from '../../assets/icons/audits.svg';
import {ReactComponent as ExportSVG } from '../../assets/icons/export.svg';
import {ReactComponent as TrendSvg } from '../../assets/icons/trending-up-outline.svg';
import {ReactComponent as CubeSvg } from '../../assets/icons/cube-outline.svg';
import {ReactComponent as PieSvg } from '../../assets/icons/pie-chart-outline.svg';
import {ReactComponent as SmileSvg } from '../../assets/icons/smiling-face-outline.svg';
import {roles} from '../../config.js';
import {AuthAccessValidator} from '../auth/access-validator.js';
import {PropTypes} from 'prop-types';
import {useModelFeedback} from "../../helpers/model/use_model_feedback.js";
import {ModelAuthUserList} from "../../model/auth/user-list.js";
import {AuthWithUser} from "../auth/with-user.js";
import {ModelParagraph} from '../../model/admin/paragraph.js';

const iconStyle={
    marginRight: '5px'
}

const SideBar = function (props) {
    const [open, setOpen] = useState(false)
    const [state, dispatch] = useStateContext();
    const resultSetText = useModelFeedback(ModelParagraph);
    const resultSetUsers = useModelFeedback(ModelAuthUserList, {status: 'PENDING'});

    if(resultSetText.feedback){
        return resultSetText.feedback;
    }

    if(resultSetUsers.feedback){
        return resultSetUsers.feedback;
    }
  
    let items = props.items? props.items : props.inAdmin? props.itemsAdmin : props.itemsDashboard;
    return <aside className={`zol-side-bar ${(open) ? 'zol-side-bar-open' : 'zol-side-bar-close'}`}
            onMouseEnter={()=>setOpen(true)}
            onMouseLeave={()=>setOpen(false)}>
        {items.map((item)=>{
            const notification = (item.id === 'users' && resultSetUsers.data.length)?
                    <span className="dot"></span> :
                    '';
            const titleItem = resultSetText.data.find((itemText)=>{
                if((item.id === 'personal-dashboard' && itemText.type === 'dashboard_header') ||
                    (item.id === 'nice' && itemText.type === 'nice_header') ||
                    (item.id === 'nice' && itemText.type === 'nice_header') ||
                    ( item.id === 'outcomes' && itemText.type === 'outcome_header') ||
                    ( item.id === 'recommendations' && itemText.type === 'recommendations_header')){
                    return itemText
                }
                return null
            });
            const title = (titleItem !== undefined && titleItem !== null) ?  titleItem.text :  item.title;
            return <AuthAccessValidator key={item.id} validateUser={item.validation} showError={false}>
                <div className={`zol-side-bar-item`}
                        onClick={()=>{
                            dispatch({type: props.stateProperty, value: item.id})
                        }}>
                    {item.icon}
                    {(open) ?
                            title:
                            null
                    }
                    {notification}
                </div>
            </AuthAccessValidator>
        })}
        <AuthWithUser enableFeedback={false}>
            {(user) => {
                if(user === null){
                    return null;
                }
                return <div className={`zol-side-bar-section`}>
                    {props.navigationItems.map((item)=>{
                        const title = (item.id === 'admin')? 
                            (user.roles.includes(1)? 'Admin': item.title):
                            item.title;
                        const className = 'zol-side-bar-item' + 
                                (state.page === item.id ? ' active-section': '');
                        return <div key={item.id} className={className}
                                onClick={()=>{
                                    dispatch({type: 'page', value: item.id});
                                }}>
                            {(open) ?
                                title:
                                null
                            }
                        </div>
                    })}
                </div>    
            }}
        </AuthWithUser>    
    </aside>
};

SideBar.propTypes = {
    inAdmin: PropTypes.bool
};

SideBar.defaultProps = {
    inAdmin: false,
    width: 80,
    stateProperty: 'section',
    itemsAdmin:[
        {
            icon: <AuditSvg style={iconStyle}/>,
            id: 'audit',
            title: 'Audits'
        },
        {
            icon: <ExportSVG style={iconStyle}/>,
            id: 'export',
            title: 'Export report'
        },
        {
            icon: <UsersSvg style={iconStyle}/>,
            id: 'users',
            title: 'Users',
            validation: (user) => ( user.roles.includes(roles.admin) )
        },
        {
            icon: <OrganizationSvg style={iconStyle}/>,
            id: 'organization',
            title: 'Organization',
            validation: (user) => ( user.roles.includes(roles.admin) )
        },
        {
            icon: <SettingsSvg style={iconStyle}/>,
            id: 'settings',
            title: 'Settings',
            validation: (user) => ( user.roles.includes(roles.admin) )
        }
    ],
    itemsDashboard:[
        {
            icon: <PieSvg style={iconStyle}/>,
            id: 'personal-dashboard',
            title: 'Dashboard'
        },
        {
            icon: <CubeSvg style={iconStyle}/>,
            id: 'nice',
            title: 'NICE'
        },
        {
            icon: <TrendSvg style={iconStyle}/>,
            id: 'outcomes',
            title: 'Outcomes and Performance'
        },
        {
            icon: <SmileSvg style={iconStyle}/>,
            id: 'recommendations',
            title: 'Recommendations'
        }
    ],
    navigationItems:[
        {
            id: 'dashboard',
            title: 'Dashboard'
        },{
            id: 'admin',
            title: 'Audits'
        }
    ]
};

export {SideBar}