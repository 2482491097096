import React from 'react';
import {useModelFeedback} from '../../../helpers/model/use_model_feedback.js';
import {ModelOrgHierarchy} from '../../../model/org-hierarchy.js';
import {ModelAuthUser} from '../../../model/auth/user.js';
import {CardHeader} from '../../../components/card/header.js';
import {Card} from '../../../components/card/card.js';
import {offWhiteColor, brandPurpleColor} from '../../../assets/variables.scss';
import { useOrderedGroups } from '../../../helpers/model/use_ordered_groups.js';

const CardTeamNamesList = (props) => {
    const resultSet = useModelFeedback(
            ModelOrgHierarchy,
            {select: 'provider, provider_id, team, team_id', order: 'provider asc'},
            (m) => ( m.getRawData() )
    );
    const resultSetUser = useModelFeedback(ModelAuthUser, {id: 'me'});  
    
    const resultSetOrdered = useOrderedGroups();

    const options = {};

    if(resultSet.feedback){
        return resultSet.feedback;
    }
    if(resultSetUser.feedback){
        return resultSetUser.feedback;
    }

    const userGroups = resultSetUser.data[0].groups;
    // because the API can only sort by one field, we need to sort on teams here
    const data = resultSet.data.slice(0).sort((a, b) => (
        a.provider + a.team < b.provider + b.team? -1: 1
    ));

    for(const item of data){
        // if(userGroups.includes(item.provider_id)){
            // add an underscore to the key to make sure the order remains (object with numeric keys get
            // reordered)
            options['_'+resultSetOrdered[item.provider_id]] = <strong>{options['_'+resultSetOrdered[item.provider_id]] || item.provider}</strong>;
        // }
        // if(userGroups.includes(item.team_id)){
            options['_'+resultSetOrdered[item.team_id]] = <span style={{marginLeft: '15px'}}>{item.team}</span>;
        // }
    };

    const circleStyle = {
        display: 'inline-block',
        borderRadius:"100%",
        textAlign: 'center',
        marginRight: "15px",
        width: "16px",
        height: '16px',
        lineHeight: "16px",
        fontSize: '9px',
        backgroundColor: offWhiteColor
    };

    const styleLine = {
        display: 'flex', 
        marginBottom: 10, 
        breakInside: 'avoid',
        fontSize: '12px',
        lineHeight: '16px'
    };

    function getKeyByValue(object, value){
        return Object.keys(object).find(key => object[key] === value) || 
                Object.keys(object).find(key => object[key] === parseInt(value)) ;
    }

    return  <Card id={'team_list'} cardSize={'xl'} style={{float: 'left'}} className="zol-card-auto-height">
        <CardHeader title={'Providers / Teams'}/>
        <div style={{columnCount: 3}}>
            {Object.keys(options).map((option, key)=>{
                const groupId = getKeyByValue(resultSetOrdered, option.substring(1));
                const styleCircle = {
                    ...circleStyle,
                    backgroundColor: userGroups.includes(parseInt(groupId))? 
                            brandPurpleColor: offWhiteColor,
                    color: userGroups.includes(parseInt(groupId))? 'white': 'black'
                }
                return <div key={key} style={styleLine}>
                    <span style={styleCircle}>
                        {option.substring(1)}
                    </span>
                    {options[option]}
                </div>
            })}
        </div>
    </Card>
};

export {CardTeamNamesList}