import React from "react";
import './card.scss';
import {useStateContext} from "../../../helpers/state-context.js";
import {useModelFeedback} from "../../../helpers/model/use_model_feedback.js";
import { ModelCardsCollection } from "../../../model/dashboard/cards-collection";
import {UIButton} from "../../../components/ui/button/button.js";
import {ConfigureSelectCards} from "./select-cards.js";

const buttonStyle={
    float: 'right', 
    display: 'inline-block'
};

const ViewDashboardConfigure = function (props) {
    const [, dispatch] = useStateContext(['page', 'renderSize']);
    const resultSet = useModelFeedback(ModelCardsCollection, {id: 'me'});
    if(resultSet.feedback){
        return resultSet.feedback;
    }
    const order = resultSet.order;

    const amount = []
    amount["small"] = 4;
    amount["medium"] = 2;
    amount["large"] = 1;



    return <div>
        <div style={buttonStyle}>
            {/* We'll only have a done button, changes will be saved directly when you make them, there
                won't be a difference between cancel and done */ }
            <UIButton onClick={()=>{dispatch({type: 'section', value: 'personal-dashboard'})}}>
                Done
            </UIButton>
        </div>
        <h1>Customise my dashboard</h1>
        <p>
            Click on a card to add/remove it from your personal dashboard. Use the arrows on the right set 
            your preferred order. You can select 4 small, 2 medium and 1 large card.
        </p>
        
        <div className= "zol-dashboard-settings">
            {order.map((item) => (
                <ConfigureSelectCards key={item} type={item} amount={amount[item]}/>
            ))}
        </div>
    </div>

}

export {ViewDashboardConfigure}