import {isMax, isMin, isNumber} from "../../../helpers/form/validation.js";
import {UIInput} from '../../../components/ui/input/input.js';
import {UIInputSelect} from '../../../components/ui/input/select.js';

const formatQuestions = function(questions, modelData, previousAnswers, depth=0){
    const elements = [];
    for(const element of questions){
        const data = modelData[element.id];
        const previousData = previousAnswers ? previousAnswers[element.id] : null;
        const lastAnswer = previousData? previousData.answer : null;
        let insert = {};
        if(data){
            insert = {
                title: (depth === 0? data.question_number + ': ': '') + data.question_title,
                description: data.question_description,
                value: data.answer,
                warning: (lastAnswer === null || lastAnswer === 0 || lastAnswer !== data.answer)? false: true
            };
        }
        elements.push({
            ...element,
            ...insert,
            name: 'q' + element.id,
            children: element.children? 
                    formatQuestions(element.children, modelData, depth + 1): false
        });
    }
   
    return elements;
}

const getFormElements = function(steps, modelData){
    const elements = [];
    for(const step of steps){
        if(step.content){
            elements.push(...formatQuestions(step.content, modelData))
        }
    }
    return elements;
}


const checkPositiveNumber = [
    {
        checkValue: isNumber,
        message: 'This is not a valid number'
    }, {
        checkValue: (value) => isMin(value, 0),
        message: 'Negative values are not allowed'
    },
];

const equalToCaseload = [
    ...checkPositiveNumber,
    {
        // all inputs from 21 together (22, 23, 24) should be equal to 5
        checkValue: (value, values) => isMax(value, values.q5) && isMin(value, values.q5),
        message: 'The sum of these values must be equal to the value from question Q4b'
    }
];

const lessThanCaseload = [
    ...checkPositiveNumber,
    {
        // all inputs from 21 together (22, 23, 24) should be equal to 5
        checkValue: (value, values) => isMax(value, values.q5),
        message: 'This value can not exeed the caseload value from question Q4b'
    }
];

const getTotal = function(values, keys){
    let total = 0;
    for(const key of keys){
        if(values[key]){
            total += parseInt(values[key]);
        }
    }
    return total;
}

// This config contains the default configuration of the audit.
// If there is any data in the model it will be updated in ./audit.js
const auditStepsConfig = [
    {
        id: 1,
        title: 'Part 1: About your EIP team',
        content: [
            {
                id: 1,
                element: UIInputSelect,
                type: 'inline',
                required: true,
                options: {
                    1: 'Stand-Alone Model',
                    2: 'Hub and Spoke Model',
                    3: `Integrated Model i.e Integrated with a Community Mental Health Team (CMHT) or Child
                        and Adolescent Mental Health Service (CAMHS)`
                },
                placeholder: 'Select option'
            },
            {
                id: 2,
                type: 'inline',
                element: UIInput,
                required: true,
                validations: checkPositiveNumber
            },
            {
                id: 3,
                type: 'inline',
                element: UIInput,
                required: true,
                validations: checkPositiveNumber
            },
        ]
    },

    {
        id: 2,
        title: 'Part 2: About your EIP team',
        content: [
            {
                id: 4,
                type: 'inline',
                element: UIInput,
                required: true,
                validations: checkPositiveNumber
            },
            {
                id: 5,
                type: 'wide',
                element: false,
                columns: 3,
                required: true,
                processValue: (value, values) => ( getTotal(values, ['q6','q84', 'q7','q8']) ),
                validations: checkPositiveNumber,
                children: [
                    ...[6, 84, 7,8].map(id => ({
                        id: id,
                        element: UIInput,
                        type: 'wide',
                        processValue: (value) => (value || 0),
                        validations: checkPositiveNumber
                    }))
                ]
            },
            {
                id: 9,
                type: 'wide',
                element: false,
                columns: 3,
                required: true,
                processValue: (value, values) => ( 
                    getTotal(values, ['q10','q11','q12','q13','q14','q15','q16','q17','q18','q107']) 
                ),
                validations: equalToCaseload,
                children: [
                    ...[10,11,12,13,14,15,16,17,18,107].map(id => ({
                        id: id,
                        element: UIInput,
                        type: 'wide',
                        processValue: (value) => (value || 0),
                        validations: checkPositiveNumber
                    }))
                ]
            },
            {
                id: 19,
                element: UIInputSelect,
                type: 'inline',
                required: true,
                options: {
                    1: 'Yes', 
                    2: 'No', 
                    3: 'Not sure'
                },
                placeholder: 'Select option'
            },
            {
                id: 20,
                element: UIInputSelect,
                type: 'inline',
                options: {
                    1: 'Stand-Alone ARMS team', 
                    2: 'Integrated ARMS team'
                },
                placeholder: 'Select option',
                conditions: [
                    (values) => ( parseInt(values.q19, 10) === 1 )
                ],
                validations: [
                    {
                        checkValue: (value, values) => ( values.q19 !== 1 || !! value ),
                        message: "Please select the type of ARMS used"
                    }
                ]
            },
            {
                id: 21,
                type: 'wide',
                columns: 3,
                required: true,
                element: false,
                processValue: (value, values) => ( getTotal(values, ['q22', 'q85', 'q23','q24']) ),
                children: [
                    ...[22, 85,23,24].map(id => ({
                        id: id,
                        element: UIInput,
                        type: 'wide',
                        processValue: (value) => (value || 0),
                        validations: checkPositiveNumber
                    }))
                ]
            },
        ]
    },
    {
        id: 3,
        title: 'Part 1: NICE concordance',
        content: [
            {
                id: 25,
                type: 'inline',
                element: UIInput,
                validations: lessThanCaseload,
            },
            {
                id: 26,
                type: 'inline',
                element: UIInput,
                conditions: [
                    // enable when answer of 25 is more than 0
                    (values) => ( values.q25 && parseInt(values.q25, 10) > 0 )
                ],
                validations: [
                    ...checkPositiveNumber,
                    {
                        checkValue: (value, values) => ( isMax(value, values.q25)),
                        message: "This value can not exeed the value from question N1"
                    }
                ]
            },
            {
                id: 27,
                type: 'inline',
                element: UIInput,
                validations: lessThanCaseload
            },
            {
                id: 28,
                type: 'inline',
                element: UIInput,
                conditions: [
                    (values) => ( values.q27 && parseInt(values.q27, 10) !== 0 )
                ],
                validations: [
                    ...checkPositiveNumber,
                    {
                        checkValue: (value, values) => ( isMax(value, values.q27) ),
                        message: "This value cannot exeed the value from question N2"
                    }
                ]
            },
            {
                id: 29,
                type: 'inline',
                element: UIInput,
                conditions: [
                    (values) => ( values.q28 && parseInt(values.q28, 10) !== 0 )
                ],
                validations: [
                    ...checkPositiveNumber,
                    {
                        checkValue: (value, values) => ( isMax(value, values.q28) ),
                        message: "This value cannot exeed the value from question N2a"
                    }
                ]
            },
            {
                id: 30,
                type: 'inline',
                element: UIInput,
                validations: lessThanCaseload
            },
            {
                id: 31,
                type: 'inline',
                element: UIInput,
                conditions: [
                    (values) => ( values.q30 && parseInt(values.q30, 10) !== 0 )
                ],
                validations: [
                    ...checkPositiveNumber,
                    {
                        checkValue: (value, values) => ( isMax(value, values.q30) ),
                        message: "This value cannot exeed the value from question N3"
                    }
                ]
            },
            {
                id: 32,
                element: UIInputSelect,
                type: 'inline',
                options: {
                    1: 'Yes', 
                    2: 'No', 
                    3: 'Not Sure'
                },
                placeholder: 'Select option',
                conditions: [
                    (values) => ( values.q31 && parseInt(values.q31, 10) !== 0 )
                ],
                validations: []
            },
            {
                id: 33,
                type: 'inline',
                element: UIInput,
                conditions: [
                    (values) => ( values.q30 && parseInt(values.q30, 10) !== 0 )
                ],
                validations: [
                    ...checkPositiveNumber,
                    {
                        checkValue: (value, values) => ( isMax(value, values.q30) ),
                        message: "This value cannot exeed the value from question N3"
                    }
                ]
            }
        ]
    },
    {
        id: 4,
        title: 'Part 2: NICE concordance',
        content: [
            {
                id: 34,
                type: 'inline',
                element: UIInput,
                validations: lessThanCaseload
            },
            {
                id: 35,
                type: 'inline',
                element: UIInput,
                conditions: [
                    (values) => ( values.q34 && parseInt(values.q34, 10) !== 0 )
                ],
                validations: [
                    ...checkPositiveNumber,
                    {
                        checkValue: (value, values) => ( isMax(value, values.q34) ),
                        message: "This value cannot exeed the value from question N4"
                    }
                ]
            },
            {
                id: 36,
                type: 'inline',
                element: UIInput,
                conditions: [
                    (values) => ( values.q34 && parseInt(values.q34, 10) !== 0 )
                ],
                validations: [
                    ...checkPositiveNumber,
                    {
                        checkValue: (value, values) => ( isMax(value, values.q34) ),
                        message: "This value cannot exeed the value from question N4"
                    }
                ]
            },
            {
                id: 37,
                type: 'inline',
                element: UIInput,
                validations: lessThanCaseload
            },
            {
                id: 38, 
                type: 'inline',
                element: UIInput,
                validations: lessThanCaseload
            },
            {
                id: 39,
                type: 'inline',
                element: UIInput,
                conditions: [
                    (values) => ( values.q38 && parseInt(values.q38, 10) !== 0 )
                ],
                validations: [
                    ...checkPositiveNumber,
                    {
                        checkValue: (value, values) => ( isMax(value, values.q38) ),
                        message: "This value cannot exeed the value from question N6"
                    }
                ]
            },
        ]
    },
    {
        id: 5,
        title: 'NICE concordance - Carers',
        content: [
            {
                id: 40, 
                type: 'inline',
                element: UIInput,
                validations: lessThanCaseload
            },
            {
                id: 41, 
                type: 'inline',
                element: UIInput,
                validations: lessThanCaseload
            }
        ]
    },
    {
        id: 6,
        title: 'Outcomes',
        content: [
            {
                id: 43, 
                type: 'inline',
                element: UIInput,
                validations: lessThanCaseload
            },
            {
                id: 44, 
                type: 'inline',
                element: UIInput,
                conditions: [
                    (values) => ( values.q43 && parseInt(values.q43, 10) !== 0 )
                ],
                validations: [
                    ...checkPositiveNumber,
                    {
                        checkValue: (value, values) => ( isMax(value, values.q43) ),
                        message: "This value cannot exeed the value from question O1"
                    }
                ]
            },
            {
                id: 45, 
                type: 'inline',
                element: UIInput,
                validations: lessThanCaseload
            },
            {
                id: 46, 
                type: 'inline',
                element: UIInput,
                conditions: [
                    (values) => ( values.q45 && parseInt(values.q45, 10) !== 0 )
                ],
                validations: [
                    ...checkPositiveNumber,
                    {
                        checkValue: (value, values) => ( isMax(value, values.q45) ),
                        message: "This value cannot exeed the value from question O2"
                    }
                ]
            },
            {
                id: 47, 
                type: 'inline',
                element: UIInput,
                conditions: [
                    (values) => ( values.q45 && parseInt(values.q45, 10) !== 0 )
                ],
                validations: [
                    ...checkPositiveNumber,
                    {
                        checkValue: (value, values) => ( isMax(value, values.q45) ),
                        message: "This value cannot exeed the value from question O2"
                    }
                ]
            },
            {
                id: 48, 
                type: 'inline',
                element: UIInput,
                conditions: [
                    (values) => ( values.q45 && parseInt(values.q45, 10) !== 0 )
                ],
                validations: [
                    ...checkPositiveNumber,
                    {
                        checkValue: (value, values) => ( isMax(value, values.q45) ),
                        message: "This value cannot exeed the value from question O2"
                    }
                ]
            },
            {
                id: 49, 
                type: 'inline',
                element: UIInput,
                validations: lessThanCaseload
            },
            {
                id: 50, 
                type: 'inline',
                element: UIInput,
                validations: lessThanCaseload
            },
        ]
    },
    {
        id: 7,
        title: 'Outcomes',
        content: [
            {
                id: 51, 
                type: 'inline',
                element: UIInput,
                validations: [...checkPositiveNumber]
            },
            {
                id: 52, 
                type: 'inline',
                element: UIInput,
                conditions: [
                    (values) => ( values.q51 && parseInt(values.q51, 10) !== 0 )
                ],
                validations: [
                    ...checkPositiveNumber /*,
                    {
                        checkValue: (value, values) => ( isMax(value, values.q51) ),
                        message: "This value cannot exeed the value from question O5"
                    }*/
                ]
            },
            {
                id: 53, 
                type: 'inline',
                element: UIInput,
                conditions: [
                    (values) => ( values.q51 && parseInt(values.q51, 10) !== 0 )
                ],
                validations: checkPositiveNumber
            },
            {
                id: 54, 
                type: 'inline',
                element: UIInput,
                conditions: [
                    (values) => ( values.q51 && parseInt(values.q51, 10) !== 0 )
                ],
                validations: [
                    ...checkPositiveNumber /*,
                    {
                        checkValue: (value, values) => ( isMax(value, values.q52) ),
                        message: "This value cannot exeed the value from question O5a"
                    }*/
                ]
            },
            {
                id: 55, 
                type: 'inline',
                element: UIInput,
                conditions: [
                    (values) => ( values.q51 && parseInt(values.q51, 10) !== 0 )
                ],
                validations: [
                    ...checkPositiveNumber /*,
                    {
                        checkValue: (value, values) => ( isMax(value, values.q52) ),
                        message: "This value cannot exeed the value from question O5a"
                    }*/
                ]
            },
            {
                id: 56, 
                type: 'inline',
                element: UIInput,
                validations: lessThanCaseload
            },
            {
                id: 57, 
                type: 'inline',
                element: UIInput,
                validations: lessThanCaseload
            },
            {
                id: 58, 
                type: 'inline',
                element: UIInput,
                validations: lessThanCaseload
            },
            {
                id: 59, 
                type: 'inline',
                element: UIInput,
                validations: lessThanCaseload
            }
        ]
    },
    {
        id: 8,
        title: 'Workforce',
        content: [
            ...[0,1,2,3,4,5,6,7].map(index => ({
                id: 60 + index,
                type: 'inline',
                columns: 2,
                required: true,
                element: false,
                processValue: (value, values) => ( getTotal(values, ['q'+(68+index), 'q'+(76+index)]) ),
                validations: checkPositiveNumber,
                children: [
                    ...[68,76].map(start => ({
                        id: start + index,
                        element: UIInput,
                        type: 'wide',
                        processValue: (value) => (value || 0),
                        validations: checkPositiveNumber
                    }))
                ]
            }))
        ]
    },
    {
        id: 9,
        title: 'Workforce',
        content: [
            // unfortunately the last question (W15) was added later and doesn't follow the sequence so we
            // have to map those differently to id 104, 105 and 106.
            ...[0,1,2,3,4,5,104].map(index => ({
                id: index > 100 ? index : 86 + index,
                type: 'inline',
                columns: 2,
                required: true,
                element: false,
                processValue: (value, values) => ( 
                    getTotal(values, ['q' + (index > 100 ? index + 1 : 92 + index), 
                            'q' + (index > 100 ? index + 2 : 98 + index)]) 
                ),
                validations: checkPositiveNumber,
                children: [
                    ...[92,98].map((start, i) => ({
                        id: index > 100 ? index + i + 1 : start + index,
                        element: UIInput,
                        type: 'wide',
                        processValue: (value) => (value || 0),
                        validations: checkPositiveNumber
                    }))
                ]
            }))
        ]
    }
];

export {auditStepsConfig, formatQuestions, getFormElements};