import {config} from '../../config.js';
import {Model} from '../../helpers/model/model.js';
import {ModelXhr} from '../../helpers/model/xhr.js';

class ModelAuthToken extends ModelXhr {

    static modelName = "ModelAuthToken";

    static getSelect(input) {
        return {};
    }

    static hasValidToken(){
        // todo: also check if the existing token is still valid
        return !! localStorage.getItem("auth_token");
    }

    static createItem(username, password, onSuccess=()=>{}, onFailure=()=>{}){
        this.saveData(ModelAuthToken, config.api_endpoint + 'oauth2_token', 'POST', 
            {
                grant_type: 'password',
                username: username,
                password: password
            }, 
            (response) => {
                if(! response.success){
                    onFailure(response.error);
                    return;
                }
                if(response.access_token){
                    localStorage.setItem("auth_token", response.access_token);
                    // get timestamp for 100 days from now;
                    const date = new Date();
                    date.setHours(date.getHours() + 100 * 24);
                    localStorage.setItem("auth_token_exp", date.getTime());

                    Model.flushAllMemory();
                    onSuccess();
                    return;
                }
                onFailure("An unknown error occured.");
            },
            {
                contentType: 'param'
            }
        );
    }

    static signOut(){
        sessionStorage.removeItem("auth_token");
        sessionStorage.removeItem("auth_token_exp");
        localStorage.removeItem("auth_token");
        localStorage.removeItem("auth_token_exp");
        Model.flushAllMemory();
    }


    constructor(selectData) {
        super(selectData, {
            endpoint: config.api_endpoint + 'oauth2_token',
            method: 'POST'
        });

        this.cacheEnabled = false;
    }

    getSelect(input) {
        return ModelAuthToken.getSelect(input);
    }

}

export {ModelAuthToken};