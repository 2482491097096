import {PropTypes} from 'prop-types';
import {useModelFeedback} from '../../helpers/model/use_model_feedback.js';
import {ModelAuthUser} from '../../model/auth/user.js';

const AuthWithUser = function(props){

    const resultSet = useModelFeedback(ModelAuthUser, {id: 'me'});

    if(resultSet.feedback && props.enableFeedback){
        return resultSet.feedback;
    }

    if(resultSet.status === ModelAuthUser.Status.SUCCESS){
        const user = resultSet.data[0];
        return props.children(user);
    }

    return props.children(null);

}

AuthWithUser.propTypes = { 
    enableFeedback: PropTypes.bool,
    children: PropTypes.func.isRequired
};

AuthWithUser.defaultProps = { 
    enableFeedback: true
}

export {AuthWithUser};