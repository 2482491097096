import React from "react";
import {ViewDashboardMeasures} from "../measures/measures.js";
import "./measures.scss";
import {DashboardDefaultPage} from '../default-page.js';
import {useModelFeedback} from '../../../helpers/model/use_model_feedback.js';
import {ModelParagraph} from '../../../model/admin/paragraph.js';

const ViewDashboardMeasuresOverview = function (props) {
    const resultSetText = useModelFeedback(ModelParagraph, {type: 'recommendations_title'});
    if(resultSetText.feedback){
        return resultSetText.feedback
    }
    return <DashboardDefaultPage shareable={true} title={resultSetText.data[0].text}>
        <p style={{margin: '0 0 0 10px'}}>
            The scores displayed are based on your team compared to other teams. <br/> 
            It does not give an absolute judgement of the quality, but shows how your team is doing relative 
            to other teams.
        </p>
        <div className="zol-measures-content">
            <ViewDashboardMeasures/>
        </div>
    </DashboardDefaultPage>
}

export {ViewDashboardMeasuresOverview}