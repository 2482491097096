import React from "react";
import "./export.scss";
import { StateContext, StateConsumer} from "../../../helpers/state-context.js";
import {ViewAdminExportAuditReport} from "./audit-report.js";
import {ViewAdminExportManagerReport} from "./manager-report.js";
import {ViewAdminExportManagerReportGenerated} from "./manager-report/manager-report-generated.js";
import {useModelFeedback} from "../../../helpers/model/use_model_feedback.js";
import {ModelAuthUser} from "../../../model/auth/user.js";

const ViewAdminExportIndex = function (props) {    
    const resultSetUser = useModelFeedback(ModelAuthUser, {id: 'me'});
    
    if(resultSetUser.feedback){
        return resultSetUser.feedback;
    }

    const primaryGroup = resultSetUser.status === 'success'?
            Object.keys(resultSetUser.data[0].primary_groups || {0: 0})[0]:
            0;

    return <StateContext initialState={{group: primaryGroup, managerReport: false, isExportingPDF: false}}>
        <StateConsumer>
            {(state, dispatch) => {
                if(state.managerReport){
                    return <ViewAdminExportManagerReportGenerated {...props}/>
                }else{
                    return <div style={{padding: '0 15px'}}>
                        <ViewAdminExportAuditReport {...props}/>
                        <ViewAdminExportManagerReport {...props}/>      
                    </div>
                }
            }}
        </StateConsumer>
    </StateContext>
}

export {ViewAdminExportIndex}
