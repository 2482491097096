import React from 'react';
import {PropTypes} from 'prop-types';
import { useStateContext } from '../../../helpers/state-context';
import styles from '../../../assets/variables.scss'


const UILinkInternal = function(props){
    
    const [, dispatch] = useStateContext(['page', 'section']);

    const openLink = () => {
        if(props.section){
            dispatch({type: 'section', value: props.section});
        }
        if(props.page){
            dispatch({type: 'page', value: props.page});
        }
    }
    
    return <span style={{color: styles.primaryColor, cursor: 'pointer'}} onClick={openLink}>
        {props.children}
    </span>
}

UILinkInternal.propsTypes = {
    section: PropTypes.string,
    page: PropTypes.string
}

export {UILinkInternal};