import './trend.scss';
import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import {scaleLinear, scalePoint} from 'd3-scale';
import {useSize} from "../../../helpers/use_size.js";
import {TrendLine} from "./line.js";
import {TrendAxis} from "./axis.js";
import {TrendDots} from "./dots.js";

const findMinMax = (array, key, extra = 0) => {
    const initial = (array[0] === undefined) ?
        0 :
        array[0][key];

    let min = parseFloat(initial);
    let max = parseFloat(initial);

    for (let i = 1; i < array.length; i++) {
        let v = parseFloat(array[i][key]);
        min = (v < min) ? v : min;
        max = (v > max) ? v : max;
    }
    let finalMin = min - extra
    return [parseInt((finalMin < 0) ? 0 : finalMin), parseInt(max + extra)];
};


const calculateScale = (data, size, domain) => {
    const y = scaleLinear()
        .rangeRound([size.height, 0])
        .domain(domain || findMinMax(data, 'y', 5));
    const x = scalePoint()
        .domain(data.map(i=>i.x))
        .range([0, size.width]);
    return {x, y}
};

const ChartTrend = function (props) {
    const {data, color, domain, lines} = props;
    const [size, measuredRef] = useSize((w,h)=>({width: w, height: h}), {width: 1, height: 1});
    const width = size.width;
    const height = size.height;
    
    const [scale, setScale] = useState(calculateScale(data, {width, height}, domain));
    useEffect(() => {
        // recalculate scale when size changes
        setScale(calculateScale(data, {width, height},domain))
    }, [height, width, data, domain]);
    
   
    return <div ref={measuredRef} style={{height: '100%', width: '100%'}}>
        <svg className={'zol-chart-trend'} width={width + 'px'}
             height={height + 'px'}
             viewBox={'0 0 ' + width + ' ' + (height + 25)}>
            <g transform={`translate(20, 5)`}>
                <TrendAxis data={data} axis={scale} width={width} height={height}/>
            </g>
            <g transform={`translate(20, 5)`}>
                <TrendLine data={data} scale={scale} lineWidth={3} color={color}/>
                <TrendDots data={data} scale={scale} color={color}/>
                {lines ?
                    lines.map((threshold, key) =>
                        <TrendLine key={key} lineWidth={1} data={data}
                                threshold={threshold.value}
                                scale={scale} color={threshold.color}/>) :
                        ''
                }
            </g>
        </svg>
    </div>
};

ChartTrend.defaultProps = {
    color: 'rgb(30, 177, 200)',
    lines: false,
    data: [
        {y: 50, x: 'Winter 2017', dot: 'Smoking'},
        {y: 25, x: 'Spring 2017', dot: 'Smoking'},
        {y: 15, x: 'Summer 2017', dot: 'Smoking'},
        {y: 10, x: 'Fall 2017', dot: 'Smoking'},
        {y: 20, x: 'Winter 2018', dot: 'Smoking'},
        {y: 30, x: 'Fall 2018', dot: 'Smoking'},
    ]
};

ChartTrend.propTypes = {
    domain: PropTypes.array,
    data: PropTypes.arrayOf(
        PropTypes.shape({
            x: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).required,
            y: PropTypes.number.required,
            dot: PropTypes.string,
        })
    ).isRequired,
    color: PropTypes.string
};

export {ChartTrend}