import React from "react";
import {PropTypes} from "prop-types";
import './dashboard.scss';
import {ReactComponent as IconEdit} from "../../assets/icons/edit.svg";
import {useStateContext} from "../../helpers/state-context.js";
import {UIButton} from "../../components/ui/button/button.js";
import {FilterGroup} from '../../components/filter/group.js';
import {FilterPeriod} from '../../components/filter/period.js';
import {ReactComponent as IconDownload} from "../../assets/icons/download.svg";
// import {notification} from '../../helpers/elements/notifications';
import {PDFExportContainer} from '../../helpers/pdf-export/container.js';
import {FilterGroupType} from '../../components/filter/group-type.js';

const divStyle={
    float: 'right', 
    display: 'inline-block',
};

const DashboardDefaultPage = function (props) {
    const [, dispatch] = useStateContext(['section', 'isExportingPDF']);
    const shareContainerId = 't4r-share-container';

    const downloadPage = () => {
        dispatch({type: 'isExportingPDF', value: 'dashboard'})
    };

    return <PDFExportContainer id={'dashboard'}>
        <div id={shareContainerId}>
            <div style={divStyle} className={'pdf-export-hide'}>

                {props.shareable?
                    <UIButton className="medium" onClick={()=>downloadPage()}
                              style={{marginRight: 10}}>
                        <IconDownload style={{padding:'0 5px 0 0'}}/>
                    </UIButton>:
                    null
                }

                {props.configurable?
                    <UIButton className="medium" style={{marginRight: 10}}
                            onClick={()=>{dispatch({type: 'section', value: 'configure'})}}>
                        <IconEdit style={{padding:'0 5px 0 0'}}/> Configure
                    </UIButton>:
                    null
                }
            </div>

            <h1 style={{marginBottom: '0px', marginLeft: 10}}>
                {props.title}
            </h1>

            <div className="zol-dashboard-filter">
                <FilterGroupType />
                <FilterGroup />
                <FilterPeriod />
            </div>

            <div className="zol-dashboard-content">
                {props.children}

            </div>

        </div>
    </PDFExportContainer>
}

DashboardDefaultPage.defaultProps = {
    configurable: false,
    shareable: false
};

DashboardDefaultPage.propTypes = {
    configurable: PropTypes.bool,
    shareable: PropTypes.bool,
    title: PropTypes.string.isRequired
};

export {DashboardDefaultPage}