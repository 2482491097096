import React, { useEffect, useState } from "react";
import {PropTypes} from 'prop-types';
import styles from "../../../assets/variables.scss";
import {UIText} from "../../../components/ui/text.js";
import {useForm} from "../../../helpers/form/use_form.js";
import {FormElement} from '../../../helpers/form/element.js';
import { useModelFeedback } from "../../../helpers/model/use_model_feedback.js";
import {ReactComponent as IconArrowLeft} from "../../../assets/icons/arrow_left.svg";
import { useStateContext } from "../../../helpers/state-context";
import { ModelOrgHierarchy } from "../../../model/org-hierarchy";
import { UIInputSelect } from "../../../components/ui/input/select";
import {types as groupTypes, titles as groupTypeTitles, parents as groupParents} from './settings.js'
import { ModelAuthGroup } from "../../../model/auth/group";
import {UIInput} from '../../../components/ui/input/input.js';
import {UIButton} from '../../../components/ui/button/button.js';


const formConfig = [
    {
        title: 'Name',
        name: 'title',
        element: UIInput,
        required: true,
        type: 'wide'
    },
    {
        title: 'Type',
        name: 'type',
        element: UIInputSelect,
        options: Object.keys(groupTypes).reduce((obj, type) => {
            obj[type] = groupTypeTitles[groupTypes[type]];
            return obj;
        }, {}),
        required: true,
        type: 'wide'
    },
    {
        title: 'Parent group',
        name: 'parent_id',
        element: UIInputSelect,
        options: [],
        required: true,
        conditions: [
            (values) => (values.type !== 'SUBREGION')
        ],
        type: 'wide'
    },
];

const OrganizationEdit = function (props) {

    const resultSet = useModelFeedback(ModelAuthGroup, {id: props.groupId || 1});
    const [groupType, setGroupType] = useState('region');
    const resultSetParents = useModelFeedback(
            ModelOrgHierarchy, {type: groupParents[groupType] || groupType});
    
    const [state, dispatch] = useStateContext(['view', 'id']);

    let elements = formConfig
            // only allow selection of type when creating new item
            .filter((item) => ( item.name !== 'type' || state.view === 'new' ))
            .map((item) => {
                if(item.name === 'parent_id'){
                    // insert the options for parent id
                    item.options = resultSetParents.feedback? []: resultSetParents.data;
                }
                return item;
            });

    // get a new form based on the configuration.
    const Form = useForm(elements);

    const [valuesPresent, setValuesPresent] = useState(false);

    useEffect(() => {
        if(! resultSet.feedback && ! valuesPresent){
            if(state.view === 'edit' && resultSet.data[0]){
                Form.setValues(resultSet.data[0]);
                setGroupType(groupTypes[resultSet.data[0].type]);
            }
            setValuesPresent(true);
        }
    }, [resultSet, Form, valuesPresent, state.view]);


    if(resultSet.feedback){
        return resultSet.feedback;
    }else if(! valuesPresent){
        return null;
    }
    
    const closeForm = () => {
        dispatch({type: 'setValues', value: {view: '', id: ''}});
    }

    const checkType = (values) => {
        if(values.type && groupTypes[values.type] !== groupType){
            setGroupType(groupTypes[values.type]);
        }
    }

    const submitForm = () => {

        if(Form.validate()){
            if(state.view === 'new'){
                ModelAuthGroup.createItem(Form.getValues(), closeForm);
            }else{
                ModelAuthGroup.updateItem(props.groupId, Form.getValues(), closeForm, (error) => {
                    alert("Error saving group: " + error);
                })
            }
        }

    }


    return <div>
        <UIText type={'body-L-bold'} color={'mediumColor'} style={{cursor: 'pointer', marginRight: 10}}
                onClick={closeForm}>
            <IconArrowLeft style={{color: styles.darkColor, marginRight: 5}}/>Back
        </UIText>
        <UIText type={'head-1'} color={'darkColor'} style={{marginBottom: 10}}>
            {state.view === 'new'? 'New organization unit': 'Edit organization unit'}
        </UIText>

        <div className="zol-admin-content">
            <Form.Component onChange={checkType}>
                {elements.map(element => (
                    <FormElement key={element.name} {...element} />
                ))}          

                <UIButton className="off-white" onClick={closeForm} style={{marginRight: '15px'}}>
                    Cancel
                </UIButton>  
                <UIButton className="primary" onClick={submitForm}>
                    Save
                </UIButton>  

            </Form.Component>
        </div>

    </div>;

};

OrganizationEdit.defaultProps = {
    
}

OrganizationEdit.propTypes = {
    groupId: PropTypes.number
}

export {OrganizationEdit};