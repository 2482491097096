// TODO: we want to get rid of this dependency. Is there a better way to set this configuration?
import {CONFIG} from '../../config.js';

export const compileUrl = (state) => {
    const path = compilePath(state.path);
    const query = compileQuery(state.query);
    const hash = compileHash(state.hash);
    return CONFIG.application_root + path + query + hash;
}

const compilePath = (path) => {
    const pathEncoded = path.map((param) => ( param===null? '': encodeURIComponent(param) ));
    return pathEncoded.length > 0? ('/' + pathEncoded.join('/')).replace(/\/+$/, ''): '';
}

const compileQuery = (query) => {
    const queryEncoded = [];
    for(const param of Object.keys(query)){
        if(query[param] !== null){
            queryEncoded.push(param + '=' + encodeURIComponent(query[param]));
        }
    }
    return queryEncoded.length > 0? '?' + queryEncoded.join('&'): '';
}

const compileHash = (hash) => {
    const hashEncoded = hash.map((param) => ( param===null? '': encodeURIComponent(param) ));
    const hashResult = hashEncoded.join(':').replace(/:+$/, '');
    return hashResult.length > 0? '#' + hashResult: '';
}
