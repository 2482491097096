import React from "react";
import './admin.scss';
import {UrlStateContext} from "../../helpers/url-state-context/url-state-context.js";
import {getSimpleReducer} from "../../helpers/state-context/get_simple_reducer.js";
import {useStateContext} from "../../helpers/state-context.js";
import {SideBar} from "../../components/side-bar/side-bar.js";
import styles from '../../assets/variables.scss'
import {ViewAdminSettings} from "./settings/settings.js";
import {ViewAdminOrganizationIndex} from "./organization/index.js";
import {ViewAdminAuditIndex} from "./audit/index.js";
import {ViewAdminUserIndex} from "./users/index.js";
import {AuthAccessValidator} from "../../components/auth/access-validator.js";
import {ViewAdminExportIndex} from './export/index.js';

const stylePage = {
    display: 'flex', 
    position: 'relative',
    paddingTop: '71px', // this part falls behind the header
    minHeight: '100%',
    // height: '100%'
};

const styleContent = {
    backgroundColor: styles.offWhiteColor, 
    marginLeft: 75,  
    width: 'calc(100% - 75px)',
    padding: '50px 75px', 
    position: 'relative'
    // overflow: 'auto'
};

const ViewAdminContent = function (props) {
    const [state] = useStateContext();

    // let subContent = <ViewAdminSettings/>
    let subContent = <ViewAdminAuditIndex />
    if(state.section === 'settings'){
        subContent = <ViewAdminSettings />
    }else if(state.section === 'export'){
        subContent = <ViewAdminExportIndex />
    }else if(state.section === 'users'){
        subContent = <ViewAdminUserIndex />
    }else if(state.section === 'organization'){
        subContent = <ViewAdminOrganizationIndex />
    }else if(state.section === 'audit'){
        subContent = <ViewAdminAuditIndex />
    }

    return  <div style={stylePage}>
        <SideBar inAdmin={true} />
        <div style={styleContent}>
            {subContent}
        </div>
    </div>
};

const ViewAdmin = function (props) {
    return <AuthAccessValidator>

        <UrlStateContext path={['section']} initialState={{section: 'audit'}} 
                reducer={getSimpleReducer(['section'])}>

            <ViewAdminContent />

        </UrlStateContext>

    </AuthAccessValidator>
};


export {ViewAdmin}