import React from "react";
import {PropTypes} from 'prop-types';
import styles from "../../assets/variables.scss";
import {useForm} from '../../helpers/form/use_form.js';
import {UIText} from "../ui/text.js";
import {UIInputSelect} from "../ui/input/select.js";
import {FormElement} from '../../helpers/form/element.js';
import {UIButton} from "../ui/button/button.js";
import './filter.scss';


const Filter = function (props) {

    const Form = useForm(props.filters);

    const applyFilters = () => {
        if(Form.validate()){
            const values = Form.getValues();
            props.onChange(values);
        }
    }

    const resetFilters = () => {
        Form.setValues({});
        props.onChange({});
    }

    return <div style={{padding: 20, color: styles.darkColor, backgroundColor: '#fff'}}>
        <UIText type={'body-L'} color={'mediumColor'}>
            Filter
        </UIText>
        <Form.Component>
            {/* Need a function here in order to make the values rerender */ }
            {props.filters.map((filter)=> (
                <div className="zol-dashboard-filter" key={filter.name}>
                    <FormElement element={UIInputSelect} 
                            title={filter.title}
                            name={filter.name} 
                            options={filter.options}
                            placeholder={filter.placeholder} />
                </div>
            ))}
        </Form.Component>

        <div style={{display: 'flex', justifyContent: 'space-between', margin: '20px 0 0'}}>
            <UIButton style={{width: '45%'}} onClick={applyFilters}>
                Apply
            </UIButton>
            <UIButton style={{width: '45%'}} className={'off-white'} onClick={resetFilters}>
                Reset
            </UIButton>
        </div>
    </div>
};

Filter.propTypes = {
    onChange: PropTypes.func.isRequired,
    filters: PropTypes.array.isRequired
};

Filter.defaultProps = {}

export {Filter}