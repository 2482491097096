import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useStateContext} from './use_state_context.js';
import {ReactStateContext} from './react-state-context.js';
import { getSimpleReducer } from './get_simple_reducer.js';

/*
    After a reducer has manipulated the state value, this function checks whether only properties 
    have changed that were set by the 'initialState' prop of this context. 
    This method is in place for the following reasons:
    - It prevents state properties to be undefined
    - It makes clear for which state properties this reducer is responsible (have a single 'owner')
    - Changes in the parent context can flow down without interference of this context (values 'owned'
      by the parent context will never be overwritten here)
    - We can still overwrite state values in this context, but we have to clearly define that. The 
      changes made here will never affect components outside this context.
*/
const cleanContextState = function(next, previous, initial){
    const clean = {};
    for(const key of Object.keys(next)){
        if(initial.hasOwnProperty(key)){
            clean[key] = next[key];
        }else if(next[key] !== previous[key]){
            console.warn('Reducer tried to manipulate state property ' + key + ' that wasn\'t defined in ' + 
                    'the initialState prop of this context. State update will be ignored.');
        }
    }
    return clean;
}

const depatchDefaultAction = function (state, action, currentContextKeys){

    if(action.type === 'setProperty' || (! action.type && action.key)){
        if(currentContextKeys.indexOf(action.key) !== -1){
            const nextState = {...state};
            if(action.setValue){
                nextState[action.key] = action.setValue(state[action.key]);
            }else{
                nextState[action.key] = action.value;
            }
            return nextState;
        }
    }

    return state;
}

// updating the internal state reference, so multiple calls to the dispatcher will get chained instead of
// overwriting each other.
const updateOldContextRefference = function(state, nextState){
    for(const key of Object.keys(state)){
        if(nextState.hasOwnProperty(key)){
            state[key] = nextState[key];
        }
    }
}

/*  the StateContext should be used as a Map, meaning just key-value pairs, we don't use strucuted tree
    data. We can group different values together by using a dot-separated notation. See useStateContext 
    for more info. Always define a initialState, only the properties of that object can be manipulated
    by the reducer defined in this context.
*/
const StateContext = function(props) {
    
    // bring in the state that is set by any parent context
    const [parentState, parentDispatcher] = useStateContext();
   
    const reducer = (contextValue, action, updateContext=true) => {
        const stateReducer = props.reducer? props.reducer: getSimpleReducer(Object.keys(props.initialState));
        let nextState = stateReducer(contextValue.state, action);
        if(nextState === contextValue.state){
            // check the default actions for this context
            nextState = depatchDefaultAction(contextValue.state, action, 
                    Object.keys(props.initialState));
        }

        if(nextState === contextValue.state){

            // If the action was not handled by this reducer, we propagate the action up to parent
            const newParentState = {...parentDispatcher(action, updateContext)};
            // then again insert the properties maintained by this context
            for(let key of Object.keys(props.initialState)){
                newParentState[key] = contextValue.state[key];
            }
            if(updateContext){
                updateOldContextRefference(contextValue.state, newParentState);
            }
            return newParentState;
        }else{
            // this reducer may only transform properties defined in its initialState
            const nextStateClean = cleanContextState(nextState, contextValue.state, props.initialState);

            // Action was handled by this reducer, save the new state in the current context
            if(updateContext){
                setContextValue({reducer: reducer, state: {...contextValue.state, ...nextStateClean}});
                updateOldContextRefference(contextValue.state, nextStateClean);
            }
            return nextStateClean;
        }
    }
    
    const [contextValue, setContextValue] = useState({
        state: {...parentState, ...props.initialState},
        reducer: reducer,
        callback: props.reducer
    });

    // if the initialState changes, make sure to set it. (but maybe keep any existing values?)
    const currentKeys = Object.keys(contextValue.state);
    const newInitKeys = Object.keys({...parentState, ...props.initialState});
    let changed = false;
    if(currentKeys.length === newInitKeys.length){
        for(const key of currentKeys){
            if(newInitKeys.indexOf(key) === -1){
                changed = true;
            }
        }
    }else{
        changed = true;
    }
    
    if(changed){
        const newInit = {};
        for(const key of Object.keys(props.initialState)){
            newInit[key] = contextValue.state.hasOwnProperty(key)? contextValue.state[key]: 
                    props.initialState[key];
        }
        setContextValue({
            state: {...parentState, ...newInit}, 
            reducer: reducer,
            callback: props.callback
        });
    }else if(props.reducer !== contextValue.callback){
        setContextValue({
            state: contextValue.state, 
            reducer: reducer,
            callback: props.reducer
        });
    }


    // Combining the contextValue with the parent context, to make sure we have the latest values
    const nextState = {
        ...parentState, 
        ...cleanContextState(contextValue.state, contextValue.state, props.initialState)
    };

    // check if any properties have changed. In that case update the local context.
    // Note that we need to reference to a new reducer (not recycling its value), because the current reducer
    // function has a refferene to an old contextValue.
    if(Object.keys(contextValue.state).length !== Object.keys(nextState).length){
        setContextValue({reducer: reducer, state: nextState, callback: props.callback});
    }else{
        for(const key of Object.keys(nextState)){
            if(! contextValue.state.hasOwnProperty(key) || contextValue.state[key] !== nextState[key]){
                setContextValue({reducer: reducer, state: nextState, callback: props.callback});
                break;
            }
        }
    }

    return <ReactStateContext.Provider value={contextValue}>
        {props.children}
    </ReactStateContext.Provider>;

}

StateContext.defaultProps = {};

StateContext.propTypes = {
    initialState: PropTypes.object.isRequired,
    reducer: PropTypes.func
};


export {StateContext};