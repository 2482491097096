import React, {useEffect, useState} from 'react';
import {PropTypes} from 'prop-types';
import {UIButton} from '../../../../components/ui/button/button.js';
import {useModelFeedback} from '../../../../helpers/model/use_model_feedback.js';
import {ModelEmail} from '../../../../model/email.js';
import {ModelParagraph} from "../../../../model/admin/paragraph.js";

const AuditComponentEmailTeam = function (props) {
    const [progress, setProgress] = useState({
        start: false,
        success: 0,
        fail: 0,
        pending: []
    });
    const [emailBody, setEmailBody] = useState('');
    const resultSetText = useModelFeedback(ModelParagraph, {type: 'mail_team_default'});     

    useEffect(() => {
        if(progress.pending.length > 0){
            const pending = progress.pending.slice(0);
            // get first pending item and send email
            const email = pending.splice(0, 1)[0];
            ModelEmail.createItem(email,
                (resultSet) => { 
                    // when e-mail was sent, update progress to trigger sending the next email
                    setProgress({
                        ...progress,
                        success: progress.success + 1,
                        pending
                    });
                },
                (error) => { 
                    // when e-mail failed, update progress to trigger sending the next email
                    console.warn('E-mail has not been sent to :' + email.name);
                    console.info(error);
                    setProgress({
                        ...progress,
                        fail: progress.fail + 1,
                        pending
                    });
                }
            );
        }
    }, [progress]);

    useEffect(() => {
        if(resultSetText.data && resultSetText.data[0]){
            setEmailBody(resultSetText.data[0].text);
        }
    }, [resultSetText.data]);

    if(resultSetText.feedback){
        return resultSetText.feedback;
    } 

    // add all the emails that we want to send in a list and add it to progress state
    const sendMail = () => {
        const emailsPending = [];
        for(const user of props.respondents){
            if(user && user.email){
                let body = emailBody;
                body = body.replaceAll(/{name}/g, user.name);
                body = body.replaceAll(/{team}/g, props.group);

                emailsPending.push({
                    email: user.email,
                    subject: 'EIP Matrix Audit ' + props.group,
                    body: body
                }); 
            }
        }
             
        // this triggers the useEffect that will start sending email one by one
        setProgress({
            start: true,
            success: 0,
            fail: 0,
            pending: emailsPending
        });
            
    }

    return <div>
        {progress.start? 
            <>
                <h3>{progress.success} emails have been sent!</h3>
                <p>
                    {progress.pending.length > 0? 
                        progress.pending.length + 'emails pending...': 
                        'All emails sent'
                    }
                </p>
                {progress.fail > 0? 
                    <h4>{progress.fail} emails failed!</h4>:
                    null
                }
                <UIButton className={'off-white'} style={{float: 'right'}} 
                        onClick={()=> { props.closeModal() }}>
                    Close
                </UIButton>
            </>:
            <>
                <h3>Send message to all {props.group} members</h3>
                <p>Below e-mail will be send to all members in the team.</p>
                <textarea value={emailBody} onChange={(e) => { setEmailBody(e.currentTarget.value) }} 
                        style={{width: '100%', height: '200px'}} />
                <UIButton className={'off-white'} style={{float: 'right'}} onClick={()=> { sendMail() }}>
                    Send email
                </UIButton>
            </>
            
        }
    </div>
}

AuditComponentEmailTeam.propTypes = {
    group: PropTypes.string.isRequired,
    respondents: PropTypes.array.isRequired,
    closeModal: PropTypes.func.isRequired
};

export {AuditComponentEmailTeam};