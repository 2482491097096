import React from "react";
import {UIText} from "../../../components/ui/text.js";
import {useModelFeedback} from '../../../helpers/model/use_model_feedback.js';
import { ModelAuthUser } from "../../../model/auth/user.js";

const ViewAuthDetails = function (props) {

    const resultSet = useModelFeedback(ModelAuthUser, {id: 'me'});

    let content = null;
    if(resultSet.feedback){
        content = resultSet.feedback
    }else if(resultSet.data.length > 0){
        content = JSON.stringify(resultSet.data[0], null, 4);
    }

    return <div style={{maxWidth: 800, margin: 'auto', width: 800, padding: '0 15px'}}>
            
            <h1>User details</h1>
            <UIText type={'body-L'} color={'mediumColor'} style={{whiteSpace: 'pre'}}>
                {content}
            </UIText>

    </div>;
};


export {ViewAuthDetails};