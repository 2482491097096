import React from "react";
import { UrlStateContext } from "../../../helpers/url-state-context/url-state-context.js";
import { StateConsumer } from "../../../helpers/state-context.js";
import { AuditList} from './list.js';
import {AuditListTeam} from './list-team.js';
import { AuditReview} from './review.js';
import {AuditSteps} from './steps.js';
import {AuthAccessValidator} from '../../../components/auth/access-validator.js';
import {AuthWithUser} from '../../../components/auth/with-user.js';
import {roles} from '../../../config.js';

const ViewAdminAuditIndex = function (props) {

    return <UrlStateContext path={['view', 'id']} initialState={{view: 'list', id: null}}>

        <StateConsumer watch={['view', 'id']}>
            {(state, ) => {
                if(state.view === 'edit'){
                    return <>
                        <AuthAccessValidator showError={false}
                                validateUser={(user) => (! user.roles.includes(roles.team))}>
                            <AuditReview auditId={parseInt(state.id)} />
                        </AuthAccessValidator>

                        <AuthAccessValidator showError={false}
                                validateUser={(user) => (user.roles.includes(roles.team))}>
                            <AuditSteps auditId={parseInt(state.id)} />
                        </AuthAccessValidator>
                    </>;

                }else{
                    return <>
                        <AuthAccessValidator showError={false}
                                validateUser={(user) => (! user.roles.includes(roles.team))}>
                            <AuditList />
                        </AuthAccessValidator>

                        <AuthAccessValidator  showError={false}
                                validateUser={(user) => (user.roles.includes(roles.team))}>
                            <AuthWithUser>
                                {(user) => <AuditListTeam groupId={user.group} />}
                            </AuthWithUser>
                        </AuthAccessValidator>
                    </>
                }
            }}

        </StateConsumer>

    </UrlStateContext>;

}

export {ViewAdminAuditIndex}