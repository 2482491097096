import React, {useState} from "react";
import {useStateContext} from "../../../helpers/state-context.js";
import {Card} from "../../../components/card/card.js";
import ChartVerticalBar from "../../../components/chart/vertical-bar/vertical-bar.js";
import {getColorPalette} from "../../../helpers/color.js";
import {ChartLegend} from "../../../components/chart/legend.js";
import {CardHeader} from "../../../components/card/header.js";
import {ModelDashboardAnswerDistribution} from "../../../model/dashboard/answer-distribution.js";
import {UIInputSelect} from "../../../components/ui/input/select.js";
import {useModelFeedback} from "../../../helpers/model/use_model_feedback.js";
import {getLastYear, getLastPeriod, getYearPeriods} from "../../../helpers/functions/periods.js";

function getOptions(selectedQuarter){
    let year = selectedQuarter?.substring(0, selectedQuarter.length-2);
    return [
        {id: 1, label: [selectedQuarter, getLastPeriod(selectedQuarter)], title: 'last quarter'},
        {id: 2, label: getYearPeriods(year).concat(getLastYear(year)), title: 'last year'}
    ]
}

function getQuestionOptions(){
    return [
        {question_ids: [49, 50], title: 'Social outcomes', titles:['In Employment or Education', 'In Settled Accommodation']},
        {question_ids: [45, 46], title: 'Relapse prevention', titles:['Admission Rates', 'Admissions Under Section']},
        {question_ids: [51, 54], title: 'Discharge destinations', titles:['FEP Discharges', 'ARMS Discharges']}
    ]
};

const DashboardCardOutcomeDetails = function (props) {
    const {question} = props;
    const palettte = getColorPalette(2)
    const [period, setPeriod] = useState('last quarter');
    const [questionOption, setQuestionOption] = useState('Social outcomes');
    const [state] = useStateContext(['period', 'group']);
    const periodOptions = getOptions(state.period);
    const questionOptions = getQuestionOptions();
    let data= [];
    let question_ids = [];
    let titles = [];
    let title = question;

    const year = state.period?.substring(0, state.period.length-2);
    const previousYear = `${year -1}`;

    if(question === 'all'){
        title = questionOptions.find((p)=>p.title === questionOption)?.title;
        question_ids = questionOptions.find((p)=>p.title === questionOption)?.question_ids;
        titles = questionOptions.find((p)=>p.title === questionOption)?.titles;
    }else{
        question_ids = questionOptions.find((p)=>p.title === question)?.question_ids;
        titles = questionOptions.find((p)=>p.title === question)?.titles;
    }

    const resultSetAnswer = useModelFeedback(ModelDashboardAnswerDistribution,
        {
            question_ids: [45, 46, 49, 50, 51, 54],
            group_id: state.group,
            periods: periodOptions.find((p)=>p.title === period)?.label
            
        });
    const resultSetCaseload = useModelFeedback(ModelDashboardAnswerDistribution,
        {
            question_id: 5,
            group_id: state.group,
            periods: periodOptions.find((p)=>p.title === period)?.label
        });

    if(resultSetAnswer.feedback || resultSetCaseload.feedback){
        return resultSetAnswer.feedback || resultSetCaseload.feedback;
    }
    const toPercentage = (y, question_id) =>{
        let value1, value2 = 0;
        if(y==='y0'){
            if(period === 'last quarter'){
                value2 = resultSetCaseload.data.find(row => row.period === getLastPeriod(state.period))?.value;
                value1 = resultSetAnswer.data.find(row => row.period === getLastPeriod(state.period) && row.id === question_id)?.value;
            }else{
                const lastyear= resultSetAnswer.data.filter((row) => {
                    return row.period.includes(previousYear) && row.id === question_id});

                value1= lastyear.reduce(function(prev, cur){ return prev+cur.value},0);
                const lastyearCaseload= resultSetCaseload.data.filter((row) => {
                    return row.period.includes(previousYear)});
                value2= lastyearCaseload.reduce(function(prev, cur){ return prev+cur.value},0);   
            }
        }else{
            if(period === 'last quarter'){
                value2= resultSetCaseload.data.find(row => row.period === state.period)?.value;
                value1 = resultSetAnswer.data.find(row => row.period === state.period && row.id === question_id)?.value;
            }else{
                const thisyear= resultSetAnswer.data.filter((row) => {
                    return row.period.includes(year) && row.id === question_id});
                value1= thisyear.reduce(function(prev, cur){ return prev+cur.value},0);
                const thisyearCaseload= resultSetCaseload.data.filter((row) => {
                    return row.period.includes(year)});
                value2= thisyearCaseload.reduce(function(prev, cur){ return prev+cur.value},0); 
            }
        }
        
        return (value1/value2 *100).toFixed()
    };


    function getAxis(){
        return {
            x: 'question',
            y: ['y0', 'y1'] 
        }
    }


    for(let i=0; i<question_ids.length; i++){
        data[i]= {
            question_id: question_ids[i],
            question: titles[i],
            y0 : toPercentage('y0', question_ids[i]), 
            y1 : toPercentage('y1', resultSetAnswer.data[i]?.id)
        }
    }

    const getPeriodSelect = () => {
        const options = periodOptions.map((o)=>o.title);
        return <>
            <p>Compare to</p>
            <p><UIInputSelect
                options={options}
                name={'period-select'}
                placeholder={props.placeholder}
                onChange={(value)=>{setPeriod(value)}}
                value={period} /></p>
            </>
    };

    const getQuestionSelect = () => {
        const options = questionOptions.map((o)=>o.title);
        return <UIInputSelect
            options={options}
            name={'period-select'}
            placeholder={props.placeholder}
            onChange={(value)=>{setQuestionOption(value)}}
            value={questionOption} />
    };

    const getTitle = function (){
        if(question==='all'){
            return <div style={{width: '100%', position: 'relative'}}>
                {getQuestionSelect()}
            </div>
        }else{
            return title
        }
    };


    const max = data.reduce((max, item)=>{
        const value1 = parseFloat(item.y1);
        const value2 = parseFloat(item.y0);
        if(value1 > max){
            max = value1
        }
        if(value2 > max){
            max = value2
        }
        return max;
    }, 0);

    return <Card id={props.id} cardSize={props.cardSize}>
        <CardHeader id={props.id} title={getTitle} rightColumn={getPeriodSelect()}
                style={{height: 35, paddingBottom: 15}}/>
        <div style={{display: "flex", height: 'calc(100% - 50px)', justifyContent: 'space-between'}}>
            <div style={{width: '80%', height: '90%'}}>
                <ChartVerticalBar 
                        axisTitles= {{
                            x: 'question',
                            y: (period === 'last quarter')?
                                [getLastPeriod(state.period), state.period]:
                                [previousYear, year]
                        }}
                        formatAxis={{
                            y: (value)=>`${value}%`
                        }}
                        axis={getAxis()} 
                        showAxisKey={true}
                        shortTooltip={true}
                        data={data} 
                        type="grouped"
                        domain={[0, max + ((max / 100) *20)]}
                        palette={palettte}/>
            </div>
            <ChartLegend direction={'vertical'} 
                palette={palettte}
                data={(period === 'last quarter')?
                    [getLastPeriod(state.period), state.period]:
                    [previousYear, year]}/>
        </div>
    </Card>
};

DashboardCardOutcomeDetails.defaultProps = {
    id: 11,
    question: 'all'
};

export {DashboardCardOutcomeDetails}

