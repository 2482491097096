import React from "react";
import PropTypes from 'prop-types';
import {line} from "d3-shape";

const TrendLine = function (props) {
    const {data, scale, color, lineWidth} = props;
    let createLine = line()
        .x((d) => scale.x(d.x))
        .y((d) => scale.y(d.y));

    let createLineThreshold = line()
        .x((d) => scale.x(d.x))
        .y((d) => scale.y(props.threshold));
    
    if(createLine(data) !== null && !createLine(data).includes('NaN') && !props.threshold){
        return <path className={'trend-path'} stroke={color} strokeWidth={lineWidth} d={createLine(data)}/>
    }else if(props.threshold && createLineThreshold(data) !== null &&
        !createLineThreshold(data).includes('NaN')){
        return <path className={'trend-path'} stroke={color} strokeWidth={lineWidth}
                d={createLineThreshold(data)}/>
    }
    return null
};

TrendLine.defaultProps = {
    lineWidth: 3
}

TrendLine.propTypes = {
    color: PropTypes.string,
    lineWidth: PropTypes.number,
    data: PropTypes.array,
    scale: PropTypes.object
};

export {TrendLine}