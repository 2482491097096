import './button.scss'
import React from "react";

const UIButton = function (props) {
    const {children, type, className,  ...otherProps } = props;

    return <button className={`button ${className ? className : ''}`} {...otherProps}>
        {children}
    </button>
}

UIButton.defaultProps = {
    className: 'primary'
};

export {UIButton}