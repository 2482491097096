import React, {useState} from "react";
import {useStateContext} from "../../../helpers/state-context.js";
import {Card} from "../../../components/card/card.js";
import ChartVerticalBar from "../../../components/chart/vertical-bar/vertical-bar.js";
import {getColorPalette} from "../../../helpers/color.js";
import {CardHeader} from "../../../components/card/header.js";
import {ChartLegend} from "../../../components/chart/legend.js";
import {ModelDashboardAnswerDistribution} from "../../../model/dashboard/answer-distribution.js";
import {useModelFeedback} from "../../../helpers/model/use_model_feedback.js";
import {getFullYear, currentYear, getLastYear} from "../../../helpers/functions/periods.js";
import {UIInputSelect} from "../../../components/ui/input/select.js";

function getOptions(selectedQuarter){
    return [
        {id: 1, label: [selectedQuarter], title: 'Selected quarter'},
        {id: 2, label: getFullYear(), title: 'This year: '+currentYear},
        {id: 3, label: getLastYear(), title: 'Past year: '+(currentYear-1)},
    ]
}

const DashboardCardOutcomeOverviewCaseload = function (props) {
    const {axis} = props;
    const palette = getColorPalette(2);
    const graphPalette = getColorPalette(2).reverse(); //the dataset gets reversed in the vertical bar component
    const [period, setPeriod] = useState('Selected quarter');
    const [state] = useStateContext(['period', 'group','section']);
    const periodOptions = getOptions(state.period);

    const resultSet = useModelFeedback(ModelDashboardAnswerDistribution, {
        question_ids: [5, 49, 50], 
        periods:  periodOptions.find((p)=>p.title === period)?.label,
        group_id : state.group});

    if(resultSet.feedback){
        return resultSet.feedback;
    }

    const toPercentages = (id1, id2) =>{
        const id1Item = resultSet.data.find(row => row.id === id1)
        const id1Value = (id1Item === undefined) ? 0 : id1Item.value;

        const id2Item = resultSet.data.find(row => row.id === id2);
        const id2Value = (id2Item === undefined) ? 0 : id2Item.value;

        let percentage1 = parseFloat((id1Value / id2Value * 100).toFixed());
        return {
            percentage1: (isNaN(percentage1)) ? 0 : percentage1,
            percentage2: (isNaN(100-percentage1)) ? 0 : 100-percentage1
        }
    }

    function getTooltip(id1, id2, denominatorName){
        const id1Item = resultSet.data.find(row => row.id === id1)
        const id1Value = (id1Item === undefined) ? 0 : id1Item.value;

        const id2Item = resultSet.data.find(row => row.id === id2);

        const id2Value = (id2Item === undefined) ? 0 : id2Item.value;
        return `${id1Value} of ${id2Value} - ${toPercentages(id1, id2).percentage1}% of ${denominatorName}`;
    }

    let data= [
        {
            Type: 'Employment / Education',
            header: 'In Employment or Education',
            outcome: toPercentages(49, 5).percentage1,
            no_outcome: toPercentages(49, 5).percentage2,
            tooltip: getTooltip(49, 5, 'FEP Caseload')
        },
        {
            Type: 'Accommodation',
            header: 'In Settled Accommodation',
            outcome: toPercentages(50, 5).percentage1,
            no_outcome: toPercentages(50, 5).percentage2,
            tooltip: getTooltip(50, 5, 'FEP Caseload')
        }
    ];


    const max = Math.max(...data.map(i=>{
        if(i.outcome < 0){
            return i.no_outcome
        }
        if(i.no_outcome < 0){
            return i.outcome
        }
        return i.no_outcome + i.outcome
    }), 100)

    const getPeriodSelect = () => {
        const options = periodOptions.map((o)=>o.title);
        return <UIInputSelect
            options={options}
            name={'period-select'}
            placeholder={props.placeholder}
            onChange={(value)=>{setPeriod(value)}}
            value={period} />
    };

    return <Card id={props.id} cardSize={props.cardSize}>
        <CardHeader id={props.id} title={"Social Inclusion & Security"} rightColumn={getPeriodSelect()} style={{height: 35, paddingBottom: 15}}/>
        <div style={{
            display: "flex",
            width: '100%',
            height: 'calc(100% - 50px)',
            justifyContent: 'space-between'
        }}>
            <div style={{width: 'calc(100% - 120px)', height: '100%'}}>
                <ChartVerticalBar type={'stacked'}
                                  axis={axis}
                                  tooltipHeader={true}
                                  showAxisKey={true}
                                  data={data}
                                  formatAxis={{
                                      y: (value)=>`${value}%`
                                  }}
                                  domain={[0, max]}
                                  palette={graphPalette}/>
            </div>
            <ChartLegend direction={'vertical'}
                    palette={palette}
                    style={{width: 120}}
                    data={['Patients with this Outcome', 'Patients without this Outcome']}/>
        </div>

    </Card>
};

DashboardCardOutcomeOverviewCaseload.defaultProps = {
    id: 18,
    axis: {
        x: 'Type',
        y: ['outcome', 'no_outcome']
    },
};

export {DashboardCardOutcomeOverviewCaseload}

