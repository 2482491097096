import React from 'react';
import {Card} from "../../../components/card/card.js";
import '../measures/measures.scss';
import styles from "../../../assets/variables.scss";
import {useModelFeedback} from "../../../helpers/model/use_model_feedback.js";
import {ModelDashboardAnswers} from "../../../model/dashboard/answers.js";
import {ModelAuditIndicator} from '../../../model/audit/indicator.js';
import {useStateContext} from "../../../helpers/state-context.js";
import {ViewMeasureItem} from '../measures/page/measure.js';
import {questionIds, indicatorIds, findData, getLevel, getScore, getIndicatorQuestionIds} from 
        '../measures/calculate-level.js';



const DashboardCardMeasure = function (props) {
    const [state, dispatch] = useStateContext(['page','period', 'group']);
    
    const resultSetIndicator = useModelFeedback(ModelAuditIndicator, {ids: indicatorIds});
    const indicatorQuestionIds = (resultSetIndicator.status==='success')? 
            getIndicatorQuestionIds(resultSetIndicator.data): [];

    const resultSet = useModelFeedback(ModelDashboardAnswers,
        { 
            select: 'period, sum(answer) as value, question_id as id',
            question_ids: [...questionIds, ...indicatorQuestionIds],    
            period: state.period,
            group_id: state.group
        });
    
    if(resultSet.feedback || resultSetIndicator.feedback){
        return resultSet.feedback || resultSetIndicator.feedback
    }

    const a1 = findData(resultSet.data, [state.period], 56, 59); // O6 / O8a
    const a2 = findData(resultSet.data, [state.period], 57, 59); // O7 / O8a
    // take the average of both
    const v1 = parseFloat(a1[state.period].replace('%', ''));
    const v2 = parseFloat(a2[state.period].replace('%', ''));
    const outcomeMeasure = isNaN(v1) || isNaN(v2)? '-': Math.round(0.5 * (v1 + v2)) + '%';
    const outcomeThresholds = [25, 50, 75];

    const indicatorData = resultSetIndicator.data.map((item) => (
        {
            values: findData(resultSet.data, [state.period], item.fk_field1, item.fk_field2),
            thresholds: [item.threshold1 * 100, item.threshold2 * 100, item.threshold3 * 100]
        }
    ));

    const levels = {0: 0, 1: 0, 2: 0, 3: 0, 4: 0, outcome: 0};
    for(const indicator of indicatorData){
        const thresholds = indicator.thresholds.every(item => item ===0)? false: indicator.thresholds;
        const level = getLevel(indicator.values[state.period], thresholds);
        levels[level]++;
    }

    const score = getScore(levels, getLevel(outcomeMeasure, outcomeThresholds));

    const level = score === 0? null: ['worst', 'bad', 'good', 'best'][score-1];
    const title = score === 0? '': 'Level ' + score;
    
    if(level !== null){
        return <ViewMeasureItem title={title} 
                level ={true}
                onClick={()=>{dispatch({type: 'section', value: 'recommendations'})}}
                position={level}/> ;
    }
    //return general card
    return <Card id={props.id} cardSize={props.cardSize}>
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <h3 style={{color: styles.mediumDarkColor, position: 'absolute', bottom: '100px'}}>
                Data is missing, the overall level cannot be calculated.
            </h3>
        </div>
    </Card>
};

export {DashboardCardMeasure};