import './card.scss'
import React from "react";
import PropTypes from "prop-types";
import {useModelFeedback} from "../../helpers/model/use_model_feedback.js";
import {ModelAuditGraphs} from "../../model/audit/graphs.js";
import {PDFExportContainer} from '../../helpers/pdf-export/container.js';

const Card = function (props) {
    const {children, id, style} = props;
    const resultSetGraphs = useModelFeedback(ModelAuditGraphs);
    if(resultSetGraphs.feedback){
        return resultSetGraphs.feedback
    }
    const card = resultSetGraphs.data.find((row)=> row.id === id)
    const cardSize = props.cardSize? props.cardSize : (id === undefined) ? 'medium' : card.cardSize;

    const getComponent = (child) => {
        // Because we don't have the CardHeader inside the card component we pass down the cardId to the CardHeader
        // if it exists so we can find the correct card to download when download is clicked at the CardHeader.
        if(React.isValidElement(child) && child.type.name === 'CardHeader'){
            return React.cloneElement(child, {card, cardId: props.id})
        }
        return child
    };

    return <PDFExportContainer id={props.id?.toString()}
            className={`zol-card zol-card-${cardSize} ${props.className}`}
            style={style}>
        {Array.isArray(children) ?
            children.map((child, key) =>
                <React.Fragment key={key}>{getComponent(child)}</React.Fragment>
            ) :
            getComponent(children)
        }
    </PDFExportContainer>
};

Card.defaultProps = {
    id: 0
};

Card.propTypes = {
    cardSize: PropTypes.oneOf(['small', 'sm', 'medium', 'ml', 'large', 'option']),
    id: PropTypes.number,
    className: PropTypes.string
};

export {Card}
