import './card.scss'
import React, {useState} from "react";
import {ReactComponent as DownloadSvg} from "../../assets/icons/download.svg";
import {useStateContext} from '../../helpers/state-context.js';

const divStyle={
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
};

const downloadIconStyle={
    cursor: 'pointer',
    width: 20,
    height: 20,
    marginLeft: '5px'
};

const infoInnerStyle = {
    fontWeight: 900,
    height: 'auto',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)'
}

const CardHeader = function (props) {
    const [, dispatch] = useStateContext(['isExportingPDF']);
    const [showDescription, setDescription] = useState(false)
    const currentCard = props.card;

    const downloadCard = () => {
        dispatch({type: 'isExportingPDF', value: (props.cardId || props.id || 0).toString()});
    };

    const getTitle = () => {
        const isFunction = typeof props.title === "function";
        const title = props.title && !isFunction ? 
                props.title :
                currentCard?.title;
        return isFunction? props.title(title): title;
    };

    return <div className={'card-header'} style={{...divStyle, ...props.style}}>
        <div style={{minWidth: '50%'}} >
            <h3 >{getTitle()}</h3>
        </div>

        <div  style={{display: 'flex', alignItems:'center'}}>
            {props.rightColumn}
            <DownloadSvg style={downloadIconStyle} onClick={()=>downloadCard('pdf')}
                    className={'pdf-export-hide'}/>
            {currentCard?.information?
                <div style={{position: 'relative'}}>
                    {/* TODO: hide tootltip when clicking somewhere else in the document */}
                    <div className={`info-container ${(showDescription)? 'selected' : ''}`} 
                            onClick={()=>setDescription(prev=>!prev)}>
                        <span style={infoInnerStyle}>
                            ?
                        </span>
                    </div>
                    {showDescription ?
                        <div className={'more-info-container'}>{currentCard.information}</div> :
                        null
                    }
                </div> :
                null
            }
        </div>
    </div>
};


export {CardHeader}
