import {config} from '../../config.js';
import {ModelXhr} from '../../helpers/model/xhr.js';

class ModelAuditGraphs extends ModelXhr {

    static modelName = "ModelAuditGraphs";

    static currentValues = null;

    static getSelect(input) {
        return {
            select: 'id, title, description, section, cardSize, information'
        };
    }

    static updateItem(id, data, onSuccess=()=>{}, onFailure=()=>{}){
        this.saveData(ModelAuditGraphs, config.api_endpoint + 'audit_graph/' + id, 'PATCH', 
            data, 
            (response) => {
                if(response.success){
                    const graph = response.result? (response.result[0] || null): null;
                    onSuccess(graph);
                }else{
                    onFailure(response.error || "Unknow error");
                }
            }
        );
    }

    constructor(selectData) {
        super(selectData, {
            endpoint: config.api_endpoint + 'audit_graph',
            method: 'GET'
        });

        this.cacheEnabled = true;
    }

   
    getSelect(input) {
        return ModelAuditGraphs.getSelect(input);
    }


}

export {ModelAuditGraphs};