import React, {useState} from "react";
import PropTypes from 'prop-types';
import {getElementPagePosition} from "../../../helpers/functions/get_element_page_position.js";
import Tooltip from "../../../helpers/elements/tooltip.js";

const TrendDots = function (props) {
    const {data, scale, color, onDotClick} = props;
    const [hover, setHover] = useState('')

    const onMouseOver= (e, item)=> {
        if (item === null) {
            Tooltip.getInstance().setState({});
            setHover('')
            return;
        }
        if (!e.currentTarget) {
            return;
        }
        setHover(item.x)
        const node = e.currentTarget;
        const pos = getElementPagePosition(node);
        const width = node.getBoundingClientRect().width;
        let text = `${item.dot} \n ${item.y}%`
        Tooltip.getInstance().setState({
            position: [pos.left + 0.5 * width, pos.top],
            text: <span style={{textAlign: 'center'}}>{text}</span>,
            anchor: 'bottom'
        });

    };

    return <g>
        {data.map((item, key) => {
            const x = scale.x(item.x);
            const y = scale.y(parseFloat(item.y))
            if(x !== null && !isNaN(x) && y !== null && !isNaN(y)){
                return <React.Fragment key={key}>
                    <g className={'trend-circle'} onClick={() => onDotClick(item)}
                       onMouseOver={(e) => onMouseOver(e, item)}
                       onMouseOut={(e) => onMouseOver(e, null)}
                       style={{cursor: 'pointer'}}>
                        <circle r={8} fill={color}
                                stroke={`#fff`} strokeWidth={(hover === item.x) ? 5 : 1}
                                cx={x} cy={y}/>
                    </g>
                </React.Fragment>
            }
            return null
        })}
    </g>
};

TrendDots.defaultProps = {
    onDotClick: ()=>{},
};

TrendDots.propTypes = {
    data: PropTypes.array.isRequired,
    scale: PropTypes.object,
    color: PropTypes.string,
    onDotClick: PropTypes.func,
};

export {TrendDots}