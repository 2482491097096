import React, {useState} from "react";
import styles from '../../../../assets/variables.scss';
import {UIText} from '../../../../components/ui/text.js';
import {UIButton} from '../../../../components/ui/button/button.js';
import {ReactComponent as IconNotify} from "../../../../assets/icons/notify.svg";
import {ChartProgressCircle} from '../../../../components/chart/progress-circle/progress-circle.js';
import {ChartTimeline} from '../../../../components/chart/timeline.js';
import {useModelFeedback} from '../../../../helpers/model/use_model_feedback.js';
import {ModelAuditProgress} from '../../../../model/audit/progress.js';
import {UIModal} from "../../../../components/ui/modal/modal.js";
import {AuditComponentRemindUnstarted} from "./remind-unstarted.js";
import { ModelAuditPeriod } from "../../../../model/audit/period";
import { getCurrentPeriod } from "../../../../helpers/functions/periods";

const AuditComponentProgress = function(props){
    const [showModal, setShowModal] = useState(false);
    const resultSet = useModelFeedback(ModelAuditProgress);
    const resultSetPeriod = useModelFeedback(ModelAuditPeriod, {name: getCurrentPeriod()});

    if(resultSet.feedback){
        return resultSet.feedback;
    }
    if(resultSetPeriod.feedback){
        return resultSetPeriod.feedback;
    }
    const period = resultSetPeriod.data[0];
    const togglePeriod = () => {
        const newStatus = period.status === 'PUBLISHED'? 'UNPUBLISHED': 'PUBLISHED';
        ModelAuditPeriod.updateItem(period.id, {status: newStatus}, () => {
            alert('Period ' + newStatus.toLowerCase());
        });
    }
    
    let total = 0;

    const titles = {
        completed: "Completed",
        started: 'Started',
        not_started: 'Not started'
    };
    
    const colors = {
        completed: styles.brandBlueColor,
        started: styles.brandYellowColor,
        not_started: styles.brandRedColor
    };
    
    const data = resultSet.data.map((obj) => {
        total += obj.count;
        return {
            id: obj.progress,
            title: titles[obj.progress],
            value: obj.count,
            color: colors[obj.progress]
        };
    // order the result based on the order of items in the titles object
    }).sort((a, b) => ( Object.keys(titles).indexOf(a.id) - Object.keys(titles).indexOf(b.id)));
 
    return <div style={{padding: 20, color: styles.darkColor, backgroundColor: '#fff'}}>
        <UIText type={'body-L'} color={'mediumColor'}>Progress</UIText>
        <ChartTimeline />

        <div style={{position:"relative"}}>
            <div style={{maxWidth: "380px", height: '250px', margin:"32px auto", textAlign: 'center'}}>
                <ChartProgressCircle data={data} total={total} />
            </div>
        </div>

        <UIButton className={'off-white'} style={{width: '100%'}} onClick={()=> setShowModal(true)}>
            <IconNotify style={{marginRight: 5}}/> Remind all unstarted
        </UIButton>
        
        <UIButton style={{width: '100%', marginTop: '10px'}} onClick={togglePeriod}>
            {period.status === 'PUBLISHED'? 
                'Unpublish this period':
                'Publish this period'
            }
        </UIButton>
        {showModal? 
            <UIModal visible={true} onClose={()=>{setShowModal(false)}}> 
                <AuditComponentRemindUnstarted closeModal={()=>setShowModal(false)} period={period.name}/>
            </UIModal> :
            null
        }
    </div>;
}

export {AuditComponentProgress};