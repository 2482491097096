import {config} from '../../config.js';
import {ModelXhr} from '../../helpers/model/xhr.js';
import {ModelAudit} from './audit.js';

class ModelAuditList extends ModelXhr {

    static modelName = "ModelAuditList";

    static getSelect(input) {
        const filter = [];
        
        for(const [field, value] of Object.entries(input)){
            if(field === 'period' && value === 'current'){
                filter.push('period eq \'' + ModelAudit.getCurrentPeriodName() + '\'');
            }else if(field === 'period' && value  === 'history'){
                filter.push('period lt \'' + ModelAudit.getCurrentPeriodName() + '\'');
            
                // option value '0' is typically for 'All values'
            }else if(value && value.length > 0 && value !== '0'){ 
                const arrValue = value instanceof Array? value: [value];
                const f = arrValue.map(v => {
                    if(isNaN(v)){
                        return field + ' eq \'' + v + '\'';
                    }else{
                        return field + ' eq ' + v;
                    }
                });
                filter.push(f.length > 1? '(' + f.join(' or ') + ')': f[0]);
            }
        }

        return {
            select: 'id, step, period_id, period, group_id, group, user_id, user_name, user_email, ' +
                    'question_id, answer',
            filter: filter.join(' and '),
            order: 'period desc'
        };
    }



    constructor(selectData) {
        super(selectData, {
            endpoint: config.api_endpoint + 'audit-list',
            method: 'GET'
        });

        this.cacheEnabled = false;
    }

   
    getSelect(input) {
        return ModelAuditList.getSelect(input);
    }


}

export {ModelAuditList};