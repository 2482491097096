import React, {useState} from "react";
import {useStateContext} from "../../../helpers/state-context.js";
import {Card} from "../../../components/card/card.js";
import {useModelFeedback} from "../../../helpers/model/use_model_feedback.js";
import {ModelDashboardAnswers} from "../../../model/dashboard/answers.js";
import {ModelOrgHierarchy} from "../../../model/org-hierarchy.js";
import {CardHeader} from "../../../components/card/header.js";
import {UIInputSelect} from "../../../components/ui/input/select.js";
import { getColorPalette } from "../../../helpers/color.js";
import {VerticalBarIcon} from '../../../components/chart/vertical-bar/icon.js';
import {VerticalBarComponentCircle} from '../../../components/chart/vertical-bar/component/circle.js';

function getGroupOptions(){
    return [
        {id: 1, label: 'providers', title: 'providers'},
        {id: 2, label: 'teams', title: 'teams'}
    ]
};

function getQuestionOptions(){
    return [
        {id: 45, title: 'Admission to Mental Health Hospital'},
        {id: 46, title: 'Admission under Mental Health Act'},
        {id: 47, title: 'Admission to Mental Health Acute Ward for 28 days or more'},
        {id: 48, title: 'Re-admitted to Mental Health Acute Ward Within 30 Days of Discharge'},
        {id: 49, title: 'In Employment (Paid, Unpaid, Part or Full Time and Voluntary Work) or Education'},
        {id: 50, title: 'In Settled Accommodation'}
    ]
};

function getChildIds(i, resultSetProviders, resultSetHierarchy){
    const provider_id = resultSetProviders.data[i].id;
    const childIds = [];
    resultSetHierarchy.data.forEach((item)=>{
        if(item.provider_id === provider_id){
            childIds.push(item.team_id);
        }
    })
    return childIds;
}

function getProviderValue(childIds, data){
    let totalNumerator = 0;
    let totalDenominator = 0;
    for(const id of childIds){
        totalNumerator += data.find((row)=>row.group_id === id).numerator;
        totalDenominator += data.find((row)=>row.group_id === id).denominator;
    }
    const percentage = (totalNumerator ===0 || totalDenominator ===0)? 
            0 : (totalNumerator/ totalDenominator*100).toFixed();
    return {totalNumerator, totalDenominator, percentage};
}

/**
 * @return {null}
 */
const DashboardCardOutcomeComparision = function (props) {
    const {axis} = props;
    const [state] = useStateContext(['period', 'group', 'isExportingPDF']);
    const [group, setGroup] = useState('teams');
    const [question, setQuestion] = useState('Admission to Mental Health Hospital');
    const groupOptions = getGroupOptions();
    const questionOptions = getQuestionOptions();
    let data = [];
    const resultSetHierarchy = useModelFeedback(ModelOrgHierarchy, {select: 'provider, provider_id, team, team_id'}, (m) => (m.getRawData()));
    const resultSetProviders = useModelFeedback(ModelOrgHierarchy, {type: 'provider'}, (m)=> m.getRawData());
    const resultSetAnswer = useModelFeedback(ModelDashboardAnswers,
        {
            question_id: questionOptions.find((p)=>p.title === question)?.id,
            period: state.period,
            select: 'group_id, answer as value, group_title, question_id, period_id'
        });
    const resultSetCaseload = useModelFeedback(ModelDashboardAnswers,
        {
            question_id: 5,
            period: state.period,
            select: 'group_id, answer as value, group_title, question_id, period_id'
        });

    if(resultSetAnswer.feedback || resultSetCaseload.feedback ||
        resultSetProviders.feedback || resultSetHierarchy.feedback){
        return resultSetAnswer.feedback || resultSetCaseload.feedback ||
            resultSetProviders.feedback || resultSetHierarchy.feedback
    }


    for(let i=0; i<resultSetAnswer.data.length; i++){
        const group_id = resultSetAnswer.data[i].group_id
        const numerator = resultSetAnswer.data.find(row => row.group_id === group_id).value;
        const denominator = resultSetCaseload.data.find(row => row.group_id === group_id).value;
        const percentage = (numerator/denominator*100).toFixed();


        const activeIds = resultSetHierarchy.data.reduce((array, item) =>{
            let newArray = [...array]
            if(item.provider_id === state.group || item.team_id === state.group){
                newArray.push(...[item.provider_id, item.team_id])
            }
            return newArray
        }, []);
        const color = activeIds.includes(resultSetAnswer.data[i].group_id) ?
            '#7300f2' :
            null;
        data.push({
            group_id: group_id,
            numerator: numerator,
            color: color,
            denominator: denominator,
            header: resultSetAnswer.data[i].group_title,
            tooltip: numerator+'/'+denominator+' ('+percentage+'% of FEP Caseload)',
            value: isNaN(percentage) ? 0 : percentage
        })
    }

    if(group==='teams'){
        data.sort((a, b) => (parseInt(a.value) > parseInt(b.value)) ? 1 : -1 );
    }else{
        if(data.length){
            let providerData = [];
            for(let i=0; i<resultSetProviders.data.length; i++){
                const childIds = getChildIds(i, resultSetProviders, resultSetHierarchy);
                const values = getProviderValue(childIds, data);
                const color = [...childIds, resultSetProviders.data[i].id].includes(state.group) ?
                        '#7300f2' :
                        null
                providerData.push({
                    group_id: resultSetProviders.data[i].id,
                    header: resultSetProviders.data[i].title,
                    value : values.percentage,
                    color: color,
                    tooltip: values.totalNumerator+'/'+values.totalDenominator+' ('+values.percentage+'%)'
                })
            }
            
            data = providerData.sort((a,b)=>(parseInt(a.value) > parseInt(b.value)) ? 1 : -1 );
        } 
    }
    

    const getGroupSelect = () => {
        const options = groupOptions.map((o)=>o.title);
        return <>
        <p style={{marginRight:'5px'}}>Compare across</p>
        <p>
            <UIInputSelect
            options={options}
            name={'period-select'}
            placeholder={props.placeholder}
            onChange={(value)=>{setGroup(value)}}
            value={group} />
        </p>
        </>
    };

    const getQuestionSelect = () => {
        const options = questionOptions.map((o)=>o.title);
        return <UIInputSelect
            options={options}
            name={'period-select'}
            onChange={(value)=>{setQuestion(value)}}
            value={question} />
    };

    const max = Math.max(...data.map((i)=>i.value))

    return <Card id={props.id} cardSize={'large'}>
        <CardHeader id={props.id} title={getQuestionSelect} rightColumn={getGroupSelect()} style={{height:35}}/>
        <div style={{width: '100%', height: 340, display: 'inline-block'}}>
            <VerticalBarIcon axis={axis}
                    palette = {[getColorPalette(2).pop()]}
                    hideAxisX={true}
                    formatAxis={{
                        y: (value)=>`${value}%`
                    }}
                    tooltipHeader={true}
                    data={data.map(({group_id, numerator, denominator, ...item }) => ({
                        ...item,
                        icon: (state.isExportingPDF) ?
                            <VerticalBarComponentCircle>
                                {group_id}
                            </VerticalBarComponentCircle> :
                            null,
                    }))}
                    type="grouped"
                    domain={[0, max + ((max / 100) *10)]}/>
        </div>
    </Card>
};

DashboardCardOutcomeComparision.defaultProps = {
    id: 15,
    axis: {
        x: 'header',
        y: ['value']
    }
};

export {DashboardCardOutcomeComparision}

