import styles from '../../../assets/variables.scss';

export function getColor(line, lines){
    for(let i=0; i<lines.length; i++){
        if(parseFloat(line.data.value) <= parseFloat(lines[i].value)){
            return lines[i].color;
        }else if(parseFloat(line.data.value) > parseFloat(lines[lines.length-1].value)){
            //it is bigger than the last threshold
            return styles.brandBlueColor;
        }
    }
}