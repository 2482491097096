import React, { useEffect, useState } from "react";
import styles from "../../../assets/variables.scss";
import {UIText} from "../../../components/ui/text.js";
import {useForm} from "../../../helpers/form/use_form.js";
import {FormElement} from '../../../helpers/form/element.js';
import { useModelFeedback } from "../../../helpers/model/use_model_feedback.js";
import {ReactComponent as IconArrowLeft} from "../../../assets/icons/arrow_left.svg";
import { useStateContext } from "../../../helpers/state-context";
import { ModelAuthGroup } from "../../../model/auth/group.js";
import { UIInputSelect } from "../../../components/ui/input/select.js";
import { ModelAuthUser } from "../../../model/auth/user.js";
import {UIInput} from '../../../components/ui/input/input.js';
import {UIButton} from '../../../components/ui/button/button.js';
import {isEmail} from '../../../helpers/form/validation.js';
import {useModel} from '../../../helpers/model/use_model.js';
import {ModelAuthRole} from '../../../model/auth/role.js';


const formConfig = [
    {
        title: 'Name',
        name: 'name',
        element: UIInput,
        required: true,
        type: 'wide'
    },
    {
        title: 'Email',
        name: 'email',
        element: UIInput,
        required: true,
        type: 'wide',
        validations: [
            {
                checkValue: isEmail,
                message: 'The provided email address is invalid'
            }
        ]
    },
    {
        title: 'Role',
        name: 'role',
        element: UIInputSelect,
        options:{
            1: "Administrator",
            2: "NHS England Manager",
            3: "Commissioning Manager",
            4: "Provider Director",
            5: "EIP Team Lead",
        },
        processValue: (value) => ( parseInt(value, 10) ),
        required: true,
        type: 'wide'
    },
    {
        title: 'Organization unit',
        // Options are inserted from the model
        options: [],
        processValue: (value) => ( parseInt(value, 10) ),
        name: 'group',
        element: UIInputSelect,
        required: true,
        type: 'wide'
    },
];

const getGroupType = function(role){
    if(! role || role === 5){
        return 'TEAM';
    }else if(role === 4){
        return 'PROVIDER';
    }else if(role === 3){
        return 'CCG';
    }else if(role === 2){
        return 'SUBREGION';
    }else if(role === 1){
        return 'ADMIN';
    }
    return 'TEAM';
}

const UserEdit = function (props) {

    const resultSet = useModelFeedback(ModelAuthUser, {id: props.userId, status: 'APPROVED'});
    const [groupType, setGroupType] = useState('TEAM');
    const [optionsAvailable, setOptionsAvailable] = useState(false);
    const resultSetGroup = useModelFeedback(ModelAuthGroup, {filter: 'type eq \'' + groupType + '\''});
    const [state, dispatch] = useStateContext(['view', 'id']);
    const [valuesPresent, setValuesPresent] = useState(state.view === 'new');

    // Fetch the available roles and groups from the models
    const resultSetRole = useModel(ModelAuthRole);

    const Form = useForm(formConfig);

    useEffect(() => {
        if(! resultSet.feedback && ! valuesPresent && optionsAvailable){
            if(state.view === 'edit' && resultSet.data[0]){
                const data = {
                    role: resultSet.data[0].roles[0],
                    group: resultSet.data[0].groups[0],
                    name: resultSet.data[0].name,
                    email: resultSet.data[0].email
                };
                const group = getGroupType(data.role || null);
                if(group !== groupType){
                    setGroupType(group);
                }else{
                    Form.setValues(data);
                    setValuesPresent(true);
                }
            }
        }
    }, [resultSet, Form, valuesPresent, optionsAvailable, state.view, groupType]);

    if(optionsAvailable && (resultSetGroup.feedback || resultSetRole.feedback)){
        setOptionsAvailable(false);
    }
    if(resultSet.feedback){
        return resultSet.feedback;
    }

    const options = {};
    for(let question of formConfig){
        if(question.name === 'role'){
            options.role = resultSetRole.status === 'success'? resultSetRole.data: [];
        }else if(question.name === 'group'){
            options.group = resultSetGroup.status === 'success' ?
                resultSetGroup.data.reduce((obj, item) => {
                    obj[item.id] = item.title;
                    return obj;
                }, {}):
                [];
        }

        if(! optionsAvailable && ! resultSetGroup.feedback && ! resultSetRole.feedback){
            setOptionsAvailable(true);
        }
    }

    if(! valuesPresent){
        return null;
    }

    const updateValues = (values) => {
        values = Form.getProcessedValues(values);
        if(values.role){
            const group = getGroupType(values.role);
            if(group !== groupType){
                setGroupType(group);
            }
        }
    }
    
    const closeForm = () => {
        dispatch({type: 'setValues', value: {view: '', id: ''}});
    }

    const submitForm = () => {
        if(Form.validate()){
            if(state.view === 'new'){
                ModelAuthUser.createItem(Form.getProcessedValues(), closeForm);
            }else{
                ModelAuthUser.updateItem(props.userId, Form.getProcessedValues(), closeForm);
            }
        }
    }

    return <div>
        <UIText type={'body-L-bold'} color={'mediumColor'} style={{cursor: 'pointer', marginRight: 10}}
                onClick={closeForm}>
            <IconArrowLeft style={{color: styles.darkColor, marginRight: 5}}/>Back
        </UIText>
        <UIText type={'head-1'} color={'darkColor'} style={{marginBottom: 10}}>
            {state.view === 'new'? 'New user': 'Edit user'}
        </UIText>

        <div className="zol-admin-content">
            <Form.Component onChange={updateValues}>
                {formConfig.map(element => {
                    const opts = options[element.name]? {options: options[element.name]}: {};
                    return<FormElement key={element.name} {...element} {...opts} />;
                })}          

                <UIButton className="off-white" onClick={closeForm} style={{marginRight: '15px'}}>
                    Cancel
                </UIButton>  
                <UIButton className="primary" onClick={submitForm}>
                    Save
                </UIButton>  

            </Form.Component>
        </div>

    </div>;
}

export {UserEdit}