import React, {useState} from "react";
import {useStateContext} from "../../../helpers/state-context.js";
import {Card} from "../../../components/card/card.js";
import {CardHeader} from "../../../components/card/header.js";
import {useModelFeedback} from "../../../helpers/model/use_model_feedback.js";
import {ModelDashboardAnswerDistribution} from "../../../model/dashboard/answer-distribution.js";
import ChartVerticalBar from "../../../components/chart/vertical-bar/vertical-bar.js";
import {UIInputSelect} from "../../../components/ui/input/select.js";
import {getFullYear, getLastYear, getLastPeriod, currentYear} from "../../../helpers/functions/periods.js";

function getOptions(selectedPeriod){
    return [
        {id: 1, label: [selectedPeriod, getLastPeriod(selectedPeriod)], title: 'last quarter'},
        {id: 2, label: getFullYear().concat(getLastYear()), title: 'last year'}
    ]
}

const DashboardCardWorkforce = function (props) { 
    const [state] = useStateContext(['period', 'group']);
    const [period, setPeriod] = useState('last quarter');
    const periodOptions = getOptions(state.period);
    const questions = [
        //id is added to make it alphabetically correct
        {id: 1, question_id: 97, title: 'Administrators'},
        {id: 2, question_id: 72, title: 'Care Coordinators'},
        {id: 3, question_id: 73, title: 'CBTp Therapists'},
        {id: 4, question_id: 75, title: 'Family Intervention Therapists'},
        {id: 5, question_id: 70, title: 'Non-Medical Prescribers'},
        {id: 6, question_id: 95, title: 'Peer Support Workers'},
        {id: 7, question_id: 69, title: 'Psychiatrists'},
        {id: 8, question_id: 93, title: 'Psychologists'},
        {id: 9, question_id: 94, title: 'Support Workers'},
        {id: 10, question_id: 68, title: 'Team Leaders'}
    ];
    const question_ids = questions.reduce((previous, current)=>{
        previous.push(current['question_id']);
        return previous
    }, []);
    
    let data = [];
    const resultSet = useModelFeedback(ModelDashboardAnswerDistribution,
        {
            question_ids: question_ids,
            periods: periodOptions.find((p)=>p.title === period)?.label,
            group_id : state.group,
            select: ""
        });
    if(resultSet.feedback){
        return resultSet.feedback;
    }
    
    const getValue = (y, question_id)=>{
        if(y === 'y0'){ //first period
            if(period === 'last quarter'){
                return resultSet.data.find(
                        row => row.period === getLastPeriod(state.period) && row.id === question_id)?.value;
            }else{ //get last year
                const lastyear= resultSet.data.filter((row) => {
                    return row.period.includes(currentYear-1) && row.id === question_id});
                return lastyear.reduce(function(prev, cur){ return prev+cur.value},0);
            }
        }else{ //2nd period
            if(period === 'last quarter'){
                return resultSet.data.find(
                        row => row.period === state.period && row.id === question_id)?.value;
            }else{ //get current year
                const thisyear= resultSet.data.filter((row) => {
                    return row.period.includes(currentYear) && row.id === question_id});
                return thisyear.reduce(function(prev, cur){ return prev+cur.value},0);
            }
        }
        
    }
   
    for(let i=0; i<questions.length; i++){
        data[i]={
            question_id: questions[i].question_id,
            question: questions[i].title,
            y0 : getValue('y0', questions[i].question_id), 
            y1 : getValue('y1', questions[i].question_id)
        }
    }

    function getAxis(){
        return {
            x: 'question',
            y: ['y0', 'y1']
        }
    }

    const getPeriodSelect = () => {
        const options = periodOptions.map((o)=>o.title);
        return <>
            <p>Compare to</p>
            <p><UIInputSelect
                options={options}
                name={'period-select'}
                placeholder={props.placeholder}
                onChange={(value)=>{setPeriod(value)}}
                value={period} /></p>
            </>
    };

    return <Card id={props.id} cardSize={props.cardSize}>
        <CardHeader rightColumn={getPeriodSelect()}
                id={props.id}/>
        {(resultSet.feedback) ?
            resultSet.feedback :
           <div style={{height: '80%'}}>
               <ChartVerticalBar data={data}
                        axisTitles= {{
                            x: 'question',
                            y: (period === 'last quarter')?
                                [getLastPeriod(state.period), state.period]:
                                [currentYear-1, currentYear]
                        }}
                        shortTooltip={true}
                        axis={getAxis()}
                        showAxisKey={true}
                        type="grouped"/>
           </div>
        }
    </Card>
};


DashboardCardWorkforce.defaultProps = {
    value: 'eip-workforce'
};

export {DashboardCardWorkforce}

