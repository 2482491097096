import React from "react";
import {UIInputSelect} from "../ui/input/select.js";
import { useStateContext } from "../../helpers/state-context.js";
import { useModelFeedback } from "../../helpers/model/use_model_feedback.js";
import { ModelAuditPeriod} from '../../model/audit/period.js';
import "./filter.scss";
import { getLastPeriod, getYear } from "../../helpers/functions/periods.js";

const FilterPeriod = function (props) {
    const [state, dispatch] = useStateContext(['period']);

    const selectionCriteria = {
        select: "id, name",
        filter: "status eq 'PUBLISHED'"
    };
    const resultSet = useModelFeedback(ModelAuditPeriod, selectionCriteria);

    if(resultSet.feedback){
        return resultSet.feedback;
    }

    const sortedData = resultSet.data.slice().sort((a,b)=> a.name < b.name ? -1 : 1);
      
    const options = resultSet.data.length === 0 ?
            [getLastPeriod()] :
            sortedData.map((item) => {
                const year = getYear(item.name);
                if(item.name.includes('Q1')){
                    return {[item.name]: item.name+' (fiscal year '+(year-1)+'-'+(year)+')'};
                }else{
                    return {[item.name]:item.name};
                }
            }).reduce((obj, item)=> {
                obj ={
                    ...obj, ...item
                }
                return obj
            }, {});

    
    const setPeriod = (value) => {
        const cleanValue = value.substring(0,6);
        if(cleanValue !== state.period){
            dispatch({type: 'period', value: cleanValue});
        }
        if(props.onChange){
            props.onChange(state.period);
        }
        
    };
    
    return <div className="zol-dashboard-filter-period">

        <UIInputSelect 
                options={options}
                name="filter-period"
                onSave={(value) => {setPeriod(value)} }
                value={state.period} />
    </div>
};

FilterPeriod.propTypes = { };
FilterPeriod.defaultProps = { };

export {FilterPeriod}