import {config} from '../../config.js';
import {ModelXhr} from '../../helpers/model/xhr.js';

class ModelDashboardAnswers extends ModelXhr {

    static modelName = "ModelDashboardAnswers";

    static getSelect(input) { 
        let select = {};
        const filter = [];
        
        if(input.question_ids){
            filter.push('(question_id eq ' + input.question_ids.join(' or question_id eq ') + ')');
        }
        if(input.question_id){
            filter.push('question_id eq ' + input.question_id);
        }
        if (input.period){
            filter.push('period eq \'' + input.period + '\'');
        }
        if(input.periods){
            filter.push('(period eq \'' + input.periods.join('\' or period eq \'') + '\')');
        }
        if (input.group_id){
            filter.push('group_id eq ' + input.group_id);
        }
        
        select = {
            select: input.select? input.select : 'group_id, question_title as description, answer as value, question_id as id',
            filter: filter.join(' and ')
        }
        return select;
    }

    constructor(selectData) {
        super(selectData, {
            endpoint: config.api_endpoint + 'dashboard_answer',
            method: 'GET'
        });

        this.cacheEnabled = true;
    }
   
    getSelect(input) {
        return ModelDashboardAnswers.getSelect(input);
    }
}

export {ModelDashboardAnswers};