import React, {useState} from "react";
import {useModelFeedback} from "../../helpers/model/use_model_feedback.js";
import {UISwitch} from "../../components/ui/switch.js";
import {cardsConfig} from "./cards_config.js";
import {ModelAuditGraphs} from "../../model/audit/graphs.js";
import {DashboardDefaultPage} from './default-page.js';

const ViewDashboardCharts = function (props) {
    const [switchValue, setSwitchValue] = useState('small')
    const resultSetGraphs = useModelFeedback(ModelAuditGraphs); 

    if(resultSetGraphs.feedback){
        return resultSetGraphs.feedback
    }
    
    return <div>
        <UISwitch style={{float:'right', marginRight: 10}}
                value={switchValue}
                data={[
                    {
                        id: 'small',
                        label: 'Small Cards'
                    },
                    {
                        id: 'medium',
                        label: 'Medium Cards'
                    },
                    {
                        id: 'large',
                        label: 'Large Cards'
                    }
                ]}
                onChange={setSwitchValue}/>
        <DashboardDefaultPage title="All available cards">
            {resultSetGraphs.data.map((item, key)=>{
                return <React.Fragment key={key}>
                    {(item.cardSize === switchValue) ?
                        cardsConfig[item.id]? cardsConfig[item.id].component: null:
                        null
                    }
                </React.Fragment>

            })}
        </DashboardDefaultPage>
    </div>;

}

export {ViewDashboardCharts}