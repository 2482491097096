import React from "react";
import {PropTypes} from 'prop-types';
import {UIButtonPaper} from '../../../components/ui/button/paper.js';
import styles from "../../../assets/variables.scss";


const AdminComponentSideBar = function (props) {

    return <div style={{float: 'right', width: '25%'}}>

        {Object.keys(props.options).map(option => (
            <UIButtonPaper key={option}
                    title={props.options[option]} 
                    onClick={()=>{props.onChange(option)}}
                    iconStyle={{display: option===props.value? 'none': 'block'}}
                    style={{
                        fontWeight: option===props.value? 'bold': 'normal', 
                        borderLeft: option===props.value? '5px solid ' + styles.primaryColor: '0'
                    }} />
        ))}

    </div>;
}

AdminComponentSideBar.defaultProps = {
    onChange: () => {}
};

AdminComponentSideBar.propTypes = {
    options: PropTypes.object.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func
};

export {AdminComponentSideBar};