import React, {useState} from 'react';
import {ParagraphsList} from './list.js';
import {ViewSettingsPageHeader} from '../page/header.js';
import {ParagraphEdit} from './edit.js';
import {useModelFeedback} from '../../../../helpers/model/use_model_feedback.js';
import {ModelParagraph} from '../../../../model/admin/paragraph.js';

const ViewSettingsParagraphs = function (props) {
    const resultSet = useModelFeedback(ModelParagraph)
    const [paragraphId, setParagraphId] = useState(null);
    if(resultSet.feedback){
        return resultSet.feedback;
    }


    return <div>
        <ViewSettingsPageHeader {...props}/>
        {(paragraphId === null) ?
            <ParagraphsList data={resultSet.data} editItem={(value) => setParagraphId(value)}/> :
            <ParagraphEdit paragraph={resultSet.data.filter((item)=>item.id === paragraphId)} close={() => setParagraphId(null)}/>

        }
    </div>
};

ViewSettingsParagraphs.defaultProps = {
    data: [
        {
            id: 5,
            type: 'test_type',
            text: 'test_text'
        }
    ]
}

export {ViewSettingsParagraphs}