import React from "react";
import {UrlStateContext} from "../../../helpers/url-state-context/url-state-context.js";
import {StateConsumer} from "../../../helpers/state-context.js";
import {ViewAdminUsers} from "./users.js";
import {UserEdit} from './edit.js';
import {AuthAccessValidator} from '../../../components/auth/access-validator.js';

const ViewAdminUserIndex = function (props) {

    return <UrlStateContext path={['view', 'id']} initialState={{view: 'list', id: null}} >

        <StateConsumer watch={['view', 'id']}>
            {(state, ) => {
                if(state.view === 'edit' || state.view === 'new'){
                    return <>
                        <AuthAccessValidator showError={true}
                                validateUser={(user) => (user.permissions.includes('manage_users'))}>
                            <UserEdit userId={parseInt(state.id)} />
                        </AuthAccessValidator>

                    </>;

                }else{
                    return <>
                        <AuthAccessValidator showError={true}
                                validateUser={(user) => (user.permissions.includes('manage_users'))}>

                            <div style={{padding: '0 15px'}}>
                                <ViewAdminUsers/>
                            </div>
                        
                        </AuthAccessValidator>
                    </>
                }
            }}

        </StateConsumer>

    </UrlStateContext>;

}

export {ViewAdminUserIndex}