import React from 'react';
import {useStateContext} from "../../../helpers/state-context.js";
import {Card} from "../../../components/card/card.js";
import {ReactComponent as ArrowRight} from "../../../assets/icons/arrow_right.svg";
import {ReactComponent as ArrowChange} from "../../../assets/icons/no-change.svg";
import {ReactComponent as ArrowUp} from "../../../assets/icons/arrow-up.svg";
import {ReactComponent as ArrowDown} from "../../../assets/icons/arrow-down.svg";
import {ModelDashboardAnswerDistribution} from "../../../model/dashboard/answer-distribution.js";
import {useModel} from "../../../helpers/model/use_model.js";
import {getLastPeriod} from "../../../helpers/functions/periods.js";

const iconStyle = {
    width: '70%',
    height: 'auto',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)'
};

const partStyle = { 
    width: '35%',
    height: '55%'
}

const iconContainerStyle = {
    //padding: 10,
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    margin: '0 25px',
    width: '35%',
    position: 'relative',
    height: '0',
    paddingBottom: '35%'
}

const GetAmount = (question_id, group_id, selectedPeriod)=>{
    const resultSet = useModel(ModelDashboardAnswerDistribution,
        { 
            question_id: question_id,
            periods: [selectedPeriod, getLastPeriod(selectedPeriod)],
            group_id: group_id
        });
    if(resultSet.status === 'success' && resultSet.data.length > 1){
        const currentValue = resultSet.data.find((row)=> row.period === selectedPeriod)?.value;
        const previousValue =resultSet.data.find((row)=> row.period !== selectedPeriod)?.value;
        return [currentValue , previousValue];
    }else if(resultSet.status === 'success' && resultSet.data.length >0 && resultSet.data[0].period === selectedPeriod){
        return [resultSet.data[0].value, resultSet.data[0].value];
    }
    return [0, 0]
}

const DashboardCardNumber = function (props) {
    const {title, question_id} = props;
    const [state] = useStateContext(['period', 'group']);

    const [newNumber, number] = GetAmount(question_id, state.group, state.period);
    const isIncreased = (newNumber > number);
    const isEqual = (newNumber === number);
    const increasePercentage = (((newNumber - number) / number )* 100).toFixed()

    const color = (isEqual)? 'rgb(231, 131, 36)' : (isIncreased) ? 'rgb(31, 175, 197)' : 'rgb(231, 36, 92)';
    const colorAlpha = (isEqual)? 'rgba(242, 212, 166, .5)' : (isIncreased) ? 'rgba(101, 216, 234, .5)' : 'rgba(242, 166, 188, .5)';
    const icon = (isEqual)?
        <ArrowChange style={{...iconStyle, fill: color}}/> :
        (isIncreased) ?
            <ArrowUp style={{...iconStyle, fill: color}}/> :
            <ArrowDown style={{...iconStyle, fill: color}}/>;


    return <Card id={props.id} cardSize={props.cardSize}>
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
            <div style={{...iconContainerStyle, backgroundColor: colorAlpha}}>
                {icon}
            </div>
            <div style={partStyle}>
                <h3>{title}</h3>
                <h1 style={{margin: 0}}>{newNumber}</h1>
                <span style={{color: color}}>
                    {isNaN(increasePercentage)? 
                        '-':    // show dash when the percentage can not be calculated
                        <>
                            {isEqual?   // only show arrow when there is a difference
                                null:
                                <ArrowRight style={{
                                            transform: 'rotate(' + (isIncreased? -45: 45) + 'deg)',
                                            marginRight: '10px'
                                        }}/>
                            }
                            {(isIncreased? '+' : '') + increasePercentage}%
                        </>
                    }
                </span>
            </div>
        </div>
    </Card>
};

DashboardCardNumber.defaultProps = {
    title: 'title',
    number: 100,
    newNumber: 105,
    question_id: 5
};

export {DashboardCardNumber}
