import React, {useState} from "react";
import {useStateContext} from "../../../helpers/state-context.js";
import {ChartAverageBar} from "../../../components/chart/average-bar/average-bar.js";
import {ReactComponent as Check} from "../../../assets/icons/check.svg";
import {ReactComponent as Alarm} from "../../../assets/icons/status_alarm.svg";
import {useModelFeedback} from "../../../helpers/model/use_model_feedback.js";
import {ModelDashboardAnswers} from "../../../model/dashboard/answers.js";
import {ModelDashboardAnswerDistribution} from "../../../model/dashboard/answer-distribution.js";
import {Card} from "../../../components/card/card.js";
import {CardHeader} from "../../../components/card/header.js";
import {UIInputSelect } from "../../../components/ui/input/select";
import Tooltip from '../../../helpers/elements/tooltip.js';

const iconStyle = {
    width: 25,
    height: 25
};

const questionOptions = {
    // list is in alphabetical order, need strings as key to maintain the order
    'q97': 'Administrators',
    'q72': 'Care Coordinators',
    'q73': 'CBTp Therapists',
    'q75': 'Family Intervention Therapists',
    'q70': 'Non-Medical Prescribers',
    'q95': 'Peer Support Workers',
    'q69': 'Psychiatrists',
    'q93': 'Psychologists',
    'q94': 'Support Workers',
    'q68': 'Team Leaders'
};

const formatData = function(resultSet){
    if(resultSet.status !== ModelDashboardAnswers.Status.SUCCESS){
        return resultSet;
    }

    // we have answers for question 5 (caseload) and one of the selected workforce anwers for all teams
    // we want to have the caseload by care worker (q5 / q_selected)
    // merge answers by group
    const groupResult = resultSet.data.reduce((groups, row) => {
        groups[row.group_id] = groups[row.group_id] || {};
        if(row.id === 5){
            groups[row.group_id].caseload = row.value;
        }else{
            groups[row.group_id].workers = row.value;
        }
        return groups;
    }, {});
    // get a list of the values
    const values = Object.values(groupResult)
            .map((group) => ( group.caseload / group.workers ))
            // filter out NaN values (workers == 0)
            .filter((value) => ( value !== Infinity && ! isNaN(value) )); 
    // return all usefull metrics
    const data = {
        values: values,
        average: values.length===0? 
                0: (values.reduce((sum, item) => ( sum + item ), 0) / values.length).toFixed()
    };

    return {...resultSet, data};
}

const DashboardCardWorkForceOverview = function (props) {

    const [questionId, setQuestionId] = useState('q97');
    const [state] = useStateContext(['period', 'group']);

    const resultSet = useModelFeedback(ModelDashboardAnswers, 
            {
                question_ids: [parseInt(questionId.substring(1), 10), 5], 
                period: state.period,
                select: 'group_id, answer as value, question_id as id'
            },
            (model) => ( formatData(model.getData()) ));

    // need this, because group can be a provider an in that case we need to sum up the answers of all teams
    // ModelDashboardAnswerDistribution does that.
    const resultSetTeam = useModelFeedback(ModelDashboardAnswerDistribution, 
            {
                question_ids: [parseInt(questionId.substring(1), 10), 5], 
                period: state.period, 
                group_id: state.group,
            },
            (model) => ( formatData(model.getData())) );

    if(resultSet.feedback || resultSetTeam.feedback){
        return resultSet.feedback || resultSetTeam.feedback;
    }

    const teamValue = resultSetTeam.data.average;   // since only one group, the average is the value here
    const average = resultSet.data.average;
    const values = resultSet.data.values;
    // Note that formatData converts the averate to a string with toFixed(), so before doing comparison we 
    // need to convert it to an number again.
    const isPositive = parseFloat(teamValue) <= parseFloat(average);


    const onHover = (e, item) => {
        if (item === null) {
            return Tooltip.getInstance().setState({});
        }

        Tooltip.getInstance().setState({
            position: [e.clientX, e.clientY - 8],
            header: false,
            text: item.title,
            anchor: 'bottom'
        });
    }

    const statusFeedback = isPositive?
        <Check style={iconStyle}
                onMouseOver={(e) => onHover(e, {title: "Good work! Your team is doing great!"})}
                onMouseOut={(e) => onHover(e, null)} />:
        <Alarm style={iconStyle}
                onMouseOver={(e) => onHover(e, {title: "Your team has a relatively high caseload per health care worker."})}
                onMouseOut={(e) => onHover(e, null)} />;

    return <Card id={props.id} cardSize={props.cardSize}>

        <CardHeader id={props.id}
                rightColumn={statusFeedback}/>

        <div style={{maxWidth: '200px'}}>
            <UIInputSelect options={questionOptions}
                    name={'period-select'}
                    onChange={(value)=>{setQuestionId(value)}}
                    value={questionId} />
        </div>

        <p>{props.description}</p>
        

        <ChartAverageBar data={values} pin={teamValue}/>

    </Card>
};


export {DashboardCardWorkForceOverview}

