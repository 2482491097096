import React, {useEffect, useState} from "react";
import "./export.scss";
import {UIButton} from "../../../components/ui/button/button.js";
import {ViewAdminExportStepOne} from "./step-one.js";
import {ViewAdminExportStepTwo} from "./step-two.js";
import {getCurrentPeriod} from "../../../helpers/functions/periods.js";
import {Card} from "../../../components/card/card.js";
import {useStateContext} from "../../../helpers/state-context.js";
import {config} from "../../../config.js";

const ViewAdminExportAuditReport = function (props) {

    const [state, ] = useStateContext(['group']);
    const orderedSection = ['Q','N','O','W', 'I'];
    const [exportSettings, setExportSettings] = useState({
        groupType: 'team',
        groupId: state.group,
        regionId: 0, // 0 = all teams
        periods: [getCurrentPeriod()],
        sections: orderedSection.slice(0)
    });

    useEffect(() => {
        if(parseInt(state.group) !== exportSettings.groupId){
            setExportSettings({...exportSettings, groupId: parseInt(state.group)});
        }
        if(exportSettings.groupType !== 'period' && exportSettings.periods.length > 1){
            setExportSettings({...exportSettings, periods: [getCurrentPeriod()]});
        }
    }, [state.group, exportSettings, setExportSettings]);


    const getReport = () => {
        const hasPeriods = exportSettings.periods.length > 0;
        const hasIndicators = exportSettings.sections.length > 0
        if(! hasPeriods){
            alert('Please select at lease one period.');
            return;
        }
        if(! hasIndicators){
            alert('Please select at least one section for the export.');
            return;
        }

        let filter = '(period eq \'' + exportSettings.periods.join('\' or period eq \'') + '\')';
        if(exportSettings.groupType === 'period'){
            filter += ' and group_id eq ' + exportSettings.groupId;
        }else{
            filter += ' and group_id eq ' + exportSettings.regionId;
        }

        const sections = exportSettings.sections
                .sort((a, b) => ( orderedSection.indexOf(a) - orderedSection.indexOf(b) ))
                .join(',');

        const payload = {
            groupType: exportSettings.groupType,
            filter: filter,
            sectionsSelected: sections,
            token: localStorage.getItem('auth_token')
        }
        const p = [];
        for(let key in payload){
            p.push(encodeURIComponent(key) + '=' + encodeURIComponent(payload[key]));
        }
        const params = p.join('&');

        window.open(config.api_endpoint + 'export?' + params);
        
    };

    
    return <div>
        
        <h1>Generate audit report</h1>
        <Card cardSize={'large'} style={{height: 'auto'}}>
            <ViewAdminExportStepOne settings={exportSettings} setSettings={setExportSettings} />
            <ViewAdminExportStepTwo settings={exportSettings} setSettings={setExportSettings} />

            <div style={{float:'right', display:'inline-block'}}> 
                <UIButton style={{marginRight: '10px'}} onClick={getReport}>
                    Dowload Excel data
                </UIButton>
            </div>

        </Card>
     

    </div>
}

export {ViewAdminExportAuditReport}
