const questionIds = [56, 57, 59]; // these are used to calculate the 2 outcome measure
const indicatorIds = [2, 5, 8, 10, 12, 14, 16];

const getIndicatorQuestionIds = function (data){
    // put it in a Set to prevent duplicates
    const questionIds = new Set();
    for(const item of data){
        questionIds.add(item.fk_field1);
        questionIds.add(item.fk_field2);
    }
    return [...questionIds];
}

function findData(data, periods, numeratorId, denominatorId){
    const results = {};
    for(const period of periods){
        const numerator = data.find((item) => ( 
            item.id === numeratorId && item.period === period 
        ))?.value;
        const denominator = data.find((item) => ( 
            item.id === denominatorId && item.period === period
        ))?.value;

        results[period] = denominator === undefined || numerator === undefined?
                '-':
                (denominator? (100 * numerator / denominator).toFixed(): 0) + '%';
    }
    return results;
}

const getLevel = function(value, thresholds){
    value = value.replace('%', '');
    if(! thresholds || isNaN(value)){
        return 0;
    }
    let level = 1;
    for(const t of thresholds){
        if(value >= t){
            level++;
        }
    }
    return level;
}

const getScore = function(levels, outcomeLevel){
    if(levels[0] > 1){
        return 0;
    }else if(levels[4] === 6 && outcomeLevel >= 3){
        return 4;
    }else if(levels[3] + levels[4] === 6 && outcomeLevel >= 2){
        return 3;
    // you may have max 2 items (including outcome) in level 1 to end up as level 2
    }else if((levels[1] < 3 && outcomeLevel > 1) || levels[1] < 2){
        return 2;
    }
    return 1;
}

export {questionIds, indicatorIds, findData, getLevel, getScore, getIndicatorQuestionIds};