import React from "react";
import PropTypes from "prop-types";
import {getColor} from './functions.js';

const VerticalBarGrouped = function (props) {
    const {axisState, axis, axisTitles, data, palette, onMouseHover, calculateRoundedPath, hover} = props;
    const lines = props.lines ? props.lines.sort((a,b)=> (a.value>b.value)?1:-1): false;
    return <g>
        {data.map((group, i) => {
            const x = axisState.x(group[axis.x])
            return <g key={i} transform={`translate(${(x === undefined || x <= 0) ? 0 : x}, 0)`}>
                {axis.y.map((groupKey, j) => {
                    const groupItem = group[groupKey];
                    const fill = group.color ?
                        group.color :
                        lines ?
                            getColor({data: group},lines) :
                            (Array.isArray(palette[j])) ?
                                `rgb(${palette[j]})` :
                                palette[j]
                    // if there is an array value and values are the same, add some extra to display in chart
                    const doubleExtendValue =
                        (Array.isArray(groupItem) && groupItem[0] === groupItem[1]) ? 0 : 0;

                    const valueStart = (Array.isArray(groupItem)) ? groupItem[0] - doubleExtendValue : 0;
                    const valueEnd = (Array.isArray(groupItem)) ?
                        groupItem[1] + doubleExtendValue : groupItem;
                    let x = axisState.x1(groupKey);
                    let height = axisState.y(valueStart) - axisState.y(valueEnd);
                    let y = axisState.y(valueEnd);

                    const bandWidth = axisState.x1.bandwidth();
                    const width = Math.min(20, bandWidth);
                    x = x + (bandWidth - width) / 2;    // if width less that bandWidth, align bar to center

                    if(height < 20){
                        y -= (10 - height);
                        height = 10;
                    }
                    const path = calculateRoundedPath(x, y, width, height, true, false);

                    const title = axisTitles? axisTitles.y[j] : groupKey;
                    const tooltip = props.shortTooltip === true ? {[title]: groupItem} :{[title]: groupItem, ...group}; //short term solution to tooltip problem, todo: create tooltip on card component
                    return <path key={j} d={path} fill={fill}
                                 style={{opacity: (hover === `${i+j}` || hover === '') ? 1 : .5}}
                                 onMouseOut={(e) => onMouseHover(e, null)}
                                 onMouseOver={(e) =>
                                     onMouseHover(e, {
                                         data: tooltip,
                                         key: groupKey,
                                         axis: axis,
                                         hover: `${i+j}`
                                     })} />
                })}
            </g>
        })}
    </g>
};

VerticalBarGrouped.propTypes = {
    hover: PropTypes.string,
    calculateRoundedPath: PropTypes.func,
    axisState: PropTypes.object,
    data: PropTypes.array,
    axis: PropTypes.shape({
        x: PropTypes.string,
        y: PropTypes.arrayOf(PropTypes.string)
    }),
    palette: PropTypes.array,
    onMouseHover: PropTypes.func,
};


export {VerticalBarGrouped}