import React from "react";
import {UIText} from "../../components/ui/text.js";
import {UIButton} from "../../components/ui/button/button.js";
import {useStateContext} from "../../helpers/state-context.js";
import {useModelFeedback} from '../../helpers/model/use_model_feedback.js';
import {ModelParagraph} from '../../model/admin/paragraph.js';



const ViewOnboard = function (props) {
    const resultSet = useModelFeedback(ModelParagraph, {type: 'sign_in'})
    const [,dispatch] = useStateContext();

    if(resultSet.feedback){
        return resultSet.feedback;
    }

    return <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
        <div style={{maxWidth: 850, padding: 10}}>
            <UIText type={'head-1'} color={'darkColor'} style={{marginBottom: 10}}>
                Welcome to the EIP Matrix
            </UIText>
            <UIText type={'body-L'} color={'mediumColor'} style={{whiteSpace: 'pre-line'}}>
                {resultSet.data[0].text}
            </UIText>

            <div style={{display: 'flex', justifyContent: 'center'}}>
                <UIButton style={{width: 130, margin: 25}}
                          onClick={()=>{dispatch({type: 'page', value: 'sign-in'})}}>
                    Sign in
                </UIButton>
                <UIButton className={'off-white'} style={{width: 130, margin: 25}}
                          onClick={()=>{dispatch({type: 'page', value: 'sign-up'})}}>
                    Sign up
                </UIButton>
            </div>
        </div>
    </div>
}

export {ViewOnboard}