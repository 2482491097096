import React from "react";
import {KPIEmoticon} from '../../../../components/kpi/emoticon.js';
import { formatNumber } from "../../../../helpers/format/number.js";
import {auditStepsConfig} from "../config.js";


const getPercentage = function(value){
    if(isNaN(value)){
        return '-';
    }
    return Math.round(100 * value);
}

const getAnswerValue = function(answers, questionId){
    for(const a of answers){
        if(a.q === questionId){
            for(let section of auditStepsConfig){
                for(let question of section.content){
                    if(question.id === questionId){
                        if(question.options && question.options[a.v]){
                            return question.options[a.v];
                        }
                        break;
                    }
                }
            }
       
            return a.v;
        }
    }
    return '-';
}

const getAnswerPercentage = function(answers, denominator, numerator){
    let numeratorValue = 0;
    if(Array.isArray(numerator)){
        for(const id of numerator){
            numeratorValue += getAnswerValue(answers, id);
        }
        numeratorValue = numeratorValue / numerator.length;
    }else{
        numeratorValue = getAnswerValue(answers, numerator);
    }
    denominator = getAnswerValue(answers, denominator);
    if(isNaN(numeratorValue) || isNaN(denominator) || denominator === 0){
        return '-';
    }
    return Math.round(100 * numeratorValue / denominator);
}

const getIndicatorValue = function(answers, indicatorData, indicatorId){
    const indicator = indicatorData.find((item) => ( item.id === indicatorId));
    let numerator = 0;
    let denominator = 0;
    if(indicator){
        for(const answer of answers){
            if(answer.q === indicator.fk_field1){
                numerator = answer.v;
            }else if(answer.q === indicator.fk_field2){
                denominator = answer.v;
            }
        }
    }else{
        console.warn("Could not find data for indicator " + indicatorId);
    }
    if(denominator === 0){
        return '-';
    }
    return numerator / denominator;
}

const getIndicatorPercentage = function(answers, indicatorData, indicatorId){
    return getPercentage(getIndicatorValue(answers, indicatorData, indicatorId));
}

const getThresholds = function(indicatorData, indicatorId){
    const indicator = indicatorData.find((item) => ( item.id === indicatorId));
    if(indicator){
        return [indicator.threshold1, indicator.threshold2, indicator.threshold3];
    }
    console.warn("Could not find data for indicator " + indicatorId);
    return [0.25, 0.5, 0.75];
}


function getNICEIndicators(indicatorData){
    // We could load the titles from the indicatorData, not sure if we want that though...
    return [
        {
            title: 'CBT for Psychosis', // indicator 1: question: n1a - 26
            width: 1,
            value: (row) => ( getIndicatorPercentage(row.answers, indicatorData, 1) ),
            renderCell: (value) => ( <KPIEmoticon value={value} thresholds={getThresholds(indicatorData, 1)} /> )
        },{
            title: 'Family Interventions', // indicator 5: question: n2b - 29
            width: 1,
            value: (row) => ( getIndicatorPercentage(row.answers, indicatorData, 5) ),
            renderCell: (value) => ( <KPIEmoticon value={value} thresholds={getThresholds(indicatorData, 5)} /> )
        },{
            title: 'Clozapine', // indicator 8: question N3b - 33
            width: 1,
            value: (row) => ( getIndicatorPercentage(row.answers, indicatorData, 8) ),
            renderCell: (value) => ( <KPIEmoticon value={value} thresholds={getThresholds(indicatorData, 8)} /> )
        },{
            title: 'Physical Health Checks', // indicator 12, question: N5 - 37
            width: 1,
            value: (row) => ( getIndicatorPercentage(row.answers, indicatorData, 12) ),
            renderCell: (value) => ( <KPIEmoticon value={value} thresholds={getThresholds(indicatorData, 12)} /> )
        },{
            title: 'Smoking Cessation', // indicator: 14, question: N6a - 39
            width: 1,
            value: (row) => ( getIndicatorPercentage(row.answers, indicatorData, 14) ),
            renderCell: (value) => ( <KPIEmoticon value={value} thresholds={getThresholds(indicatorData, 14)} /> )
        },{
            title: 'Carer Education', // indicator 16, question N8 - 41
            width: 1,
            value: (row) => ( getIndicatorPercentage(row.answers, indicatorData, 16) ),
            renderCell: (value) => ( <KPIEmoticon value={value} thresholds={getThresholds(indicatorData, 16)} /> )
        }
    ]
}

function getExpectedCaseload(data, row){
    return data.find((item)=>item.group_id === row.group.id)?.value;
}

function getInvestementPerPatient(value){
    if(! value || isNaN(value)){
        return '-';
    }
    return '£' + formatNumber(value, true, 4);
}

const getCaseloadPrevalence = function(row, expectedData){
    const expected = getExpectedCaseload(expectedData, row);
    if(! expected || isNaN(expected)){
        return '-';
    }
    const caseload = getAnswerValue(row.answers, 5);
    return caseload === '-'? caseload: getPercentage(caseload / expected);
}

function getCaseloadIndicators(indicatorData, expectedData){
    return [
        {
            title: 'Caseload VS Prevalence', 
            width: 1,
            value: (row) => ( getCaseloadPrevalence(row, expectedData) ),
            renderCell: (value) => ( <span>{isNaN(value)? value: value + '%'}</span> )
        },{
            title: 'Investment per Patient', 
            width: 1,
            value: (row) => ( getInvestementPerPatient(getIndicatorValue(row.answers, indicatorData, 17)) ),
            renderCell: (value) => ( <span>{value}</span> )
        },{
            title: 'Service Model Type', 
            width: 1,
            value: (row) => ( getAnswerValue(row.answers, 1) ),
            renderCell: (value) => ( <span>{value || '-'}</span> )
        },{
            title: 'Commissioned for ARMS?', 
            width: 1,
            value: (row) => ( getAnswerValue(row.answers, 19) ),
            renderCell: (value) => ( <span>{value || '-'}</span> )
        }
    ]
}



function getOutcomeIndicators(indicatorData){

    // TODO: We should make indicators for these (and include thresholds). But then remove 2 outcome measures as possiblity
    return [
        {
            title: 'Employment/Education', //  49
            width: 1,
            value: (row) => ( getAnswerPercentage(row.answers, 34, 49) ),
            renderCell: (value) => ( <span>{isNaN(value)? value: value + '%'}</span> )
        },{
            title: 'Settled Accommodation', //  50
            width: 1,
            value: (row) => ( getAnswerPercentage(row.answers,5, 50) ),
            renderCell: (value) => ( <span>{isNaN(value)? value: value + '%'}</span> )
        },{
            title: 'Admitted to Hospital', //  45
            width: 1,
            value: (row) => ( getAnswerPercentage(row.answers, 5, 45) ),
            renderCell: (value) => ( <span>{isNaN(value)? value: value + '%'}</span> )
        },{
            title: 'Admitted Under Section', //  46
            width: 1,
            value: (row) => ( getAnswerPercentage(row.answers,45, 46) ),
            renderCell: (value) => ( <span>{isNaN(value)? value: value + '%'}</span> )
        },{
            title: 'Admitted for 28 Days or Longer', // 47
            width: 1,
            value: (row) => ( getAnswerPercentage(row.answers, 45, 47) ),
            renderCell: (value) => ( <span>{isNaN(value)? value: value + '%'}</span> )
        },{
            title: '2 Outcome Measures Recorded', 
            width: 1,
            value: (row) => ( getAnswerPercentage(row.answers, 59, [56, 57]) ),
            renderCell: (value) => ( <KPIEmoticon value={value} thresholds={[0.25, 0.5, 0.75]} /> )
        }
    ]
}

export {
    getNICEIndicators, 
    getIndicatorPercentage, 
    getThresholds, 
    getCaseloadIndicators, 
    getOutcomeIndicators
};