import React from "react";
import {PropTypes} from 'prop-types';
import {UIButton} from "./button/button.js";

const UISwitch = function (props) {
    
    const styleSwitch = {
        display: 'flex',
        flexDirection: (props.direction === 'horizontal') ? 'initial' : 'column',
        backgroundColor: '#e2e7fc',
        border: '5px solid #e2e7fc',
        maxWidth: 'fit-content',
        borderRadius: 10,
        float: 'left',
        ...props.style
    };

    return <div style={styleSwitch}>
        {props.data.map((item)=>{
            return <UIButton key={item.id}  
                    className={(props.value === item.id) ? 'primary' : 'medium'}
                    onClick={()=>props.onChange(item.id)}>

                {item.label}

            </UIButton>
        })}
    </div>
};

UISwitch.propTypes = {
    direction: PropTypes.oneOf(['horizontal', 'vertical']),
    data: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.any.isRequired,
        label: PropTypes.any.isRequired
    })).isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func
};

UISwitch.defaultProps = {
    direction: 'horizontal',
    value: null,
    onChange: () => {}
};


export {UISwitch}