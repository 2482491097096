// Taken from https://github.com/suitcss/preprocessor/blob/master/lib/encapsulation.js

export const inheritedStyle = {
    borderCollapse: 'separate',
    borderSpacing: 0,
    boxSizing: 'content-box',
    captionSide: 'top',
    color: 'initial',
    cursor: 'auto',
    direction: 'initial',
    emptyCells: 'show',
    fontSizeAdjust: 'none',
    fontFamily: 'initial',
    fontSize: 'medium',
    fontStyle: 'normal',
    fontStretch: 'normal',
    fontVariant: 'normal',
    fontWeight: 'normal',
    font: 'initial',
    letterSpacing: 'normal',
    lineHeight: 'normal',
    listStyleImage: 'none',
    listStylePosition: 'outside',
    listStyleType: 'disc',
    listStyle: 'initial',
    orphans: 2,
    quotes: 'initial',
    tabSize: 8,
    textAlign: 'initial',
    textAlignLast: 'auto',
    textDecorationColor: 'initial',
    textIndent: 0,
    textJustify: 'auto',
    textShadow: 'none',
    textTransform: 'none',
    visibility: 'visible',
    whiteSpace: 'normal',
    widows: 2,
    wordBreak: 'normal',
    wordSpacing: 'normal',
    wordWrap: 'normal'
};

export const nonInheritedStyle = {
    animation: 'none 0s ease 0s 1 normal none running',
    backfaceVisibility: 'visible',
    background: 'transparent none repeat 0 0 / auto auto padding-box border-box scroll',
    border: 'medium none currentColor',
    borderImage: 'none',
    borderRadius: '0',
    bottom: 'auto',
    boxShadow: 'none',
    clear: 'none',
    clip: 'auto',
    columns: 'auto',
    columnCount: 'auto',
    columnFill: 'balance',
    columnGap: 'normal',
    columnRule: 'medium none currentColor',
    columnSpan: '1',
    columnWidth: 'auto',
    content: 'normal',
    counterIncrement: 'none',
    counterReset: 'none',
    cssFloat: 'none',
    height: 'auto',
    hyphens: 'none',
    left: 'auto',
    margin: '0',
    maxHeight: 'none',
    maxWidth: 'none',
    minHeight: '0',
    minWidth: '0',
    opacity: '1',
    outline: 'medium none invert',
    overflow: 'visible',
    overflowX: 'visible',
    overflowY: 'visible',
    padding: '0',
    pageBreakAfter: 'auto',
    pageBreakBefore: 'auto',
    pageBreakInside: 'auto',
    perspective: 'none',
    perspectiveOrigin: '50% 50%',
    position: 'static',
    right: 'auto',
    tableLayout: 'auto',
    textDecoration: 'none',
    top: 'auto',
    transform: 'none',
    transformOrigin: '50% 50% 0',
    transformStyle: 'flat',
    transition: 'none 0s ease 0s',
    unicodeBidi: 'normal',
    verticalAlign: 'baseline',
    width: 'auto',
    zIndex: 'auto'
};
