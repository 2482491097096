import './progress-steps.scss'
import React, {useState, useEffect} from "react";
import PropTypes from 'prop-types';
import {UIProgressStepsNavigationDot} from "./navigation-dot.js";
import {UIProgressStepsFooter} from "./footer.js";

const UIProgressSteps = function (props) {
    const {variant, steps} = props;

    // we keep the status whether a step is done and the currentStep internally
    const [stepStatus, setStepStatus] = useState(steps.map(() => false));
    const [currentStep, setCurrentStep] = useState(null);


    // when initial loaded, validate all steps and set the first step to start with (this is the first step 
    // that is not yet valid). If props.startIndex is set, that one is used to indicate where to start and 
    // the steps will not be validated
    useEffect(() => {
        const newStatus = [];
        let currentStepIsSet = false;
        for(let i = 0; i < steps.length; i++){
            if(props.startIndex !== undefined){
                newStatus.push(i < props.startIndex);
            }else{
                newStatus.push(props.validateStep(steps[i], i, currentStep));
            }
            if(! newStatus[i] && ! currentStepIsSet){
                setCurrentStep(i);
                currentStepIsSet = true;
            }
            if(! currentStepIsSet){ // all steps completed, start at the beginning
                setCurrentStep(0);
            }
        }

        setStepStatus(newStatus);

        // disable warning: we only want this effect to run once, even when dependencies currentStep, 
        // stepStatus, props.validateStep or steps change.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    const currentIndex = currentStep===null? 0: currentStep;
    const isLastStep = currentIndex === steps.length - 1;
    const isFirstStep = currentIndex === 0;

    // navigate to another set
    const updateStep = (to) => {
        if(to === 'next'){
            // we let this callback decide if the current step is completed before we can continue
            if(props.validateStep(steps[currentStep], currentStep, currentStep)){
                stepStatus[currentStep] = true;
                //todo: if same answer as previous period --> give message
                if(! isLastStep){
                    setCurrentStep(currentStep + 1);
                    props.onSelectStep(currentStep + 1);
                }else{
                    props.onFinish();
                }
            }else{
                stepStatus[currentStep] = false;
            }
            setStepStatus(stepStatus);
        }else if(to === 'previous'){
            // previous is always allowed (for now), we don't need to validate anything
            if(! isFirstStep){
                setCurrentStep(currentStep - 1);
                props.onSelectStep(currentStep - 1);
            }

        }else{
            // check if all the steps before the one that we want to open is valid. That way we know we can 
            // proceed to the requested step.
            to = parseInt(to);
            for(let i = 0; i < to; i++){
                if(! props.validateStep(steps[i], i, currentStep)){
                    stepStatus[i] = false;
                    // not valid, go to this step instead
                    setCurrentStep(i);
                    props.onSelectStep(i);
                    return;
                }else{
                    stepStatus[i] = true;
                }
            }
            setStepStatus(stepStatus);
            setCurrentStep(to);
            props.onSelectStep(to);
        }
    };

    return <div className={'zol-ui-progress-steps-container'}>
        <div className={`zol-ui-progress-steps-${variant}`}>
            {steps.map((step, index)=>{
                return <UIProgressStepsNavigationDot key={index} 
                        width={100 / steps.length}
                        isActive={(currentStep===null && index === 0) || index === currentStep}
                        isCompleted={stepStatus[index]}
                        onClick={updateStep}
                        stepIndex={index}
                        step={step}
                        isFirst={index === 0}
                        isLast={index === steps.length - 1}/>
            })}
        </div>

        <div className={'zol-ui-progress-steps-content'}>

            {props.children(steps[currentIndex].content, steps[currentIndex].id, steps[currentIndex].title)}

            <UIProgressStepsFooter 
                    onPrevClick={(isFirstStep) ? null : () => updateStep('previous')}
                    onNextClick={()=> updateStep('next')}
                    nextText={(isLastStep) ? 'Finish' : 'Next'} />
       </div>
       
    </div>
}

UIProgressSteps.defaultProps ={
    variant: 'horizontal',
    validateStep: (step, stepIndex, currentSelectedIndex) => { 
        // by default only the current step and those before it are considered valid
        if(currentSelectedIndex && stepIndex <= currentSelectedIndex){
            return true;
        }
        return false;
    },
    onSelectStep: () => {},
    onFinish: () => {},
}

UIProgressSteps.propTypes = {
    variant: PropTypes.oneOf(['horizontal', 'vertical']),
    steps: PropTypes.PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
            title: PropTypes.string,
            content: PropTypes.any.isRequired          
        })
    ).isRequired,
    // function called when user want to proceed to next step, return true to continue or false to abort
    validateStep: PropTypes.func, 
    onSelectStep: PropTypes.func,
    onFinish: PropTypes.func,
    children: PropTypes.func.isRequired,
    startIndex: PropTypes.number
};

export {UIProgressSteps}