class RequestAnimationFrame{

    constructor(callback, scope, ...args){

        this.requestAnimationFrame = (function(){
            return window.requestAnimationFrame ||
                window.webkitRequestAnimationFrame ||
                window.mozRequestAnimationFrame ||
                window.oRequestAnimationFrame ||
                window.msRequestAnimationFrame ||
                function(callback){
                    return window.setTimeout(callback, 1000/30);
                };
        })();

        this.callback = callback;
        this.scope = scope || null;
        this.args = args || [];
        this.time = Date.now();

        this.timerId = null;
        this.tick = this.tick.bind(this);
        this.nextCycle();

    }

    nextCycle(){
        if(this.requestAnimationFrame){
            this.timerId = this.requestAnimationFrame.call(window, this.tick);
        }
    }

    tick(){
        if(! this.callback){
            return;
        }

        let d = Date.now();
        let delta = d - this.time;
        this.time = d;

        this.callback.apply(this.scope, [...this.args, delta]);
        this.nextCycle();
    }

    stop(){
        if(this.timerId){
            if(window.cancelAnimationFrame){
                window.cancelAnimationFrame(this.timerId)
            }else{
                window.clearTimeout(this.timerId);
            }
        }
    }

    remove(){
        this.stop();
        for (var prop in this) {
            if (this.hasOwnProperty(prop)) {
                delete this[prop];
            }
        }
    }

}

export default RequestAnimationFrame;
