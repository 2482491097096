import React from "react";
import {ReactComponent as CarerSvg} from "../../assets/nice/carer_focused.svg";
import {ReactComponent as ClozapineSvg} from "../../assets/nice/clozapine.svg";
import {ReactComponent as CBTpSvg} from "../../assets/nice/cognative_behavior.svg";
import {ReactComponent as EducationSvg} from "../../assets/nice/education_employment.svg";
import {ReactComponent as FamilySvg} from "../../assets/nice/family_intervention.svg";
import {ReactComponent as HealthSvg} from "../../assets/nice/physical_health.svg";
import {ReactComponent as SmokeSvg} from "../../assets/nice/wellbeing_support.svg";

const iconStyle={
    width: '35px',
    margin: '0px 3px 0px 0px',
    verticalAlign : 'middle'
}

export const NICEIcons = [ //todo: do this per question_id instead of label
    {label: "Smoking Cessation", indicatorIds: [14], ids:[38,39], icon: <SmokeSvg style={iconStyle}/>, iconColor:'rgb(40, 151, 152)'},
    {label: "Cognitive Behavioral Therapy for Psychosis", indicatorIds: [2], ids: [25, 26], icon: <CBTpSvg style={iconStyle}/>, iconColor:'rgb(62, 123, 76)'},
    {label: "Physical Health Assessment", indicatorIds: [12], ids:[37], icon: <HealthSvg style={iconStyle}/>, iconColor:'rgb(122, 152, 82)'},
    {label: "Family Interventions", indicatorIds: [5], ids:[27,28,29], icon: <FamilySvg style={iconStyle}/>, iconColor:'rgb(76, 141, 88)'},
    {label: "Carer Focused Education & Support", indicatorIds: [16], ids:[40,41], icon: <CarerSvg style={iconStyle}/>, iconColor:'rgb(0, 114, 109)'},
    {label: "Individual Placement Support", indicatorIds: [10], ids:[34,35,36], icon: <EducationSvg style={iconStyle}/>, iconColor:'rgb(74, 69, 69)'},
    {label: "Clozapine", indicatorIds: [8], ids:[33], icon: <ClozapineSvg style={iconStyle}/>, iconColor:'rgb(154, 177, 33)'},
];
