import React from "react";
import {PropTypes} from 'prop-types';
import styles from "../../assets/variables.scss";
import {ReactComponent as IconStatusBad} from "../../assets/icons/emoticon_bad.svg";
import {ReactComponent as IconStatusLow} from "../../assets/icons/emoticon_low.svg";
import {ReactComponent as IconStatusGood} from "../../assets/icons/emoticon_good.svg";
import {ReactComponent as IconStatusGreat} from "../../assets/icons/emoticon_great.svg";

const KPIEmoticon = function (props){ 

    if(isNaN(props.value)){
        return <span>-</span>;
    }

    let thresholds = props.thresholds;
    if(! (thresholds instanceof Array) || thresholds.length !== 3){
        console.warn("KPIEmoticons expects an array of length 3 for the thressholds, fall back to default");
        thresholds = [.25, .5, .75];
    }


    const icons = [IconStatusBad, IconStatusLow, IconStatusGood, IconStatusGreat];
    const colors = [styles.brandRedColor, styles.brandYellowColor, styles.brandGreenColor, 
            styles.brandBlueColor];

    let Icon = icons[0];
    let color = colors[0];

    for(let i = 0; i < 3; i++){
        if(props.value > 100*thresholds[i]){
            Icon = icons[i+1];
            color = colors[i+1];
        }
    }

    return <span style={{color: color}}>
        <Icon style={{marginRight: 5, verticalAlign: 'middle', width: '22px', height: '22px'}}/> 
        {props.value + (props.isPercentage? '%': '')}
    </span>;

};

KPIEmoticon.propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    isPercentage: PropTypes.bool,
    thresholds: PropTypes.array
};

KPIEmoticon.defaultProps = {
    value: '-',
    isPercentage: true,
    thresholds: [0.25, 0.5, 0.75]
};

export {KPIEmoticon}