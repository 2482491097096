import {config} from '../../config.js';
import {ModelXhr} from '../../helpers/model/xhr.js';
import {getTwoYears} from "../../helpers/functions/periods.js";

class ModelDashboardMeasureTips extends ModelXhr {

    static modelName = "ModelDashboardMeasureTips";

    static getSelect(input) {
        const filter = [];
        // Only show tips that were given up until 2 years ago
        filter.push('(period eq \'' + getTwoYears().join('\' or period eq \'') + '\')'); 
        if(input.measure_id){
            filter.push('measure_id eq '+ input.measure_id);
        }

        const select = {
            filter: filter.join(' and '),
            order: 'id desc'    // most recent tip first
        };
        if(input.select){
            select.select = input.select;
        }

        return select
    }

    static createItem(data, onSuccess=()=>{}){
        this.saveData(ModelDashboardMeasureTips, config.api_endpoint + 'measure_tip', 'POST', 
            data,
            (response) => {
                if(response.success){
                    const graph = response.result? (response.result[0] || null): null;
                    onSuccess(graph);
                }
            }
        );
    }

    static updateItem(id, data, onSuccess=()=>{}){
        this.saveData(ModelDashboardMeasureTips, config.api_endpoint + 'measure_tip/'+id, 'PATCH', 
            data, 
            (response) => {
                if(response.success){
                    const graph = response.result? (response.result[0] || null): null;
                    onSuccess(graph);
                }
            }
        );
    }

    constructor(selectData) {
        super(selectData, {
            endpoint: config.api_endpoint + 'measure_tip',
            method: 'GET'
        });

        this.cacheEnabled = true;
    }

    getEndpoint(){
        // When we get items we use the view (tip controller) which include user and group names. Otherwise
        // we connect to the table (measure_tip).
        if(this.method === 'GET'){
            return config.api_endpoint + 'tip';
        }
        return config.api_endpoint + 'measure_tip';
    }

   
    getSelect(input) {
        return ModelDashboardMeasureTips.getSelect(input);
    }

}

export {ModelDashboardMeasureTips};
