
export const types = {
    SUBREGION: 'region',
    STP: 'stp',
    CCG: 'ccg',
    PROVIDER: 'provider',
    TEAM: 'team'
};

export const titles = {
    region: 'Subregions',
    stp: 'Sustainability and Tranformation Partnership (STP)',
    ccg: 'Clinical Commissioning Group (CCG)',
    provider: 'Provider',
    team: 'EIP Team'
};

export const parents = {
    region: null,
    stp: 'region',
    ccg: 'stp',
    provider: 'ccg',
    team: 'provider'
};