import './header.scss'
import React, {useEffect, useState} from "react";
import {PropTypes} from 'prop-types';
import logo from '../../assets/logo.jpg';
import {ReactComponent as IconMenu} from '../../assets/icons/menu.svg';
import {useStateContext} from '../../helpers/state-context.js';
import {HeaderUser} from './user.js';
import {AuthWithUser} from '../../components/auth/with-user.js';

const ViewHeader = function (props) {
    const [menuOpen, setMenuOpen] = useState(false)
    const [state,dispatch] = useStateContext(['page', 'renderSize']);
    const isMobile = state.renderSize === 'xs';

    // TODO: close the menu when clicking anywhere in the document.
    useEffect(()=>{
        setMenuOpen(false);
    }, [state.renderSize]);


    const sections = [
        {
            title: 'Dashboard',
            id: 'dashboard'
        },
        {
            title: 'Audits',
            id:  'admin'
        },
    ];

    const navigateTo = (page) => {
        dispatch({type: 'page', value: page});
        setMenuOpen(false);
    };

    const getNavItem = (section, user) => {
        const className = 'zol-header-item' + (state.page === section.id ? ' zol-active': '');
        const title = section.id === 'admin'? 
                (user.roles.includes(1)? 'Admin': section.title):
                section.title;
        return <li key={section.id} className={className} title={`Go to the ${section.title}`}
                onClick={()=>{ navigateTo(section.id) }}>
            {title}
        </li>;
    }
    

    
    return <header className={'zol-header'}>
        <img src={logo} alt={'logo'}/>

        <AuthWithUser enableFeedback={false}>
            {(user) => {
                if(user === null){
                    return null;
                }
                const navItems = sections.map((section) => ( getNavItem(section, user) ));

                return <>
                    <div className="zol-header-nav">
                        {isMobile ?
                            <span className={'zol-header-item'}
                                    onClick={()=>setMenuOpen(()=> (! menuOpen))}>
                                <IconMenu />
                            </span>:
                            <ul>
                                {navItems.map(item => (item))}
                            </ul>
                        }
                        <HeaderUser user={user} />
                    </div>
                    {(menuOpen) ?
                        <div className="zol-header-menu-mobile">
                            <ul>
                                {navItems.map(item => (item))}
                            </ul>
                        </div>:
                        null
                    }
                </>;
            }}
        </AuthWithUser>
        
    </header>;

}

ViewHeader.propTypes = {
    page: PropTypes.string.isRequired
}

export {ViewHeader};