import './element.scss';
import PropTypes from 'prop-types';
import React from "react";
import { useStateContext } from '../state-context';

const FormElement = function (props) {

    const [state, dispatchFormValue] = useStateContext(['__form-values', '__form-errors']);
    const formValues = state['__form-values'];
    const formErrors = state['__form-errors'];


    // when this is a conditional element, check whether all conditions are true, if not we don't show this
    // element.
    if(props.conditions){
        for(const condition of props.conditions){
            if(! condition(formValues)){
                return null;
            }
        }
    }

    const onChange = (value) => {
        props.onChange(value);
        dispatchFormValue({type: '__form-setValue', name: props.name, value: value});
    };

    const hasError = formErrors[props.name]? true: false;
    // We only show the first error message, there might be more, but the first is most of the time most 
    // applicable
    const errorMessage = hasError ? formErrors[props.name][0] : '';

    const elementProps = {
        onChange: onChange,
        onSave: props.onSave,
        onEnter: props.onEnter,
        disabled: props.disabled,
        value: formValues[props.name],
        hasError: hasError,
        required: props.required,
        name: props.name,
        options: props.options || [],
        placeholder: props.placeholder
    };

    let children = null;
    if(props.children && props.children.length > 0){
        const styleChildren = {
            width: 'calc(' + Math.max(25, (100/props.children.length)) + '% - 10px)', 
            margin: '0 5px'
        };
        children = <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between'}}>
            {props.children.map((question)=> {
                return <FormElement key={question.name} 
                        style={styleChildren}
                        {...question} />;
            })}
        </div>;
    }

    return <div className={'zol-form-element'} style={props.style}>
        <div className={'zol-form-element-input' + (props.type==='wide'? ' zol-form-element-wide': '')}>
            {(typeof props.title === 'string') ?
                <label className={'zol-form-element-title'}>
                    {props.title}
                    {props.required?
                        <span style={{color: 'red', fontSize: 18}}> *</span>:
                        null
                    }
                </label>:
                props.title
            }
            
            <div className={'zol-single-item-container'}>
                {props.element? <props.element {...elementProps} />: null}
                {children}
                
            </div>

        </div>

        {props.description?
            <span className="zol-form-element-description">
                {props.description}
            </span>:
            null
        }

        {hasError?
            <span className={'zol-form-element-error-text'}>
                {errorMessage}
            </span>:
            null
        }
    </div>;
};

FormElement.defaultProps = {
    value: '',
    type: 'inline',
    required: false,
    validations: [],
    conditions: [],
    options: [],
    onSave: (value) => {},
    onChange: (value) => {},
    children: false
};

FormElement.propTypes = {
    name: PropTypes.string.isRequired,
    element: PropTypes.oneOfType([PropTypes.elementType, PropTypes.bool]).isRequired,
    type: PropTypes.oneOf(['wide', 'inline']),
    value: PropTypes.any,
    title: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    validations: PropTypes.arrayOf(
        PropTypes.shape({
            checkValue: PropTypes.func.isRequired,
            message: PropTypes.string.isRequired
        })
    ),
    conditions: PropTypes.arrayOf(PropTypes.func),
    options: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    onSave: PropTypes.func,
    onChange: PropTypes.func,
    onEnter: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.bool])
};

export {FormElement}