import React from 'react';
import {UIText} from "../../../../components/ui/text.js";


const ViewSettingsPageHeader = function (props) {

    return <div style={{display: 'flex', alignItems: 'center'}}>
        <UIText type={'head-2'} color={'darkColor'}>{props.title}</UIText>
    </div>
}

ViewSettingsPageHeader.defaultProps={
    title: 'Share your story to inspire others'
}

export {ViewSettingsPageHeader}