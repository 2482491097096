import React, {useState} from 'react';
import {ReactComponent as ArrowRightSvg} from "../../../../assets/icons/arrow_right.svg";
import {ReactComponent as MehSvg} from "../../../../assets/icons/emoticon_low.svg";
import {ReactComponent as FrownSvg} from "../../../../assets/icons/emoticon_bad.svg";
import {ReactComponent as SmileSvg} from "../../../../assets/icons/emoticon_good.svg";
import {ReactComponent as StatusBestSvg} from "../../../../assets/icons/emoticon_great.svg";
import {ViewMeasuresTips} from  "./tips.js";
import {UIModal} from "../../../../components/ui/modal/modal.js";
import "../measures.scss";
import styles from "../../../../assets/variables.scss";
import {PropTypes} from "prop-types";
import {useStateContext} from '../../../../helpers/state-context.js';
import {useModelFeedback} from '../../../../helpers/model/use_model_feedback.js';
import {ModelParagraph} from '../../../../model/admin/paragraph.js';

const smileyStyle={
    width: '25%',
    height: '25%',
    marginBottom: '10px',
}

let positions = {
    worst: {
        icon: <FrownSvg style={{...smileyStyle, color: styles.brandRedColor}}/>,
        levelDescription: "Your team needs to improve.",
        description: 'Your team falls in the category of worst scores for this measure compared to others.',
        message: 'Check how other teams are doing it',
        newTipAllowed: false,
    },
    bad: {
        icon: <MehSvg style={{...smileyStyle, color: styles.brandYellowColor}}/>,
        levelDescription: "Your team could do better!",
        description: 'Your team could do better! You team\'s score is below average compared to others.',
        message: 'See how to improve',
        newTipAllowed: false,
    },
    good: {
        icon: <SmileSvg style={{...smileyStyle, color: styles.brandGreenColor}}/>,
        levelDescription: "Your team is doing a good job!",
        description: 'Your team did good! Your score is in the average range compared to others.',
        message: 'See how to do even better',
        newTipAllowed: true,
    },
    best: {
        icon: <StatusBestSvg style={{...smileyStyle, color: styles.brandBlueColor}}/>,
        levelDescription: "Your team did great!",
        description: 'Your team did excellent! Your score is above average compared to others.',
        message: 'Share your story to inspire the rest!',
        newTipAllowed: true
    },
}
const infoInnerStyle = {
    fontWeight: 900,
    height: 'auto',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)'
}

const ViewMeasureItem = function (props) {
    const resultSetText = useModelFeedback(ModelParagraph)
    const [state] = useStateContext(['section', 'group', 'period']);
    const {position} = props;
    const [showTips, setShowTips] = useState(false);

    for(const positionKey of Object.keys(positions)){
        const descriptionText = resultSetText.data.find((item)=>item.type === `measure_${positionKey}_text`);
        if(descriptionText){
            positions[positionKey]['description'] = descriptionText.text
        }
        const levelText = resultSetText.data.find((item)=>item.type === `measure_${positionKey}_level_text`);
        if(levelText){
            positions[positionKey]['levelDescription'] = levelText.text
        }
        const actionText = resultSetText.data.find((item)=>item.type === `measure_${positionKey}_action`);
        if(actionText){
            positions[positionKey]['message'] = actionText.text
        }
    }

    const [showDescription, setDescription] = useState(false);
    const pos = positions[position];
    return <div className={'zol-measures-card'}
            style={(state.section === 'recommendations') ? {height:'auto'}: {}}>

        <div style={{display: 'flex', flexDirection: 'column', position: 'relative'}}>
            {(props?.information !== "" && props?.information !== null) ?
                <div style={{position: 'absolute', right: '5%', top: '5%'}}>
                    <div className={`info-container ${(showDescription)? 'selected' : ''}`} 
                            onClick={()=>setDescription(prev=>!prev)}>
                        <span style={infoInnerStyle}>
                            ?
                        </span>
                    </div>
                    {showDescription ?
                        <div className={'more-info-container'}>{props.information}</div> :
                        null
                    }
                </div> :
                null
            }
            {pos.icon}
            <h2>{props.title}</h2>
            {props.level ? 
                <p style={{fontSize: '24px'}}>{pos.levelDescription}</p> : 
                <p>{pos.description}</p>}
        </div>
        <div className={'zol-measures-card-tips'}
                onClick= {props.onClick?props.onClick: () => setShowTips(true)}>
            <h4>{pos.message}</h4>
            <ArrowRightSvg className={'arrow'}/>
        </div>
        {showTips ? <UIModal
                onClose={()=>setShowTips(false)}
                visible={true}
                children={<ViewMeasuresTips measure={props.title}
                        id={props.id}
                        group = {state.group}
                        setShowTips={setShowTips}
                        newTipAllowed={pos.newTipAllowed}/>}/>
            : null}
    </div>
};

ViewMeasureItem.defaultProps = {
    title: 'Measure',
    level: false
};

ViewMeasureItem.propTypes={
    onClick: PropTypes.func
};

export {ViewMeasureItem}