import React from "react";
import "./filter.scss";
import {UIInputSelect} from "../ui/input/select.js";
import { useStateContext } from "../../helpers/state-context";
import {useModelFeedback} from '../../helpers/model/use_model_feedback.js';
import {ModelAuthUser} from '../../model/auth/user.js';

const FilterGroupType = function (props) {
    const [state, dispatch] = useStateContext(['groupType']);
    const resultSetUser = useModelFeedback(ModelAuthUser, {id: 'me'});
    const userRoles = resultSetUser.feedback? []: resultSetUser.data[0].roles;
    if(resultSetUser.feedback){
        return resultSetUser.feedback;
    }
    // Dont show groupType if user doesn't have group 'regional' or 'admin' role.
    if(! userRoles.includes(1) && ! userRoles.includes(2)){
        return null;
    }
    
    return <div className="zol-dashboard-filter-group" style={props.style}>
        <UIInputSelect
            options={{
                provider: 'Providers / teams',
                region: 'Regional'
            }}
            style={props.optionStyle}
            name="filter-group"
            placeholder={'Select a team'}
            onChange={(value) => dispatch({type: 'groupType', value: value})}
            value={state.groupType} 
            selected={'region'}/>
    </div>;
};

FilterGroupType.propTypes = { };
FilterGroupType.defaultProps = {};

export {FilterGroupType};