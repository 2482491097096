import {config} from '../../config.js';
import {ModelXhr} from '../../helpers/model/xhr.js';

class ModelAuthUserList extends ModelXhr {

    static modelName = "ModelAuthUserList";

    static getSelect(input) {
        const filter = [];
        if(input.status){
            filter.push('status eq \'' + input.status + '\'');
        }
        return {
            filter: filter.join(' and ')
        };
    }

    static createMail(data, onSuccess=()=>{}, onError=()=>{}){
        this.saveData(ModelAuthUserList, config.api_endpoint + 'user-list', 'POST', 
            data, 
            (response) => {
                if(response.success){
                    const user = response.result? (response.result[0] || null): null;
                    onSuccess(user);
                }else{
                    onError(response.error || 'Unknown error');
                }
            }
        );
    }

    constructor(selectData) {
        super(selectData, {
            endpoint: config.api_endpoint + 'user-list',
            method: 'GET'
        });

        this.cacheEnabled = false;
    }

   
    getSelect(input) {
        return ModelAuthUserList.getSelect(input);
    }


}

export {ModelAuthUserList};