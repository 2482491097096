import PropTypes from 'prop-types';
import React from "react";

const UIInputTextarea = function (props) {
    return <textarea className={`zol-single-input ${props.hasError ? 'zol-single-error' : ''}`}
            value={props.value || ''}
            onChange={(e)=>props.onChange(e.target.value)}
            onBlur={(e)=>props.onSave(e.target.value)} />
};

UIInputTextarea.defaultProps = {
    value: '',
    hasError: false,
    onChange: () => {},
    onSave: () => {},
};

UIInputTextarea.propTypes = {
    name: PropTypes.string.isRequired,
    hasError: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    onSave: PropTypes.func
};

export {UIInputTextarea}