import React from 'react';
import {offWhiteColor, brandPurpleColor} from '../../../../assets/variables.scss';
import {useModel} from '../../../../helpers/model/use_model.js';
import {ModelAuthUser} from '../../../../model/auth/user.js';
import {useOrderedGroups} from "../../../../helpers/model/use_ordered_groups.js";

const circleStyle = {
    position: 'absolute',
    left: '50%',
    top: '5px',
    borderRadius:"100%",
    textAlign: 'center',
    width: "16px",
    height: '16px',
    lineHeight: "16px",
    fontSize: '9px',
    backgroundColor: offWhiteColor
};

const VerticalBarComponentCircle = function(props){
    const resultSet = useModel(ModelAuthUser, {id: 'me'});
    const resultSetOrdered = useOrderedGroups();
    const groups = [];
    if(resultSet.status === ModelAuthUser.Status.SUCCESS){
        const user = resultSet.data[0] || {};
        if(user.groups){
            groups.push(...user.groups);
        }
    }

    const styleCircle = {
        ...circleStyle,
        backgroundColor: groups.includes(parseInt(props.children))? brandPurpleColor: offWhiteColor,
        color: groups.includes(parseInt(props.children))? 'white': 'black'
    }

    return <span style={styleCircle}>
        {resultSetOrdered[props.children]}
    </span>
};

export {VerticalBarComponentCircle}