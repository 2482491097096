import React from "react";
import {DashboardCardOutcomeDetails} from "./cards/outcome-details.js";
import {DashboardCardOutcomeComparision} from "./cards/outcome-comparision.js";
import {DashboardCardOutcomeOverviewAdmission} from "./cards/outcome-overview-admission.js";
import {DashboardDefaultPage} from './default-page.js';
import { DashboardCardOutcomeOverviewCaseload } from "./cards/outcome-overview-caseload.js";
import {useModelFeedback} from '../../helpers/model/use_model_feedback.js';
import {ModelParagraph} from '../../model/admin/paragraph.js';

const ViewDashboardOutcomes = function (props) {
    
    const resultSetText = useModelFeedback(ModelParagraph, {type: 'outcome_title'});
    if(resultSetText.feedback){
        return resultSetText.feedback
    }

    return <DashboardDefaultPage shareable={true} title={resultSetText.data[0].text}>
        <DashboardCardOutcomeOverviewAdmission title={'Overview'} cardSize={'ml'}/> 
        <DashboardCardOutcomeOverviewCaseload title={'Overview'} cardSize={'sm'}/> 
        <DashboardCardOutcomeComparision title={'Admission to Mental Health Hospital'} cardSize={'large'}/>
        <DashboardCardOutcomeDetails question={'Social outcomes'} cardSize={'sm'}/>
        <DashboardCardOutcomeDetails question={'Relapse prevention'} cardSize={'sm'}/>
        <DashboardCardOutcomeDetails question={'Discharge destinations'} cardSize={'sm'}/>
    </DashboardDefaultPage>
};

export {ViewDashboardOutcomes};