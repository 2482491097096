import {config} from '../../config.js';
import {ModelXhr} from '../../helpers/model/xhr.js';
import {ModelAudit} from './audit.js';

class ModelAuditProgress extends ModelXhr {

    static modelName = "ModelAuditProgress";

    static getSelect(input) {
        const filter = [];
        
        if(input.period){
            filter.push('period eq \'' + input.period + '\'');
        }else{
            filter.push('period eq \'' + ModelAudit.getCurrentPeriodName() + '\'');
        }
        return {
            select: 'progress, count',
            filter: filter.join(' and ')
        };
    }


    constructor(selectData) {
        super(selectData, {
            endpoint: config.api_endpoint + 'audit-progress',
            method: 'GET'
        });

        this.cacheEnabled = false;
    }

   
    getSelect(input) {
        return ModelAuditProgress.getSelect(input);
    }


}

export {ModelAuditProgress};