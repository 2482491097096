import React from "react";
import {stack} from "d3-shape";
import PropTypes from "prop-types";
import {getColor} from './functions.js';

const VerticalBarStacked = function (props) {
    const {axisState, axis, data, id, palette, onMouseHover, calculateRoundedPath, hover} = props;
    const lines = props.lines ? props.lines.sort((a,b)=> (a.value>b.value)?1:-1): false; 
    // Create dataset to draw stacked
    const dataset = stack().keys(axis.y)(data).reverse();
    return <g>
        {dataset.map((group, i) => {
            const isBottom = (i === 0);
            const isTop = (i === dataset.length - 1);
            const isSingle = (dataset.length === 1);
            // Rounded top and bottom, rest are rectangles
            let radius = isSingle ?
                [true, true] :
                isTop ? [false, true] :
                    isBottom ? [true, false] :
                        [false, false];
                        
            return <g key={i}>
                {group.map((item, j) => {
                    const fill = item.data.color ?
                        item.data.color :
                        lines ?
                            getColor(item,lines) :
                            (Array.isArray(palette[i])) ?
                                `rgb(${palette[i]})` :
                                palette[i];
                    let topItem = `grid-${j}`;
                    const bandWidth = axisState.x1.bandwidth();
                    const width = Math.min(20, bandWidth);
                    let x = axisState.x(item.data[axis.x]) + (axisState.x.bandwidth() / 2 - width/2);
                    let height = axisState.y(item[0]) - axisState.y(item[1]);
                    let y = axisState.y(item[1]);


                    const totalValue = dataset.reduce((totalValue, item)=>{
                        return totalValue + parseFloat(item[i].data[item.key])
                    }, 0);
                    // Check if total height is above max domain so it doesn't go over the maximum domain when
                    // height is 20;
                    const isBelowMax = totalValue < axisState.y.domain()[1]
                    if(height < 20 && isBelowMax){
                        y -= (20 - height);
                        height = 20;
                    }

                    const path = calculateRoundedPath(x, y, width, height,  ...radius, dataset.length === 1);
                    return <path key={j} d={path} className={topItem} fill={fill}

                                 style={{opacity: (hover === `${j}` || hover === '') ? 1 : .5}}

                                 onMouseOut={(e) => onMouseHover(e, null)}
                                 onMouseOver={(e) => onMouseHover(e, {
                                             ...item,
                                             key: group.key,
                                             topElement: `#${id} .${topItem}`,
                                             axis: axis,
                                             hover: `${j}`
                                         })} /> 
                })}
            </g>
        })}
    </g>
};

VerticalBarStacked.propTypes = {
    hover: PropTypes.string,
    calculateRoundedPath: PropTypes.func,
    axisState: PropTypes.object,
    data: PropTypes.array,
    axis: PropTypes.shape({
        x: PropTypes.string,
        y: PropTypes.arrayOf(PropTypes.string)
    }),
    id: PropTypes.string,
    palette: PropTypes.array,
    onMouseHover: PropTypes.func,
};

export {VerticalBarStacked}