import {config} from '../../config.js';
import {ModelXhr} from '../../helpers/model/xhr.js';

class ModelAuditPeriod extends ModelXhr {

    static modelName = "ModelAuditPeriod";

    static getSelect(input) {
        const select = {...input};
        if(select.name){
            select.filter = 'name eq \'' + select.name + '\'';
            delete select.name
        }
        return select;
    }

    static updateItem(id, data, onSuccess=()=>{}){
        this.saveData(ModelAuditPeriod, config.api_endpoint + 'period/' + id, 'PATCH', 
            data, 
            (response) => {
                if(response.success){
                    const user = response.result? (response.result[0] || null): null;
                    onSuccess(user);
                }else{
                    // todo: post status to global message channel
                    console.error(response.error || "Unknow error");
                }
            }
        );
    }


    constructor(selectData) {
        super(selectData, {
            endpoint: config.api_endpoint + 'period',
            method: 'GET'
        });

        this.cacheEnabled = false;
    }

    getEndpoint(){
        return this.endpoint;
    }

    getSelect(input) {
        return ModelAuditPeriod.getSelect(input);
    }


}

export {ModelAuditPeriod};