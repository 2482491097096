import React from "react";
import './dashboard.scss';
import {UrlStateContext} from "../../helpers/url-state-context/url-state-context.js";
import {getSimpleReducer} from "../../helpers/state-context/get_simple_reducer.js";
import {useStateContext, StateContext} from "../../helpers/state-context.js";
import {SideBar} from "../../components/side-bar/side-bar.js";
import {ViewDashboardConfigure} from "./configure/index.js";
import {ViewDashboardNice} from "./nice.js";
import {ViewDashboardMeasuresOverview} from "./measures/measures-overview.js";
import {ViewDashboardOutcomes} from "./outcomes.js";
import {ViewDashboardOverview} from "./overview.js";
import {AuthAccessValidator} from "../../components/auth/access-validator.js";
import {ViewDashboardCharts} from "./charts.js";
import {getCurrentPeriod} from "../../helpers/functions/periods.js";
import {useModelFeedback} from "../../helpers/model/use_model_feedback";
import { ModelAuthUser } from "../../model/auth/user";
import {StateConsumer} from '../../helpers/state-context.js';

const stylePage = {
    display: 'flex', 
    position: 'relative'
};


const ViewDashboardContent = function (props) {
    const [state] = useStateContext(['section']);

    const resultSetUser = useModelFeedback(ModelAuthUser, {id: 'me'});
    if(resultSetUser.feedback){
        return resultSetUser.feedback
    }
    const primaryGroup = parseInt(Object.keys(resultSetUser.data[0].primary_groups || {})[0]) || '';
    const userRoles = resultSetUser.data[0].roles;
    const currentPeriod = getCurrentPeriod();

    let subContent = <ViewDashboardOverview />
    if(state.section === 'configure'){
        subContent = <ViewDashboardConfigure />
    }else if(state.section === 'nice'){
        subContent = <ViewDashboardNice />
    }else if(state.section === 'recommendations'){
        subContent = <ViewDashboardMeasuresOverview />
    }else if(state.section === 'outcomes'){
        subContent = <ViewDashboardOutcomes />
    }else if(state.section === 'charts'){
        subContent = <ViewDashboardCharts />
    }
   
    return  <div style={stylePage}>
        <SideBar inAdmin={false}/>
        <StateContext initialState={{
            period: currentPeriod,
            group: primaryGroup ===1? 0: primaryGroup,
            groupType: userRoles.includes(1) || userRoles.includes(2)? 'region': 'provider',
            isExportingPDF: false
        }}
                reducer={getSimpleReducer(['period', 'group', 'isExportingPDF', 'groupType'])}>
           <StateConsumer>
               {(state)=>{
                   return <>
                       <div className={'zol-dashboard-container'} 
                                style={state.isExportingPDF? {backgroundColor: 'white'}: {}}>
                            {subContent}
                       </div>
                   </>
               }}
           </StateConsumer>
        </StateContext>
    </div>
};

const ViewDashboard = function (props) {
    return <AuthAccessValidator>

        <UrlStateContext path={['section']} initialState={{section: 'personal-dashboard'}} 
                reducer={getSimpleReducer(['section'])}>

            <ViewDashboardContent />

        </UrlStateContext>

    </AuthAccessValidator>
};


export {ViewDashboard}