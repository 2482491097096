import React, {useState} from "react";
import {settingsConfig} from "./settings-config.js";
import {ViewSettingsPageGraphs} from "./page/graphs.js";
import {ViewSettingsPagesDefault} from "./page/default.js";
import {ViewSettingsIndicatorIndex} from "./indicator/index.js";
import {ViewSettingsPageQuestions} from "./page/questions.js";
import {ViewSettingsPagePeriods} from "./page/periods.js";
import {ViewSettingsParagraphs} from './paragraphs';

const ViewAdminSettings = function (props) {
    const [activeSetting, setActiveSetting] = useState()

    const renderSetting = (setting) =>{
        const selectedSetting = settingsConfig.find((item)=>item.id === setting)
        const props = {
            selectedSetting: selectedSetting,
            activeSetting: activeSetting,
            setActiveSetting: setActiveSetting
        };

        switch(setting){
            case "indicators": return <ViewSettingsIndicatorIndex {...props}/>;
            case "paragraphs": return <ViewSettingsParagraphs {...props}/>;
            case "graphs": return <ViewSettingsPageGraphs {...props}/>;
            case "questions": return <ViewSettingsPageQuestions {...props}/>;
            case "periods": return <ViewSettingsPagePeriods {...props}/>;
            default: return <ViewSettingsPagesDefault {...props}/>;
        }
    };

    return <div style={{padding: '0 15px'}}>
        {renderSetting(activeSetting)}
    </div>
}

export {ViewAdminSettings}

// help section
// intro paragraph