import React from "react";
import {PropTypes} from 'prop-types';
import "./input.scss";
import "../../../helpers/form/element.scss";

//TODO: rename checkboxGroup
const UIInputCheckbox = function (props) {
    
    const setNewValue = (option, setActive) => {
        const value = setActive? 
            [...props.value, option]:
            props.value.filter((item) => item !== option);
        props.onChange(value);
        props.onSave(value);
    };

    const options = props.options instanceof Array? props.options: Object.keys(props.options);

    return <div className={'zol-ui-input-checkbox'}
            style={{display: 'flex', flexWrap: 'wrap'}}>
        {options.map((o)=>{
            const label = props.options instanceof Array? o: props.options[o];
            const isChecked = Array.isArray(props.value)? props.value.includes(o): false;
            return <label key={o} className={'zol-ui-input-checkbox-item'} style={{margin: '10px 0px', ...props.style}}>
                <input type={'checkbox'} 
                        checked={isChecked}
                        onChange={(e)=> setNewValue(o, ! isChecked)} />
                {label}
            </label>
        })}

    </div>
}

UIInputCheckbox.defaultProps = {
    value: [],
    hasError: false,
    onChange: () => {},
    onSave: () => {},
};

UIInputCheckbox.propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    onChange: PropTypes.func,
    onSave: PropTypes.func,
    value: PropTypes.array,
    hasError: PropTypes.bool
};

export {UIInputCheckbox};