import React, {useCallback, useEffect} from "react";
import {Axis, axisPropsFromBandedScale, axisPropsFromTickScale, BOTTOM, LEFT} from "react-d3-axis";
import PropTypes from "prop-types";
import {UISvgText} from '../../ui/svg-text.js';

const VerticalBarAxis = function (props) {
    const {axis, axisState, width, height, hideAxisX, hideAxisY, id} = props;

    const fixAxis = useCallback(() => {
        // Add horizontal lines to axis over full width
        const horizontalLines = document.querySelectorAll(`#${id} .zol-chart-vertical-bar .y-axis g line`);
        for(const line of horizontalLines){
            line.setAttribute('x1',width -49)
        }
    }, [width, id]);

    useEffect(()=>{fixAxis()}, [props.data, width, fixAxis, id]);
    return <>
        <g className={'y-axis'} transform={`translate(0, 0)`}>
            <Axis 
                {...axisPropsFromTickScale(axisState.y, 3)}
                format={(d) => {
                    if(props.hideAxis  || hideAxisY){
                        return ''
                    }
                    if(props.formatAxis !== undefined && props.formatAxis.y !== undefined){
                        return props.formatAxis.y(d)
                    }
                    return d
                }
                }
                style={{orient: LEFT, tickFontSize: 16}}/>
        </g>
        {(hideAxisX) ?
            null :
            <g className={'x-axis'} transform={`translate(0, ${height})`}>
                <Axis {...axisPropsFromBandedScale(axisState.x)}
                        style={{orient: BOTTOM, tickFontSize: 14}}
                        format={((d)=>{
                            // Format axis tick with props
                            if(props.formatAxis !== undefined && props.formatAxis.x !== undefined ){
                                const item = props.data.find((i)=> d === i[axis.x])
                                if(item !== undefined){
                                    return props.formatAxis.x(item)
                                }
                            }
                            return <UISvgText width={axisState.x.bandwidth()} x={0} y={14} linesOnly={true}
                                              max={2}>
                                {d}
                            </UISvgText>
                        })}/>
            </g>
        }
    </>
};

VerticalBarAxis.propTypes = {
    axisState:  PropTypes.object.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
    hideAxisX: PropTypes.bool,
    hideAxisY: PropTypes.bool,
};

export {VerticalBarAxis}