import React, {useState} from 'react';
import {ViewSettingsPageHeader} from "./header.js";
import {useModelFeedback} from "../../../../helpers/model/use_model_feedback.js";
import {ModelDashboardMeasureTips} from "../../../../model/dashboard/measure-tips.js";
import {ModelAuthUser} from "../../../../model/auth/user.js";
import {Separator} from "../../../../components/separator.js";
import {ViewMeasuresGiveTips} from './give-tips.js';
import {UIButton} from '../../../../components/ui/button/button.js';
import {ReactComponent as EditSvg} from "../../../../assets/icons/edit.svg";

// JV: name is inconsitent with the path of the file. Why is there a page folder? These are not pages.
const ViewMeasuresTips = function (props) {
    const {newTipAllowed} = props;
    const [newTip, setNewTip] = useState(false);
    const [editTip, setEditTip] = useState(null);
    const resultSet = useModelFeedback(ModelDashboardMeasureTips, {measure_id: props.id});
    const resultSetUser = useModelFeedback(ModelAuthUser, {id: 'me'});
    const userId = resultSetUser.feedback? 0: resultSetUser.data[0]?.id || 0;

    
    if(resultSet.feedback){
        return resultSet.feedback;
    }

    if(newTip || editTip !== null){
        return <ViewMeasuresGiveTips {...props} 
                item = {editTip}
                closeModal={()=>props.setShowTips(false)}
                onBackClick={()=>setNewTip(false)} />
    }

    // TODO: maybe use switch to
    return <>
        <ViewSettingsPageHeader {...props} title={'Recommendations by other teams'}/>
        <div style={{margin: "20px 0"}}>
            {(resultSet.data.length > 0) ?
                resultSet.data.map((item, key)=>{
                    return <div key={key} className="zol-measures-tip">
                        <Separator />
                        <h3><span>{item.period}</span>{item.group_title}</h3>
                        {item.fk_user_id === userId? 
                            <EditSvg style={{float: 'right', cursor: 'pointer'}}
                                    onClick={()=>setEditTip(item)}/>:
                            null
                        }
                        <h4>{item.user_name} said:</h4>
                        <p>{item.tip}</p>
                    </div>
                }) :
                'There are no recommendations from other teams available for this measure.' +
                (newTipAllowed? ' Be the first to share!': '')
            }
        </div>
        {(newTipAllowed) ?
            <UIButton onClick={()=>setNewTip(true)}>
                Share your recommendations
            </UIButton> :
            null
        }
    </>
}

// JV: Don't forget the propTypes and defaultProps!

export {ViewMeasuresTips}