import React from "react";
import {PropTypes} from 'prop-types';
import {roles} from '../../../../config.js';
import {UIButtonPaper} from '../../../../components/ui/button/paper.js';
import {Filter} from '../../../../components/filter/filter.js';
import {AuthAccessValidator} from '../../../../components/auth/access-validator.js';
import {AuditComponentProgress} from './progress.js';
import {useStateContext} from "../../../../helpers/state-context.js";
import {useModelFeedback} from '../../../../helpers/model/use_model_feedback.js';
import {ModelOrgHierarchy} from '../../../../model/org-hierarchy.js';
import {ModelAuditPeriod} from '../../../../model/audit/period.js';

const getOptions = function(resultSet, key, filters, additionalOptions={}){
    return resultSet.feedback? 
        []: 
        resultSet.data
            .filter(item => {
                if(key === 'team' && filters.provider && item.provider_id !== parseInt(filters.provider)){
                    return false;
                }else if((key === 'provider' || key === 'team') && 
                        filters.region && item.region_id !== parseInt(filters.region)){
                    return false;
                }
                return true;
            })
            .sort((a, b) => ( a.title < b.title? -1: 1))
            .reduce((obj, item) => {
                obj[item[key + '_id']] = item[key];
                return obj;
            }, additionalOptions);
}

const AuditComponentSideBar = function (props) {
    const [, dispatch] = useStateContext(['page']);
    
    const resultSetHierarchy = useModelFeedback(ModelOrgHierarchy, {
        select: 'region, region_id, provider, provider_id, team, team_id'
    }, (m) => ( m.getRawData() ));

    const resultSetPeriod = useModelFeedback(ModelAuditPeriod, {
        select: "name as period_id, name as period",
        filter: "",
        order: 'period desc'
    });

    const onFilterChange = (newFilter) => {
        const teamIds = newFilter.team? 
                [newFilter.team]: 
                Object.keys(getOptions(resultSetHierarchy, 'team', newFilter)).map(item => parseInt(item));
        props.onFilterChange({...newFilter, groups: teamIds});
    };

    const optionsRegion = getOptions(resultSetHierarchy, 'region', props.filter, {0: 'All regions'});
    const optionsProvider = getOptions(resultSetHierarchy, 'provider', props.filter, {0: 'All providers'});
    const optionsTeam = getOptions(resultSetHierarchy, 'team', props.filter, {0: 'All teams'});
    const optionsPeriod = getOptions(resultSetPeriod, 'period', {}, {0: 'All periods'});

    const filterElements = [
        {
            name: 'region',
            title: 'Region',
            options: optionsRegion,
            placeholder: 'Select region',
            value: props.filter.region
        },
        {
            name: 'provider',
            title: 'Provider',
            options: optionsProvider,
            placeholder: 'Select provider',
            value: props.filter.provider
        },
        {
            name: 'team',
            title: 'EIP Team',
            options: optionsTeam,
            placeholder: 'Select team',
            value: props.filter.team
        },
        {
            name: 'period',
            title: 'Period',
            options: optionsPeriod,
            placeholder: 'Select period',
            value: props.filter.period
        }
    ];
    //todo: links go to exact section instead of settings section
    return <div style={{float: 'right', width: '25%'}}>
        
        {props.showProgress ?
            <AuditComponentProgress />:
            null
        }

        {props.showFilters ? <Filter onChange={onFilterChange} filters={filterElements} /> : null}

        <AuthAccessValidator showError={false}
                validateUser={(user) => (user.roles.includes(roles.admin))}>
            
            <UIButtonPaper title={'Change indicators'}
                    onClick={()=>{dispatch({type: 'section', value: 'settings'})}}/>

            <UIButtonPaper title={'Manage audit period'}
                    onClick={()=>{dispatch({type: 'section', value: 'settings'})}}/>

        </AuthAccessValidator>

    </div>;
};

AuditComponentSideBar.defaultProps = {
    showProgress: false,
    showFilters: false,
};

AuditComponentSideBar.propTypes = {
    onFilterChange: PropTypes.func.isRequired,
    filter: PropTypes.object.isRequired,
    showProgress: PropTypes.bool,
    showFilters: PropTypes.bool,
}

export {AuditComponentSideBar}



