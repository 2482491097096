import React from "react";
import {PropTypes} from 'prop-types';
import {UIText} from "../../../components/ui/text.js";
import styles from "../../../assets/variables.scss";
import {ReactComponent as IconEdit} from "../../../assets/icons/edit.svg";
import {ReactComponent as IconDelete} from "../../../assets/icons/delete.svg";
import {ReactComponent as IconPlus} from "../../../assets/icons/plus.svg";
import { useModelFeedback } from "../../../helpers/model/use_model_feedback.js";
import { ModelOrgHierarchy } from "../../../model/org-hierarchy.js";
import {UITable} from '../../../components/ui/table/table.js';
import { useStateContext } from "../../../helpers/state-context";
import {UIButton} from '../../../components/ui/button/button.js';
import { ModelAuthGroup } from "../../../model/auth/group.js";

const styleIconEdit = {
    cssFloat: 'right', 
    width: 25, 
    height: 25,
    cursor: 'pointer', 
    color: styles.primaryColor
};
const styleIconDelete = {
    ...styleIconEdit,
    marginLeft: '20px'
};

const styleBtnNew = {
    display: 'flex',
    alignItems: 'center',
    padding: '15px 15px',
    backgroundColor: '#e2e7fc',
    color: styles.primaryColor,
    cssFloat: 'right'
};

const OrganizationList = function (props) {

    const resultSet = useModelFeedback(ModelOrgHierarchy, {type: props.section}, (m) => (m.getRawData()));
    const [, dispatch] = useStateContext(['view', 'id']);

    const editItem = (groupId) => {
        if(! groupId){
            dispatch({type: 'setValues', value: {view: 'new', id: null}});    
        }else{
            dispatch({type: 'setValues', value: {view: 'edit', id: groupId}});
        }
    };

    const deleteItem = (event, groupId) => {
        if(window.confirm("You are about to permenantly delete a unit. This will also delete " +
                "all audit data related to this organization unit. Do you really want to delete this unit " +
                "and all its answers?")){
            ModelAuthGroup.deleteItem(groupId);
        }
        event.stopPropagation();
    }
    
    const tableConfig = {
        title: {
            title: 'Name',
            value: (row) => ( row.title ),
            width: 3
        },
        edit: {
            width: 1,
            renderCell: (value, row) => ( <>
                <IconDelete onClick={(e)=> { deleteItem(e, row.id); }} 
                        style={styleIconDelete}/>
                <IconEdit onClick={()=> { editItem(row.id); }} 
                        style={styleIconEdit}/>
            </>)
        }
    };

    const config = Object.keys(tableConfig).map((column)=>{
        return {
            id: column,
            value: (row) => ( row[column] ),
            renderCell: (value)=> value,
            ...tableConfig[column],
        };
    });



    return <div style={{float: 'left', width: 'calc(75% - 30px)', paddingRight: 30}}>
        <UIText type={'head-2'} color={'darkColor'}>Organisational Units</UIText>
        <UIButton style={styleBtnNew} onClick={() => {editItem() }}>
            <IconPlus style={{marginRight: 5}}/>
            New organization unit
        </UIButton>

        {resultSet.feedback?
            resultSet.feedback:
            <UITable columns={config} data={resultSet.data} 
                    onRowClick={(row) => {editItem(row.id)}} />
        }

    </div>;
}

OrganizationList.propTypes = {
    section: PropTypes.string.isRequired
};

export {OrganizationList};