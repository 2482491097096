import React, {useState} from "react";
import { UrlStateContext } from "../../../helpers/url-state-context/url-state-context.js";
import { StateConsumer } from "../../../helpers/state-context.js";
import { OrganizationList} from './list.js';
import {OrganizationEdit} from './edit.js';
import {AuthAccessValidator} from '../../../components/auth/access-validator.js';
import {titles as sections} from './settings.js';
import {AdminComponentSideBar} from '../component/side-bar.js';

const ViewAdminOrganizationIndex = function (props) {

    const [section, setSection] = useState('region');

    return <UrlStateContext path={['view', 'id']} initialState={{view: 'list', id: null}} >

        <StateConsumer watch={['view', 'id']}>
            {(state, ) => {
                if(state.view === 'edit' || state.view === 'new'){
                    return <>
                        <AuthAccessValidator showError={true}
                                validateUser={(user) => (user.permissions.includes('manage_groups'))}>
                            <OrganizationEdit groupId={parseInt(state.id)} />
                        </AuthAccessValidator>

                    </>;

                }else{
                    return <>
                        <AuthAccessValidator showError={true}
                                validateUser={(user) => (user.permissions.includes('manage_groups'))}>

                            <div style={{padding: '0 15px'}}>
                                <OrganizationList section={section} />
                            
                                <AdminComponentSideBar value={section} onChange={setSection} 
                                        options={sections} />
                            </div>
                        
                        </AuthAccessValidator>
                    </>
                }
            }}

        </StateConsumer>

    </UrlStateContext>;

}

export {ViewAdminOrganizationIndex}