import {config} from '../../config.js';
import {ModelXhr} from "../../helpers/model/xhr.js";

class ModelCardsCollection extends ModelXhr{

    static modelName = "ModelCardsCollection";

    static getSelect(input) {
        const select = {
            select: input.select ? input.select : 'id, fk_user_id, favourites, order',
        };

        return select;
    }

    addCard(cardId){
        const data = this.getData();
        let favourites = data.favourites;
        favourites.push(cardId);
        favourites.sort(function(a,b){
            return a-b;
        });
        ModelCardsCollection.updateItem({favourites})
    
    }

    removeCard(cardId){
        const data = this.getData();
        
        let favourites = data.favourites;
        favourites.splice(favourites.indexOf(cardId),1);
        ModelCardsCollection.updateItem({favourites});
        
    }
    
    updateSet(set){
        const favourites = set;
        ModelCardsCollection.updateItem({favourites});
        
    }

    updateOrder(newOrder){
        const order = newOrder;
        ModelCardsCollection.updateItem({order})
        
    }

    static updateItem(data){
        const endpoint = config.api_endpoint + 'cards_collection/';
        data.favourites = JSON.stringify(data.favourites);
        data.order = JSON.stringify(data.order);
        this.saveData(ModelCardsCollection, endpoint, 'PATCH', data);
    }

    constructor(selectData) {
        super(selectData, {
            endpoint: config.api_endpoint + 'cards_collection',
            method: 'GET'
        });

        this.cacheEnabled = false;
        this.update();
    }

    getSelect(input) {
        return ModelCardsCollection.getSelect(input);
    }

    getData() {
        const resultSet = super.getData(); 
        let favourites = [1,2,3,5,7,9,12]; //default favourites
        let id = null;
        let order = ['small', 'medium', 'large']; //default order
        try {
            if(resultSet.status === 'success' && resultSet.data.length > 0){
                favourites = (resultSet.data[0].favourites !== null) ? JSON.parse(resultSet.data[0].favourites): favourites;
                id = resultSet.data[0].id;
                order = (resultSet.data[0].order !== null) ? JSON.parse(resultSet.data[0].order): order;
            }
        } catch (e) {
            console.warn('Cannot load favourites or order.')
        }

        return {
            ...resultSet,
            id: id,
            favourites: favourites,
            order: order,
            addCard: this.addCard.bind(this),
            removeCard: this.removeCard.bind(this),
            updateSet: this.updateSet.bind(this),
            updateOrder: this.updateOrder.bind(this)}
    }
}

export {ModelCardsCollection}
