import React from "react";
import {PropTypes} from 'prop-types';
import {UISwitch} from '../../../../components/ui/switch.js';

const AuditComponentPeriodSwitch = function (props) {

    return <div style={{width: '100%', display: 'inline-block'}}>
        <UISwitch value={props.value}
                data={[
                    {
                        id: 'current',
                        label: 'Current period'
                    },
                    {
                        id: 'history',
                        label: 'History'
                    }
                ]}
                onChange={props.onChange}/>
    </div>;
};

AuditComponentPeriodSwitch.defaultProps = {
    value: 'current'
};

AuditComponentPeriodSwitch.propTypes = {
    value: PropTypes.oneOf(['current', 'history']),
    onChange: PropTypes.func.isRequired
}

export {AuditComponentPeriodSwitch}
