import React from "react";
import PropTypes from 'prop-types';
import {useSize} from "../../../helpers/use_size.js";
import {ChartLegend} from "../legend.js";

const arrowStyle = {
    width: 0,
    height: 0,
    position: "absolute",
    top: 0,
    borderStyle:"solid",
    borderWidth:"15px 15px 0 15px",
    marginLeft: "-7.5px"
};

const barStyle = {
    width: '100%',
    borderRadius: 10,
    backgroundImage: `linear-gradient(to left, rgba(255, 255, 255, .4) , rgba(255, 255, 255, .9))`,
    height: 20,
    marginTop: 12.5,
    position: "absolute"
};

const pinStyle = {
    height: 30,
    width: 7.5,
    borderRadius: 10,
    position: "absolute",
    marginTop: 7.5,
    marginLeft: -3.25
};

// TODO: Needs the right naming for chart. Wasn't sure what this chart is called so just called it AverageBar.
const ChartAverageBar = function (props) {
    const {data, color, arrowColor, pin} = props;

    const [size, measuredRef] = useSize();
    let width = size.width;

    // There's no data
    if(data.length === 0){
        data.push(0);
    }

    const min = Math.min(...data);
    const max = Math.max(...data);
    const average = (data.reduce( ( total, ans ) => total + ans, 0 ) / data.length).toFixed(0)

    const pinPercentage = ((parseFloat(pin) - min) / (max - min)) || 1;
    const pinLeft = (width-5) * pinPercentage;
    const arrowAveragePercentage = ((average - min) / (max - min)) || 1;

    const legendData = [`Average: ${average}`, `You: ${pin}`]
    const legendPalette = [arrowColor, color]



    return <div ref={measuredRef}>
        <div style={{position: 'relative', height: 40, width: width, margin: '30px 0 20px 0'}}>
            {/*bar*/}
            <div style={{
                backgroundColor: color,
                ...barStyle
            }}/>

            {/*pin*/}
            <div style={{
                backgroundColor: color,
                left: (pinLeft > (width-5)) ? width-5 : pinLeft,
                ...pinStyle}}/>

            {/*arrow*/}
            <div style={{
                left: ((width-5) * arrowAveragePercentage - 6),
                borderColor:`${arrowColor} transparent transparent transparent`,
                ...arrowStyle
            }}/>
        </div>
        <ChartLegend data={legendData} palette={legendPalette}/>
    </div>
};

ChartAverageBar.defaultProps = {
    data: [60, 70, 100, 200, 200, 200, 200, 150, 125,300,50],
    pin: 70,
    color: 'hsl(188, 74%, 45%)',
    arrowColor: 'orange'
};

ChartAverageBar.propTypes = {
    data: PropTypes.arrayOf(PropTypes.number).isRequired,
    color: PropTypes.string.isRequired,
    pin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    arrowColor: PropTypes.string,

};

export {ChartAverageBar}