import React from "react";
import {UITable} from "../components/ui/table/table.js";

const TestTable = function (props) {
    const columns = {
        area: {
            title: 'Area',
            width: 3,
            sort: true
        },
        period: {
            title: 'Period',
            width: 2
        },
        completed: {
            title: 'Completed',
            width: 3
        },
        dup:{
            title: 'Dup',
            width: 2,
        },
        referrals:{
            title: 'Referrals',
            width: 2,
        },
        admissions: {
            title: 'Admissions',
            width: 2
        },
        employment: {
            title: 'Employment',
            width: 2
        },
        care: {
            title: 'Care',
            width: 2
        },
        download: {
            width: 1
        },
        preview: {
            width: 1
        }
    };

    const columnsConfig = Object.keys(columns).map((column)=>{
        return {
            id: column,
            value: (row) => ( row[column] ),
            renderCell: (value)=> value,
            ...columns[column],
        }
    })

    const data = [
        {
            area: 'Bristol EIP Team',
            period: 'aest'
        },
        {
            area: 'ristol EIP Team',
            period: 'cest'
        },
        {
            area: 'uristol EIP Team',
            period: 'test'
        },
        {
            area: 'aristol EIP Team',
            period: 'best'
        },
    ];

    return <div style={{maxWidth: 1200,  margin: 'auto', padding: 10}}>
        <UITable data={data} columns={columnsConfig}/>
    </div>
}

export {TestTable}