
// TODO: Move to a configuration file (outside helpers)
const denominators = [ 
    {id: 25, title: 'Offer of CBT', denominator: 5},
    {id: 26, title: 'Delivery of CBT', denominator: 5},
    {id: 27, title: 'Contact with Family', denominator: 5},
    {id: 28, title: 'Offer of Family Interventions', denominator: 27, 
        description: 'Percentage of people that are in contact with their family that have been offered family interventions'},
    {id: 29, title: 'Delivery of Family Interventions', denominator: 27, 
        description: 'Percentage of people that have received 2 or more family interventions'},
    {id: 30, title: 'Medication', denominator: 5},
    {id: 31, title: 'Medication Management', denominator: 30, 
        description:'Percentage of people that are symptotic after treatment trial of two antipsychotics'},
    {id: 33, title: 'Clozapine', denominator: 31, 
        description:"Percentage of people whose illness has not responded adequately to two differnet antipsychots, that have been offered Clozapine"},
    {id: 34, title: 'Vocation', denominator: 5},
    {id: 35, title: 'IPS', denominator: 34, 
        description: 'Percentage of people that have had 2 or more IPS specialist meetings'},
    {id: 36, title: 'Generic Employment and Educational Support', denominator: 34, 
        description: "Percentage of people who are supported with generic employment and educational support"},
    {id: 37, title: 'Physical Health Assessment', denominator: 5},
    {id: 38, title: 'Smokers', denominator: 5},
    {id: 39, title: 'Smoking Cessation', denominator: 38, 
        description:"Percentage of people who smoke that have been offered smoking cessation support in the last year"},
    {id: 40, title: 'Carers', denominator: 5},
    {id: 41, title: 'Carers Support', denominator: 40, 
        description: "Percentage of many carers that have been offered carer-focused assessment, education and support programmes,"+
        " e.g. Carers Assessment, Respite, Carers Support Group or informal Family Work."}
];

export {denominators}

