import {config} from '../../config.js';
import {ModelXhr} from '../../helpers/model/xhr.js';

class ModelAudit extends ModelXhr {

    static modelName = "ModelAudit";

    static currentValues = null;

    static getSelect(input) {
        let select = {};
        if(input.id){
            select.filter = 'id eq ' + input.id;
        }
        if(input.period_id){
            select.filter = 'fk_period_id eq '+input.period_id;
        }
        if(input.progress){
            select = {
                select: 'step, sum(id) as count',
                filter: 'period eq \'' + ModelAudit.getCurrentPeriodName() + '\''
            }
        }
        return select;
    }

    static updateItem(id, data, onSuccess=()=>{}){
        this.saveData(ModelAudit, config.api_endpoint + 'audit/' + id, 'PATCH', 
            data, 
            (response) => {
                if(response.success){
                    const audit = response.result? (response.result[0] || null): null;
                    onSuccess(audit);
                }else{
                    // todo: post status to global message channel
                    console.error(response.error || "Unknow error");
                }
            }
        );
    }

    static getCurrentPeriodName(){
        const d = new Date();
        return d.getFullYear() + 'Q' + Math.ceil((d.getMonth() + 1)/3);
    }
    
    constructor(selectData) {
        super(selectData, {
            endpoint: config.api_endpoint + 'audit',
            method: 'GET'
        });

        this.cacheEnabled = true;
    }

   
    getSelect(input) {
        return ModelAudit.getSelect(input);
    }

}

export {ModelAudit};