import {config} from '../../config.js';
import {ModelXhr} from '../../helpers/model/xhr.js';

class ModelAuditIndicatorRatio extends ModelXhr {

    static modelName = "ModelAuditIndicatorRatio";

    static getSelect(input) {
        const filters = []
        if(input.id){
            filters.push('id eq ' + input.id);
        }
        if(input.ids){
            filters.push('id eq ' + input.ids.join(' or id eq '));
        }
        if(input.team_id){
            filters.push('team_id eq ' + input.team_id);
        }
        if(input.period){
            filters.push('period eq \'' + input.period + '\'');
        }
        if(input.period_id){
            filters.push('period_id eq ' + input.period_id);
        }

        const select = input.select || 'id, title, period, team_id, team, value, level'

        const criteria = {
            filter: filters.join(' and '), 
            select,
        };

        if(input.order){
            criteria.order = input.order;
        }
        return criteria;
    }


    constructor(selectData) {
        super(selectData, {
            endpoint: config.api_endpoint + 'indicator-ratio',
            method: 'GET'
        });

        this.cacheEnabled = true;
    }


    getSelect(input) {
        return ModelAuditIndicatorRatio.getSelect(input);
    }
    
    getRelativeGroupScores(groupId, resultSetIndicator){
        const resultSet = this.getData();
        if(resultSet.status !== ModelAuditIndicatorRatio.Status.SUCCESS){
            return resultSet;
        }
        if(resultSetIndicator.status !== ModelAuditIndicatorRatio.Status.SUCCESS){
            return resultSetIndicator;
        }

        const indicators = {};
        // collect data by indicator
        for(const row of resultSet.data){
            if(! indicators[row.id]){
                indicators[row.id] = {
                    id: row.id,
                    title: row.title,
                    others: [row.value],
                    values: []
                }
            }else{
                indicators[row.id].others.push(row.value);
            }
            if(row.team_id === groupId || row.provider_id === groupId){
                indicators[row.id].values.push(row.value);
            }
        }

        // calculate zvalue for own result
        for(const indicatorId of Object.keys(indicators)){
            const ind = indicators[indicatorId];
            // our value can be a list of teams when we have selected a provider.
            const value = ind.values.reduce((sum, i) => (sum + i), 0) / ind.values.length;
            ind.zvalue = this.getZScore(ind.others, value);
            
            // inserting the title from the other resultSet
            for(const details of resultSetIndicator.data){
                if(details.id === ind.id){
                    ind.title = details.title;
                    ind.description = details.description;
                    break;
                }
            }
        }

        return {...resultSet, data: indicators};

    }

    getZScore(values, value){
        // z = x - mean / standard_deviation
        // standard_deviation = sqrt( sum((x - mean)^2) / n)
        const n = values.length;
        const mean = values.reduce((sum, i) => (sum + i), 0) / n;
        const rootSum = values.map((x) => Math.pow(x - mean, 2))
                .reduce((sum, i) => (sum + i));
        const stdv = Math.sqrt(rootSum / n);
        return (value - mean) / stdv;
    }

}

export {ModelAuditIndicatorRatio};