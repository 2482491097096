import React from "react";
import {PropTypes} from 'prop-types';
import styles from '../../../../assets/variables.scss';
import "../export.scss";
import {findData, getLevel, getScore} from '../../../dashboard/measures/calculate-level.js';


const colors = [styles.brandRedColor, styles.brandYellowColor, styles.brandGreenColor, styles.brandBlueColor];
const getColor = function(value, thresholds){
    const level = getLevel(value, thresholds);
    if(level === 0){
        return 'transparent';
    }
    return colors[level-1];
}


const ViewAdminExportManagerReportTableTwo = function (props) {
    const {answerData, indicatorData, periods} = props;
    const dataTable2 = indicatorData.map((item) => (
        {
            id : item.id,
            header: item.title,
            description: item.description,
            values: findData(answerData, periods, item.fk_field1, item.fk_field2),
            thresholds: [item.threshold1 * 100, item.threshold2 * 100, item.threshold3 * 100]
        }
    ));

    // 2 outcome indicator (this follow the formulla 0.5 * (O6 + O7) / O8a)
    const a1 = findData(answerData, periods, 56, 59); // O6 / O8a
    const a2 = findData(answerData, periods, 57, 59); // O7 / O8a
    const outcomeMeasures = {};
    // take the average of both
    for(const p of Object.keys(a1)){
        const v1 = parseFloat(a1[p].replace('%', ''));
        const v2 = parseFloat(a2[p].replace('%', ''));
        outcomeMeasures[p] = isNaN(v1) || isNaN(v2)? '-': Math.round(0.5 * (v1 + v2)) + '%';
    }
    const outcomeThresholds = [25, 50, 75];


    return <table className="zol-manager-table" style={{minWidth:'50%'}}>
        <tbody>
            <tr>
                <td className={"header-row"} style={{textAlign:'left'}}>
                    NICE Standard for Early Intervention in Psychosis
                </td>
                {periods.map((item)=>{
                    return <td key={item} className={"header-row"}>{item}</td>
                })}
                <td style={{backgroundColor: colors[0], width:'10%'}}>
                    Level 1: Needs Significant Improvement
                </td>
                <td style={{backgroundColor: colors[1], width:'10%'}}>
                    Level 2: Requires Improvement
                </td>
                <td style={{backgroundColor: colors[2], width:'10%'}}>
                    Level 3: Performing Well
                </td>
                <td style={{backgroundColor: colors[3], width:'10%'}}>
                    Level 4: Top Performing
                </td>
            </tr>
            {dataTable2.map((indicator) => {
                const thresholds = indicator.thresholds.every(item => item ===0)? false: indicator.thresholds;
                
                return <tr key={indicator.id}>
                    <td className={"header-column-table2"}>{indicator.header}
                        <p>{indicator.description}</p>
                    </td>
                    {periods.map((period) => ( 
                        <td key={period} 
                                style={{backgroundColor: getColor(indicator.values[period], thresholds)}}>
                            {indicator.values[period]}
                        </td> 
                    ))}
                    {thresholds? 
                        <td className="threshold">{'<'+thresholds[0]}%</td>: 
                        <td className="no-threshold"></td>
                    }
                    {thresholds? 
                        thresholds.map((value, i) => ( <td key={i} className="threshold">≥{value}%</td> )): 
                        [1,2,3].map((value) => ( <td key={value} className="no-threshold"></td>))
                    }
                </tr>
            })}
            <tr>
                <td className={"header-column-table2"}>2 Outcome Measures Recorded</td>
                {periods.map((period) => (
                    <td key={period} 
                            style={{backgroundColor: getColor(outcomeMeasures[period], outcomeThresholds)}}>
                        {outcomeMeasures[period]}
                    </td>
                ))}
                <td className="threshold">{'<25%'}</td>
                {outcomeThresholds.map((value, i) => ( <td key={i} className="threshold">≥{value}%</td> ))}  
            </tr>
            <tr>
                <td className={"header-column-table2"}>Overall CCQI Rating
                    <p>
                        i.e. 1 (Needs Significant Improvement) 2 (Needs Improvement) 3 (Performing Well) or 4 
                        (Top Performing).
                    </p>
                </td>
                {periods.map((period) => {
                    const levels = {0: 0, 1: 0, 2: 0, 3: 0, 4: 0, outcome: 0};
                    for(const indicator of dataTable2){
                        const thresholds = indicator.thresholds.every(item => item ===0)? 
                                false: indicator.thresholds;
                        const level = getLevel(indicator.values[period], thresholds);
                        levels[level]++;
                    }
                    const score = getScore(levels, getLevel(outcomeMeasures[period], outcomeThresholds));

                    return <td key={period} 
                            style={{backgroundColor: score === 0? 'transparent': colors[score-1]}}>
                        {score === 0? '-': score}
                    </td>;
                })}
                {[0,1,2,3].map((value) => ( <td key={value} className="no-threshold"></td>))}                
            </tr>
        </tbody>
    </table>
}

ViewAdminExportManagerReportTableTwo.propTypes = {
    periods: PropTypes.array.isRequired,
    answerData: PropTypes.array.isRequired,
    indicatorData: PropTypes.array.isRequired
}

export {ViewAdminExportManagerReportTableTwo};
