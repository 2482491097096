import React, { useEffect, useState } from "react";
import {PropTypes} from 'prop-types';
import styles from "../../../../assets/variables.scss";
import {UIText} from "../../../../components/ui/text.js";
import {useForm} from "../../../../helpers/form/use_form.js";
import {isNumber} from "../../../../helpers/form/validation.js";
import {FormElement} from '../../../../helpers/form/element.js';
import { useModelFeedback } from "../../../../helpers/model/use_model_feedback.js";
import {ReactComponent as IconArrowLeft} from "../../../../assets/icons/arrow_left.svg";
import { useStateContext } from "../../../../helpers/state-context.js";
import { UIInputSelect } from "../../../../components/ui/input/select.js";
import {UIInput} from '../../../../components/ui/input/input.js';
import { UIInputTextarea } from "../../../../components/ui/input/textarea.js";
import {UIButton} from '../../../../components/ui/button/button.js';
import { ModelAuditQuestion } from "../../../../model/audit/question.js";
import { ModelAuditIndicator } from "../../../../model/audit/indicator.js";


const thresholdTitles = {
    1: 'Level 1 - Level 2',
    2: 'Level 2 - Level 3',
    3: 'Level 3 - Level 4'
};


const formConfig = [
    {
        title: 'Indicator title',
        name: 'title',
        element: UIInput,
        required: true,
        type: 'wide'
    },
    {
        title: 'Description/explanation',
        name: 'description',
        element: UIInputTextarea,
        required: false,
        type: 'wide'
    },
    {
        title: 'Type',
        name: 'type',
        element: UIInputSelect,
        options: {
            ratio: 'Ratio'
        },
        required: true,
        type: 'wide'
    },
    {
        title: 'Category',
        name: 'category',
        element: UIInputSelect,
        options: {
            nice: 'NICE',
            caseload: 'Caseload',
            outcomes: 'Outcomes'
        },
        required: true,
        type: 'wide'
    },
    {
        title: 'Numerator',
        name: 'fk_field1',
        element: UIInputSelect,
        options: [],
        required: true,
        conditions: [
            (values) => (values.type === 'ratio')
        ],
        type: 'wide'
    },
    {
        title: 'Denominator',
        name: 'fk_field2',
        element: UIInputSelect,
        options: [],
        required: true,
        conditions: [
            (values) => (values.type === 'ratio')
        ],
        type: 'wide'
    },
    {
        title: 'Thresholds',
        name: 'thresholds',
        element: false,
        columns: 3,
        type: 'wide',
        children: [1, 2, 3]
            .map(id => ({
                name: 'threshold' + id,
                element: UIInput,
                title: thresholdTitles[id],
                type: 'wide',
                processValue: (value) => ( parseFloat(value) || 0),
                validations: [
                    {
                        checkValue: isNumber,
                        message: 'This is not a valid number'
                    }, 
                    {
                        checkValue: (value) => ( value < 4 && value > -1),
                        message: 'Please provide the value as a decimal fraction, not as percentage. ' +
                            'The allowed range is -1 to 4, which translates to -100% to 400%.'
                    }
                ]
            }))
    }
];

const IndicatorEdit = function (props) {
    // When we create a new item, we do not have a indicatorId, but we still need to call the model. We pass
    // id=0 in that case. This is not ideal.
    const resultSet = useModelFeedback(ModelAuditIndicator, {id: props.indicatorId || 0});
    const [indicatorType, setIndicatorType] = useState('ratio');
    const resultSetQuestion = useModelFeedback(ModelAuditQuestion);
    
    const [state, dispatch] = useStateContext(['view', 'id']);

    let elements = formConfig
            .map((item) => {
                if(['fk_field1', 'fk_field2'].includes(item.name) && indicatorType === 'ratio'){
                    // insert the options for question fields
                    item.options = resultSetQuestion.feedback? {}: resultSetQuestion.data.reduce(
                        (options, item) => { 
                            options[item.id] = item.number + ' - ' + item.title; 
                            return options; 
                        }, 
                        {}
                    );
                }
                return item;
            });

    // get a new form based on the configuration.
    const Form = useForm(elements);

    const [valuesPresent, setValuesPresent] = useState(false);

    useEffect(() => {
        if(! resultSet.feedback && ! valuesPresent){
            if(state.view === 'edit' && resultSet.data[0]){
                Form.setValues(resultSet.data[0]);
                setIndicatorType(resultSet.data[0].type);
            }
            setValuesPresent(true);
        }else if(valuesPresent && resultSet.feedback && state.view === 'edit'){
            setValuesPresent(false);
        }
    }, [resultSet, Form, valuesPresent, state.view]);

    if(resultSet.feedback){
        return resultSet.feedback;
    }else if(! valuesPresent){
        return null;
    }
    
    const closeForm = () => {
        dispatch({type: 'setValues', value: {view: '', id: ''}});
    }

    const checkType = (values) => {
        if(values.type && values.type !== indicatorType){
            setIndicatorType(values.type);
        }
    }

    const submitForm = () => {

        if(Form.validate()){
            if(state.view === 'new'){
                ModelAuditIndicator.createItem(Form.getValues(), closeForm);
            }else{
                ModelAuditIndicator.updateItem(props.indicatorId, Form.getValues(), closeForm);
            }
        }

    }

    return <div>
        <UIText type={'body-L-bold'} color={'mediumColor'} style={{cursor: 'pointer', marginRight: 10}}
                onClick={closeForm}>
            <IconArrowLeft style={{color: styles.darkColor, marginRight: 5}}/>Back
        </UIText>
        <UIText type={'head-1'} color={'darkColor'} style={{marginBottom: 10}}>
            {state.view === 'new'? 'New indicator': 'Edit indicator'}
        </UIText>

        <div className="zol-admin-content">
            <Form.Component onChange={checkType}>
                {elements.map(element => (
                    <FormElement key={element.name} {...element} />
                ))}          

                <UIButton className="off-white" onClick={closeForm} style={{marginRight: '15px'}}>
                    Cancel
                </UIButton>  
                <UIButton className="primary" onClick={submitForm}>
                    Save
                </UIButton>  
            </Form.Component>
        </div>

    </div>;

};

IndicatorEdit.defaultProps = {
    
}

IndicatorEdit.propTypes = {
    indicatorId: PropTypes.number
}

export {IndicatorEdit};