import {useModel} from './use_model.js';
import {ModelOrgHierarchy} from '../../model/org-hierarchy.js';
//import {ModelAuthUser} from '../../model/auth/user.js';

const toAlpha = (num) => {
    if(num < 1 || num > 26 || typeof num !== 'number'){
       return -1;
    }
    const leveller = 64;
    //since actually A is represented by 65 and we want to represent it with one
    return String.fromCharCode(num + leveller);
 };

const useOrderedGroups = function(){
    const resultSet = useModel(
            ModelOrgHierarchy,
            {select: 'provider, provider_id, team, team_id', order: 'provider asc'},
            (m) => ( m.getRawData() )
    );
 //   const resultSetUser = useModel(ModelAuthUser, {id: 'me'});

    const options = {}
    if(resultSet.status === ModelOrgHierarchy.Status.SUCCESS){

        const data = resultSet.data.slice(0).sort((a, b) => (
            a.provider + a.team < b.provider + b.team? -1: 1
        ));

        //only save data that user has access to
      //  const userGroups = data.filter(function(row) { return resultSetUser.data[0].groups.includes(row.provider_id) });

        let teamId = 1;
        
        const unique_providers = [...new Set(data.map(item => item.provider_id))];
        
        for(const item of data){
            //get place of provider_id in a list with distinct provider_ids
            const providerIndex = unique_providers.indexOf(item.provider_id);

            options[item.provider_id] = toAlpha(providerIndex+1);
            options[item.team_id] = teamId;
            teamId += 1;
        }
    }

    return options;
}

export {useOrderedGroups};