import 'core-js';
import ResizeObserver from 'resize-observer-polyfill'; // seems antd forgot to include this polyfill...
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import {App} from './App';
import * as serviceWorker from './serviceWorker';
import {UrlStateContext} from './helpers/url-state-context.js';
import {SizedApp} from './helpers/elements/sized-app.js';

window.ResizeObserver = window.ResizeObserver || ResizeObserver;

const parseSize = (width) => {
    let size = '';
    if (width > 1600) {
        size = 'xxl';
    } else if (width > 1200) {
        size = 'xl';
    } else if (width > 992) {
        size = 'lg';
    } else if (width > 768) {
        size = 'md';
    } else if (width > 576) {
        size = 'sm';
    } else {
        size = 'xs';
    }
    return size;
};

const component = (
    <UrlStateContext path={['page']} initialState={{page: 'index'}}>
        <SizedApp parseSize={parseSize} defaultSize="xl">
            <App />
        </SizedApp>
    </UrlStateContext>
);

ReactDOM.render(component, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
