import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import {FormElement} from "./element.js";

const FormGroup = function (props) {
    const [error, setError] = useState(false);

    useEffect(()=>{
        let validated = true;
        // Check if all validations are valid.
        for(const validation of props.validations){
            if(validation.externalValidationValue &&
                    !validation.validation(validation.externalValidationValue)){
                validated = false;
                setError(validation.message);
                break;
            }
        }
        // Remove existing error when validated.
        if(validated && error){
            setError(false)
        }
    }, [error, props.validations])

    return <div className={'zol-form-element'}>
        <label className={'zol-form-element-title'}>{props.title}</label>
        <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between'}}>
            {props.data.map((question)=>{
                return <FormElement key={question.answer_id} {...question} 
                        style={{width: '31%', margin: '0 5px'}}
                        onBlur={(value, answer_id) => props.onBlur(value, answer_id)} />
            })}
        </div>

        {error?
            <span style={{display: 'inline-block', color: 'red'}}>{error}</span>:
            null
        }
        
    </div>
};

FormGroup.defaultProps = {
    columns: 3
};

FormGroup.propTypes = {
    title: PropTypes.string,
    columns: PropTypes.number,
    data: PropTypes.array,
    onChange: PropTypes.func
};

export {FormGroup}