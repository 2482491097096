import './my-account.scss';
import {useModelFeedback} from '../helpers/model/use_model_feedback.js';
import {ModelAuthUser} from '../model/auth/user.js';
import React, {useEffect} from 'react';
import {useStateContext} from '../helpers/state-context.js';
import {UIText} from '../components/ui/text.js';
import {FormElement} from '../helpers/form/element.js';
import {UIButton} from '../components/ui/button/button.js';
import {useForm} from '../helpers/form/use_form.js';
import {UIInput} from '../components/ui/input/input.js';
import {UIInputPassword} from '../components/ui/input/password.js';
import {isEqual} from '../helpers/form/validation.js';

const formConfig = [
    {
        title: 'Name',
        name: 'name',
        element: UIInput,
        required: true,
        type: 'wide'
    },
    {
        title: 'Current password',
        name: 'password_current',
        element: UIInputPassword,
        isRequired: true,
        type: 'wide'
    },
    {
        title: 'New Password (leave empty to keep current)',
        name: 'password',
        element: UIInputPassword,
        validations: [
            {
                checkValue: (value) => ( value === null || (value && value.length >= 8)),
                message: 'The password needs to be at least 8 characters long'
            }
        ],
        type: 'wide'
    },
    {
        title: 'Confirm password',
        name: 'password_confirm',
        element: UIInputPassword,
        validations: [
            {
                checkValue: (value, allValues) => (isEqual(value, allValues.password)),
                message: 'The provided passwords do not match'
            }
        ],
        type: 'wide'
    }
]

const ViewMyAccount = (props) => {
    const [, dispatch] = useStateContext();
    const resultSet = useModelFeedback(ModelAuthUser, {id: 'me'});

    const Form = useForm(formConfig);

    useEffect(() => {
        if(resultSet.status === ModelAuthUser.Status.FAILED){
            // Could not fetch the user, redirect to sign in
            dispatch({type: 'page', value: 'sign-in'});
        }

        if(!resultSet.feedback){
            const data = resultSet.data[0];
            Form.setValues(data);
        }
    }, [Form, resultSet, dispatch]);

    if(resultSet.feedback){
        return resultSet.feedback;
    }
    const user = resultSet.data[0];

    const submitForm = () => {

        if(Form.validate()){
            const formValues = Form.getProcessedValues();
            if(formValues.password === ''){
                delete formValues.password;
                delete formValues.password_confirm;
            }
            ModelAuthUser.updateItem(user.id, formValues, 
                (res) => {
                    dispatch({type: 'page', value: 'dashboard'});
                },
                (error) => {
                    alert(error);
                }
            );
        }
    };

    return <div className={'my-account-container'}>
        <div className={'my-account'}>
            <UIText type={'head-1'} color={'darkColor'} style={{marginBottom: 10}}>
                My account
            </UIText>

            <div className="my-account-content">
                <Form.Component>
                    {formConfig.map(element => (
                        <FormElement key={element.name} {...element} />
                    ))}

                    <UIButton className="primary" onClick={submitForm}>
                        Save
                    </UIButton>

                </Form.Component>
            </div>
        </div>
    </div>
};


export {ViewMyAccount}