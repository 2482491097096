import {config} from '../../config.js';
import {ModelXhr} from "../../helpers/model/xhr.js";

class ModelParagraph extends ModelXhr {
    static modelName = "ModelParagraph";


    static getSelect(input) {
        return input
    }

    static updateItem(id, data, onSuccess=()=>{}){
        this.saveData(ModelParagraph,
            config.api_endpoint + 'paragraph/' + id, 'PATCH',
            data,
            (response) => {
                if(response.success){
                    const paragraph = response.result? (response.result[0] || null): null;
                    onSuccess(paragraph);
                }else{
                    // todo: post status to global message channel
                    console.error(response.error || "Unknow error");
                }
            }
        );
    }

    getSelect(input) {
        return ModelParagraph.getSelect(input);
    }

    constructor(selectData) {
        super(selectData, {
            endpoint: config.api_endpoint + 'paragraph',
            method: 'GET'
        });

        this.cacheEnabled = true;
        this.update();
    }

    getPayload() {
        return {}
    }

    getEndpoint(){
        return this.endpoint + (this.select.type? '/' + this.select.type: '');
    }

}

export {ModelParagraph};
