import './dashboard.scss';
import React from "react";
import {DashboardCardNiceOverview} from "./cards/nice-overview.js";
import {DashboardCardNiceInterventionsList} from "./cards/nice-interventions-list.js";
import {DashboardCardNiceInterventions} from "./cards/nice-interventions.js";
import {DashboardCardNiceComparison} from "./cards/nice-comparison.js";
import {DashboardCardNiceProgress} from "./cards/nice-progress.js";
import {DashboardDefaultPage} from './default-page.js';
import {useModelFeedback} from '../../helpers/model/use_model_feedback.js';
import {ModelParagraph} from '../../model/admin/paragraph.js';


const ViewDashboardNice = function (props){
    const resultSetText = useModelFeedback(ModelParagraph, {type: 'nice_title'});
    if(resultSetText.feedback){
        return resultSetText.feedback
    }
    return <DashboardDefaultPage shareable={true} title={resultSetText.data[0].text}>
        <DashboardCardNiceOverview title={'Overview'} cardSize={'medium'}/>
        <DashboardCardNiceInterventionsList title={'Interventions'} cardSize={'medium'}/>
        <DashboardCardNiceInterventions title={'CPTp Offered/Delivered'} cardSize={'sm'}/>
        <DashboardCardNiceComparison cardSize={'ml'}/>
        <DashboardCardNiceProgress cardSzie={'large'}/>
    </DashboardDefaultPage>;

}

export {ViewDashboardNice}