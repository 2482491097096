import React, {useState} from "react";
import styles from "../../../assets/variables.scss";
import {UIInputSelect} from "../../../components/ui/input/select.js";
import {Card} from "../../../components/card/card.js";
import {CardHeader} from "../../../components/card/header.js";
import {useModelFeedback} from "../../../helpers/model/use_model_feedback.js";
import {ModelDashboardAnswers} from "../../../model/dashboard/answers.js";
import {ModelOrgHierarchy} from "../../../model/org-hierarchy.js";
import {ModelAuditIndicator} from "../../../model/audit/indicator.js";
import {VerticalBarIcon} from '../../../components/chart/vertical-bar/icon.js';
import {VerticalBarComponentCircle} from '../../../components/chart/vertical-bar/component/circle.js';
import { useStateContext } from '../../../helpers/state-context';

function getGroupOptions(){
    return [
        {id: 1, label: 'providers', title: 'providers'},
        {id: 2, label: 'teams', title: 'teams'}
    ]
};

function getQuestionOptions(){
    return [
        {id: 26, title: 'Who have had 2 or more sessions of CBTp'},
        {id: 29, title: 'Who have received 2 or more sessions for family interventions'},
        {id: 33, title: 'Who have been offered clozapine'},
        {id: 36, title: 'Who has been supported with generic employment and educational support'},
        {id: 37, title: 'Who received comprehensive Physical Health Checks'},
        {id: 39, title: 'Who received smoking cessation'},
        {id: 40, title: 'Who have (an) identified carer(s)'}
    ]
};

function getChildIds(i, resultSetProviders, resultSetHierarchy){
    const provider_id = resultSetProviders.data[i].id;
    const childIds = [];
    resultSetHierarchy.data.forEach((item)=>{
        if(item.provider_id === provider_id){
            childIds.push(item.team_id);
        }
    })
    return childIds;
}

function getProviderValue(childIds, dataAnswer, dataCaseload){
    let totalNumerator = 0;
    let totalDenominator = 0;
    for(const id of childIds){
        totalNumerator += dataAnswer.find(row => row.group_id === id)?.value || 0;
        totalDenominator += dataCaseload.find(row => row.group_id === id)?.value || 0;
    }
    return (totalNumerator/totalDenominator *100).toFixed()
}

const DashboardCardNiceComparison = function (props) {
    //TODO: option to see across teams, providers etc
    const [group, setGroup] = useState('teams');
    const [state, ] = useStateContext(['page', 'section', 'period', 'group', 'isExportingPDF']);
    const [question, setQuestion] = useState('Who received comprehensive Physical Health Checks');
    const groupOptions = getGroupOptions();
    let data = [];
    const resultSetIndicator = useModelFeedback(ModelAuditIndicator);
    const resultSetHierarchy = useModelFeedback(ModelOrgHierarchy, {select: 'provider, provider_id, team, team_id'}, (m) => (m.getRawData()));
    const resultSetProviders = useModelFeedback(ModelOrgHierarchy, {type: 'provider'}, (m)=> m.getRawData());
    const questionOptions = resultSetIndicator.feedback? [] :
        getQuestionOptions().map((item)=>({
                id: item.id,
                title: item.title,
                denominator: resultSetIndicator.data.find((row)=> row.fk_field1 === item.id)?.fk_field2 || 0
            })
        );
    const question_id = questionOptions.find((p)=>p.title === question)?.id;
    const resultSetAnswer = useModelFeedback(ModelDashboardAnswers,
        {
            question_id: question_id,
            period: state.period,
            select: 'group_id, answer as value, group_title, question_id, period_id'
        });
    
    const resultSetDenominator = useModelFeedback(ModelDashboardAnswers,
        {
            question_id: questionOptions.find((row)=> row.id === question_id)?.denominator || 0,
            period: state.period,
            select: 'group_id, answer as value, group_title, question_id, period_id'
        });

    if(resultSetHierarchy.feedback || resultSetProviders.feedback){
        return resultSetHierarchy.feedback || resultSetProviders.feedback;
    }
   
    const toPercentage = (group_id) =>{
        const value1 = resultSetAnswer.data.find(row => row.group_id === group_id)?.value || 0;
        const value2 = resultSetDenominator.data.find(row => row.group_id === group_id)?.value || 0;
        const value = (value1/value2 *100).toFixed();
        return (!isNaN(parseFloat(value)) && isFinite(value)) ? value : 0
    }
    
    if(!resultSetAnswer.feedback && !resultSetDenominator.feedback){
        for(let i=0; i<resultSetAnswer.data.length; i++){
            const value = toPercentage(resultSetAnswer.data[i].group_id)
            const activeIds = resultSetHierarchy.data.reduce((array, item) =>{
                let newArray = [...array]
                if(item.provider_id === state.group || item.team_id === state.group){
                    newArray.push(...[item.provider_id, item.team_id])
                }
                return newArray
            }, []);
            const color = activeIds.includes(resultSetAnswer.data[i].group_id) ?
                styles.brandPurpleColor :
                null; 
            data.push({
                group_id: resultSetAnswer.data[i].group_id,
                value: value,
                color: color,
                header: resultSetAnswer.data[i].group_title,
                tooltip: (isNaN(value) ? 0 : value)+`%`,
            })
        }
    }
    if(group==='teams'){
        data.sort((a, b) => (parseInt(a.value) > parseInt(b.value)) ? 1 : -1 );
    }else{
        if(data.length){
            let providerData = [];
            for(let i=0; i<resultSetProviders.data.length; i++){
                const childIds = getChildIds(i, resultSetProviders, resultSetHierarchy);
                const value = getProviderValue(childIds, resultSetAnswer.data, resultSetDenominator.data);
                const color = [...childIds, resultSetProviders.data[i].id].includes(state.group) ?
                styles.brandPurpleColor :
                    null
                providerData.push({
                    group_id: resultSetProviders.data[i].id,
                    color: color,
                    value : value,
                    header: resultSetProviders.data[i].title,
                    tooltip: value+`%`,
                })
            }
            data = providerData.sort((a,b)=>(parseInt(a.value) > parseInt(b.value)) ? 1 : -1 );
        } 
    }

    const getGroupSelect = () => {
        const options = groupOptions.map((o)=>o.title);
        return <UIInputSelect
            options={options}
            name={'period-select'}
            placeholder={props.placeholder}
            onChange={(value)=>{setGroup(value)}}
            value={group} />
    };

    const getQuestionSelect = () => {
        const options = questionOptions.map((o)=>o.title);
        return <UIInputSelect
            style={{width: 300}}
            options={options}
            name={'period-select'}
            placeholder={props.placeholder}
            onChange={(value)=>{setQuestion(value)}}
            value={question} />
    };

    const getTitle = function (title){
        return <div style={{width: '100%', display:'inline-flex'}}>
            <p>{title}</p>
            <p>{getQuestionSelect()}</p>
        </div>
    };

    function getLines(){
        const item = resultSetIndicator.data.find((item)=> item.fk_field1 ===question_id);
        if(item?.threshold1){
            return [
                {value: item.threshold1*100, color: styles.brandRedColor},
                {value: item.threshold2*100, color: styles.brandYellowColor},
                {value: item.threshold3*100, color:styles.brandGreenColor}]
        }
    }

    const lines = resultSetIndicator.status === 'success'? getLines(): [];
    const maxValue = Math.max(...data.map((item)=>parseFloat(item.value)), 100);
    const max = (maxValue > 100) ? maxValue + 10 : 100;

    return <Card cardSize={props.cardSize}  id={props.id}>
        <CardHeader id={props.id} title={getTitle} rightColumn={getGroupSelect()} style={{height: 35}}/>
        <div style={{height: 240}}>
            <VerticalBarIcon data={data.map(({group_id, ...item }) => ({
                        ...item,
                        icon: (state.isExportingPDF) ?
                            <VerticalBarComponentCircle>
                                {group_id}
                            </VerticalBarComponentCircle>:
                            null,
                    }))}
                    axis={{x: 'header', y:['value']} }
                    type='grouped'
                    tooltipHeader={true}
                    formatAxis={{
                        y: (value) => `${value}%`
                    }}
                    domain={[0,max]}
                    hideAxisX={true}
                    lines={lines}/>
        </div>

    </Card>
};

DashboardCardNiceComparison.defaultProps = {
    id : 17
};

export {DashboardCardNiceComparison}

