import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {useStateContext} from '../state-context.js';
import {Loader} from '../elements/loader.js';
import {ModelPDFExport} from './pdf-export.js';
import {CardTeamNamesList} from '../../view/dashboard/cards/team-names-list.js';
// import {notification} from '../elements/notifications';
// import {useSize} from '../use_size.js';

// TODO:
const PDFExportContainer = (props) => {
    const [state, dispatch] = useStateContext(['isExportingPDF']);
    const {template,  ...rest} = props;
    const containerRef = useRef();
    const isExporting = (state.isExportingPDF && (state.isExportingPDF === props.id))

    useEffect(()=>{
        let timerId = null;
        if(isExporting && containerRef.current.innerHTML !== null){
            timerId = setTimeout(()=>{
                return ModelPDFExport.export({
                    extension: 'pdf',
                    html: containerRef.current.innerHTML,
                    options: {
                        format: '',
                        width: 1200,
                        height: 1600
                    }
                }, (res)=>{
                    dispatch({type: 'isExportingPDF', value: false});
                })
            }, 3000);
        }else{
            // dispatch({type: 'isExportingPDF', value: false});
            // notification.error({message: `Coundn't find card to download`});
        }

        return () => {
            clearTimeout(timerId);
        }
    }, [state.isExportingPDF, isExporting, dispatch]);
   
    // Why do we have a class zol-card-mobile here even in the desktop version..?
    return <div ref={containerRef}
                {...rest}
                className={isExporting ? 'zol-pdf-export' : 'zol-card-mobile ' + props.className}
                style={{position: 'relative', ...props.style}}>
        {/*TODO: */}
        <div style={{
                    width: (isExporting) ? 1400 : '100%', 
                    height: props.className.indexOf('zol-card-auto-height') !== -1? 'auto': '100%'
                }}>
            {(isExporting && template) ?
                template :
                props.children}
            {/* Include TeamNamesList only while exporting dashboard, card id 8 */}
            {['dashboard', '8', '17', '15', '12'].includes(state.isExportingPDF) && isExporting?
                <CardTeamNamesList/>:
                null
            }

        </div>
        {state.isExportingPDF ?
            <div className={'pdf-export-hide'} style={{
                        position: 'absolute', 
                        top: 0,  
                        width: '100%', 
                        height: '100%', 
                        backgroundColor: '#edf2f5'
                    }}>
                <Loader />
            </div> :
            null
        }
    </div>
};

PDFExportContainer.defaultProps = {
    className: ''
}

PDFExportContainer.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    className: PropTypes.string
};

export {PDFExportContainer}