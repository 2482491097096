import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

const UISvgText = function(props){
    const {x, y, lineheight} = props;
    const [lines, setLines] = useState([]);


    useEffect(()=>{

        const getLines = () => {
            const {wordsWithComputedWidth, spaceWidth} = getWordWidths();
            const wordsByLines = wordsWithComputedWidth.reduce((result, {word, width}) => {
                const previousLine = result[result.length - 1] || {words: [], width: 0};

                if(previousLine.words.length === 0){
                    // First word on line
                    const newLine = {words: [word], width};
                    result.push(newLine);
                }else if(previousLine.width + width + (previousLine.words.length * spaceWidth) < props.width){
                    // Word can be added to an existing line
                    previousLine.words.push(word);
                    previousLine.width += width;
                }else{
                    // Word too long to fit on existing line
                    const newLine = {words: [word], width};
                    result.push(newLine);
                }

                return result;
            }, []);

            return wordsByLines.map((line) => line.words.join(' '));
        };

        const getWordWidths = () => {
            // Calculate length of each word to be used to determine number of words per line
            const words = props.children.split(/\s+/);
            const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
            const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
            Object.assign(text.style, props.style);
            svg.appendChild(text);
            document.body.appendChild(svg);

            const wordsWithComputedWidth = words.map((word) => {
                text.textContent = word;
                return {word, width: text.getComputedTextLength()};
            });

            text.textContent = '\u00A0'; // Unicode space
            const spaceWidth = text.getComputedTextLength();

            document.body.removeChild(svg);

            return {wordsWithComputedWidth, spaceWidth};
        };

        const lines = getLines();
        setLines(lines);
    }, [setLines, props.width, props.children, props.style]);

    const ellipsis = (lines.length > props.max) ? '...' : '';
    const drawLines = lines.slice(0, props.max);

    const renderLines = () => {
        return drawLines.map((word, key) => {
            return <tspan key={key} x={x} y={y} dy={`${key * lineheight}em`}>
                {word}{drawLines.length - 1 === key ? ellipsis : ''}
            </tspan>;
        });
    }

    if(props.linesOnly){
        return renderLines()
    }

    return <text {...props}>
        {renderLines()}
    </text>;
};

UISvgText.defaultProps = {
    lineheight: 1
};

UISvgText.propTypes = {
    max: PropTypes.number,
    style: PropTypes.object,
    children: PropTypes.string,
    linesOnly: PropTypes.bool,
    width: PropTypes.number,
    lineheight: PropTypes.number,
    x: PropTypes.number,
    y: PropTypes.number
};

export {UISvgText};
