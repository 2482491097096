import React from "react";
import "./filter.scss";
import {UIInputSelect} from "../ui/input/select.js";
import { useStateContext } from "../../helpers/state-context";
import { useModelFeedback } from "../../helpers/model/use_model_feedback";
import {ModelOrgHierarchy} from '../../model/org-hierarchy.js';
import {ModelAuthUser} from '../../model/auth/user.js';

const FilterGroup = function (props) {
    
    const [state, dispatch] = useStateContext(['group', 'groupType', 'section']);

    // get the provider/teams hierarchy
    const select = (state.section === 'recommendations' || props.onlyTeams) ? 
            'team, team_id' : 
            'provider, provider_id, team, team_id';

    // TODO: we would like to also add order on team but this is currently not supported by the API
    const order = (state.section === 'recommendations' || props.onlyTeams) ? null : 'provider asc';

    const selectionCriteria = state.groupType === 'region'? {type: 'region'} : {select: select, order:order};

    const resultSet = useModelFeedback(ModelOrgHierarchy, selectionCriteria, (m) => ( m.getRawData() ));
    
    // get all the groups the user has access to
    const resultSetUser = useModelFeedback(ModelAuthUser, {id: 'me'});
    const userGroups = resultSetUser.feedback? []: resultSetUser.data[0].groups;

    const options = {};
    if(resultSet.feedback){
        return resultSet.feedback;
    }
    if(resultSetUser.feedback){
        return resultSetUser.feedback;
    }

    let hasSelectedGroup = false;
    if(state.groupType === 'region'){
        for(const item of resultSet.data){
            options['_'+item.id] = item.title;
            hasSelectedGroup = (state.group === item.id) ? true : hasSelectedGroup
        }
        // Add null option for all combined regions
        if(!options['_0']){
            options['_0'] = 'All regions';
            hasSelectedGroup = (state.group === 0) ? true : hasSelectedGroup
        }
        if(!hasSelectedGroup && Object.keys(options).length > 0){
            setTimeout(() => {
                dispatch({type: 'group', value: parseInt(Object.keys(options)[0].substring(1), 10)})
            }, 0);
        }
    }else{

        // because the API can only sort by one field, we need to sort on teams here
        const data = resultSet.data.slice(0).sort((a, b) => (
            a.provider + a.team < b.provider + b.team? -1: 1
        ));

        for(const item of data){
            if(userGroups.includes(item.provider_id)){
                // add an underscore to the key to make sure the order remains (object with numeric keys get
                // reordered)
                options['_'+item.provider_id] = options['_'+item.provider_id] || item.provider;
                hasSelectedGroup = state.group === item.provider_id? true: hasSelectedGroup;
            }
            if(userGroups.includes(item.team_id)){
                options['_'+item.team_id] = '-- ' + item.team;
                hasSelectedGroup = state.group === item.team_id? true: hasSelectedGroup;
            }
        };

        if(! hasSelectedGroup && Object.keys(options).length > 0){
            // the selected group is not available. This can happen when the primary group of the user is not a
            // team or a provider. In this case we select the first available item
            // setting a timeout, so that this is dispatch after the component finished rendering.
            setTimeout(() => {
                dispatch({type: 'group', value: parseInt(Object.keys(options)[0].substring(1), 10)})
            }, 0);
        }
    }

    const setGroup = (value) => {
        // remove the underscore before setting the value
        const cleanValue = value.substring(1);
        if(cleanValue !== state.group){
            dispatch({type: 'group', value: parseInt(cleanValue, 10)});
        }
        if(props.onChange){
            props.onChange(state.group);
        }
    };

    return <div className="zol-dashboard-filter-group" style={props.style}>
        <UIInputSelect 
                options={options}
                style={props.optionStyle}
                name="filter-group"
                placeholder={'Select a team'}
                onChange={(value) => { setGroup(value)} }
                value={'_' + state.group} />
    </div>;
};

FilterGroup.propTypes = { };
FilterGroup.defaultProps = {
    onlyTeams : false
};

export {FilterGroup};