import React, { useEffect, useState, useCallback } from "react";
import './audit.scss';
import styles from "../../../assets/variables.scss";
import {auditStepsConfig, formatQuestions, getFormElements} from './config.js';
import {UIText} from "../../../components/ui/text.js";
import {useForm} from "../../../helpers/form/use_form.js";
import {FormElement} from '../../../helpers/form/element.js';
import { useModelFeedback } from "../../../helpers/model/use_model_feedback.js";
import {ModelAuditAnswer} from '../../../model/audit/answer.js';
import {ModelAuditQuestionAnswer} from '../../../model/audit/question-answer.js';
import {ModelAudit} from '../../../model/audit/audit.js';
import {UIProgressSteps} from '../../../components/ui/progress-steps/progress-steps.js';
import {ReactComponent as IconArrowLeft} from "../../../assets/icons/arrow_left.svg";
import { useStateContext } from "../../../helpers/state-context";
import {AdminAuditFinished} from './finished.js';
import {scrollTo} from '../../../helpers/scroll_to.js';

const AuditSteps = function (props) {
    const [finished, setFinished]= useState(false);
    const [element, setElement] = useState(null);
    const resultSet = useModelFeedback(ModelAuditQuestionAnswer, 
            {id: props.auditId}, (m) => m.getDataByQuestionId());
    const modelData = resultSet.feedback? {}: resultSet.data;
    // When first rendering this component make sure we load the latest data, we invalidate the model
    // instance so we force loading fresh data.
    useEffect(() => {
        ModelAuditQuestionAnswer.invalidateAllInstances(ModelAuditQuestionAnswer);
    }, []);

    const resultSetAudit = useModelFeedback(ModelAudit, {id: props.auditId});

    const [, dispatch] = useStateContext(['view', 'id']);
 
    // get a new form based on the configuration given in the props.
    const Form = useForm(getFormElements(auditStepsConfig, modelData));
    const [valuesPresent, setValuesPresent] = useState(false);

    useEffect(() => {
        if(!resultSet.feedback && ! valuesPresent){
            const values = {};
            for(const row of Object.values(resultSet.data)){
                values['q' + row.question_id] = row.answer === 0? '': row.answer;
            }
            Form.setValues(values);
            setValuesPresent(true);
        }
    }, [resultSet, Form, valuesPresent]);

    const measuredRef = useCallback(node => {
        if (node !== null) {
            setElement(node);
        }
    }, []);


    if(resultSet.feedback){
        return resultSet.feedback;
    }else if(! valuesPresent){
        return null;
    }

    let currentStep = 0;
    // update the step if it is higher than what we currently have
    if(resultSetAudit.status === 'success' && resultSetAudit.data.length>0){
        currentStep = resultSetAudit.data[0].step;
    }

    // this method is called from the UIProgressSteps to check if a step is completed or not
    const validateStep = (step) => {
        if(! step){
            return false;
        }
        const subset = formatQuestions(step.content, modelData).map((item) => item.name);
        const valid = Form.validate(subset);
        if(valid){
            
            // update audit
            ModelAudit.updateItem(props.auditId, {step: Math.max(currentStep, step.id + 1)});

        }
        return valid;
    }

    const onStepChange = () => {
        // scroll to top
        if(element){
            scrollTo(element, true);
        }
    }

    const saveAnswers = (values) => {
        const currentValues = ModelAuditAnswer.getCurrentValues();
        if(currentValues === null){
            return;
        }
        const newValues = Form.getProcessedValues(values);
        const changedValues = {};
        for(const name of Object.keys(newValues)){
            const id = name.substring(1);   // name is 'q' + id
            if(newValues[name] !== undefined && newValues[name] !== null &&
                    currentValues[id] !== newValues[name]){
                // value has changed, validate without generating feedback for the user
                if(Form.validate([name], newValues, false)){
                    // we have a valid new answer, update
                    changedValues[id] = newValues[name];
                }
            }
        }
        
        ModelAuditAnswer.saveAnswers(changedValues);
    }

    // this method is called from the AdminAuditFinished when the user clicks on 'Go back'
    const closeForm = () => {
        dispatch({type: 'setValues', value: {view: '', id: ''}});
    }

    const updateValues = (values) => {
        saveAnswers(values);
    };

    // Show
    if(finished){
        return <AdminAuditFinished onClick={closeForm}/>
    }

    return <div ref={measuredRef}>
        <UIText type={'body-L-bold'} color={'mediumColor'} style={{cursor: 'pointer', marginRight: 10}}
                onClick={closeForm}>
            <IconArrowLeft style={{color: styles.darkColor, marginRight: 5}}/>Back
        </UIText>
        <UIText type={'head-1'} color={'darkColor'} style={{marginBottom: 10}}>
            {/* Note: model data is an object with question ids as keys. The ndex 1 is the first question */}
            Audit - {modelData[1].period}
        </UIText>

        <Form.Component onChange={updateValues}>
            <div className="zol-audit-panel">
                <UIProgressSteps steps={auditStepsConfig} validateStep={validateStep}
                        startIndex={currentStep? currentStep-1: 0} onFinish={()=>setFinished(true)}
                        onSelectStep={onStepChange}>
                    {(stepContent, id, title) => (
                        <div className="zol-audit-step">
                        
                            <UIText type={'head-2'} color={'darkColor'} style={{marginBottom: 10}}>
                                {title}
                            </UIText>

                            {formatQuestions(stepContent, modelData).map((question) => (
                                <FormElement key={question.name} {...question} />
                            ))}
                        </div>                       
                    )}
                </UIProgressSteps>
            </div>
        </Form.Component>

    </div>;

};

AuditSteps.defaultProps = {
    
}

AuditSteps.propTypes = {

}

export {AuditSteps}