import React from "react";
import {useStateContext} from "../../../../helpers/state-context.js";
import "../export.scss";
import styles from '../../../../index.scss';
import {ModelDashboardAnswerDistribution} from "../../../../model/dashboard/answer-distribution.js";
import {ModelAuditIndicator} from "../../../../model/audit/indicator.js";
import {useModelFeedback} from "../../../../helpers/model/use_model_feedback.js";
import {getLastThreePeriods} from "../../../../helpers/functions/periods.js";
import {ViewAdminExportManagerReportTableOne} from "./table-one.js";
import {ViewAdminExportManagerReportTableTwo} from "./table-two.js";
import {Card} from "../../../../components/card/card.js";
import {UIText} from "../../../../components/ui/text.js";
import {ReactComponent as IconArrowLeft} from "../../../../assets/icons/arrow_left.svg";
import {ModelAuthGroup} from "../../../../model/auth/group.js";
import {ReactComponent as IconDownload} from "../../../../assets/icons/download.svg";
import {UIButton} from "../../../../components/ui/button/button.js";
import {PDFExportContainer} from '../../../../helpers/pdf-export/container.js';
import {questionIds, indicatorIds, getIndicatorQuestionIds} from 
        '../../../dashboard/measures/calculate-level.js';


const ViewAdminExportManagerReportGenerated = function (props) {
    const [state, dispatch] = useStateContext(['group', 'managerReport', 'isExportingPDF']);

    const downloadPage = () => {
        dispatch({type: 'isExportingPDF', value: 'export-table'});
    };
    
    // TODO: We have a few 'open' question for table one, where this works fine. But for the indicators we
    // should not fetch the answers through the ModelDashboardAnswerDistribution, but through 
    // ModelAuditIndicatorRatio. Otherwise when the indicator numerator or denominator are changes, things
    // will fail here.
    const allQuestionIds = [5, 21, 3, ...questionIds];
    
    const resultSetIndicator = useModelFeedback(ModelAuditIndicator, {ids: indicatorIds});
    const indicatorQuestionIds = (resultSetIndicator.status==='success')? 
            getIndicatorQuestionIds(resultSetIndicator.data): [];
    
    const resultSet = useModelFeedback(ModelDashboardAnswerDistribution,
        { 
            question_ids: [...allQuestionIds, ...indicatorQuestionIds],    
            periods: getLastThreePeriods(),
            group_id: state.group
        });

    const resultSetGroup = useModelFeedback(ModelAuthGroup, {filter: 'id eq ' + state.group});

    if(resultSet.feedback || resultSetIndicator.feedback || resultSetGroup.feedback){
        return resultSet.feedback || resultSetIndicator.feedback || resultSetGroup.feedback;
    }

    // TODO: Make the order and availability of the periods match in both tables.
    return <PDFExportContainer id={'export-table'}>
        <Card cardSize={'large'} style={{height: 'auto'}}>    
            <div style={{float: 'right', display: 'inline-block',}} className={'pdf-export-hide'}>
                <UIButton className="medium" onClick={()=>downloadPage('pdf')}
                          style={{marginRight: 10}}>
                    <IconDownload style={{padding:'0 5px 0 0'}}/>
                </UIButton>
            </div> 
            <UIText type={'body-L-bold'} color={'mediumColor'} className={'pdf-export-hide'}
                    style={{cursor: 'pointer', marginRight: 10}}
                    onClick={()=>{ dispatch({type: 'managerReport', value: false})}}>
                <IconArrowLeft style={{color: styles.colorDark, marginRight: 5}}/>Back
            </UIText>    
            
            <h1>EIP Matrix Summary Report</h1>
            <h2>{resultSetGroup.data[0].title}</h2>
            <ViewAdminExportManagerReportTableOne data={resultSet.data} 
                    periods={getLastThreePeriods().reverse()}/>
            <ViewAdminExportManagerReportTableTwo answerData={resultSet.data} 
                    periods={getLastThreePeriods().reverse()}
                    indicatorData={resultSetIndicator.data} />
        </Card>
    </PDFExportContainer>
}

export {ViewAdminExportManagerReportGenerated}
