import React, { useEffect, useState } from "react";
import {PropTypes} from 'prop-types';
import './audit.scss';
import styles from "../../../assets/variables.scss";
import {auditStepsConfig, formatQuestions, getFormElements} from './config.js';
import {UIText} from "../../../components/ui/text.js";
import {useForm} from "../../../helpers/form/use_form.js";
import {FormElement} from '../../../helpers/form/element.js';
import { useModelFeedback } from "../../../helpers/model/use_model_feedback.js";
import {ModelAuditAnswer} from '../../../model/audit/answer.js';
import {ModelAuditList} from '../../../model/audit/list.js';
import {ModelAuditQuestionAnswer} from '../../../model/audit/question-answer.js';
import {ReactComponent as IconArrowLeft} from "../../../assets/icons/arrow_left.svg";
import { useStateContext } from "../../../helpers/state-context";
import { getLastPeriod } from "../../../helpers/functions/periods.js";
import {ReactComponent as IconNotify} from "../../../assets/icons/notify.svg";
import {UIButton} from '../../../components/ui/button/button.js';


const AuditReview = function (props) {   
    const [lastAuditId, setLastAuditId] = useState(0);
    const resultSet = useModelFeedback(
            ModelAuditQuestionAnswer, {id: props.auditId}, (m) => m.getDataByQuestionId());
    // Note ModelAuditQuestionAnswer sets currentValues in the ModelAuditAnswer class. We need to make sure
    // this is not done with the previous answers, that's why we set the selectionCriteria isPrevious: true
    const resultSetPrevious = useModelFeedback(
            ModelAuditQuestionAnswer, {id: lastAuditId, isPrevious: true}, (m) => m.getDataByQuestionId());
    const modelData = resultSet.feedback? {}: resultSet.data;
   
    const resultSetList = useModelFeedback(ModelAuditList);

    // get a new form based on the configuration given in the props.
    const Form = useForm(getFormElements(auditStepsConfig, modelData));
    const [valuesPresent, setValuesPresent] = useState(false);
    const [, dispatch] = useStateContext(['view', 'id']);

    // When first rendering this component make sure we load the latest data, we invalidate the model
    // instance so we force loading fresh data.
    useEffect(() => {
        ModelAuditQuestionAnswer.invalidateAllInstances(ModelAuditQuestionAnswer);
    }, []);

    useEffect(() => {
        if(! resultSet.feedback && ! valuesPresent){
            const values = {};
            for(const row of Object.values(resultSet.data)){
                values['q' + row.question_id] = row.answer === 0? '': row.answer;
            }
            Form.setValues(values);
            setValuesPresent(true);
        }
    }, [resultSet, Form, valuesPresent]);

    if(resultSet.feedback || resultSetList.feedback){
        return resultSet.feedback || resultSetList.feedback;
    }else if(! valuesPresent){
        return null;
    }
    const previousData = resultSetPrevious.status === 'success'? resultSetPrevious.data: null;

    const closeForm = () => {
        dispatch({type: 'setValues', value: {view: '', id: ''}});
    }

    const saveAnswers = (values) => {

        const currentValues = ModelAuditAnswer.getCurrentValues();
        if(currentValues === null){
            return;
        }
        const newValues = Form.getProcessedValues(values);
        const changedValues = {};
        Form.validate(null, newValues);
        for(const name of Object.keys(newValues)){
            const id = name.substring(1);   // name is 'q' + id
            if(newValues[name] !== undefined && newValues[name] !== null &&
                    currentValues[id] !== newValues[name] &&
                    ! (currentValues[id] === 0 && newValues[name] === '')){
                // value has changed, validate and show feedback to the user on error.
                if(Form.validate([name], newValues, false)){
                    // we have a valid new answer, update the value
                    changedValues[id] = newValues[name];
                }
            }
        } 
        ModelAuditAnswer.saveAnswers(changedValues);
    }
    
    const group_id = modelData.size > 0 ? '' : modelData[1].group_id;
    const period = modelData.size > 0 ? '' : modelData[1].period;
    const auditIdLastYear = resultSetList.data.find(
            (row)=> row.group.id === group_id && row.period.name === getLastPeriod(period))?.id;
    if(auditIdLastYear !== lastAuditId && auditIdLastYear !== null){
        setLastAuditId(auditIdLastYear);
    }
    
    return <div>
        <UIText type={'body-L-bold'} color={'mediumColor'} style={{cursor: 'pointer', marginRight: 10}}
                onClick={closeForm}>
            <IconArrowLeft style={{color: styles.darkColor, marginRight: 5}}/>Back
        </UIText>
        <UIText type={'head-1'} color={'darkColor'} style={{marginBottom: 10}}>
            Review audit - EIP Team
        </UIText>

        <Form.Component onChange={saveAnswers}>
            {(formInstance) => (   
                auditStepsConfig.map(step => (
                    <div className="zol-audit-review-step" key={step.id}>
                        <UIText type={'head-2'} color={'darkColor'} style={{marginBottom: 10}}>
                            {step.title}
                        </UIText>

                        {formatQuestions(step.content, modelData, previousData).map((question) => {
                            if(question.warning){
                                question.description = <div>
                                    {question.description}
                                    <div className={'zol-warning'}>
                                        <IconNotify style={{color: styles.brandRedColor}}/>
                                        <span className={'zol-tooltiptext'}>
                                            The value equals the answer of the previous audit.
                                        </span>
                                    </div>
                                </div>;
                            }
                            return <FormElement key={question.name} {...question} />;
                        })}

                    </div>
                ))
            )}
            

        </Form.Component>

        <UIButton onClick={closeForm}>Close</UIButton>

    </div>;

};

AuditReview.defaultProps = {
    
}

AuditReview.propTypes = {
    auditId: PropTypes.number.isRequired
}

export {AuditReview}