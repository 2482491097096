const config = {
    api_endpoint: window.api_endpoint || 'https://localhost:8443/apiv1/',
    application_root: '',
    pdf_endpoint: 'https://server4.zolabo.com:5000/exportPDF',
}

const roles = {
    admin: 1,
    region: 2,
    commission: 3,
    provider: 4,
    team: 5
}

// constancts should ideally be capitalized
const CONFIG = config;

export {config, roles, CONFIG}