import {config} from '../../config.js';
import {ModelXhr} from '../../helpers/model/xhr.js';

class ModelAuditIndicator extends ModelXhr {

    static modelName = "ModelAuditIndicator";

    static getSelect(input) {
        const filter = [];
        if(input.category){
            filter.push('category eq \'' + input.category + '\'');
        }
        if(input.id){
            filter.push('id eq ' + input.id);
        }
        if(input.ids && input.ids instanceof Array){
            filter.push('(id eq ' + input.ids.join(' or id eq ') + ')');
        }
        return {
            select: '',
            filter: filter.join(' and '),
            order: 'id asc'
        };
    }

    static formatData(data){
        return {
            ...data,
            threshold1: parseFloat(data.threshold1),
            threshold2: parseFloat(data.threshold2),
            threshold3: parseFloat(data.threshold3)
        };
    }

    static createItem(data, onSuccess=()=>{}){
        
        this.saveData(ModelAuditIndicator, config.api_endpoint + 'indicator', 'POST', 
            ModelAuditIndicator.formatData(data),
            (response) => {
                const indicator = response.result? (response.result[0] || null): null;
                onSuccess(indicator);
            }
        );
    }

    static updateItem(id, data, onSuccess=()=>{}){
        this.saveData(ModelAuditIndicator, 
            config.api_endpoint + 'indicator/' + id, 'PATCH', 
            ModelAuditIndicator.formatData(data),
            (response) => {
                if(response.success){
                    const indicator = response.result? (response.result[0] || null): null;
                    onSuccess(indicator);
                }else{
                    // todo: post status to global message channel
                    console.error(response.error || "Unknow error");
                }
            }
        );
    }

    static deleteItem(id, onSuccess=()=>{}){
        this.saveData(ModelAuditIndicator, config.api_endpoint + 'indicator/' + id, 'DELETE', 
            null, 
            (response) => {
                if(response.success){
                    const indicator = response.result? (response.result[0] || null): null;
                    onSuccess(indicator);
                }else{
                    // todo: post status to global message channel
                    console.error(response.error || "Unknow error");
                }
            }
        );
    }


    constructor(selectData) {
        super(selectData, {
            endpoint: config.api_endpoint + 'indicator',
            method: 'GET'
        });

        this.cacheEnabled = false;
    }

    getEndpoint(){
        return this.endpoint + (this.select.id? '/' + this.select.id: '');
    }

    getSelect(input) {
        return ModelAuditIndicator.getSelect(input);
    }


}

export {ModelAuditIndicator};