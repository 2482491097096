import React from "react";
import "../export.scss";

function findData(data, periods, questionId){
    let results = [];
    for(const period of periods){
        const value = data.find((row)=> row.id===questionId && row.period === period)?.value;
        results.push(value? value: '-');
    }
    return results;
}

const ViewAdminExportManagerReportTableOne = function (props) {
    const {data, periods} = props;

    let dataTable1 ={
        'periods': periods,
        'ARMS': findData(data, periods, 21),
        'FEP': findData(data, periods, 5),
        'budget': findData(data, periods, 3)
    };
    return <table className="zol-manager-table" style={{minWidth:'50%'}}>
        <tbody>
            <tr>
                <td style={{textAlign:'left'}}>Domain</td>
                {dataTable1.periods.map((item, idx) => (
                    <td key={idx} className={"header-row"}>{item}</td>
                ))}
            </tr>
            <tr>
                <td className={"header-column"}>At Risk Mental States (ARMS) Caseload</td>
                {dataTable1.ARMS.map((item, idx) => (
                    <td key={idx}>{item}</td>
                ))}
            </tr>
            <tr>
                <td className={"header-column"}>First Episode Psychosis (FEP) Caseload</td>
                {dataTable1.FEP.map((item, idx) => (
                    <td key={idx}>{item}</td>
                ))}
            </tr>
            <tr>
                <td className={"header-column"}>Total Caseload</td>
                {dataTable1.FEP.map((item, idx) => {
                    let value = '-'
                    if(! isNaN(item) && ! isNaN(dataTable1.ARMS[idx])){
                        value = item + dataTable1.ARMS[idx];
                    }else if(isNaN(item) || isNaN(dataTable1.ARMS[idx])){
                        value = '-';
                    }else{ //one of them is not known
                        if(isNaN(item)){
                            value = dataTable1.ARMS[idx];
                        }else{
                            value = item
                        }
                    }
                    return <td key={idx}>{value}</td>
                })}
            </tr>
            <tr>
                <td className={"header-column"}>Investment Per Patient</td>
                {dataTable1.budget.map((number, idx) => {
                    let value = '-'; 
                    if(!isNaN(number) && !isNaN(dataTable1.FEP[idx])){
                        value = '£ ' + (number / dataTable1.FEP[idx]).toFixed(2)
                    }
                    return <td key={idx}>
                        {value}
                    </td>
                })}
            </tr>
        </tbody>
    </table>
}

export {ViewAdminExportManagerReportTableOne}
