const calculateTotalValue = (data, state) => {
    // calculate initial total value based on metric
    let value = data.reduce((total, item) => total + item[state.metric], 0);

    // get value of hovered item
    if (state.activeItem) {
        const filteredItem = data.filter(item => (item.description === state.activeItem));
        value = filteredItem[0][state.metric];
    }

    return value;
};

const getPercentage = (value, displayValue) =>{
    let percentage = value / displayValue*100;
    return percentage.toFixed(2)
};

export {calculateTotalValue, getPercentage}