import {config} from '../../config.js';
import {ModelXhr} from '../../helpers/model/xhr.js';

class ModelAuthPasswordRecovery extends ModelXhr {

    static modelName = "ModelAuthPasswordRecovery";

    static getSelect(input) {
        return input;
    }

    static createItem(data, onSuccess=()=>{}, onError=()=>{}){
        
        this.saveData(ModelAuthPasswordRecovery, config.api_endpoint + 'password_recovery', 'POST', 
            data, 
            (response) => {
                if(response.success){
                    const user = response.result? (response.result[0] || null): null;
                    onSuccess(user);
                }else{
                    onError(response.error || 'Unknown error');
                }
            }
        );
    }

    static updateItem(data, onSuccess=()=>{}, onError=()=>{}){
        this.saveData(ModelAuthPasswordRecovery, config.api_endpoint + 'password_recovery', 'PATCH', 
            data, 
            (response) => {
                if(response.success){
                    const user = response.result? (response.result[0] || null): null;
                    onSuccess(user);
                }else{
                    // todo: post status to global message channel
                    const error = response.error || "Unknow error";
                    console.error(error);
                    onError(error);
                }
            }
        );
    }

    constructor(selectData) {
        super(selectData, {
            endpoint: config.api_endpoint + 'password_recovery',
            method: 'GET',
        });

        this.cacheEnabled = false;
    }

    getSelect(input) {
        return ModelAuthPasswordRecovery.getSelect(input);
    }


}

export {ModelAuthPasswordRecovery};