import React from "react";
import './dashboard.scss';
import {DashboardDefaultPage} from "./default-page.js";
import {useModelFeedback} from "../../helpers/model/use_model_feedback.js";
import { ModelAuthUser } from "../../model/auth/user.js";
import {cardsConfig} from "./cards_config.js";
import { ModelCardsCollection } from "../../model/dashboard/cards-collection";
import {ModelParagraph} from '../../model/admin/paragraph.js';
import {ModelAuditGraphs} from "../../model/audit/graphs.js";
import { useStateContext } from "../../helpers/state-context";

const ViewDashboardOverview = function (props) {
    const resultSetUser = useModelFeedback(ModelAuthUser, {id: 'me'});
    const resultSetText = useModelFeedback(ModelParagraph, {type: 'dashboard_title'});
    const resultSetCards = useModelFeedback(ModelCardsCollection, {id: 'me'});
    const resultSetGraphs = useModelFeedback(ModelAuditGraphs); 
    const [state, ] = useStateContext(['isExportingPDF']);

    if(resultSetUser.feedback){
        return resultSetUser.feedback;
    }
    if(resultSetText.feedback){
        return resultSetText.feedback;
    }
    if(resultSetCards.feedback){
        return resultSetCards.feedback;
    }
    if(resultSetGraphs.feedback){
        return resultSetGraphs.feedback;
    }
    
    const favourites = resultSetCards.favourites;
    const order = resultSetCards.order;

    const getGreeting = (isExportingPDF=false) => {
        if(isExportingPDF){
            return 'Overview';
        }
        let text = resultSetText.data[0].text;
        const currentHour = new Date().getHours();
        let partOfDay = (currentHour >= 6 && currentHour < 12)? 'morning':
                (currentHour >= 12 && currentHour < 18)? 'afternoon':
                (currentHour >= 18 && currentHour <= 23)? 'evening' : 'night';
        let user = (resultSetUser.status === 'success'? resultSetUser.data[0]?.name: null) || 'visitor';
        text = text.replace('{partOfDay}', partOfDay).replace('{name}', user);

        return text
    }

    // get the selected cards by size, we may consider moving this to the model
    const f = {small: [], medium: [], large: []};
    for(const row of resultSetGraphs.data){
        if(favourites.includes(row.id)){
            f[row.cardSize].push(row);
        }
    }
    
    return <DashboardDefaultPage title={getGreeting(state.isExportingPDF)} configurable={true} shareable={true}>
        {order.map((size) => (
            // we need this div to keep rows seperated
            <div key={size}> 
                {f[size].map((chart) => (
                    <React.Fragment key={chart.id}>
                        {cardsConfig[chart.id]? 
                            // adding the chart properties to the component
                            React.cloneElement(cardsConfig[chart.id].component, {...chart}): 
                            null
                        }
                    </React.Fragment>
                ))}
            </div>
        ))}
    </DashboardDefaultPage>

}

export {ViewDashboardOverview}