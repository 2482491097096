import Vector2 from './vector2';
import Matrix3 from './matrix3';

class Transformation{

    constructor(x=0, y=0, scale=1, rotation=0){

        this.position = new Vector2(x, y);

        this.objectRotation = new Matrix3(
            [Math.cos(rotation), -1*Math.sin(rotation), 0],
            [Math.sin(rotation), Math.cos(rotation), 0],
            [0, 0, 1]  );
        this.rotateAngle = rotation;
        this.rotation = new Matrix3();

        this.translation = new Matrix3([1,0,this.position.x], [0,1,this.position.y], [0,0,1]);
        this.scale = new Matrix3();
        this.objectScale = new Matrix3([scale, 0, 0], [0, scale, 0], [0, 0, 1]);
        this.transformation = new Matrix3();

        this.objectScaleFact = {x:scale, y:scale};
        this.scaleFact = {x:1, y:1};

        this.updateTransformationMatrix = true;

    }

    update(){
        this.updateTransformationMatrix = true;
    }

    getTransformationMatrix(baseMatrix = new Matrix3()){
        if(! this.updateTransformationMatrix){
            return this.transformation;
        }

        this.transformation = baseMatrix.copy();

        this.transformation.multiplyWith(this.scale);
        this.transformation.multiplyWith(this.rotation);    // axis rotation
        this.transformation.multiplyWith(this.translation);
        this.transformation.multiplyWith(this.objectRotation); // object rotation
        this.transformation.multiplyWith(this.objectScale);

        this.updateTransformationMatrix = false;

        return this.transformation;
    }

    getTransformationString(baseMatrix = new Matrix3()){
        const transform = this.getTransformationMatrix(baseMatrix);
        const m = transform.getMatrix();
        const data = m[0][0] + ',' + m[1][0] + ',' + m[0][1] + ',' + m[1][1] + ','
            + m[0][2] + ',' + m[1][2];
        return 'matrix(' + data + ')';
    }

    setPosition(x, y, addToExisting=false){
        // todo we do not always have to clear, sometimes the object is moved again
        // while it hasn't been rerendered yet
        if(addToExisting){
            this.position.x += x;
            this.position.y += y;
        }else{
            this.position.x = x;
            this.position.y = y;
        }
        this.translation.set(0, 2, this.position.x);
        this.translation.set(1, 2, this.position.y);
        this.update();
    }

    setScale(scale, addToExisting=false){
        if(! addToExisting){
            if(this.scaleFact === scale){
                return;
            }
            this.scale = new Matrix3();
        }
        if(! (scale instanceof Object)){
            scale = {x: scale, y: scale};
        }
        var s = new Matrix3([scale.x, 0, 0], [0, scale.y, 0], [0, 0, 1]);
        this.scale.multiplyWith(s);
        this.scaleFact = scale;
        this.update();
    }

    setObjectScale(scale, addToExisting=false){
        if(! addToExisting){
            if(this.objectScaleFact === scale){
                return;
            }
            this.objectScale = new Matrix3();
        }
        if(! (scale instanceof Object)){
            scale = {x: scale, y: scale};
        }
        var s = new Matrix3([scale.x, 0, 0], 	[0, scale.y, 0], [0, 0, 1]);
        this.objectScale.multiplyWith(s);
        this.objectScaleFact = scale;

        this.update();
    }

    setRotate(angle, addToExisting=false){
        if(! addToExisting){
            if(this.rotateAngle === angle) return;
            this.objectRotation = new Matrix3();
        }
        var s = new Matrix3(
            [Math.cos(angle), -1*Math.sin(angle), 0],
            [Math.sin(angle), Math.cos(angle), 0],
            [0, 0, 1]  );
        this.objectRotation.multiplyWith(s);
        this.rotateAngle = angle;

        this.update();
    }

    rotateAxis(angle){

        this.rotation = new Matrix3(
            [Math.cos(angle), -1*Math.sin(angle), 0],
            [Math.sin(angle), Math.cos(angle), 0],
            [0, 0, 1]  );

        this.update();

    }

    remove(){
        delete this.position;

        delete this.objectRotation;
        delete this.rotateAngle;
        delete this.rotation;

        delete this.translation;
        delete this.scale;
        delete this.objectScale;
        delete this.transformation;

        delete this.objectScaleFact;
        delete this.scaleFact;

        delete this.updateTransformationMatrix;
    }

}

export default Transformation;
