import React, {useState, useEffect} from 'react';
import { useModelFeedback } from '../../../../helpers/model/use_model_feedback.js';
import {ViewSettingsPageHeader} from "./header.js";
import {useForm} from "../../../../helpers/form/use_form.js";
import {UIInputTextarea} from '../../../../components/ui/input/textarea.js';
import {UIInput} from '../../../../components/ui/input/input.js';
import { FormElement } from '../../../../helpers/form/element.js';
import {UIButton} from "../../../../components/ui/button/button.js";
import {UITable} from "../../../../components/ui/table/table.js";
import {ModelAuditQuestion} from '../../../../model/audit/question.js';
import {ReactComponent as IconEdit} from "../../../../assets/icons/edit.svg";

const formConfig = [
    {
        title: 'Question number',
        name: 'number',
        element: UIInput,
        required: true,
        type: 'wide'
    },
    {
        title: 'Title',
        name: 'title',
        element: UIInput,
        required: true,
        type: 'wide'
    },
    {
        title: 'Description',
        name: 'description',
        element: UIInputTextarea,
        type: 'wide'
    }
];

const tableConfig = [
    {
        id: 'number',
        title: 'Number',
        value: (row) => ( row.number ),
        width: 1
    },
    {
        id: 'title',
        title: 'Title',
        value: (row) => ( row.title ),
        width: 4,
    },
    {
        id: 'edit',
        width: 1,
        value: (row) => ( 'edit' ),
        renderCell: (value) => (
            <IconEdit style={{width: 25, height: 25, cssFloat: 'right'}}/>
        )
    }
];

const ViewSettingsPageQuestions = function (props) {
    
    const resultSet = useModelFeedback(ModelAuditQuestion);
    const [questionId, setQuestionId] = useState(null);
    const [valuesPresent, setValuesPresent] = useState(false);
    const Form = useForm(formConfig);

    // this sets the Form values based on what's in the model
    useEffect(() => {
        if(! resultSet.feedback && questionId !== null && ! valuesPresent){
            let values = {};
            for(const row of resultSet.data){
                if(row.id === questionId){
                    for(const field of formConfig){
                        values[field.name] = row[field.name];
                    }
                }
            }
            Form.setValues(values);
            setValuesPresent(true);
        }
        if(valuesPresent && questionId === null){
            setValuesPresent(false);
        }
    }, [resultSet, Form, valuesPresent, questionId]);


    if(resultSet.feedback){
        return resultSet.feedback;
    }

    let content = null;
    if(questionId === null){
        // show the list

        // order questions based on the number
        const order = ['Q', 'N', 'O', 'W'];
        const data = resultSet.data.slice().sort((a, b) => {
            const rank = order.indexOf(a.number.substring(0, 1)) - order.indexOf(b.number.substring(0, 1));
            if(rank === 0){
                return a.number.substring(1) < b.number.substring(1)? -1: 1;
            }
            return rank;
        });
        
    
        content = <UITable columns={tableConfig} data={data} 
                onRowClick={(row) => { setQuestionId(row.id) }} />;

    }else{
        // show question details

        const submitForm = () => {
            if(Form.validate()){
                const onSuccess = (token) => {
                    setQuestionId(null);
                }   
                const onFailure = (error) => {
                    // misusing the description error placeholder here
                    Form.setError('description', error);
                }
                ModelAuditQuestion.updateItem(questionId, Form.getValues(), onSuccess, onFailure);
            }
        }
        
        content = <div style={{backgroundColor: 'white', padding: '40px'}}>
            
            <Form.Component>

                {formConfig.map(element => (
                    <FormElement  key={element.name} {...element} />
                ))}

                <UIButton className="primary" style={{marginRight: '20px'}} onClick={submitForm}>
                    Save
                </UIButton>

                <UIButton className="off-white" style={{}} 
                        onClick={() => { setQuestionId(null) }}>
                    Cancel
                </UIButton>

            </Form.Component>

        </div>;
    }

    
    return <>
        <ViewSettingsPageHeader {...props}/>
        <div style={{margin: "20px 0"}}>

        	{content}

        </div>
    </>
}

export {ViewSettingsPageQuestions}