import React from "react";
import {useStateContext} from "../../../helpers/state-context.js";
import {ChartDonut} from "../../../components/chart/donut/donut.js";
import {CardHeader} from "../../../components/card/header.js";
import {Card} from "../../../components/card/card.js";
import {useModel} from "../../../helpers/model/use_model.js";
import {ModelDashboardAnswerDistribution} from "../../../model/dashboard/answer-distribution.js";

const DashboardCardBreakdownByAge = function (props) {
    const {question_ids, title, id} = props;
    let donutData = [];
    const [state] = useStateContext(['period', 'group']);
    const resultSet = useModel(ModelDashboardAnswerDistribution, {
            question_ids: question_ids, 
            period: state.period,
            group_id : state.group});
    
    if(resultSet.status === 'success'){
        donutData = resultSet.data.filter((row) => (
            {
                id: row.question_id,
                description: row.description,
                value: row.value
            } 
        ));
    }
    donutData.sort((a,b)=> a.description > b.description?1:-1);

    return <Card id={id} cardSize={props.cardSize}>
        <CardHeader title={title} id={id}/>
        <ChartDonut metrics = {['value']}
                metricTitles = {{value: 'Age'}}
                data={donutData}/>
        </Card>
};



export {DashboardCardBreakdownByAge}

