import {config} from '../config.js';
import {ModelXhr} from '../helpers/model/xhr.js';

class ModelEmail extends ModelXhr {

    static modelName = "ModelEmail";

    static getSelect(input) {
        return input;
    }

    static createItem(data, onSuccess=()=>{}, onError=()=>{}){
        
        this.saveData([ModelEmail], config.api_endpoint + 'email', 'POST', 
            data, 
            (response) => {
                if(response.success){
                    onSuccess(response);
                }else{
                    onError(response.error || 'Unknown error');
                }
            }
        );
    }

    constructor(selectData) {
        super(selectData, {
            endpoint: config.api_endpoint + 'email',
            method: 'GET',
        });

        this.cacheEnabled = false;
    }


    getSelect(input) {
        return ModelEmail.getSelect(input);
    }


}

export {ModelEmail};