import React, {useState} from "react";
import {Card} from "../../../components/card/card.js";
import {CardHeader} from "../../../components/card/header.js";
import {ChartDonut} from "../../../components/chart/donut/donut.js";
import {useModel} from "../../../helpers/model/use_model.js";
import {ModelDashboardAnswerDistribution} from "../../../model/dashboard/answer-distribution.js";
import {useStateContext} from "../../../helpers/state-context.js";
import { ModelDashboardExpectedCaseload} from "../../../model/dashboard/expected-caseload.js";
import '../../../components/ui/select.scss';
import {UIInputSelect} from "../../../components/ui/input/select.js";
import {getFullYear, getLastYear, getInThreeYears, currentYear} from "../../../helpers/functions/periods.js";

function getOptions(selectedPeriod){
    return [
        {id: 1, label: [selectedPeriod], title: 'Selected quarter'},
        {id: 2, label: getFullYear(), title: 'Current year: '+currentYear},
        {id: 3, label: getLastYear(), title: 'Past year: '+ (currentYear-1)},
        {id: 4, label: getInThreeYears(), title:'In 3 years'}
    ]
}

const DashboardCardFep = function (props) {
    const [period, setPeriod] = useState('Selected quarter');
    const [state] = useStateContext(['period', 'group']);
    const periodOptions = getOptions(state.period);
    let donutData = [];
 
    const resultSetCaseload = useModel(ModelDashboardAnswerDistribution, 
            {question_id: props.question_id, 
                periods:  periodOptions.find((p)=>p.title === period)?.label , 
                group_id : state.group});
    const resultSetExpected = useModel(ModelDashboardExpectedCaseload, {group_id : state.group});

    if(resultSetCaseload.status === 'success' && resultSetCaseload.data.length && 
            resultSetExpected.status ==='success' && resultSetExpected.data.length){
        const caseloadValue = resultSetExpected.data[0].value - resultSetCaseload.data[0].value
            donutData.push({
                id: resultSetCaseload.data[0].id,
                description: 'FEP Caseload accessing EIP Team',
                value: resultSetCaseload.data[0].value
            },{
                id: 5.5,
                description: 'Proportion of People with FEP Not Accessing EIP',
                value: (caseloadValue < 0) ?
                        0 :
                        caseloadValue
            })
    }

    const getPeriodSelect = () => {
        const options = periodOptions.map((o)=>o.title);
        return <div>
            <UIInputSelect
                options={options}
                name={'period-select'}
                placeholder={props.placeholder}
                onChange={(value)=>{setPeriod(value)}}
                value={period} />
        </div>
    };

    return <Card id={props.id} cardSize={props.cardSize}>
        <CardHeader title={props.title} rightColumn={getPeriodSelect()}/>
        <ChartDonut metrics = {['value']}
                    metricTitles = {{value: ' '}}
                    data={donutData}
                    valueInDonut={donutData.find((row)=> row.id = 5)?.value}
                    showWholeLegend={true}/>
    </Card>
};

DashboardCardFep.defaultProps = {
    title : 'FEP Caseload',
    question_id: 5,
    placeholder: 'Selected quarter',
    onSelect: ()=>{}
};

export {DashboardCardFep}

