import React from "react";
import PropTypes from 'prop-types';
import {useSize} from "../../../helpers/use_size.js";
import {scaleBand} from "d3-scale";
import ChartVerticalBar from "../../../components/chart/vertical-bar/vertical-bar.js";
import "./vertical-bar.scss";

const VerticalBarIcon = function (props) {
    const [size, measuredRef] = useSize((w, h) => ({width: w, height: h}), {width: 1, height: 1});
    
    const x = scaleBand()
            .rangeRound([0, size.width-50])
            .paddingInner(0.2)
            .domain(props.data.map(item => item[props.axis.x]));
    
    const width = Math.min(x.bandwidth(), 20);
    const offsetLeft =  (x.bandwidth() / 2 - width/2);
    return <div style={{height: '100%'}}  ref={measuredRef}>
        <div style={{height: 'calc(100% - 20px)'}}>
            <ChartVerticalBar {...props} 
                    formatAxis={{
                        ...props.formatAxis,
                        x: () => '',
                    }}
                    data={props.data.map(({icon, ...item }) => item)}/>
        </div>
        <div className={"zol-chart-vertical-bar-axis-label"}>
            {props.data.map((item, key)=>{
                const iconStyle={
                    left: x(item[props.axis.x]) + offsetLeft -7.5 + 'px'
                }
                return <span className={"icon"} key={key} style={iconStyle}>
                    {item.icon}
                    <div>
                        <h1 className={'header'}>{item.header}</h1>
                        <p className={'text'}>{item.tooltip}</p>
                    </div>
                </span>
            })}
        </div>
    </div>
};

VerticalBarIcon.defaultProps = {};

VerticalBarIcon.propTypes = {
    lines: PropTypes.arrayOf(PropTypes.object),
    color: PropTypes.string,
    width: PropTypes.number,
    scale: PropTypes.object
};

export {VerticalBarIcon}