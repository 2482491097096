import './users.scss'
import React, {useState} from "react";
import {UserList} from "./list.js";
import {UIButton} from "../../../components/ui/button/button.js";
import {ReactComponent as PlusSvg} from "../../../assets/icons/plus.svg";
import styles from '../../../assets/variables.scss'
import {UISwitch} from "../../../components/ui/switch.js";
import { useModelFeedback } from "../../../helpers/model/use_model_feedback.js";
import {ModelAuthUserList} from "../../../model/auth/user-list.js";
import { useStateContext } from "../../../helpers/state-context";

const ViewAdminUsers = function (props) {
    const [switchValue, setSwitchValue] = useState('all-users')
    const [, dispatch] = useStateContext(['view', 'id']);
    const resultSet = useModelFeedback(ModelAuthUserList, {status: 'PENDING'});
    if(resultSet.feedback){
        return resultSet.feedback;
    }

    const editItem = (userId) => {
        if(! userId){
            dispatch({type: 'setValues', value: {view: 'new', id: null}});    
        }else{
            dispatch({type: 'setValues', value: {view: 'edit', id: userId}});
        }
    };

    return <div className={''}>
        <div style={{width: '100%', display: 'inline-block'}}>
            <UISwitch value={switchValue}
                    data={[
                        {
                            id: 'all-users',
                            label: 'All users'
                        },
                        {
                            id: 'new-approvals',
                            label: 'New approvals',
                            notification: resultSet.data.length? <span className="dot"></span> :''
                        }
                    ]}
                    onChange={setSwitchValue}/>

            {(switchValue === 'all-users') ?
                <div>
                    <UIButton onClick={() => {editItem() }}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '15px 15px',
                            backgroundColor: '#e2e7fc',
                            color: styles.primaryColor,
                            float: 'right'
                        }}>
                        <PlusSvg style={{marginRight: 5}}/>
                        New user
                    </UIButton>
                </div>
                :
                null
            }
        </div>

        <UserList pendingUsers={switchValue === 'new-approvals'} onClick={editItem}/>

    </div>
}

export {ViewAdminUsers}