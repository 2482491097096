import React from "react";
import {PropTypes} from "prop-types";
import './card.scss';
import {ReactComponent as CheckSvg} from "../../../assets/icons/check.svg";
import {ModelCardsCollection} from "../../../model/dashboard/cards-collection.js";
import {useModelFeedback} from "../../../helpers/model/use_model_feedback.js";
import styles from "../../../assets/variables.scss";
import {ModelAuditGraphs} from "../../../model/audit/graphs.js";
import {cardsConfig} from "../cards_config.js";

const titles = {
    small: 'Small card',
    medium: 'Medium card',
    large: 'Large card'
};


const ConfigureSelectCards = function (props) {
    const resultSet = useModelFeedback(ModelCardsCollection, {id: 'me'});
    const resultSetGraphs = useModelFeedback(ModelAuditGraphs); 
    if(resultSet.feedback){
        return resultSet.feedback
    }
    if(resultSetGraphs.feedback){
        return resultSetGraphs.feedback
    }
    const graphs = resultSetGraphs.data.filter((row)=> row.section !== 'recommendations');
    const favourites = resultSet.favourites;
    const order = resultSet.order;
    let count = 0;
    
    for(const row of graphs){
        if(row.cardSize === props.type && favourites.includes(row.id)){ 
            count+=1;
        }
    };

    function checkCondition(cardId){
        //todo: review this, doesn't seem to be correct
        const chart = graphs.find((chart)=>chart.id === cardId);
        if(chart.cardSize === props.type){
            if(count < props.amount){
                resultSet.addCard(cardId);
            }else if(chart.cardSize === 'large'){
                let currentFavourite = favourites.map((id)=>{
                    return cardsConfig[id] && cardsConfig[id].cardSize === 'large'? cardsConfig[id]: null;
                });
                favourites.splice(favourites.indexOf(currentFavourite.id),1, cardId);
                favourites.sort(function(a,b){
                    return a-b;
                });
                resultSet.updateSet(favourites)
                
            }else{
                window.alert("Not enough room, first remove a card of size " + chart.cardSize);
            }
        }       
    }

    function changeOrder(direction){
        const currentIndex = order.indexOf(props.type);
        let newIndex = currentIndex;
        if(direction === "up" && currentIndex !== 0){
            newIndex = currentIndex - 1;
            order.splice(newIndex, 0, order.splice(currentIndex, 1)[0]);
            resultSet.updateOrder(order);
        }else if(direction === "down" && currentIndex !== (order.length-1)){
            newIndex = currentIndex + 1;
            order.splice(newIndex, 0, order.splice(currentIndex, 1)[0]);
            resultSet.updateOrder(order);
        }
    }
    
    return <div className="zol-dashboard-config-cards">
        <div className="zol-title">
            <h2>{titles[props.type]} ({count}/{props.amount})</h2>
            <span className="zol-title-line"></span>
            <div className="zol-title-order">
                <span className="zol-title-order-up"
                    onClick={()=> changeOrder("up")}></span>
                <span className="zol-title-order-down"
                    onClick={()=> changeOrder("down")}></span>
            </div>
        </div>
        {graphs.map((item) => {
            // todo: when no more items can be added we would like to remove the pointer cursor on inactive
            // cards. That indicated the user that they are disabled. On click we can keep the alert.
            const isActive = favourites.includes(item.id);
            return (item.cardSize === props.type)?
                <div key={item.id} 
                        className={'zol-dashboard-config-card zol-dashboard-config-card-' + props.type}
                        style={{backgroundColor: isActive? styles.whiteColor: '#d5d7db'}}
                        onClick={() => { 
                            if(isActive){
                                resultSet.removeCard(item.id);
                            }else{
                                checkCondition(item.id);
                            }
                        }}>
                    {isActive?
                        <span className="zol-dashboard-config-card-toggle-active">
                            <CheckSvg />
                        </span>:
                        <span className="zol-dashboard-config-card-toggle-inactive"></span>
                    }
                    <h3>{item.title}</h3>
                    <p>{item.description}</p>
                </div>:
                null;    
        })}
    </div>
}

ConfigureSelectCards.propTypes = {
    type: PropTypes.oneOf(['small', 'medium', 'large']).isRequired,
    amount: PropTypes.number.isRequired
}

export {ConfigureSelectCards};