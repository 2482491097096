import React from 'react';
import {UIText} from "../../../../components/ui/text.js";
import {ReactComponent as ArrowLeftSvg} from "../../../../assets/icons/arrow_left.svg";
import styles from "../../../../assets/variables.scss";

const ViewSettingsPageHeader = function (props) {
    return <div>
        <div style={{display: 'flex', alignItems: 'center'}}>
            <UIText type={'body-L-bold'} color={'mediumColor'} style={{cursor: 'pointer', marginRight: 10}}
                    onClick={()=>props.setActiveSetting('')}>
                <ArrowLeftSvg style={{color: styles.darkColor, marginRight: 5}}/>Back
            </UIText>
            <UIText type={'head-2'} color={'darkColor'}>{props.selectedSetting.title}</UIText>
        </div>
        <div></div>
    </div>
}

export {ViewSettingsPageHeader}