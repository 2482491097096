import './vertical-bar.scss';
import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import Tooltip from '../../../helpers/elements/tooltip.js';
import {useSize} from "../../../helpers/use_size.js";
import {scaleBand, scaleLinear} from "d3-scale";
import {getElementPagePosition} from "../../../helpers/functions/get_element_page_position.js";
import {getColorPalette} from "../../../helpers/color.js";
import {VerticalBarAxis} from "./axis.js";
import {VerticalBarStacked} from "./stacked.js";
import {VerticalBarGrouped} from "./grouped.js";
import {VerticalBarLines} from "./lines.js";

function calculateRoundedPath(x, y, width, height, roundedTop, roundedBottom, isSingle ) {
    // Returns path for a rectangle with rounded top/bottom corners.
    if(isNaN(width) || isNaN(height) || isNaN(x) || isNaN(y)){
        return ''
    }

    let radius = (height < 20 && isSingle) ? width/6 : width/2;
    let result;
    // Start top left
    result = "M" + (x + radius) + "," + y;
    // Top line
    result += "h" + (width - 2*radius);
    // Top right radius
    if (roundedTop) {
        result += "a" + radius + "," + radius + " 0 0 1 " + radius + "," + radius;
    }else {
        result += "h" + radius; result += "v" + radius;
    }
    // Right line
    result += "v" + (height - 2*radius);
    // Bottom right radius
    if (roundedBottom) {
        result += "a" + radius + "," + radius + " 0 0 1 " + -radius + "," + radius;
    }else {
        result += "v" + radius; result += "h" + -radius;
    }
    // Bottom line
    result += "h" + (2*radius - width);
    // Bottom left radius
    if (roundedBottom) {
        result += "a" + radius + "," + radius + " 0 0 1 " + -radius + "," + -radius;
    }else {
        result += "h" + -radius; result += "v" + -radius;
    }
    // Left line
    result += "v" + (2*radius - height);
    // Top left radius
    if (roundedTop) {
        result += "a" + radius + "," + radius + " 0 0 1 " + radius + "," + -radius;
    }else {
        result += "v" + -radius; result += "h" + radius;
    }
    // End
    result += "z";
    return result;
}


const getMinAndMax = (array, group, extra) => {
    let max = null;
    for (const groups of array) {
        let groupValue = null;
        group.map(groupKey => groupValue += parseInt(groups[groupKey]))

        if (groupValue) {
            max = (groupValue > max || !max) ? groupValue : max
        }
    }

    return [0, max + extra];
};

const onMouseHover = (event, item, tooltipHeader, setHover, showAxisKey) => {
    if (item === null) {
        Tooltip.getInstance().setState({});
        setHover('');
        return;
    }
    if (!event.currentTarget) {
        return;
    }
    setHover(item.hover);

    const node = (item.topElement) ? document.querySelector(item.topElement) : event.currentTarget;
    if (node) {
        const pos = getElementPagePosition(node);
        const width = node.getBoundingClientRect().width; // get the bounding rectangle
        let text = '';
        if(item.data.tooltip){
            text = item.data.tooltip;
        }else{
            for (const axisKey of Object.keys(item.data)) {
                if(showAxisKey){
                    text +=`${axisKey}: `;
                }
                text += `${item.data[axisKey]} \n`;
            }
        }
        const header =  tooltipHeader? item.data.header : false;
        const top = (pos.top<0)? 10 : pos.top; //to make sure the tooltip stays on the screen
        Tooltip.getInstance().setState({
            position: [pos.left + 0.5 * width, top],
            header: header,
            text: text,
            anchor: 'bottom'
        });
    }
};

const calculateAxis = (data, axis, size, domain) => {
    const x = scaleBand()
        .rangeRound([0, size.width-50])
        .paddingInner(0.2)
        .domain(data.map(item => item[axis.x]));
    const x1 = scaleBand()
        .domain(axis.y)
        .rangeRound([0, x.bandwidth()])
        .padding(0.2);
    const y = scaleLinear()
        .rangeRound([size.height, 50])
        .domain(domain || getMinAndMax(data, axis.y, 25));
    return {x, y, x1}
};

function ChartVerticalBar(props) {
    const {data, axis, axisTitles, tooltipHeader, type, showAxisKey, hideAxisX, hideAxisY, color, palette, domain, lines, hideAxis} = props;
    const [hover, setHover] = useState('');
    const [size, measuredRef] = useSize((w, h) => ({width: w, height: h}), {width: 1, height: 1});
    let width = size.width;

    let height = size.height + 25;
    const sectionPalette = palette ? palette : getColorPalette(axis.y.length); //previously: getSectionPalette(color, axis.y.length)
  
    //initial calculation of axis
    const [axisState, setAxisState] = useState(calculateAxis(data, axis, {width, height}, domain));

    useEffect(() => {
        // recalculate axis when size changes
        setAxisState(calculateAxis(data, axis, {width, height}, domain))
    }, [height, width, data, axis, domain, setAxisState]);
    
    const id = `vertical-chart-${Math.round(999999 * Math.random())}`;
    const barsProps = {
        onMouseHover: (e, data) => onMouseHover(e, data, tooltipHeader, setHover, showAxisKey,),
        axis: axis,
        axisTitles: axisTitles,
        axisState: axisState,
        calculateRoundedPath: calculateRoundedPath,
        data: data,
        palette: sectionPalette,
        hover: hover
    };

    return <div id={id} ref={measuredRef} style={{ display: 'inline-block', width: '100%', height: '100%'}}>
        <svg className={'zol-chart-vertical-bar'} width={width + 'px'}
                 height={height + 'px'}
                 viewBox={'0 0 ' + width + ' ' + (height)}>
            <g transform={`translate(55, -40)`}>
                {hideAxis ?
                    null :
                    <VerticalBarAxis axisState={axisState}
                            axis={axis}
                            formatAxis={props.formatAxis}
                            data={data}
                            id={id}
                            height={height}
                            width={width}
                            hideAxisX={hideAxisX}
                            hideAxisY={hideAxisY}/>
                }
                {(type === 'stacked') ?
                        <VerticalBarStacked id={id} {...barsProps} lines={lines}/> :
                        <VerticalBarGrouped id={id} {...barsProps} lines={lines} shortTooltip={props.shortTooltip}/>
                }
                {(lines && lines.length > 0) ?
                    <VerticalBarLines lines={lines}
                                      color={color}
                                      scale={axisState}
                                      width={width}/>:
                    ''
                }
                
            </g>
        </svg>
    </div>;
}

ChartVerticalBar.defaultProps = {
    type: 'grouped',
    domain: null,
    axis: {
        x: 'year',
        y: ['male', 'female']
    },
    color: "hsl(188, 74%, 45%)",
    // lines: [
    //     {value: '37', color: 'purple'},
    //     {value: '23', color: 'orange'},
    //     {value: '51', color: 'red'},
    // ],
    showAxisKey: false,
    tooltipHeader: false,
    shortTooltip: false, //short term solution to tooltip problem, todo: create tooltip on card component
    data: [
        {year: '2015', male: "100", female: "10"},
        {year: '2016', male: "50", female: "25"},
        {year: '2017', male: "75", female: "10"},
        {year: '2018', male: "40", female: "25"},
        {year: '2019', male: "80", female: "10"},
        {year: '2020', male: "10", female: "15"},
        {year: '2021', male: "75", female: "10"},
        {year: '2022', male: "40", female: "25"},
        {year: '2023', male: "80", female: "10"},
        {year: '2024', male: "10", female: "15"}
    ]
};


ChartVerticalBar.propTypes = {
    lines: PropTypes.arrayOf(PropTypes.object),
    domain: PropTypes.arrayOf(PropTypes.number),
    type: PropTypes.oneOf(['stacked', 'grouped', 'one group']),
    hideAxisX: PropTypes.bool,
    hideAxisY: PropTypes.bool,
    hideAxis: PropTypes.bool,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    axis: PropTypes.shape({
        x: PropTypes.string,
        y: PropTypes.arrayOf(PropTypes.string)
    }),
    palette: PropTypes.array,
    color: PropTypes.string,
}

export default ChartVerticalBar;
