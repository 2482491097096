import React from "react";
import {Card} from "../../../components/card/card.js";
import {CardHeader} from "../../../components/card/header.js";
import {NICEIcons} from "../../../helpers/elements/nice-icons.js";
import {useStateContext} from "../../../helpers/state-context";

function getIcon(item){
    let title = item.toLowerCase();
    for(const row of NICEIcons){
        if(title.includes(row.label.toLowerCase())){
            return row.icon;
        }
    }
}

const DashboardCardNiceInterventionsList = function (props) {
    const [state] = useStateContext(['renderSize']);
    const isMobile = (state.renderSize === 'xs');
    const {data, title} = props;
    const cardProps = {};
    if(isMobile){
        cardProps.style = {height: '100%'}
    }
    return <Card id={props.id} cardSize={'medium'} {...cardProps}>
        <CardHeader title={title} style={{height: 35}}/>
        <div style={{display: 'flex', flexWrap: 'wrap', height: 'calc(100% - 35px)'}}>
            {data.map((item, key) =>{
                return <p key={key}
                        style={{
                            flex: (isMobile) ? '0 0 100%' : '0 0 50%',
                            marginTop: '0px',
                            marginBottom: '0px',
                            display: "flex",
                            alignItems: 'center'
                        }}>
                    {getIcon(item.title)}

                    {item.title}</p>}
            )}
        </div>
    </Card>
};

DashboardCardNiceInterventionsList.defaultProps = {
    id : 'intervention_list',
    data: [
        {title: "Smoking Cessation"},
        {title: "Cognitive Behavioral Therapy for Psychosis"},
        {title: "Physical Health Assessment"},
        {title: "Family Interventions"},
        {title: "Carer Focused Education & Support"},
        {title: "Individual Placement Support"},
        {title: "Clozapine"},
    ]
};

export {DashboardCardNiceInterventionsList}

