
const d = new Date();
const currentQuarter = Math.floor(d.getMonth()/3)+1;
const currentYear = d.getFullYear();

const getCurrentPeriod = () => {
    return currentYear +"Q"+ currentQuarter;
}

const getYear = (quarter) => {
    return parseInt(quarter.substring(0,4));
}

const getLastPeriod = (selectedPeriod) =>{
    let quarter = selectedPeriod?selectedPeriod.charAt(selectedPeriod.length-1):currentQuarter;
    let year = selectedPeriod? selectedPeriod.substring(0, selectedPeriod.length-2) : d.getFullYear();
    if(parseInt(quarter) !== 1){
        return year + "Q"+ (quarter-1);
    }else{
        return parseInt(year)-1 + "Q"+ 4;
    }  
}

const getLastThreePeriods = () =>{
    return [getLastPeriod(getLastPeriod()), getLastPeriod(), getCurrentPeriod()];
}

const getHalfYear = () => {
    return [getLastPeriod(), getCurrentPeriod()];
}

const getFullYear = () => {
    let periods = []
    for(let i =1; i<=currentQuarter; i++){
        periods.push(currentYear+"Q"+i);
    }
    return periods;
};

const getYearPeriods = (year) => {
    const selectedYear = year? year : currentYear;
    let periods = [];
    for(let i =1; i<=4; i++){
        periods.push(selectedYear+"Q"+i);
    }
    return periods;
}

const getLastYear = (props) => {
    const year = props?props:currentYear;
    let lastYear = [];
    for(let i =1; i<=4; i++){
        lastYear.push(year-1+"Q"+i);
    }
    return lastYear;
};

// JV: We have inconsitent names getTwoYears and getInOneYear/getInThreeYears.
// TODO: can we make a single function: getLastYearPeriods(numberOfYears)
const getTwoYears = () => {
    let periods = getFullYear().concat(getLastYear());
    const length = periods.length;
    for(let i =1; i<=8; i++){
        if(i > periods.length){
            const quarter = 5 - i + length;
            periods.push(currentYear-2+"Q"+quarter)
        }
    }
    return periods;
}

const getInOneYear = () => {
    let periods = getFullYear();
    const periodLength = periods.length;
    for(let i =1; i<=4; i++){
        if(i > periods.length){
            const quarter = 5 - i + periodLength;
            periods.push(currentYear-1+"Q"+quarter)
        }
    }
    return periods;
}

const getInThreeYears = () => {
    let periods = getFullYear().concat(getLastYear()).concat(getLastYear(currentYear-1));
    const length = periods.length;
    for(let i =1; i<=12; i++){
        if(i > periods.length){
            const quarter = 5 - i + length;
            periods.push(currentYear-3+"Q"+quarter)
        }
    }
    return periods;
}

export {currentQuarter, currentYear, getYear, getCurrentPeriod, getLastPeriod, getHalfYear, getFullYear, getLastThreePeriods, getLastYear, getInOneYear, getTwoYears, getInThreeYears, getYearPeriods}

