import React from 'react';
import {currentQuarter, currentYear} from "../../helpers/functions/periods.js";

const d = new Date();

const periods = [
    {begin: '01-01', end: '03-31'},
    {begin: '04-01', end: '06-30'},
    {begin: '07-01', end: '09-30'},
    {begin: '10-01', end: '12-31'}
];

const startCurrentPeriod = () => {
    const quarter = periods[currentQuarter-1];
    return currentYear + '-' + quarter.begin;
}

const endCurrentPeriod = () => {
    const quarter = periods[currentQuarter-1];
    return currentYear + '-' + quarter.end;
}

const percentage = () => {
    const endDate = new Date(endCurrentPeriod());
    const startDate =  new Date(startCurrentPeriod());

    const days_difference = Math.ceil((endDate.getTime() - startDate.getTime())/(1000*3600*24));
    const days_passed = Math.ceil((d.getTime() - startDate.getTime())/(1000*3600*24));
    return Math.round(days_passed / days_difference * 100);
}

const periodStyle={
    display: 'inline-block',
    fontWeight: 'bold'
}

const dateStyle={
    display: 'inline-block',
    fill: 'rgb(129, 135, 147)',
    float: 'right'
} //borderRadius

const styleTimelineBg = {
    backgroundColor:"#e2e7fc", 
    width: '100%', 
    height: '10px', 
    marginTop: '10px', 
    borderRadius: '5px'
};

const styleTimelineInner = {
    backgroundColor: "#2E5BFF", 
    width: percentage() + '%', 
    height: '10px', 
    borderRadius: '5px'
};

const ChartTimeline = function (props) {    
    return <div style={{margin: '20px 0px'}}>
        <div>
            <span style={periodStyle}>Start date</span>
            <span style={dateStyle}>{startCurrentPeriod()}</span>
        </div>
        <div>
            <span style={periodStyle}>End date</span>
            <span style={dateStyle}>{endCurrentPeriod()}</span>
        </div>

        <div style={styleTimelineBg}> 
            <div style={styleTimelineInner}></div>
        </div>
    </div>;
}


export {ChartTimeline};
