import React from 'react';
import ReactDOM from 'react-dom';

import {inheritedStyle} from './styles.js';
import {extend} from '../functions/extend.js';
import RequestAnimationFrame from './request_animation_frame.js';

const globalStyle = extend({
    width: '100%'
}, inheritedStyle);


class Tooltip {

    static instance = null;

    static getInstance() {
        if (!Tooltip.instance) {
            const element = document.body;
            Tooltip.instance = new Tooltip({
                container: element
            });
        }
        return Tooltip.instance;
    }

    constructor(settings) {
        if (!settings.container) {
            console.warn('No container set to render tooltip to');
        }
        const parent = settings.container;
        this.container = document.createElement('div');

        // resets any styling that might have been applied
        for (const style of Object.keys(globalStyle)) {
            this.container.style[style] = globalStyle[style];
        }

        parent.appendChild(this.container);

        this.containerShadow = this.container;
        if (this.container.attachShadow) {
            this.containerShadow = this.container.attachShadow({mode: 'open'});
        }

        this.sendMessage = function () {
        };
        this.setMessenger = this.setMessenger.bind(this);

        ReactDOM.render(<TooltipComponent setMessenger={this.setMessenger}/>, this.containerShadow);
    }

    setMessenger(callback) {
        this.sendMessage = callback;
    }

    setState(state) {
        state = extend(state, {
            position: [0, 0],
            header: false,
            text: '',
            anchor: 'top'
        });

        this.sendMessage(state);
    }

    remove() {
        this.sendMessage = null;
        ReactDOM.unmountComponentAtNode(this.containerShadow);
        this.container.parentNode.removeChild(this.container);
        this.container = null;
    }

}


const tooltipStyle = {
    position: 'absolute',
    maxWidth: '300px',
    backgroundColor: 'white',
    border: '1px solid #aaa',
    borderRadius: '4px',
    padding: '7px 12px',
    fontFamily: '"FS Me Web Regular", ABeeZee, Helvetica, Arial, Verdana, sans-serif',
    color: '#4B4B4B',
    zIndex: 2000
};

const arrowStyle = {
    position: 'absolute',
    border: 'solid transparent',
    content: " ",
    height: 0,
    width: 0,
    borderColor: 'transparent',
    borderTopColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: 'transparent',
    borderLeftColor: 'transparent',
    borderWidth: '10px'
}

const arrowStyles = {
    top: extend({
        bottom: '100%',
        left: '50%',
        marginLeft: '-10px',
        borderBottomColor: '#aaa',
    }, arrowStyle),
    bottom: extend({
        top: '100%',
        left: '50%',
        marginLeft: '-10px',
        borderTopColor: '#aaa',
    }, arrowStyle),
    left: extend({
        top: '50%',
        right: '100%',
        marginTop: '-10px',
        borderRightColor: '#aaa',
    }, arrowStyle),
    right: extend({
        top: '50%',
        left: '100%',
        marginTop: '-10px',
        borderLeftColor: '#aaa',
    }, arrowStyle)
};

const arrowStylesInner = {
    top: extend({
        borderWidth: '8px',
        marginLeft: '-8px',
        borderBottomColor: 'white'
    }, arrowStyles.top),
    bottom: extend({
        borderWidth: '8px',
        marginLeft: '-8px',
        borderTopColor: 'white'
    }, arrowStyles.bottom),
    left: extend({
        borderWidth: '8px',
        marginTop: '-8px',
        borderRightColor: 'white'
    }, arrowStyles.left),
    right: extend({
        borderWidth: '8px',
        marginTop: '-8px',
        borderLeftColor: 'white'
    }, arrowStyles.right)
};

const styleText = {
    margin: 0,
    fontSize: '0.8em',
    whiteSpace: 'pre-wrap'
};

const styleHeader = {
    fontWeight: 'bold',
    fontSize: '1em',
    margin: '0px 0px 5px 0px'
};


class TooltipComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            position: [0, 0],
            header: false,
            text: '',
            anchor: 'left'
        };

        this.domNode = React.createRef();

        this.animFrame = null;
        this.updateAnchor = null;
        this.timerId = null;
        this.setTooltipState = this.setTooltipState.bind(this);
    }

    componentDidUpdate() {
        if (this.isVisible() && !this.animFrame) {
            this.animFrame = new RequestAnimationFrame(this.tick, this);
        }
        if (!this.isVisible() && this.animFrame !== null) {
            this.animFrame.remove();
            this.animFrame = null;
        }
    }

    componentDidMount() {
        this.props.setMessenger(this.setTooltipState);
    }

    componentWillUnmount() {
        if (this.animFrame !== null) {
            this.animFrame.remove();
            this.animFrame = null;
        }
        clearTimeout(this.timerId);
        this.props.setMessenger(function () {
        });
    }

    setTooltipState(state) {
        this.updateAnchor = null;
        clearTimeout(this.timerId);
        this.setState(state);
    }

    isVisible() {
        return !(!this.state.text && !this.header);
    }

    tick() {
        if (!this.isVisible()) {
            return;
        }
        if (!this.domNode || !this.domNode.current) {
            return;
        }

        if (this.updateAnchor !== null && this.updateAnchor !== this.state.anchor) {
            this.setState({anchor: this.updateAnchor});
        }

        const styles = this.getPositionStyle();
        for (const prop of Object.keys(styles)) {
            this.domNode.current.style[prop] = styles[prop];
        }

    }

    getPositionStyle() {
        let width = 300;
        let height = 30;
        if (this.domNode && this.domNode.current) {
            width = this.domNode.current.offsetWidth;
            height = this.domNode.current.offsetHeight;
        }

        let pos = this.state.position.slice();

        if (this.state.anchor === 'top') {
            pos[0] -= 0.5 * width;
            pos[1] += 18;
        } else if (this.state.anchor === 'bottom') {
            pos[0] -= 0.5 * width;
            pos[1] -= height + 18;
        } else if (this.state.anchor === 'right') {
            pos[0] -= width + 18;
            pos[1] -= 0.5 * height;
        } else { // default to left
            pos[0] += 18;
            pos[1] -= 0.5 * height;
        }

        // todo: this does not yet fully guarantee the tooltip is visible
        if (pos[1] + height > document.body.clientHeight && this.state.anchor === 'top' && this.updateAnchor !== 'top') {
            this.timerId = setTimeout(() => (this.updateAnchor = 'bottom'), 500);
        } else if (pos[1] < 0 && this.state.anchor === 'bottom' && this.updateAnchor !== 'bottom') {
            this.timerId = setTimeout(() => (this.updateAnchor = 'top'), 500);
        } else if (pos[0] < 0 && this.state.anchor === 'right' && this.updateAnchor !== 'right') {
            this.timerId = setTimeout(() => (this.updateAnchor = 'left'), 500);
        } else if (pos[0] + width > window.innerWidth && this.state.anchor === 'left' && this.updateAnchor !== 'left') {
            this.timerId = setTimeout(() => (this.updateAnchor = 'right'), 500);
        } else {
            clearTimeout(this.timerId);
        }

        return {
            left: Math.round(pos[0]) + 'px',
            top: Math.round(pos[1]) + 'px'
        };
    }


    render() {
        if (!this.isVisible()) {
            return <div ref={this.domNode}></div>;
        }

        const style = extend(this.getPositionStyle(), tooltipStyle);

        return <div ref={this.domNode} style={style}>
            {this.state.header &&
            <h1 style={styleHeader}>{this.state.header}</h1>}
            <p style={styleText}>{this.state.text}</p>
            <span style={arrowStyles[this.state.anchor]}></span>
            <span style={arrowStylesInner[this.state.anchor]}></span>
        </div>;
    }

}

Tooltip.defaultProps = {
    setMessenger: function () {
    }
};

export default Tooltip;
