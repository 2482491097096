import React from 'react';
import {ReactComponent as IconEdit} from '../../../../assets/icons/edit.svg';
import styles from '../../../../assets/variables.scss';
import {UITable} from '../../../../components/ui/table/table.js';

const styleIconEdit = {
    cssFloat: 'right',
    width: 25,
    height: 25,
    cursor: 'pointer',
    color: styles.primaryColor
};

const ParagraphsList = function (props) {
    const tableConfig = {
        title: {
            title: 'type',
            value: (row) => ( row.type ),
            width: 2,
            renderCell :(value)=>value.replace(/_/g, " ")
        },
        type: {
            title: 'text',
            value: (row) => ( row.text ),
            width: 6
        },
        edit: {
            width: 1,
            renderCell: (value, row) => <IconEdit onClick={()=> { props.editItem(row.id); }}
                                                  style={styleIconEdit}/>
        }
    };

    const config = Object.keys(tableConfig).map((column)=>{
        return {
            id: column,
            value: (row) => ( row[column] ),
            renderCell: (value)=> value,
            ...tableConfig[column],
        };
    });

    return <div>
        <UITable columns={config} data={props.data}
                 onRowClick={(row) => {props.editItem(row.id)}} />
    </div>
};

export {ParagraphsList}