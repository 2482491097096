import React from "react";
import PropTypes from 'prop-types';
import {ReactComponent as UpChevronSvg} from "../../../assets/icons/up.svg";
import {ReactComponent as DownChevronSvg} from "../../../assets/icons/down.svg";
import styles from "../../../assets/variables.scss";

const UITableSort = function (props) {
    const defaultSort = [
        {label: <UpChevronSvg/>, value: props.id+ '~DESC'},
        {label: <DownChevronSvg/>, value: props.id+ '~ASC'},
    ];

    const list = (props.sort === true) ? defaultSort : props.sort;
    return <span style={{display: 'inline-flex', flexDirection: 'column'}}>
        {list.map((sort)=>{
            const isCurrent = (props.order === sort.value)
            return <span key={sort.value}
                    style={{
                        cursor: 'pointer',
                        margin: '-5px 0',
                        color: isCurrent ? styles.primaryColor: styles.darkColor
                    }}
                    onClick={()=>{
                        const newOrder = isCurrent ? '' : sort.value
                        props.onSelect(newOrder)
                    }}
            >
                {sort.label}
            </span>
        })}
    </span>
};

UITableSort.propTypes = {
    id: PropTypes.string,
    sort: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    order: PropTypes.string,
    onSelect: PropTypes.func,
}

export {UITableSort}