import {config} from '../../config.js';
import {ModelXhr} from '../../helpers/model/xhr.js';

class ModelAuthRole extends ModelXhr {

    static modelName = "ModelAuthRole";

    static getSelect(input) {
        const select = {};
        if(input.filter){
            select.filter = input.filter;
        }
        return select;
    }

    constructor(selectData) {
        super(selectData, {
            endpoint: config.api_endpoint + 'role',
            method: 'GET'
        });

        this.cacheEnabled = true;
    }

    getSelect(input) {
        return ModelAuthRole.getSelect(input);
    }

    getData(){
        const result = super.getData();
        if(result.status === 'success'){
            const data = {};
            for(const row of result.data){
                data[row.id] = row.title;
            }
            return {...result, data: data};
        }
        return result;
    }

}

export {ModelAuthRole};