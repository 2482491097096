import React from "react";
import {PropTypes} from 'prop-types';
import {FormGroup} from '../../../helpers/form/group.js';
import {FormElement} from '../../../helpers/form/element.js';

const ViewAuthSignUpStep = function (props) {

    return <div>
        {props.formConfig.map((question) => {
            if(question.type && question.type === 'group'){
                return <FormGroup key={question.name} {...question} />;
            }else{
                return <FormElement key={question.name} {...question} />;
            }
        })}
    </div>;
}

ViewAuthSignUpStep.defaultProps = {
    setValue: (name, value) => {},
    changeValue: (name, value) => {}
}

ViewAuthSignUpStep.propTypes = {
    setValue: PropTypes.func,
    changeValue: PropTypes.func,
    formConfig: PropTypes.array.isRequired
}

export {ViewAuthSignUpStep}