import React from "react";
import PropTypes from 'prop-types';

//TODO:
// Each navigation dot has currently the same width and the number in the middle. This means the once on the
// edge (first and last) are in fact too wide. Which means the number don't start and stop at the outer edge,
// but a little bit inwards. I am okay with this right now, but if we want to outline the number and the 
// content, we would need to fix it here instead playing with the margin of the content. To see why, just 
// change the number of steps to just 2...
const UIProgressStepsNavigationDot = function (props) {
    const {width, step, stepIndex,  isFirst, isLast, circleTop, size, isActive, isCompleted} = props;

    const leftStyle = {
        top: circleTop + size / 2,
        marginRight: size / 2
    };

    const rightStyle = {
        top: circleTop + size / 2,
        marginLeft: size / 2,
    };

    const stepStyle = {
        width: `${width}%`,
    };

    let circleStyle = {
        width: size,
        height: size,
    };

    const circleClass = 'zol-step-circle ' +
            (isCompleted ? 'zol-step-circle-completed zol-step-circle-button' : '') +
            (isActive ? 'zol-step-circle-completed ' : '');
    const leftLineClass = 'zol-step-left-line ' +
            ((isCompleted || isActive) ? 'zol-step-line-completed ' : '');
    const rightLineClass = 'zol-step-right-line ' + ((isCompleted) ? 'zol-step-line-completed ' : '');

    let condProps = {};
    // You can only click on completed task to go back to. You can't skip forward, so we don't include
    // an event for uncompleted steps.
    if(isCompleted){
        condProps.onClick = ()=>props.onClick(stepIndex);
        circleStyle.cursor = 'pointer';
    }

    return <div className={'zol-step-item'} style={stepStyle}>
        
        <div className={circleClass} style={circleStyle} {...condProps}>
            {step.id}
        </div>

        {step.title && isActive ?
            <div className={'zol-step-title'}>
                {step.title}
            </div> :
            null
        }

       <div className={'step-lines'}>
           {isFirst ?
               null :
               <div className={leftLineClass} style={leftStyle}/>
           }
           {isLast ?
               null :
               <div className={rightLineClass} style={ rightStyle }/>
           }
       </div>
    </div>
};

UIProgressStepsNavigationDot.defaultProps = {
    circleTop: 24,
    size: 26,
    isFirst: false,
    isLast: false,
    isActive: false,
    isCompleted: false
};

UIProgressStepsNavigationDot.propTypes = {
    width: PropTypes.number.isRequired,
    stepIndex: PropTypes.number.isRequired,
    step: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        title: PropTypes.string,
        content: PropTypes.any.isRequired          
    }).isRequired,
    size: PropTypes.number,
    circleTop: PropTypes.number,
    isFirst: PropTypes.bool,
    isLast: PropTypes.bool,
    isActive: PropTypes.bool,
    isCompleted: PropTypes.bool,
}

export {UIProgressStepsNavigationDot}