import React from "react";
import PropTypes from "prop-types";

const legendItemStyle = {
    display: 'inline-block',
    borderRadius: '100%',
    justifyContent: 'center',
    width: 15,
    position: 'relative',
    height: 0,
    paddingBottom: 15,
    marginRight: 5,
    verticalAlign: 'middle'
};

const ChartLegend = function (props) {
    const {data, palette, direction} = props;
    return <div style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: (direction === 'vertical') ?  'column' : 'inherit',
                ...props.style
            }}>
        {data.map((item, key) =>{
            const fill = (Array.isArray(palette[key])) ? `rgb(${palette[key]})` : palette[key];
            return <span key={key} style={{margin: 5}}>
                <span style={{...legendItemStyle, backgroundColor: fill}}/>
                <span style={{width: 'calc(85% - 6px)', display: 'inline-block', verticalAlign: 'middle'}}>{item}</span>
            </span>
        })}
    </div>
};

ChartLegend.defaultProps = {
    direction: 'horizontal',
};

ChartLegend.propTypes = {
    data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    palette: PropTypes.array,
    direction: PropTypes.oneOf(['horizontal', 'vertical']),

};

export {ChartLegend}