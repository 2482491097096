import React from "react";
import PropTypes from "prop-types";

const CardTitle = function (props) {
    return <div>
        <h2 className={'step'}>{props.step}</h2>
        <h2 style={{display: 'inline'}}>{props.title}</h2>
    </div>
};

CardTitle.defaultProps = {
    title: 'Ttile',
};

CardTitle.propTypes = {
    title: PropTypes.string.isRequired,
    step: PropTypes.number
};

export {CardTitle}