import React, {useState, useEffect} from 'react';
import {useModelFeedback} from '../../../../helpers/model/use_model_feedback.js';
import {UIInputSelect} from '../../../../components/ui/input/select.js';
import {ViewSettingsPageHeader} from "./header.js";
import {useForm} from "../../../../helpers/form/use_form.js";
import {ReactComponent as IconEdit} from "../../../../assets/icons/edit.svg";
import {ModelAuditPeriod} from "../../../../model/audit/period.js";
import {UITable} from "../../../../components/ui/table/table.js";
import {FormElement} from '../../../../helpers/form/element.js';
import {UIButton} from "../../../../components/ui/button/button.js";

const formConfig = [
    {
        title: 'Publish period',
        name: 'status',
        element: UIInputSelect,
        options:{
            PUBLISHED: 'Yes',
            UNPUBLISHED: 'No'
        },
        required: true,
        type: 'wide'
    }
];

const tableConfig = [
    {
        id: 'name',
        title: 'Period',
        value: (row) => ( row.name ),
        width: 4,
    },
    {
        id: 'status',
        title: 'Published',
        value: (row) => ( row.status === 'PUBLISHED'? 'Yes': 'No' ),
        width: 2,
    },
    {
        id: 'edit',
        width: 1,
        value: (row) => ( 'edit' ),
        renderCell: (value) => (
            <IconEdit style={{width: 25, height: 25, cssFloat: 'right'}}/>
        )
    }
];

const ViewSettingsPagePeriods = function (props) {
    const resultSet = useModelFeedback(ModelAuditPeriod); 
    const Form = useForm(formConfig);
    const [periodId, setPeriodId] = useState(null);
    const [valuesPresent, setValuesPresent] = useState(false);

    const period = periodId && ! resultSet.feedback? 
            resultSet.data.find((item) => ( item.id === periodId )):
            null;

    // this sets the Form values based on what's in the model
    useEffect(() => {
        if(period && ! valuesPresent){
            Form.setValues(period || {});
            setValuesPresent(true);
        }
        if(valuesPresent && ! period){
            setValuesPresent(false);
        }
    }, [Form, valuesPresent, period]);

    if(resultSet.feedback){
        return resultSet.feedback;
    }

    let content = null;
    if(periodId === null){   // show the list
        content = <UITable columns={tableConfig} data={resultSet.data} 
                onRowClick={(row) => { setPeriodId(row.id) }} />;

    }else{        // show question details
        const submitForm = () => {
            if(Form.validate()){
                const onSuccess = (token) => {
                    setPeriodId(null);
                }   
                const onFailure = (error) => {
                    // misusing the status error placeholder here
                    Form.setError('status', error);
                }
                ModelAuditPeriod.updateItem(periodId, Form.getValues(), onSuccess, onFailure);
            }
        }
        
        content = <div style={{backgroundColor: 'white', padding: '40px'}}>
            
            <Form.Component>

                <h2>Period {period?.name}</h2>

                {formConfig.map(element => (
                    <FormElement  key={element.name} {...element} />
                ))}

                <UIButton className="primary" style={{marginRight: '20px'}} onClick={submitForm}>
                    Save
                </UIButton>

                <UIButton className="off-white" style={{}} 
                        onClick={() => { setPeriodId(null) }}>
                    Cancel
                </UIButton>

            </Form.Component>

        </div>;
    }

    return <>
        <ViewSettingsPageHeader {...props}/>
        <div style={{margin: "20px 0"}}>
            {content}
        </div>

    </>
}

export {ViewSettingsPagePeriods}