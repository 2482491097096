import React, {useState} from "react";
import {useStateContext} from "../../../helpers/state-context.js";
import {UIInputSelect} from "../../../components/ui/input/select.js";
import {Card} from "../../../components/card/card.js";
import styles from "../../../assets/variables.scss";
import {CardHeader} from "../../../components/card/header.js";
import {useModelFeedback} from "../../../helpers/model/use_model_feedback.js";
import {ModelDashboardAnswers} from "../../../model/dashboard/answers.js";
import {ModelDashboardExpectedCaseload} from "../../../model/dashboard/expected-caseload.js";
import {ModelOrgHierarchy} from "../../../model/org-hierarchy.js";
import {VerticalBarIcon} from '../../../components/chart/vertical-bar/icon.js';
import {VerticalBarComponentCircle} from '../../../components/chart/vertical-bar/component/circle.js';

function getGroupOptions(){
    return [
        {id: 1, label: 'providers', title: 'providers'},
        {id: 2, label: 'teams', title: 'teams'}
    ]
};

function getChildIds(i, resultSetProviders, resultSetHierarchy){
    const provider_id = resultSetProviders.data[i].id;
    const childIds = [];
    resultSetHierarchy.data.forEach((item)=>{
        if(item.provider_id === provider_id){
            childIds.push(item.team_id);
        }
    })
    return childIds;
}

function getProviderValue(childIds, dataAnswer, dataCaseload){
    let totalNumerator = 0;
    let totalDenominator = 0;
    for(const id of childIds){
        totalNumerator += dataAnswer.find(row => row.group_id === id)?.value;
        totalDenominator += dataCaseload.find(row => row.group_id === id && row.question_id ===5)?.value;
    }
    return (totalDenominator===0)?0:(totalNumerator/totalDenominator *100).toFixed()
}

const DashboardCardFepPredictedPrevalence = function (props) {
    //TODO: option to see across teams, providers etc
    const [state] = useStateContext(['period', 'group', 'isExportingPDF']);
    const [group, setGroup] = useState('teams');
    const groupOptions = getGroupOptions();
    let data = [];
    const resultSetHierarchy = useModelFeedback(ModelOrgHierarchy, {select: 'provider, provider_id, team, team_id'}, (m) => (m.getRawData()));
    const resultSetProviders = useModelFeedback(ModelOrgHierarchy, {type: 'provider'}, (m)=> m.getRawData());

    const resultSetCaseload = useModelFeedback(ModelDashboardAnswers,
        {
            question_ids: [5,3],
            period: state.period,
            select: 'group_id, answer as value, group_title, question_id, period_id'
        });
   
    const resultSetExpected = useModelFeedback(ModelDashboardExpectedCaseload, {byTeam: true});
   
    if(resultSetProviders.feedback || resultSetHierarchy.feedback){
        return resultSetProviders.feedback || resultSetHierarchy.feedback
    }
        
    const toPercentage = (group_id) =>{
        const value1 = resultSetExpected.data
                .find(row => row.group_id === group_id)?.value;
        const value2 = resultSetCaseload.data
                .find(row => row.group_id === group_id && row.question_id === 5)?.value;
        return (value1 === 0) ? 0 : (value2 / value1 * 100).toFixed()
    };

    if(!resultSetExpected.feedback && !resultSetCaseload.feedback){
        for(let i=0; i<resultSetExpected.data.length; i++){
            const activeIds = resultSetHierarchy.data.reduce((array, item) =>{
                let newArray = [...array];
                if(item.provider_id === state.group || item.team_id === state.group){
                    newArray.push(...[item.provider_id, item.team_id])
                }
                return newArray
            }, []);
            const color = activeIds.includes(resultSetExpected.data[i].group_id) ?
                styles.brandPurpleColor :
                null;
            const value = toPercentage(resultSetExpected.data[i].group_id)
            data[i]= {
                group_id: resultSetExpected.data[i].group_id,
                color: color,
                value:  isNaN(value) ? 0 : value,
                header: resultSetExpected.data[i].EIP_team,
                tooltip: toPercentage(resultSetExpected.data[i].group_id) + `%`
            }
        }
    }
    if(group==='teams'){
        data.sort((a, b) => (parseInt(a.value) > parseInt(b.value)) ? 1 : -1 );
    }else{
        if(data.length){
            let providerData = [];
            for(let i=0; i<resultSetProviders.data.length; i++){
                const childIds = getChildIds(i, resultSetProviders, resultSetHierarchy);
                const value = getProviderValue(childIds, resultSetExpected.data, resultSetCaseload.data);
                const color = [...childIds, resultSetProviders.data[i].id].includes(state.group) ?
                    styles.brandPurpleColor :
                    null;
                providerData.push({
                    group_id: resultSetProviders.data[i].id,
                    color: color,
                    value : isNaN(value) ? 0 : value,
                    header: resultSetProviders.data[i].title,
                    tooltip: value + `%`
                })
            }
            data = providerData.sort((a,b)=>(parseInt(a.value) > parseInt(b.value)) ? 1 : -1 );
        } 
    }

    const getGroupSelect = () => {
        const options = groupOptions.map((o)=>o.title);
        return <UIInputSelect
            options={options}
            name={'period-select'}
            placeholder={props.placeholder}
            onChange={(value)=>{setGroup(value)}}
            value={group} />
    };

    const getTitle = function (title){
        return <div style={{width: '100%', display:'inline', horizontalAlign:'left'}}>
            <p>{title}</p>
            <h4 style={{margin: '0px'}}>As % Predicted Prevalence</h4>
        </div>
    };

    const max = Math.max(...data.map((i)=>parseFloat(i.value)), 100);

    const min = Math.min(...data.map((i)=>parseFloat(i.value)), 0);

    return <Card id={props.id} cardSize={props.cardSize}>
        <CardHeader id={props.id} title={getTitle} rightColumn={getGroupSelect()}/>
        <div style={{width: '100%', height: 240, display: 'inline-block'}}>
            <VerticalBarIcon
                    data={data.map(({group_id, ...item }) => ({
                        ...item,
                        icon: (state.isExportingPDF) ?
                            <VerticalBarComponentCircle>
                                {group_id}
                            </VerticalBarComponentCircle> :
                            null,
                    }))}
                    axis={{x: 'header', y:['value']} }
                    type='grouped'
                    tooltipHeader={true}
                    iconAsLabel={true}
                    formatAxis={{
                        y: (value)=>`${value}%`
                    }}
                    domain={[min,max + ((max / 100) *10)]}/>
        </div>
    </Card>
};

export {DashboardCardFepPredictedPrevalence}

