import {config} from '../config.js';
import {ModelXhr} from '../helpers/model/xhr.js';

class ModelOrgHierarchy extends ModelXhr {

    static modelName = "ModelOrgHierarchy";

    static getSelect(input) {
        // if(! input.type){
        //     console.error('ModelOrgHierarchy requires property type to be set in selectionCriteria.');
        //     return {};
        // }

        const filters = [];
        const fields = ['region', 'stp', 'ccg', 'provider', 'team'];
        for(const field of fields){
            if(input[field]){
                filters.push(`${field}_id eq ${input[field]}`)
            }
        }
        const select = {
            select: input.select? input.select : `${input.type}_id as id, ${input.type} as title`,
            filter: filters.join(' and '),
        };
        if(input.order){
            select.order = input.order;
        }
        return select;
    }

    constructor(selectData) {
        super(selectData, {
            endpoint: config.api_endpoint + 'org_hierarchy',
            method: 'GET'
        });

        this.cacheEnabled = true;
    }

    getSelect(input) {
        return ModelOrgHierarchy.getSelect(input);
    }

    getData(){
        const result = super.getData();
        if(result.status === 'success'){
            const data = {};
            for(const row of result.data){
                data[row.id] = row.title;
            }
            return {...result, data: data};
        }
        return result;
    }

    getRawData(){
        return super.getData();
    }

}

export {ModelOrgHierarchy};