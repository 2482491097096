import {useState, useCallback, useEffect} from 'react';
import {objectsEqual} from './functions/objects_equal.js'
import {SimpleEvent} from './simple-event.js';

const useSize = function(parseValueCallback=(w, h) => ({width: w, height: h}), 
        initialSize={width: 200, height: 200} ){

    const [size, setSize] = useState(initialSize);
    const [element, setElement] = useState(null);

    useEffect(() => {
        let observer = null;
        let timerId = null;

        if(element === null){
            return;
        }

        // we do not continiously update, but only after 500 ms of no resize changes
        const onResize = () => {
            clearTimeout(timerId);
            timerId = setTimeout(() => {
                const width = element.clientWidth || (element.offsetWidth || 200);
                const height = element.clientHight || (element.offsetHeight || 200);
                if (! objectsEqual(parseValueCallback(width, height), size)){ 
                    setSize(parseValueCallback(width, height));
                }
            }, 500);
        }

        if(window.hasOwnProperty(ResizeObserver)){
            observer = new ResizeObserver(onResize);
            observer.observe(element);
        }else{
            observer = new SimpleEvent(window, 'resize', onResize);
        }
        onResize();

        return () => {
            if(window.hasOwnProperty(ResizeObserver) && observer !== null){
                observer.disconnect();
            }else if(observer !== null){
                // Remove simple event
                observer.remove();
            }
            clearTimeout(timerId);
        }
    }, [element, size, parseValueCallback]);

    const measuredRef = useCallback(node => {
        if (node !== null) {
            setElement(node);
        }
    }, []);

    return [size, measuredRef];

}

export {useSize};