import React, {useState} from "react";
import {useStateContext} from "../../../helpers/state-context.js";
import {useModel} from "../../../helpers/model/use_model.js";
import {Card} from "../../../components/card/card.js";
import {VerticalBarIcon} from "../../../components/chart/vertical-bar/icon.js";
import {getColorPalette} from "../../../helpers/color.js";
import {CardHeader} from "../../../components/card/header.js";
import {ChartLegend} from "../../../components/chart/legend.js";
import '../../../components/ui/select.scss';
import styles from '../../../assets/variables.scss';
import {UIInputSelect} from "../../../components/ui/input/select.js";
import {ModelDashboardAnswerDistribution} from "../../../model/dashboard/answer-distribution.js";
import {ModelAuditIndicator} from "../../../model/audit/indicator.js";
import {NICEIcons} from "../../../helpers/elements/nice-icons.js";
import {getFullYear, currentYear, getLastYear, getInThreeYears} from "../../../helpers/functions/periods.js";

// const questions = [26, 29, 33 ,35, 37, 39, 41];
const indicatorIds = [2, 5, 8, 10, 12, 14, 16];

// const getTotal = function (data, questionIds, key='value'){
//     let total = []
//     for(const id of questionIds){
//         const denominator = data.find((row)=> row.fk_field1 === id)?.fk_field2 || 0;
//         total.push(denominator)
//     }
//     return total;
// }

function getOptions(selectedPeriod){
    return [
        {id: 1, label: [selectedPeriod], title: 'Selected quarter'},
        {id: 2, label: getFullYear(), title: 'Current year: '+currentYear},
        {id: 3, label: getLastYear(), title: 'Past year: '+ (currentYear-1)},
        {id: 4, label: getInThreeYears(), title:'In 3 years'}
    ]
}

const DashboardCardNiceOverview = function (props) {
    const {axis} = props;
    const [period, setPeriod] = useState('Selected quarter');
    let palette = [getColorPalette(1), styles.brandLightBlueColor];
    //the dataset gets reversed in the vertical bar component
    const graphPalette = [styles.brandLightBlueColor, getColorPalette(1)];
    const [state] = useStateContext(['period', 'group']);
    const periodOptions = getOptions(state.period);
   
    const group_id = (state.group && state.group !== 'undefined') ? state.group : '';
    let data = [];
    const resultSetIndicator = useModel(ModelAuditIndicator, {ids: indicatorIds});
    const questionIds = new Set();
    if(resultSetIndicator.status === 'success'){
        for(const item of resultSetIndicator.data){
            questionIds.add(item.fk_field1);
            questionIds.add(item.fk_field2);
        };
    }
    const indicators = resultSetIndicator.status === 'success' ? resultSetIndicator.data : [];
    const resultSet = useModel(ModelDashboardAnswerDistribution, 
            {
                select: 'question_id as id, sum(answer) as value',
                question_ids: questionIds.size > 0 ? [...questionIds]: [0], 
                period: periodOptions.find((p) => (p.title === period))?.label, 
                group_id : group_id
            });
    
    
    const getIndicatorPercentage = (indicator) =>{
        const numerator = resultSet.data.find((row)=> (indicator.fk_field1 === row.id))?.value || null;
        const denominator = resultSet.data.find((row)=> (indicator.fk_field2 === row.id))?.value || null;
        if(numerator === null || denominator === null || denominator === 0){
            return null;
        }
        return Math.round(100 * numerator / denominator);
    }

    function getIcon(id){
        const icon = NICEIcons.find((row)=> row.indicatorIds.includes(id))?.icon;
        return icon || <p>x</p>;
    } 

    
    if(resultSet.status === 'success' && resultSetIndicator.status === 'success'){ 
        for(const indicator of indicators){
            const percentage = getIndicatorPercentage(indicator);
            const withRecord = percentage === null ? '-' : percentage;
            const withoutRecord = percentage === null? '-': 100 - percentage;
            data.push({
                header: indicator.title,
                icon: getIcon(indicator.id),
                tooltip: 'Patients with: ' + withRecord + '%\n' +
                        'Patients without: ' + withoutRecord + '%\n',
                'Patients with': withRecord,
                'Patients without': withoutRecord,
            })
        }
    }

    const getPeriodSelect = () => {
        const options = periodOptions.map((o)=>o.title);
        return <UIInputSelect
            options={options}
            name={'period-select'}
            placeholder={props.placeholder}
            onChange={(value)=>{setPeriod(value)}}
            value={period} />
    };

    return <Card id={props.id} cardSize={props.cardSize}>
        <CardHeader id={props.id} rightColumn={getPeriodSelect()} style={{height: 35}}/>
        <div style={{display: "flex", height: 240, justifyContent: 'space-between'}}>
            <div style={{width: 'calc(100% - 120px)', height: '100%'}}>
                <VerticalBarIcon type={'stacked'}
                        showAxisKey={true}
                        axis={axis}
                        tooltipHeader={true}
                        data={data}
                        formatAxis={{
                            y: (value)=>`${value}%`
                        }}
                        iconAsLabel={true}
                        domain={[0, 100]}
                        palette={graphPalette}/>
            </div>
            <ChartLegend direction={'vertical'} palette={palette} data={axis.y} style={{width:'120px'}}/>
        </div>
    </Card>
};

DashboardCardNiceOverview.defaultProps = {
    id : 9,
    axis: {
        x: 'header',
        y: ['Patients with', 'Patients without']
    },
};

export {DashboardCardNiceOverview}

