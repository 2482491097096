import './text.scss';
import React from "react";
import PropTypes from 'prop-types';
import styles from '../../assets/variables.scss';

const UIText = function (props) {
    const {color, type, style, onClick} = props;
    return <p className={`${type} ${props.className ? props.className : ''}`}
            style={{
                padding: 0,
                margin: 0,
                color: styles[color] ? styles[color] : color,
                ...style
            }}
            onClick={onClick}
    >
        {props.children}
    </p>
};

UIText.defaultProps = {
    type: 'body-M',
    color: 'primaryColor'
};

UIText.propTypes = {
    className: PropTypes.string,
    type: PropTypes.oneOf([
        // Use for introduction header / Big number
        "head-1",
        // Use for dashboard header
        "head-2",
        // Use for card title
        "head-3",
        // Use for paragraph title / medium number
        "head-4",
        // Use for navigation text
        "head-5",
        //Use for chart title/ dropdown text / body text large
        "head-6",
        // Use for button text
        "body-L-bold",
        // Use for paragraph text / chart title small / section title
        "body-L",
        // Use for paragraph title small
        "body-M-bold",
        // Use for body text small / legen text / Toggle text
        "body-M",
        // Use for chart bubble text highlighted
        "body-S-bold",
        // Use for buble text normal / Chart item name
        "body-S",
        // Use for chart grid number
        "body-XS",
    ]),
    color: PropTypes.string
};

export {UIText}