import './modal.scss';
import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import {UIText} from "../text.js";
import {ReactComponent as CloseSvg} from "../../../assets/icons/close.svg";

const UIModal = (props)=>{
    const [visible, setVisible] = useState(props.visible);

    useEffect(() => {
        const handleClickOutside = (e) => {
            const modal = document.getElementById('zol-modal');

            if(modal && !modal.contains(e.target)) {
                // if the toggle is clicked, it will also trigger that event (which opens the menu again)
                // unless we stop propagation.
                e.stopPropagation();
                setVisible(false);
                props.onClose();
            }
        };

        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        }
    }, [props]);


    if(!visible){
        return null;
    }

    return <div className={'zol-modal-overlay'}>
        <div id={'zol-modal'} style={{maxWidth: props.width || 500}}>
            {(props.hideHeader) ?
                null :
                <div className={'zol-modal-header'}>
                    <UIText color={'darkColor'}>{props.title}</UIText>
                    <CloseSvg className={'close-icon'} 
                            onClick={()=>{
                                setVisible(false);
                                props.onClose();
                            }}/>
                </div>
            }
            <div className={'zol-modal-content'}>
                {props.children}
            </div>
        </div>
    </div>
};

UIModal.defaultProps = {
    title: '',
    visible: true,
    onClose: ()=>{ } 
};

UIModal.propTypes = {
    hideHeader: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number ]),
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node ]),
    footer: PropTypes.node,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};

export {UIModal}