import './header.scss'
import React, {useEffect, useState} from "react";
import {PropTypes} from 'prop-types';
import {ReactComponent as IconUsers} from '../../assets/icons/users.svg';
import { ModelAuthToken } from '../../model/auth/token.js';
import { useStateContext } from '../../helpers/state-context.js';
import {ReactComponent as IconLogout} from '../../assets/icons/log-out-outline.svg';

const HeaderUser = function (props) {
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [, dispatch] = useStateContext();

    useEffect(() => {
        const handleClickOutside = (e) => {
            const modal = document.getElementById('header-user-menu');

            if(modal && !modal.contains(e.target)) {
                // if the toggle is clicked, it will also trigger that event (which opens the menu again)
                // unless we stop propagation.
                e.stopPropagation();
                setMenuIsOpen(false);
            }
        };

        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        }
    }, []);

    const signOut = () => {
        ModelAuthToken.signOut();
        dispatch({type: 'page', value: 'home'});
    };

    const navToMyAccount = () => {
        dispatch({type: 'page', value: 'my-account'})
    };

    // TODO: fix merge issue here
    const menu = menuIsOpen?
            <div id={'header-user-menu'}>
                <span className="zol-user-menu" onClick={navToMyAccount}>
                    <IconUsers style={{marginRight: 6}}/>
                    My account
                </span>
                <span className="zol-user-menu" onClick={signOut} style={{top: 120}}>
                     <IconLogout style={{marginRight: 6}}/>
                    Sign out
                </span>
            </div>:
            // <span id={'header-user-menu'} className="zol-user-menu" onClick={signOut}>
            //     Sign out
            // </span>:
            null;


    return <span  className={'zol-header-item zol-header-profile' + (menuIsOpen? ' zol-open': '')}
            title= 'Go to personal page'
            onClick={()=>{ setMenuIsOpen(! menuIsOpen); }}>
        <IconUsers />
        <em>{props.user.name}</em>
        {menu}
    </span>;
}

HeaderUser.propTypes = {
    user: PropTypes.shape({
        name: PropTypes.string.isRequired
    }).isRequired
}

export {HeaderUser};