import React from "react";
import {PropTypes} from 'prop-types';
import {UIText} from "../../../components/ui/text.js";
import {AuditTable} from './table.js';
import { ModelAuditList } from "../../../model/audit/list.js";
import { ModelAudit } from "../../../model/audit/audit.js";
import {useModelFeedback} from "../../../helpers/model/use_model_feedback.js";

const AuditListTeam = function (props) {

    const resultSet = useModelFeedback(ModelAuditList, {});
    if(resultSet.feedback){
        return resultSet.feedback;
    }
    
    const listIncluded = ['current', 'history'];
    const currentPeriod = ModelAudit.getCurrentPeriodName();

    const columnsCurrent = ['period', 'progress', 'button'];
    const columnsHistory = ['period', 'progress', 'indicator1', 'indicator2', 'indicator3', 
            'indicator4', 'indicator5'];
    
    return <div style={{padding: '0 15px'}}>
        <div style={{float: 'left', width: 'calc(100% - 30px)', paddingRight: 30}}>

            <UIText type={'head-1'} color={'darkColor'}>
                My audits
            </UIText>

            <div style={{marginTop: '30px'}}>

                {listIncluded.map((type) => {
                    const data = resultSet.data.filter((row) => {
                        if(type === 'current'){
                            return row.period.name === currentPeriod;
                        }else if(type === 'history'){
                            return row.period.name !== currentPeriod;
                        }
                        return false;
                    });
                    if(data.length === 0){
                        return null;
                    }
                    return <div key={type}>
                        <UIText type={'body-L-bold'} color={'darkColor'}>
                            {type==='current'? 'Current period': null}
                            {type==='history'? 'Previous periods (' + data.length + ')': null}
                        </UIText>
                        <AuditTable columns={type==='current'? columnsCurrent: columnsHistory} data={data} />
                    </div>;
                })}

                {resultSet.data.length === 0? 
                    <UIText type={'body-L-bold'} color={'darkColor'}>
                        There are no audits
                    </UIText>:
                    null
                }
                
            </div>
        </div>

        {/* The content of the side bar is not for this type of user */}

    </div>;
};

AuditListTeam.defaultProps = {
    period: 'all'
}

AuditListTeam.propTypes = {
    period: PropTypes.oneOf(['current', 'history', 'all'])
}

export {AuditListTeam}