import React, {useEffect} from 'react';
import {ViewAdmin} from "./view/admin/admin.js";
import {ViewAuthSignIn} from "./view/onboard/auth/sign-in.js";
import {useStateContext} from "./helpers/state-context.js";
import {ViewAuthForgotPassword} from "./view/onboard/auth/forgot-password.js";
import {ViewAuthResetPassword} from "./view/onboard/auth/reset-password.js";
import {ViewAuthSignUp} from "./view/onboard/auth/sign-up.js";
import {ViewAuthConfirmSignUp} from "./view/onboard/auth/confirm-sign-up.js";
import {ViewAuthDetails} from "./view/onboard/auth/details.js";
import {ViewDashboard} from "./view/dashboard/dashboard.js";
import {TestTable} from "./view/test-table.js";
import {ViewHeader} from './view/header/header.js'
import {ViewOnboard} from './view/onboard/onboard.js';
import { ModelAuthToken } from './model/auth/token.js';
import {ViewMyAccount} from './view/my-account.js';
import {CONFIG} from './config.js';

const mainStyle = {
    height: 'calc(100vh - 91px)',
};

const App = function(props) {
    const [state, dispatch] = useStateContext(['page']);

    useEffect(()=>{
        // Check if auth token is expired every 5 minutes. incase it is
        const checkTokenExpired = () => {
            const tokenExpire = localStorage.getItem("auth_token_exp") || 
                    sessionStorage.getItem("auth_token_exp") || 0;

            if(new Date().getTime() > parseInt(tokenExpire)){
                localStorage.removeItem('auth_token');
                localStorage.removeItem('auth_token_exp');
                sessionStorage.removeItem('auth_token');
                sessionStorage.removeItem('auth_token_exp');
                if(tokenExpire){
                    dispatch({type: 'page', value: 'sign-in'});
                }
            }
        }
        const timerId = setInterval(checkTokenExpired, 5 * 60 * 1000);
        checkTokenExpired();

        return ()=> { clearInterval(timerId); }

    }, [dispatch]);

    let content = ModelAuthToken.hasValidToken()? <ViewDashboard/>: <ViewOnboard/>;

    let page = "log-in";
    if(state.page === 'sign-in'){
        content = <ViewAuthSignIn/>
    }else if(state.page === 'forgot-password'){
        content = <ViewAuthForgotPassword/>;
    }else if(state.page === 'reset-password'){
        content = <ViewAuthResetPassword/>;
    }else if(state.page === 'sign-up'){
        content = <ViewAuthSignUp/>
    }else if(state.page === 'confirm-sign-up'){
        content = <ViewAuthConfirmSignUp/>
    }else if(state.page === 'admin' || state.page === 'audits'){
        page = "admin";
        content = <ViewAdmin/>
    }else if(state.page === 'dashboard'){
        content = <ViewDashboard/>;
        page = "user";
    }else if(state.page === 'my-account'){
        content = <ViewMyAccount/>
    // test pages
    }else if(state.page === 'user-details'){
        page = "user";
        content = <ViewAuthDetails /> 
    }else if(state.page === 'table'){
        page = "user";
        content = <TestTable/>
    }

    const toggleFeedback = () => {
        window.open(CONFIG.application_root + '/feedback', "", "width=500,height=600");
    }

    return <div>
        <ViewHeader page={page}/>
        <div style={mainStyle}>
            {content}
        </div>
        <div className="zol-feedback-toggle" onClick={toggleFeedback}>
            <span>Feedback</span>
        </div>
    </div>;
}

export {App};
