import React from "react";
import {PropTypes} from 'prop-types';
import {UITable} from "../../../components/ui/table/table.js";
import {ReactComponent as IconEdit} from "../../../assets/icons/edit.svg";
import {ReactComponent as IconDelete} from "../../../assets/icons/delete.svg";
import { useModelFeedback } from "../../../helpers/model/use_model_feedback.js";
import {ModelAuthUserList} from "../../../model/auth/user-list.js";
import {UIButton} from "../../../components/ui/button/button.js";
import { ModelAuthUser } from "../../../model/auth/user.js";

const UserList = function (props) {

    const filter = props.pendingUsers? {status: 'PENDING'}: {};
    const resultSet = useModelFeedback(ModelAuthUserList, filter);
    
    const approveUser = (userId, event) => {
        event.stopPropagation();
        ModelAuthUser.updateItem(userId, {status: 'APPROVED'}, (user) => {
            alert('User ' + user.name + ' approved!');
        });
    }

    const deleteUser = (userId, event) => {
        event.stopPropagation();
        if(window.confirm('Are you sure you want to decline and remove this user?')){
            ModelAuthUser.deleteItem(userId, (user) => {
                alert('User ' + user.name + ' deleted!');
            });
        }
    }

    if(resultSet.feedback){
        return resultSet.feedback;
    }

    const columns = {
        name: {
            title: 'Name',
            width: 3,
            sort: true
        },
        email: {
            title: 'Email',
            width: 3
        },
        role: {
            title: 'Role',
            width: 3,
            value: (row) => ( 
                row.roles? 
                    row.roles.map(role => role.title).join(', '): 
                    'No role assigned' 
            )
        },
        group:{
            title: 'Organization unit',
            width: 3,
            value: (row) => ( 
                row.groups? 
                    row.groups.map(group => group.title).join(', '): 
                    'No unit assigned'
            )
        }
    }

    if(props.pendingUsers){
        columns.approve = {
            width: 2,
            renderCell: (value, row) => (
                <UIButton onClick={(e)=> {approveUser(row.id, e)}}>Approve</UIButton>
            )
        };
        columns.decline = {
            width:2,
            renderCell: (value, row) => (
                <UIButton onClick={(e) => {deleteUser(row.id, e)}} className={'error'}>Decline</UIButton>
            )
        }

    }else{
        columns.status = {
            title: 'Status',
            width: 2,
        };
        columns.edit = {
            width: 1,
            renderCell: (value, row) => {
                return <IconEdit onClick={()=>{props.onClick(row.id)}} style={{cursor: 'pointer'}}/>
            }
        };
        columns.delete = {
            width: 1,
            renderCell: (value, row) => {
                return <IconDelete onClick={(e)=>{ e.stopPropagation(); deleteUser(row.id, e); }} 
                        style={{cursor: 'pointer'}}/>
            }
        };
    };

    const columnsConfig = Object.keys(columns).map((column)=>{
        return {
            id: column,
            value: (row) => ( row[column] || null ),
            renderCell: (value) => value,
            ...columns[column],
        }
    });
    
    return <UITable 
            data={resultSet.data} 
            columns={columnsConfig} 
            onRowClick={(row)=>{props.onClick(row.id)}} 
            emptyText={'There are no users!'}/>
}

UserList.propTypes = {
    pendingUsers: PropTypes.bool
};

UserList.defaultValues = {
    pendingUsers: false
}

export {UserList}