import './progress-circle.scss'
import PropTypes from 'prop-types';
import React from 'react';
import DonutCircleSegment from "../donut/circle-segment.js";
import {useSize} from "../../../helpers/use_size.js";
import Tooltip from "../../../helpers/elements/tooltip.js";
import {getColorPalette} from "../../../helpers/color.js";

const styleCenterValue = {
    textAnchor: 'middle',
    dominantBaseline: 'middle',
};

const onMouseHover = (event, title) => {
    if (title === null) {
        return Tooltip.getInstance().setState({});
    }
    // Add scroll offsets
    const xOffset=Math.max(document.documentElement.scrollLeft,document.body.scrollLeft);
    const yOffset=Math.max(document.documentElement.scrollTop,document.body.scrollTop);

    const x = event.clientX+xOffset;
    const y = event.clientY+yOffset;
    Tooltip.getInstance().setState({

        position: [x, y -8],
        header: false,
        text: title,
        anchor: 'bottom'
    });
};

const ChartProgressCircle = function (props) {
    const {hideTitles, data, total} = props;
    const colors = props.palette? props.palette : getColorPalette(data.length);
    const totalValue = total ? total : data.reduce((count, item) => (count + item.value), 0)
    const [size, measuredRef] = useSize((w,h)=>({width: w, height: h}), {width: 1, height: 1});

    // Use the small size as width and height so it doesn't go outside of the div
    const side = (size.height < size.width) ? size.height : size.width;
    const width = side;
    const height = side;
    
    // Make sure there is at least 1% width for the circle itself
    const circlePadding = Math.min(0.99, props.circlePadding);
    // Make sure the center radius is not larger than 70% of the total width
    const centerRadius = Math.min(props.centerRadius, 0.35 * width);
    let radius = (0.5 * width);
    const circleWidth = (radius - centerRadius) / data.length;

    return <div ref={measuredRef} style={{width: '100%', height: '100%'}}>
        <svg width={`${width}px`} height={`${height}px`} viewBox={'0 0 ' + width + ' ' + height}>
            <g transform={`translate(${width/2}, ${height/2})`}>

                {data.map((item, key) => {
                    const percentile = item.value / totalValue;
                    const r = radius;
                    radius -= circleWidth;
                    const title = `${item.title}: ${item.value} / ${totalValue} ` +
                            `( ${Math.round((item.value / totalValue) * 100)}% )`;

                    const styleDonut = {
                        fill: item.color ? item.color: 'rgb(' + colors[key].join(',') + ')'
                    }

                    return <g key={key}
                            className={`zol-progress-circle-item`}
                            onMouseOut={(e) => onMouseHover(e, null)}
                            onMouseMove={(e) => onMouseHover(e, title)}>

                        {/*Background circle*/}
                        <DonutCircleSegment style={props.styleDonutBackground}
                                percentage={1}
                                startAt={0}
                                radius={r - 0.5 * circlePadding * circleWidth}
                                innerRadius={r - circleWidth + 0.5 * circlePadding * circleWidth}
                                totalAngle={359.9} />

                        {/*Progress circle*/}
                        <DonutCircleSegment percentage={percentile}
                                style={styleDonut}
                                startAt={0}
                                radius={r - 0.5 * circlePadding * circleWidth}
                                innerRadius={r - circleWidth + 0.5 * circlePadding * circleWidth}
                                totalAngle={359.9}/>
                    </g>;
                })}

                {hideTitles || centerRadius < 60?
                    null :
                    <text style={styleCenterValue} y={`-${data.length * 20}px`}>
                        {data.map((item, key) => {

                            const styleTitle = {
                                fill: item.color ? item.color: 'rgb(' + colors[key].join(',') + ')',
                                fontWeight: 'bold'
                            }

                            return <React.Fragment key={key}>
                                <tspan x="0" dy={20}>{item.title}</tspan>
                                <tspan x="0" dy={15} style={styleTitle}> 
                                    {item.value + '/' + totalValue}
                                </tspan>
                            </React.Fragment>
                        })}
                    </text>
                }
            </g>
        </svg>
    </div>;
};

ChartProgressCircle.defaultProps = {
    centerRadius: 100,
    circlePadding: 0.4, // relative size of band width that stays empty. Value between 0 and 1
    data: [],
    hideTitles: false,
    total: 0,
    styleDonutBackground: {fill: "#e2e7fc", stroke: 'none'}
};

ChartProgressCircle.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.number.isRequired,
            title: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
            color: PropTypes.string
        })
    ).isRequired,
    centerRadius: PropTypes.number,
    circlePadding: PropTypes.number,
    hideTitles: PropTypes.bool,
    total: PropTypes.number,
    styleDonutBackground: PropTypes.object
};

export {ChartProgressCircle};
