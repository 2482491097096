import './table.scss';
import React, {useState} from 'react';
import {PropTypes} from 'prop-types';
import {UITableHeader} from "./header.js";
import {UIText} from "../text.js";

const UITable = function(props){
    
    const {maxItems} = props;
    const [currentLimit, setCurrentLimit] = useState(maxItems);
    const [order, setOrder] = useState('');
    let data = props.data;

    // get the width of the full table
    const totalWidth = props.columns.reduce(
        (sum, column) => ( sum + (column.width? column.width: 1) ), 0);

    if(totalWidth === 0){
        return null;
    }
    // calculate relative width in percentage
    const columns = props.columns.map((column) => ({
        ...column,
        width: Math.round(100 * (column.width || 1) / totalWidth) + '%'
    }));

    // Order data when order state is set.
    if(order){
        const [key, orders] = order && order.split('~');
        data = data.slice().sort((a, b) =>{
            let bValue = (b[key] ? b[key] : '').toString();
            let aValue = (a[key] ? a[key] : '').toString();
            if (orders === 'DESC') {
                return bValue.localeCompare(aValue, undefined, {'numeric': true});
            }
            return aValue.localeCompare(bValue, undefined, {'numeric': true})
            }
        )
    }

    const styleRow = {
        cursor: props.onRowClick? 'pointer': 'default',
        ...props.rowStyle
    }

    //
    const itemsLeftCount = data.length - currentLimit;
    data = data.slice(0, currentLimit);

    return <table className={'zol-table'}>
        <UITableHeader order={order} setOrder={setOrder} columns={columns}/>

        <tbody className={'zol-table-body'}>
            {data.length > 0 ?
                data.map((row, index) => (
                    <tr key={index} className={'zol-table-row'} style={styleRow} 
                            onClick={() => ( props.onRowClick? props.onRowClick(row): null )}>
                        {columns.map((column, colIndex) => (
                            <td key={colIndex} className={'zol-table-cell'}
                                    style={{width: column.width, ...column.cellStyle}}>
                                {column.renderCell? 
                                    column.renderCell(column.value(row), row):
                                    column.value(row)
                                }
                            </td>
                        ))}

                    </tr>
                )) :
                <tr>
                    <td colSpan={columns.length} style={{textAlign: 'center'}}>
                        {props.emptyText}
                    </td>
                </tr>
            }
        </tbody>

        {(maxItems) ?
            <tfoot className={'zol-table-footer'}>
                <tr>
                    <td colSpan={columns.length}  style={{textAlign: 'center'}}>

                            {(itemsLeftCount > 0) ?
                                <UIText type={'body-M'} color={'mediumColor'} className={'show-more'}>
                                    <span style={{cursor: 'pointer'}}
                                            onClick={()=>{
                                                setCurrentLimit(currentLimit+itemsLeftCount)
                                            }}>
                                        EXPAND TABLE
                                    </span>
                                </UIText>:
                                
                                null
                            }

                            {(itemsLeftCount === 0 &&
                                    data.length === currentLimit &&
                                    maxItems !== currentLimit) ?
                                <UIText type={'body-M'} color={'mediumColor'} className={'show-more'}>
                                    <span style={{cursor: 'pointer'}}
                                            onClick={()=>{
                                                setCurrentLimit(maxItems)
                                            }}>
                                        SHRINK TABLE
                                    </span>
                                </UIText>:
                                
                                null
                            }
                    </td>
                </tr>
            </tfoot>:
            null
        }
    </table>;
};

UITable.defaultProps = {
    emptyText: 'List is empty.',
    maxItems: 50
}

UITable.propTypes = {
    data: PropTypes.array.isRequired,
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            width: PropTypes.number,
        })
    ).isRequired,
    maxItem: PropTypes.number,
    emptyText: PropTypes.string,
    rowStyle: PropTypes.object,
    onRowClick: PropTypes.func
}

export {UITable};
