import './donut.scss'
import React, {useEffect, useState} from "react";
import {formatNumber} from "../../../helpers/format/number.js";
import {PropTypes} from "prop-types";
import {useSize} from "../../../helpers/use_size.js";
import Transformation from "../../../helpers/math/transformation.js";
import {calculateTotalValue} from "./functions.js";
import {DonutCircle} from "./circle.js";
import {DonutLegend} from "./legend.js";

const styleLegend = {
    width: '45%',
    minWidth: '100px'
};

const ChartDonut = function (props) {
    const [size, measuredRef] = useSize();
    const [transformation, setTransformation] = useState(new Transformation());
    const [hover, setHover] = useState(false);
    const widthFact = 0.5;
    const [donutWidth, setDonutWidth] = useState(1);

    const [metric, setMetric] = useState(props.metrics ? props.metrics[0] : 'value');

    let width = widthFact * size.width;
    let height = size.height;
    const donutDiameter = Math.min(width,height);
    let transformation2 = new Transformation();
    transformation2.setObjectScale({
        x: 0.45 * donutDiameter,
        y: 0.45 * donutDiameter
    })

    const onSegmentHover=(itemTitle, isHover)=> {
        // Set hover state.
        if (isHover) {
            setHover(itemTitle)
        }else {
            setHover(false)
        }
        // call onHover prop.
        if(props.onHover){
            if(isHover){
                props.onHover(props.state.metric, itemTitle);
            }else{
                props.onHover(null);
            }
        }
    };

    useEffect(()=>{
        const donutwidth = Math.min(size.width, size.height, 800);
        let transformationx = transformation;
        transformationx.setObjectScale({
            x: widthFact * 0.45 * donutwidth,
            y: widthFact * 0.45 * donutwidth
        });
        setTransformation(transformationx);
        setDonutWidth(donutwidth);
    }, [size.width, size.height, transformation]);

    // All states
    const state = {
        width,
        hover,
        metric,
        widthFact,
        donutWidth,
        transformation: transformation2
    };

    const transform = new Transformation(0.5 * width, 0.5 * height);

    const matrix = transform.getTransformationString();

    const displayValue = calculateTotalValue(props.data, state);

    return <div className={'donut-container'} ref={measuredRef}>
            <svg width={width} height={height} viewBox={'0 0 ' + width + ' ' + height}>
                <g transform={matrix}>
                    <DonutCircle {...props} displayValue={displayValue} state={state} onSegmentHover={onSegmentHover}/>
                    <text className={'donut-text-center'}>
                        <tspan x="0" y="0">{props.valueInDonut? props.valueInDonut : props.formatValue(displayValue)}</tspan>
                    </text>
                </g>
            </svg>
       
        <div style={styleLegend}>
            <DonutLegend {...props} setMetric={setMetric} displayValue={displayValue} state={state} onSegmentHover={onSegmentHover} />
        </div>
    </div>
};

ChartDonut.defaultProps = {
    metricTitles: {value: 'Value'},
    metrics: ['value'],
    paletteColor: false,
    data: [
        {
            id: 'part1',
            description: 'Part 1',
            // color: '#6b91cb',
            value: 2,
            value2: 2
        },
        {
            id: 'part2',
            description: 'Part 2',
            // color: '#41448c',
            value: 5,
            value2: 2
        },
    ],
    tooltip: (item, formattedValue, percentageValue) => ({
        header: item.description,
        text: [formattedValue +' ('+percentageValue+'%)']
    }),
    formatValue: (value)=>(formatNumber(value, true, 4)),
    onSegmentSelect: false,
    onHover: false
};

ChartDonut.propTypes = {
    metrics: PropTypes.arrayOf(PropTypes.string),
    metricTitles: PropTypes.object
}

export {ChartDonut}