import { getHash, stringify } from './hash.js';


export const memo = function(func, storageLimit=100){
    const memoMap = new Map();

    return function(){
        const callHash = getHash(stringify(arguments));
        if(! memoMap.has(callHash)){
            while(memoMap.size >= storageLimit){
                memoMap.delete(memoMap.keys()[0]);
            }
            memoMap.set(callHash, func.apply(this, arguments));
        }
        return memoMap.get(callHash);
    }

}