import {config} from '../../config.js';
import {ModelXhr} from '../../helpers/model/xhr.js';
import {ModelAuditAnswer} from './answer.js';

class ModelAuditQuestionAnswer extends ModelXhr {

    static modelName = "ModelAuditQuestionAnswer";

    static currentValues = null;

    static getSelect(input) {
        return {
            select: 'answer_id, period_id, period, group_id, answer, status, question_id, question_title, ' +
                    'question_number, question_description',
            filter: 'audit_id eq ' + input.id,
            isPrevious: input.isPrevious || false
        };
    }

    constructor(selectData) {
        super(selectData, {
            endpoint: config.api_endpoint + 'answer',
            method: 'GET'
        });

        this.cacheEnabled = true;
    }

   
    getSelect(input) {
        return ModelAuditQuestionAnswer.getSelect(input);
    }

    getPayload(input){
        const select = {...input};
        delete select.isPrevious;
        return select;
    }

    getDataByQuestionId(){
        const resultSet = this.getData();
        const data = {};
        if(resultSet.status === ModelAuditQuestionAnswer.Status.SUCCESS){
            for(const row of resultSet.data){
                data[row.question_id] = row;
            }

            // Only store values when these are answers of the current period.
            // TODO: This is not very transparent behaviour, look for a better way of dealing with this.
            if(! this.select.isPrevious){
                ModelAuditAnswer.currentValues = data;
            }
        }
        
        return {...resultSet, data: data};
    }


}

export {ModelAuditQuestionAnswer};