import React, {useState} from "react";
import {UIText} from "../../../components/ui/text.js";
import {FormElement} from "../../../helpers/form/element.js";
import {UIButton} from "../../../components/ui/button/button.js";
import {UILinkInternal} from "../../../components/ui/link/internal.js";
import { useForm } from '../../../helpers/form/use_form.js';
import {ModelAuthPasswordRecovery} from '../../../model/auth/password-recovery.js';
import {parseUrl} from '../../../helpers/url-state-context/parse_url.js';
import {UIInputPassword} from '../../../components/ui/input/password.js';
import {isEqual} from '../../../helpers/form/validation.js';

const getResetHash = function(){
    const urlParts = parseUrl(window.location.href);
    return urlParts.query.hash || '';
}

const ViewAuthResetPassword = function (props) {

    const Form = useForm(props.resetForm);
    const [success, setSuccess] = useState(false);
    const [hash, ] = useState(getResetHash());

    const submitForm = () => {
        if(Form.validate()){
            const data = Form.getProcessedValues();
            data.reset_hash = hash;
            ModelAuthPasswordRecovery.updateItem(data,
                () => { setSuccess(true); },
                (error) => { Form.setError('password_confirm', error) }
            );

        }
    }

    if(hash.length !== 64){
        return <div style={{display: 'flex', alignItems: 'center', height: '100%', paddingTop: '71px'}}>
            <div style={{maxWidth: 400, margin: 'auto', width: 400, padding: '0 15px'}}>
                <UIText type={'head-1'} color={'darkColor'} style={{marginBottom: 10}}>
                    Invalid reset url
                </UIText>
                <UIText type={'body-L'} color={'mediumColor'}>
                    The provided reset url is not valid.
                </UIText>
            </div>
        </div>
    }

    // TODO: We need to wrap this styling in a new component
    return <div style={{display: 'flex', alignItems: 'center', height: '100%'}}>
        <div style={{maxWidth: 400, margin: 'auto', width: 400, padding: '0 15px'}}>

            {success?
                <>
                    <UIText type={'head-1'} color={'darkColor'} style={{marginBottom: 10}}>
                        New password saved
                    </UIText>
                    <UIText type={'body-L'} color={'mediumColor'}>
                        We have changed your password. You can now 
                        &nbsp;<a href="/sign-in">login</a> with your new password.
                    </UIText>
                    <UIText type={'body-L'} color={'primaryColor'} style={{padding: 10, textAlign: 'center'}}>
                        <UILinkInternal page="sign-in">Sign in &gt;</UILinkInternal>
                    </UIText>
                </>:
                <>

                    <UIText type={'head-1'} color={'darkColor'} style={{marginBottom: 10}}>
                        Reset password
                    </UIText>

                    <UIText type={'body-L'} color={'mediumColor'}>
                        Please enter a new password.
                    </UIText>

                    <div style={{margin: '25px 0'}}>

                        <Form.Component>
                            {props.resetForm.map((item) => (
                                <FormElement key={item.name} {...item} onEnter={submitForm} />
                            ))}
                        </Form.Component>

                    </div>

                    <UIButton className="primary" style={{width: '100%'}} onClick={submitForm}>
                        Reset password
                    </UIButton>

                </>
            }
        </div>
    </div>
};

ViewAuthResetPassword.defaultProps = {
    resetForm: [
        {
            title: 'New password',
            name: 'password',
            element: UIInputPassword,
            required: true,
            validations: [
                {
                    checkValue: (value) => ( value && value.length >= 8),
                    message: 'The password needs to be at least 8 characters long'
                }
            ],
            type: 'wide'
        },
        {
            title: 'Confirm password',
            name: 'password_confirm',
            element: UIInputPassword,
            required: true,
            validations: [
                {
                    checkValue: (value, allValues) => isEqual(value, allValues.password),
                    message: 'The provided passwords do not match'
                }
            ],
            type: 'wide'
        }
    ]
}

export {ViewAuthResetPassword}