
class Matrix3{


    constructor(r0=[1,0,0], r1=[0,1,0], r2=[0,0,1]){
        if(r0.length === 9){
            r1 = [r0[3],r0[4],r0[5]];
            r2 = [r0[6],r0[7],r0[8]];
            r0 = [r0[0], r0[1], r0[2]];
        }

        this.m = [r0, r1, r2];
    }

    multiplyWith(m){
        let newM = [];
        for(let r = 0; r < 3; r++){
            newM[r] = [];
            for(let c = 0; c < 3; c++){
                let sum = 0;
                for(let i = 0; i < 3; i++){
                    sum += this.m[r][i] * m.m[i][c];
                }
                newM[r].push(sum);
            }
        }
        this.m = newM;
    }

    set(row, col, value){
        this.m[row][col] = value;
    }

    get(row, col){
        return this.m[row][col];
    }

    getMatrix(){
        return this.m;
    }

    multiplyVector(v){
        if(v.length < 3) v.push(1);
        let n = [];
        for(let i = 0; i < 3; i++){
            n.push(v[0] * this.m[i][0] + v[1] * this.m[i][1] + v[2] * this.m[i][2]);
        }
        return n;
    }

    copy(){
        let m = this.m;
        return new Matrix3([m[0][0], m[0][1], m[0][2]],
            [m[1][0], m[1][1], m[1][2]], [m[2][0], m[2][1], m[2][2]]);
    }
}

export default Matrix3;
