import React, { useEffect } from "react";
import "./input.scss";
import PropTypes from "prop-types";
import { useStateContext } from "../../../helpers/state-context";

const UIInputSelect = function (props) {

    const [state, ] = useStateContext(['isExportingPDF']);

    const isObject = props.options instanceof Array? false: true;
    const optionValues = props.options instanceof Array? props.options: Object.keys(props.options);

    const setValue = (value) => {
        if(value !== props.value){
            props.onChange(value);
            props.onSave(value);
        }
        
    }

    // by default select the first value of the list if there is no placeholder defined
    useEffect(() => {
        if(! props.value && ! props.placeholder && optionValues.length > 0){
            // todo: this interferes with setting values form API response. Somehow the reference in
            // the state to the values set by the API response get's lost in this dispatch. Have to fix
            // that first before enabling this again.
            // setValue(optionValues[0]);
        }
        // when the available options have changed, check it the value is still present or else reset it.
        if(!! props.value && props.value.length > 0 && optionValues.length > 0 &&
                ! optionValues.includes(props.value.toString())){
            setValue(optionValues[0]);
        }
    });
    
    // When exporting a select to PDF, the selected value is not maintained. Instead the first option is 
    // always shown in the PDF. So when outputting to PDF we are returning a span instead.
    // TODO: make sure the styling is okay
    if(state.isExportingPDF){
        return <span>{isObject? props.options[props.value]: props.value}</span>;
    }
    return  <select className={`zol-single-select ${props.hasError ? 'zol-single-error' : ''}`}
            value={props.value===null? '': props.value}
            style={props.style}
            onChange={(e)=>setValue(e.target.value)}>

        <option disabled hidden style={{display: 'none'}} value={""}>
            {props.placeholder}
        </option>
        
        {optionValues.map((value, i)=>
            <option key={i} value={value}>
                {isObject? props.options[value]: value}
            </option>
            )}

    </select>
};

UIInputSelect.defaultProps = {
    value: '',
    hasError: false,
    onChange: () => {},
    onSave: () => {},
    placeholder: null
};

UIInputSelect.propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    onChange: PropTypes.func,
    onSave: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    hasError: PropTypes.bool,
    placeholder: PropTypes.string
};


export {UIInputSelect}