import React, {useState} from 'react';
import {ViewSettingsPageHeader} from "./header.js";
import PropTypes from 'prop-types';
import {ModelDashboardMeasureTips} from "../../../../model/dashboard/measure-tips.js";
import {ReactComponent as IconArrowLeft} from '../../../../assets/icons/arrow_left.svg';
import styles from '../../../../assets/variables.scss';
import {UIText} from '../../../../components/ui/text.js';

function saveTip(props) {
    const tip = document.getElementById("tips").value;
    if(tip.length === 0) {
        alert("Please write down some advice for other teams before submitting your recommendation.");
    }else{ 
        ModelDashboardMeasureTips.createItem({
            tip, 
            measure_id: props.id, 
            fk_group_id: props.group
        }, props.closeModal); 
    }
}

function updateTip(props) {
    const tip = document.getElementById("tips").value;
    if(tip.length === 0) {
        alert("Please write down some advice for other teams before submitting your recommendation.");
    }else{ 
        ModelDashboardMeasureTips.updateItem(props.item.id, {
            tip
        }, props.closeModal); 
    }
}

const ViewMeasuresGiveTips = function (props) {
    const [tip, setTip] = useState(props.item?.tip || '');
    return <>
        <UIText type={'body-L-bold'} color={'mediumColor'} style={{cursor: 'pointer', marginRight: 10}}
                onClick={props.onBackClick}>
            <IconArrowLeft style={{color: styles.darkColor, marginRight: 5}}/>Back
        </UIText>
        <ViewSettingsPageHeader {...props}/>
        <div style={{margin: "20px 0"}}>
            <form id="frm1">
                <textarea type="text" 
                        id="tips" 
                        style={{height: '150px', width: '100%'}}
                        value={tip}
                        onChange={(e)=>setTip(e.currentTarget.value)}/>
                <br/>
                <br/>
                <input type="button" 
                        onClick={()=> props.item? updateTip(props): saveTip(props)}
                        value="Submit"/>
            </form>
        </div>
    </>
}

ViewMeasuresGiveTips.propTypes={
    closeModal : PropTypes.func.isRequired
}

export {ViewMeasuresGiveTips}