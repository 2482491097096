import {config} from '../../config.js';
import {ModelXhr} from '../../helpers/model/xhr.js';
import { ModelOrgHierarchy } from '../org-hierarchy.js';

class ModelAuthGroup extends ModelXhr {

    static modelName = "ModelAuthGroup";

    static getSelect(input) {
        return input;
    }

    static createItem(data, onSuccess=()=>{}, onError=()=>{}){
        
        this.saveData([ModelAuthGroup, ModelOrgHierarchy], config.api_endpoint + 'group', 'POST', 
            {
                title: data.title, 
                parent_id: data.parent_id, 
                type: data.type
            },
            (response) => {
                const group = response.result? (response.result[0] || null): null;
                onSuccess(group);
            }
        );
    }

    static updateItem(id, data, onSuccess=()=>{}, onError=()=>{}){
        this.saveData([ModelAuthGroup, ModelOrgHierarchy], config.api_endpoint + 'group/' + id, 'PATCH', 
            {
                title: data.title, 
                parent_id: data.parent_id
            },
            (response) => {
                if(response.success){
                    const group = response.result? (response.result[0] || null): null;
                    onSuccess(group);
                }else{
                    // todo: post status to global message channel
                    console.error(response.error || "Unknow error");
                    onError(response.error);
                }
            }
        );
    }

    static deleteItem(id, onSuccess=()=>{}, onError=()=>{}){
        this.saveData([ModelAuthGroup, ModelOrgHierarchy], config.api_endpoint + 'group/' + id, 'DELETE', 
            null, 
            (response) => {
                if(response.success){
                    const group = response.result? (response.result[0] || null): null;
                    onSuccess(group);
                }else{
                    // todo: post status to global message channel
                    console.error(response.error || "Unknow error");
                    onError(response.error);
                }
            }
        );
    }


    constructor(selectData) {
        super(selectData, {
            endpoint: config.api_endpoint + 'group',
            method: 'GET'
        });

        this.cacheEnabled = false;
    }

    getEndpoint(){
        return this.endpoint + (this.select.id? '/' + this.select.id: '');
    }

    getSelect(input) {
        return ModelAuthGroup.getSelect(input);
    }


}

export {ModelAuthGroup};