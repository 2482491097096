import React from "react";
import {PropTypes} from 'prop-types';
import "./export.scss";
import {UIInputSelect} from "../../../components/ui/input/select.js";
import {CardTitle} from "../../../components/card/title.js";
import {UISwitch} from '../../../components/ui/switch.js';
import {UIInputCheckbox} from "../../../components/ui/input/checkbox.js";
import {Separator} from "../../../components/separator.js";
import {FilterGroup} from "../../../components/filter/group.js";
import { useModelFeedback } from "../../../helpers/model/use_model_feedback.js";
import { ModelAuditPeriod } from "../../../model/audit/period.js";
import { ModelAuthGroup } from "../../../model/auth/group.js";
import {colorPrimary} from '../../../index.scss';

const availableGroupTypes = [
    {
        id: 'team',
        label: 'Show EIP teams'
    },
    {
        id: 'provider',
        label: 'Show EIP providers'
    },
    {
        id: 'period',
        label: 'Show multiple periods'
    }
];

const styleStepBox = {
    width: 'calc(50% - 80px)', 
    display: 'inline-block', 
    verticalAlign: 'top', 
    padding: '40px'
};

const styleSwitch = {
    backgroundColor: '#e2e7fc', 
    border: '5px solid #ffffff', 
    width: '100%', 
    margin: '0 auto'
};

const styleLink = {
    color: colorPrimary, 
    cursor: 'pointer', 
    textDecoration: 'underline'
};

const ViewAdminExportStepOne = function (props) {
       
    const resultSetPeriod = useModelFeedback(ModelAuditPeriod, 
            {select: 'name', order: 'name asc'});
    const periods = resultSetPeriod.feedback? []: resultSetPeriod.data.map((item) => (item.name));

    const resultSetGroup = useModelFeedback(ModelAuthGroup, 
        {select: 'id, title', filter: 'type eq \'SUBREGION\'', order: 'title asc'});
    const availableRegions = {
        0: 'All regions',
    };
    if(! resultSetGroup.feedback){
        resultSetGroup.data.forEach((item) => {
            availableRegions[item.id] = item.title;
        })
    }
    
    const allPeriodsSelected = props.settings.periods.length === periods.length;
    const togglePeriods = () => {
        props.setSettings({
            ...props.settings, 
            periods: allPeriodsSelected? []: periods.slice(0)
        });
    }

    return <div style={styleStepBox}>
        
        <CardTitle step={1} title={'Set up your report'}/>

        <div style={{width: '100%', display: 'inline-block', marginTop: '20px'}}>
            <UISwitch value={props.settings.groupType}
                    style={styleSwitch}
                    data={availableGroupTypes}
                    onChange={(value) => { props.setSettings({...props.settings, groupType: value})} } />
        </div>
        
        <Separator/>

        {props.settings.groupType === 'period'?
            <div>
                <h4>Choose group</h4>
                <FilterGroup optionStyle={{fontSize: '100%'}} />

                <Separator/>
                <h4>Choose periods</h4>
                <p style={{marginTop: '20px', textAlign: 'right'}}>
                    <span style={styleLink}
                            onClick={togglePeriods}>
                        {allPeriodsSelected? 'Deselect': 'Select'} all periods
                    </span>
                </p>

                <UIInputCheckbox name={'periods'} 
                        value={props.settings.periods}
                        options={periods}
                        style={{marginRight: '30px'}}
                        onChange={(values)=>{ props.setSettings({...props.settings, periods: values}) }} />
            </div>:

            <div>
                <h4>Choose region</h4>
                <UIInputSelect
                        options={availableRegions}
                        name={'area-select'}
                        onChange={(value)=>{ props.setSettings({...props.settings, regionId: value}) }}
                        value={props.settings.regionId} />

                <Separator/>
                <h4>Choose a period</h4>
                <UIInputSelect
                        options={periods}
                        name={'period-select'}
                        onChange={(value)=>{ props.setSettings({...props.settings, periods: [value]}) }}
                        value={props.settings.periods[0] || null} />
            </div>
        }
    </div>
}

ViewAdminExportStepOne.propTypes = {
    settings: PropTypes.shape({
        groupType: PropTypes.oneOf(['team','provider','period']).isRequired,
        regionId: PropTypes.any.isRequired,
        periods: PropTypes.array.isRequired,
        sections: PropTypes.array.isRequired
    }),
    setSettings: PropTypes.func.isRequired
}

export {ViewAdminExportStepOne}
