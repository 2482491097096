import React from 'react';
import {ReactComponent as BagSvg} from "../../../assets/icons/shopping-bag-outline.svg";
import {ReactComponent as QuestionSvg} from "../../../assets/icons/question-mark-circle-outline.svg";
import {ReactComponent as CompassSvg} from "../../../assets/icons/compass-outline.svg";
import {ReactComponent as MenuSvg} from "../../../assets/icons/menu.svg";
import {ReactComponent as ClockSvg} from "../../../assets/icons/clock-outline.svg";
import styles from "../../../assets/variables.scss";

const iconStyle={
    width: '60px',
    height: 'auto',
    padding: '25px 20px',
    color : styles.mediumDarkColor
}

const settingsConfig = [
    {
        id: 'questions',
        title: 'Audit questions',
        description: 'Edit the questions and descriptions of the audit',
        icon: <QuestionSvg style={iconStyle}/>
    },
    {
        id: 'indicators',
        title: 'Indicators',
        description: 'Organize and edit indicators. This includes setting up thresholds for their scores.',
        icon: <CompassSvg style={iconStyle}/>
    },
    {
        id: 'graphs',
        title: 'Graphs',
        description: 'Add descriptions or change features to the graphs that appear in the dashboard.',
        icon: <BagSvg style={iconStyle}/>,
        component: <div>Graphs</div>
    },
    {
        id: 'paragraphs',
        title: 'Paragraphs',
        description: 'Edit content that supplements sections, such as footnotes and popups.',
        icon: <MenuSvg style={iconStyle}/>,
        component: <div>Paragraphs</div>
    },
    {
        id: 'periods',
        title: 'Periods',
        description: 'Manage periods',
        icon: <ClockSvg style={iconStyle}/>
    }
];

export {settingsConfig}