import React from "react";
import {useModelFeedback} from "../../../helpers/model/use_model_feedback.js";
import {ModelAuditIndicator} from '../../../model/audit/indicator.js';
import {ModelAuditIndicatorRatio} from '../../../model/audit/indicator-ratio.js';
import "./measures.scss";
import {useStateContext} from "../../../helpers/state-context.js";
import {ViewMeasureItem} from './page/measure.js';

const indicatorsIncluded = [];
for(let i = 1; i < 17; i++){
    indicatorsIncluded.push(i);
}

const ViewDashboardMeasures = function (props) {
    const [state, ] = useStateContext(['period', 'group']);

    // getting the text of the indicators (we don't want to repeat all the text for the data point of each
    // team)
    const resultSetIndicator = useModelFeedback(ModelAuditIndicator);

    // getting the values of the indicators
    const resultSetRatio = useModelFeedback(ModelAuditIndicatorRatio, 
            {
                period: state.period,
                select: 'id, value, team_id, provider_id',
                ids: indicatorsIncluded
            },
            (model) => model.getRelativeGroupScores(state.group, resultSetIndicator),
            state.group);

    if(resultSetIndicator.feedback || resultSetRatio.feedback){
        return resultSetIndicator.feedback || resultSetRatio.feedback;
    }

    return Object.keys(resultSetRatio.data).map((indicatorId) => {
        const indicator = resultSetRatio.data[indicatorId];
        const z = indicator.zvalue;
        const posistion = z < -1? 'worst': (z < -0.7? 'bad': (z > 1? 'best': 'good'));

        return <ViewMeasureItem key={indicatorId} 
                title={indicator.title || 'Measuere ' + indicatorId} 
                id={indicatorId} 
                position={posistion} 
                information={indicator.description || ''} /> ;
    });

}

export {ViewDashboardMeasures}