import React from "react";
import {getColorPalette, getSectionPalette} from "../../../helpers/color.js";
import DonutCircleSegment from "./circle-segment.js";
import {getPercentage} from "./functions.js";

const DonutCircle = function (props) {
    const {paletteColor} = props;
    const percSpacing = 0.0;
    let totalSpacing = props.data.length * percSpacing;
    let hasNegatives = false;
    let absTotal = 0;
    for (const item of props.data) {
        if (item[props.state.metric] < 0) {
            hasNegatives = true;
        }
        absTotal += Math.abs(item[props.state.metric]);
    }
    if (hasNegatives) {
        totalSpacing += 0.06;
    }

    const segments = [];

    const colors = (paletteColor) ? getSectionPalette(paletteColor, props.data.length) : getColorPalette(props.data.length);
    let currentAngle = 0.5 * percSpacing;
    let currentNegAngle = -0.5 * percSpacing - 0.03;

    for (const item of props.data) {
        const opacity = props.state.hover &&
        props.state.hover !== item.description ? 0.5 : 1;

        let percentage = absTotal === 0 ? 0 : Math.abs(item[props.state.metric]) / absTotal;
        percentage = percentage * (1 - totalSpacing);

        let angle = currentAngle;
        if (item[props.state.metric] < 0) {
            angle = currentNegAngle - percentage;
            currentNegAngle -= percentage + percSpacing;
        } else {
            currentAngle += percentage + percSpacing;
        }

        segments.push(<DonutCircleSegment
            key={item.id || item.description}
            id={item.id || item.description}
            title={item.description}
            style={{
                fill: (paletteColor) ? colors[segments.length] : 'rgb(' + colors[segments.length].join(',') + ')',
                opacity: opacity,
            }}
            percentage={percentage}
            startAt={angle}
            radius={(props.state.hover === item.description)? 1.05 : item[props.state.metric] < 0 ? 1 : 1}
            tooltip={props.tooltip(item, props.formatValue(item[props.state.metric]), getPercentage(item[props.state.metric], props.displayValue))}
            center={{
                x: 0.45 * props.state.widthFact * props.state.donutWidth,
                y: 0.45 * props.state.widthFact * Math.max(props.state.donutWidth, 400)
            }}
            onClick={props.onSegmentSelect}
            callback={props.onSegmentHover}/>);

    }


    if (segments.length === 0) {
        segments.push(<DonutCircleSegment
            key="no-data"
            name="no-data"
            title="No data"
            style={{
                fill: '#999999',
                opacity: 1,
            }}
            percentage={1 * (1 - totalSpacing)}
            startAt={currentAngle}
            radius={1}
            tooltip={false}
            center={{
                x: 0.45 * props.state.widthFact * props.state.donutWidth,
                y: 0.45 * props.state.widthFact * Math.max(props.state.donutWidth, 400)
            }}
        />);
    }

    const transformationMatrix = props.state.transformation.getTransformationString();

    return <g transform={transformationMatrix}>
        {segments.map((item) => item)}
    </g>;
};

export {DonutCircle}