import React from "react";
import {PropTypes} from "prop-types";
import {ReactComponent as ArrowRightSvg} from "../../../assets/icons/arrow_right.svg";
import styles from "../../../assets/variables.scss";
import {UIText} from "../text.js";

const filterButtonStyle = {
    margin: '15px 0',
    backgroundColor: '#fff',
    padding: '20px 15px',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexDirection: 'column',
    cursor: 'pointer',
    position:'relative'
};


const UIButtonPaper = function (props) {
    const {titleProps, descProps, iconStyle} = props;
    const styleIcon = {
        color: styles.darkColor, 
        ...iconStyle, 
        display: 'inline-block',
        verticalAlign: 'middle',
        marginLeft: '10px'
    };

    return <div style={{...filterButtonStyle, ...props.cardStyle}} onClick={props.onClick}>
        {props.icon}
        <div style={{display: 'inline-block', width: '100%'}}>
            
            <UIText type={props.titleType} color={'darkColor'} style={titleProps}>
                {props.title}
                <ArrowRightSvg style={styleIcon}/>
            </UIText>
            {(props.description) ?
                <UIText type={'body-M'} color={'mediumColor'} style={descProps}>{props.description}</UIText> :
                null
            }
        </div>
    </div>
};

UIButtonPaper.defaultProps = {
    titleType: 'body-L',
    title: 'Change indicators',
    onClick: () => {},
    style: {},
    iconStyle: {},
    descProps: {},
    titleProps: {}
};

UIButtonPaper.propTypes = {
    titleType: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    icon: PropTypes.element,
    onClick: PropTypes.func,
    style: PropTypes.object,
    iconStyle: PropTypes.object,
    descProps: PropTypes.object,
    titleProps: PropTypes.object
};

export {UIButtonPaper}