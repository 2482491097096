import PropTypes from 'prop-types';
import React from "react";
import styles from '../../../assets/variables.scss'
import './input.scss';

const UIInputRadio = function (props) {

    const options = props.options instanceof Array? props.options: Object.keys(props.options);

    const setValue = (value) => {
        props.onChange(value);
        props.onSave(value);
    }

    return <div className={'zol-single-radio'}>

        {options.map((option)=>{
            const label = props.options instanceof Array? option: props.options[option];

            return <label key={option}> 

                <input type="radio" value={option} name={props.name} checked={option === props.value} 
                        onChange={(e)=>setValue(e.target.value)} />

                <span className={'zol-radio-button'} style={{
                            backgroundColor: (option === props.value) ? 
                                    styles.primaryColor : styles.offWhiteColor,
                            color: (option === props.value) ? styles.whiteColor : styles.primaryColor
                        }}>
                    {label}
                </span>

            </label>
        })}
    </div>
};


UIInputRadio.defaultValues = {
    value: null,
    hasError: false,
    onChange: () => {},
    onSave: () => {},
};

UIInputRadio.propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    onChange: PropTypes.func,
    onSave: PropTypes.func,
    value: PropTypes.string,
    hasError: PropTypes.bool,
};

export {UIInputRadio}