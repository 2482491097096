import React from "react";
import PropTypes from 'prop-types';
import {UIText} from "../text.js";
import {UITableSort} from "./sort.js";

const UITableHeader = function (props) {
    const {columns, order, setOrder} = props;
    return <thead>
        <tr className={'zol-table-header'}>
            {columns.map((column, colIndex) => (
                <th key={colIndex}
                        className={'zol-table-header-item'}
                        style={{width: column.width, ...column.headerStyle}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        {(column.title && typeof column.title === 'string') ?
                                <UIText type={'body-M'} color={'mediumColor'}>
                                    {column.title.toUpperCase()}
                                </UIText> :
                                column.title
                        }
                        {column.sort ?
                            <UITableSort sort={column.sort}
                                         id={column.id}
                                         order={order}
                                         onSelect={(value)=>setOrder(value)}
                            /> :
                            null
                        }
                    </div>
                </th>
            ))}

        </tr>
    </thead>
};

UITableHeader.propTypes = {
    order: PropTypes.string,
    setOrder: PropTypes.func,
    columns: PropTypes.arrayOf(PropTypes.object)
};

export {UITableHeader}