import React, {useState} from "react";
import {UIText} from "../../../components/ui/text.js";
import {UIProgressSteps} from "../../../components/ui/progress-steps/progress-steps.js";
import {ViewAuthSignUpStep} from "./sign-up-step.js";
import {useForm} from "../../../helpers/form/use_form.js";
import {isNotEmpty, isEqual, isEmail} from '../../../helpers/form/validation.js';
import {useModel} from '../../../helpers/model/use_model.js';
import {ModelAuthRole} from '../../../model/auth/role.js';
import {ModelAuthUser} from '../../../model/auth/user.js';
import {ModelOrgHierarchy} from '../../../model/org-hierarchy.js';
import {UIInput} from "../../../components/ui/input/input.js";
import {UIInputPassword} from "../../../components/ui/input/password.js";
import {UIInputSelect} from "../../../components/ui/input/select.js";
import {UIInputRadio} from "../../../components/ui/input/radio.js";
import {UIInputCheckbox} from "../../../components/ui/input/checkbox.js";
import {UILinkInternal} from "../../../components/ui/link/internal.js";
import {useStateContext} from '../../../helpers/state-context.js';

const getFormElements = function(steps){
    const elements = [];
    for(const step of steps){
        if(step.content){
            elements.push(...step.content);
        }
    }
    return elements;
};

const ViewAuthSignUp = function (props) {

    // setting up filter values for the org hierarchy selection
    const [filter, setFilter] = useState({region: null, ccg: null, provider: null, team: null});
    // get a new form based on the configuration given in the props.
    const Form = useForm(getFormElements(props.steps));

    const [,dispatch] = useStateContext(['page']);

    // Fetch the available roles and groups from the models
    const resultSetRole = useModel(ModelAuthRole, {filter: 'id ne 1'});
    const orgFields = {region: {}, ccg: {}, provider: {}, team: {}};
    const currentFilter = {};    
    for(const field of Object.keys(orgFields)){
        // Disabling react rule. This loop will always run the fix length of orgFields, so the order of hook
        // call is guaranteed.
        // eslint-disable-next-line react-hooks/rules-of-hooks
        orgFields[field] = useModel(ModelOrgHierarchy, {type: field, ...currentFilter});
        // add the filter of the parent in the hierarchy to use when selecting the next level
        if(field !== 'ccg'){
            currentFilter[field] = filter[field];
        }
    }

    // We can directly update the options in the question, because form maintains a reference to this object 
    // too. Do note that this directly modifies the defaultProps wich impacts other components too. This is
    // okay here, because we will always load new options before use and never have two instances at the same
    // time
    for(let question of props.steps[1].content){
        if(question.name === 'role'){
            question.options = resultSetRole.status === 'success'? resultSetRole.data: [];
        }else if(Object.keys(orgFields).includes(question.name)){
            question.options = orgFields[question.name].status === 'success'? 
                    orgFields[question.name].data: [];
        }
    }

    // check form values and see if we need to update the org hierarchy model
    // this method is called whenever a form value changes
    const updateValues = (values) => {
        let newFilter = {};
        for(const f of Object.keys(filter)){
            if(values[f] !== filter[f]){
                newFilter[f] = values[f];
            }
        }
        if(values.role !== Form.getValue('role')){
             // reset filter when changing the role
            newFilter = {region: filter.region || null, ccg: null, provider: null, team: null};
        }
        if(Object.keys(newFilter).length > 0){
            setFilter({...filter, ...newFilter});
        }
    }

    // this method is called from the UIProgressSteps to check if a step is completed or not
    const validateStep = (step) => {
        const subset = step.content.map((item) => item.name);
        return Form.validate(subset);
    }

    // this method is called from the UIProgressSteps when the final step was completed
    const submitForm = () => {
        // TODO: creating a user takes some time, because we need to send emails. Would be good to show
        // some kind of loader to give the user feedback that something is happening after he/she pressed
        // the button.
        ModelAuthUser.createItem(Form.getValues(),
            (user) => {
                // alert('User ' + user.name + ' created!');
                dispatch({type: 'page', value: 'confirm-sign-up'});
            },
            (error) => {
                Form.setError('agree_statement', error);
            }
        );
    }

    return <div style={{display: 'flex', alignItems: 'center', height: '100%', paddingTop: '71px'}}>
        <div style={{maxWidth: 400, margin: 'auto', width: 400, padding: '0 15px'}}>
            <UIText type={'head-1'} color={'darkColor'} style={{marginBottom: 10}}>
                Sign up
            </UIText>
            <UIText type={'body-L'} color={'mediumColor'}>
                Sign up to EIP Matrix in only 2 steps.
            </UIText>

            <Form.Component onChange={updateValues}>

                <UIProgressSteps steps={props.steps} validateStep={validateStep} startIndex={0}
                        onFinish={submitForm}>
                    {(stepContent) => (
                        <ViewAuthSignUpStep formConfig={stepContent} />
                    )}
                </UIProgressSteps>

            </Form.Component>

            <UIText type={'body-L'} color={'mediumColor'} style={{padding: 10, textAlign: 'center'}}>
                Already have an account?&nbsp;
                <UILinkInternal page="sign-in">Sign in &gt;</UILinkInternal>
            </UIText>

        </div>
    </div>;

};

ViewAuthSignUp.defaultProps = {
    steps: [
        {
            id: 1,
            title: '1/2 Your credentials',
            content: [
                {
                    title: 'Full name',
                    name: 'name',
                    element: UIInput,
                    required: true,
                    type: 'wide'
                },
                {
                    title: 'Email address',
                    name: 'email',
                    element: UIInput,
                    required: true,
                    validations: [
                        {
                            checkValue: isEmail,
                            message: 'The provided email address is invalid'
                        }
                    ],
                    type: 'wide'
                },
                {
                    title: 'Password',
                    name: 'password',
                    element: UIInputPassword,
                    required: true,
                    validations: [
                        {
                            checkValue: (value) => ( value && value.length >= 8),
                            message: 'The password needs to be at least 8 characters long'
                        }
                    ],
                    type: 'wide'
                },
                {
                    title: 'Confirm password',
                    name: 'password_confirm',
                    element: UIInputPassword,
                    required: true,
                    validations: [
                        {
                            checkValue: (value, allValues) => isEqual(value, allValues.password),
                            message: 'The provided passwords do not match'
                        }
                    ],
                    type: 'wide'
                }
            ]
        },
        {
            id: 2,
            title: '2/2 Your region and role',
            content: [
                {
                    title: 'Role',
                    // Options are inserted from the model
                    options: [],
                    name: 'role',
                    element: UIInputSelect,
                    required: true,
                    type: 'wide'
                },
                {
                    title: 'Region',
                    // Options are inserted from the model
                    options: [],
                    name: 'region',
                    element: UIInputRadio,
                    type: 'wide',
                    validations: [
                        {
                            checkValue: (value, values) => (isNotEmpty(value) ),
                            message: 'Please select your region'
                        }
                    ],
                },
                {
                    title: 'Clinical Commissioning Group (CCG)',
                    conditions: [
                        // activate field if role is Commissioning Manager (3)
                        (values) => ( values.role === '3' )
                    ],
                    // Options are inserted from the model
                    options: [],
                    name: 'ccg',
                    element: UIInputSelect,
                    validations: [
                        {
                            // This field is only required if role is Commissioning Manager (3)
                            checkValue: (value, values) => ( values.role !== '3' || isNotEmpty(value) ),
                            message: 'Please select your Clinical Commissioning Group (CCG)'
                        }
                    ],
                    type: 'wide'
                },
                {
                    title: 'Provider name',
                    conditions: [
                        // activate field if role is Provider Director (4) or EIP Team lead (5)
                        (values) => ( ['4','5'].includes(values.role) )
                    ],
                    // Options are inserted from the model
                    options: [],
                    name: 'provider',
                    element: UIInputSelect,
                    validations: [
                        {
                            // This field is only required if role is Provicer (4) or EIP Team lead (5)
                            checkValue: (value, values) => (
                                ! ['4','5'].includes(values.role) || isNotEmpty(value) 
                            ),
                            message: 'Please select your provider'
                        }
                    ],
                    type: 'wide'
                },
                {
                    title: 'EIP Team',
                    conditions: [
                        // active field if role is EIP Team lead (5) and user already selected a provider
                        (values) => ( values.role === '5' && isNotEmpty(values.provider) )
                    ],
                    // Options are inserted from the model
                    options: [],
                    name: 'team',
                    element: UIInputSelect,
                    validations: [
                        {
                            // This field is only required if role is EIP Team lead (5)
                            checkValue: (value, values) => ( 
                                ! ['5'].includes(values.role) || isNotEmpty(value) 
                            ),
                            message: 'Please select your team'
                        }
                    ],
                    type: 'wide'
                },
                {
                    title: 'Terms and conditions',
                    options: {
                        'agreement': 'I have read and acknowledge the above statement'
                    },
                    required: true,
                    name: 'agree_statement',
                    element: UIInputCheckbox,
                    type: 'wide'
                }
            ]
        }
    ]
}

export {ViewAuthSignUp}