import React, {useState} from "react";
import { UrlStateContext } from "../../../../helpers/url-state-context/url-state-context.js";
import { StateConsumer } from "../../../../helpers/state-context.js";
import { IndicatorList} from './list.js';
import {IndicatorEdit} from './edit.js';
import {AuthAccessValidator} from '../../../../components/auth/access-validator.js';
import {categories} from './settings.js';
import {AdminComponentSideBar} from '../../component/side-bar.js';

const ViewSettingsIndicatorIndex = function (props) {
    
    const [section, setSection] = useState(categories[Object.keys(categories)[0]]);
    
    return <UrlStateContext path={['view', 'id']} initialState={{view: 'list', id: null}} >
        <StateConsumer watch={['view', 'id']}>
            {(state, ) => {
                if(state.view === 'edit' || state.view === 'new'){
                    return <>
                        <AuthAccessValidator showError={true}
                                validateUser={(user) => (user.permissions.includes('manage_indicators'))}>
                            <IndicatorEdit indicatorId={parseInt(state.id)} />
                        </AuthAccessValidator>

                    </>;

                }else{
                    return <>
                        <AuthAccessValidator showError={true}
                                validateUser={(user) => (user.permissions.includes('manage_indicators'))}>

                            <div style={{padding: '0 15px'}}>
                                <IndicatorList section={section} {...props}/>
                            
                                <AdminComponentSideBar value={section} onChange={setSection} 
                                        options={categories} />
                            </div>
                        
                        </AuthAccessValidator>
                    </>
                }
            }}

        </StateConsumer>

    </UrlStateContext>;

}

export {ViewSettingsIndicatorIndex};