//Examle validations
// Predefined validation
//     {
//         checkValue: isNumber,
//         message: 'Value is not a number.'
//     }

// TODO: Check if we can make this more pretty.
// Predefined function validation
//     {
//         checkValue: (value) => isBetween(value, [0, 5]),
//         message: 'Value is not between 0 and 10'
//     },

// Custom validation
//     {
//         checkValue: (value) => (value >= 0 && value <=10),
//         message: 'Value is not between 0 and 10'
//     }

const validateFormElement = (element, formValues) => {
    const errors = [];
    const validations = element.validations? element.validations.slice(): [];
    if(element.required){
        validations.push({
            checkValue: isNotEmpty,
            message: 'This field is required, but no value was provided'
        });
    }
    for(const validation of validations){
        if(! validation.checkValue(formValues[element.name], formValues)){
            errors.push(validation.message);
        }
    }
    return errors;
}

const isEmail = function(value){
    // Check if email has '@' and a . after
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(value)
}

// Predefined validations
const isBetween = function(value, range){
    // Check if value is between range
    return parseInt(value) >= range[0] && parseInt(value) <= range[1]
};

const isNotEmpty = function(value){
    return value !== undefined && value !== null && value !== '' && 
            ! (value instanceof Array && value.length === 0);
};

const isNumber = function(value){
    return ! isNaN(value);
};

const isMin = function(value, min){
    
    // Check minimum of value
    return ! isNotEmpty(value) || parseInt(value) >= parseInt(min)
};

const isMax = function(value, max){
    // Check maximum of value
    return ! isNotEmpty(value) || parseInt(value) <= parseInt(max)
};

const isNotEqual = function(value, secondValue){
    // Check if value is not same as secondValue
    return secondValue !== value;
};

const isEqual = function(value, secondValue){
    return secondValue === value;
}

const isLength = (value, length) => {
    // Check length of value
    return value.length === length
};

const isWithinLength = (value, maxLength) => {
    // Check length of value
    return value.length <= maxLength
};

const isAboveLength = (value, minLength) => {
    return value.length > minLength
};

export {
    validateFormElement, isBetween, isNumber,isMin, isMax, isLength, 
    isNotEmpty, isNotEqual, isEqual, isEmail, isWithinLength,
    isAboveLength
}
