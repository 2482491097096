// this is definitely not a complete implementation for cloning an object
export let cloneObject = function (value, depth = 0) {
    if (value != null && value.constructor && value.constructor === Object) {
        let newValue = {};
        for (let property of Object.keys(value)) {
            // properties that start with "_recursion_" refer to itself and should
            // not be cloned.
            // todo: not the best solution
            if (property.length > 11 && property.substring(0, 11) === '_recursion_') {
                newValue[property] = value[property];
            } else {
                newValue[property] = cloneObject(value[property], depth + 1);
            }
        }
        return newValue;
        //return createObjectFromDefault({}, value);
    } else if (value instanceof Array) {
        let newValue = [];
        for (let i = 0; i < value.length; i++) {
            newValue.push(cloneObject(value[i], depth + 1));
        }
        return newValue;
    } else if (value instanceof Set) {
        return new Set(value);
    } else if (value instanceof Map) {
        return new Map(value);
    }

    return value;
}


export let createObjectFromDefault = function (object, defaults, clone = true) {

    if (defaults == null) {
        defaults = {};
    }
    // note that Object.assign does not provide a deep clone
    //let newObject = Object.assign({}, defaults);
    let newObject = defaults;
    if (clone) {
        newObject = cloneObject(defaults);
    }

    if (object == null || typeof object != 'object') {
        return newObject;
    }

    for (let property of Object.keys(object)) {
        if (object[property] && object[property].constructor &&
            object[property].constructor === Object) {

            newObject[property] = newObject[property] || {};
            // properties that start with "_recursion_" refer to itself and should
            // not be cloned.
            // todo: not the best solution
            if (property.length > 11 && property.substring(0, 11) === '_recursion_') {
                newObject[property] = object[property];
            } else {
                newObject[property] = createObjectFromDefault(object[property],
                    newObject[property], false);
            }
        } else if (object[property] instanceof Array) {
            newObject[property] = [];
            for (let i = 0; i < object[property].length; i++) {
                if (object[property][i] && object[property][i].constructor &&
                    object[property][i].constructor === Object) {
                    newObject[property].push(createObjectFromDefault({}, object[property][i], false));
                } else if (object[property][i] instanceof Array) {
                    let obj = createObjectFromDefault({}, {tmp: object[property][i]}, false);
                    newObject[property].push(obj.tmp);
                } else {
                    newObject[property].push(object[property][i]);
                }
            }
        } else {
            newObject[property] = object[property];
        }
    }
    return newObject;
}
export let extend = createObjectFromDefault;