import React from "react";
import PropTypes from 'prop-types';

const VerticalBarLines = function (props) {
    const {scale, color, lines, width} = props;
    
    const allLines = [];
    for(const [key, line] of lines.entries()){
        allLines.push(
            <line key={key}
                    strokeWidth={1.5}
                    stroke={line.color || color}
                    x1={-6}
                    x2={width - 49}
                    y1={scale.y(line.value)}
                    y2={scale.y(line.value)}/>
        )
    }

    return <g>
        {allLines.map((line=>line))}
    </g>
};

VerticalBarLines.defaultProps = {};

VerticalBarLines.propTypes = {
    lines: PropTypes.arrayOf(PropTypes.object),
    color: PropTypes.string,
    width: PropTypes.number,
    scale: PropTypes.object
};

export {VerticalBarLines}