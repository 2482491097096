import React from "react";
import "./export.scss";
import {CardTitle} from "../../../components/card/title.js";
import {UIInputCheckbox} from "../../../components/ui/input/checkbox.js";
import {Separator} from "../../../components/separator.js";
import {colorPrimary} from '../../../index.scss';


const styleStepBox = {
    width: 'calc(50% - 80px)', 
    display: 'inline-block', 
    verticalAlign: 'top', 
    padding: '40px'
};

const styleLink = {
    color: colorPrimary, 
    cursor: 'pointer', 
    textDecoration: 'underline'
};

const styleCheckBox = {
    border: '1px transparent solid', 
    display: 'inline-block', 
    marginRight: '10px', 
    width: '45%'
};

const ViewAdminExportStepTwo = function (props) {

    const sections = {
        'Q': 'About your EIP team',
        'N': 'NICE concordance',
        'O': 'Outcomes',
        'W': 'Workforce',
        'I': 'Indicators'
    };

    const allIndicatorsSelected = props.settings.sections.length === Object.keys(sections).length;
    const toggleIndicators = () => {
        props.setSettings({
            ...props.settings, 
            sections: allIndicatorsSelected? []: Object.keys(sections)
        });
    }
    
    return <div style={styleStepBox}>
        <CardTitle step={2} title={'Select indicators to include'}/>
        
        <p style={{marginTop: '20px', textAlign: 'right'}}>
            <span style={styleLink}
                    onClick={toggleIndicators}>
                {allIndicatorsSelected? 'Deselect': 'Select'} all indicators
            </span>
        </p>

        <Separator/>
        <UIInputCheckbox name={'indicators'}
                options={sections} 
                value={props.settings.sections}
                style={styleCheckBox}
                onChange={(value) => { props.setSettings({...props.settings, sections: value}) }} />
    </div>
}

export {ViewAdminExportStepTwo}
