import React from "react";
import {DashboardCardBreakdownByAge} from "./cards/breakdown-by-age.js";
import {DashboardCardInvestmentVariation} from "./cards/investment-variation.js";
import {DashboardCardFep} from "./cards/fep.js";
import {DashboardCardWorkforce} from "./cards/workforce.js";
import {DashboardCardNiceComparison} from "./cards/nice-comparison.js";
import {DashboardCardNiceInterventions} from "./cards/nice-interventions.js";
import {DashboardCardNiceOverview} from "./cards/nice-overview.js";
import {DashboardCardNiceProgress} from "./cards/nice-progress.js";
import {DashboardCardNumber} from "./cards/number.js";
import {DashboardCardOutcomeComparision} from "./cards/outcome-comparision.js";
import {DashboardCardOutcomeDetails} from "./cards/outcome-details.js";
import {DashboardCardOutcomeOverviewAdmission} from "./cards/outcome-overview-admission.js";
import { DashboardCardOutcomeOverviewCaseload } from "./cards/outcome-overview-caseload.js";
import {DashboardCardWorkForceOverview} from "./cards/workforce-overview.js";
import {DashboardCardFepPredictedPrevalence} from "./cards/fep-predicted-prevalence.js";
import {DashboardCardMeasure} from "./cards/measure.js";

// using object with the id as a key makes it easier to find the card
const cardsConfig = {
    1: {id:1, component: <DashboardCardNumber id={1} title={'FEP'} question_id={5}/>},
    2: {id:2, component: <DashboardCardNumber id={2} title={'ARMS'} question_id={21}/>},
    3: {id:3, component: <DashboardCardBreakdownByAge id={3} question_ids={[6,84,7,8]}/>},
    4: {id:4, component: <DashboardCardBreakdownByAge id={4} question_ids={[22, 85, 23, 24]}/> },
    5: {id:5, component: <DashboardCardWorkForceOverview id={5} /> },
    6: {id:6, component: <DashboardCardMeasure id={6}/> },
    7: {id:7, component: <DashboardCardFep id={7}/> },
    8: {id:8, component: <DashboardCardFepPredictedPrevalence id={8}/> },
    9: {id:9, component: <DashboardCardNiceOverview id={9}/> },
    10: {id:10, component: <DashboardCardNiceInterventions id={10}/> },
    11: {id:11, component: <DashboardCardOutcomeDetails question={'all'} id={11}/> },
    12: {id:12, component: <DashboardCardInvestmentVariation id={12}/> },
    13: {id:13, component: <DashboardCardWorkforce id={13}/> },
    14: {id:14, component: <DashboardCardNiceProgress id={14}/> },
    15: {id:15, component: <DashboardCardOutcomeComparision id={15}/> },
    16: {id:16, component: <DashboardCardOutcomeOverviewAdmission id={16}/> },
    17:{id:17, component: <DashboardCardNiceComparison id={17}/> },
    18: {id:18, component: <DashboardCardOutcomeOverviewCaseload id={18}/> }
};


export {cardsConfig}