import {config} from '../../config.js';
import {ModelXhr} from '../../helpers/model/xhr.js';

class ModelDashboardExpectedCaseload extends ModelXhr {

    static modelName = "ModelDashboardExpectedCaseload";

    static getSelect(input) {
        let select = {};
        const filter = [];

        if (input.group_id){
            filter.push('group_id eq ' + input.group_id);
        }

        select = {
            // we should not select group_id or title here, because we may want to group multiple teams
            // selecting the id/title will segregate the value again for each team. If we need a list of
            // caseloads by team, we should specify this specifically in the selectionCriteria (byTeam=true).
            select: input.select? input.select : 'sum(expected_caseload) as value' + 
                    (input.byTeam? ', group_id, EIP_team': ''),
            filter: filter.join(' and ')
        }
        return select;
    }

    constructor(selectData) {
        super(selectData, {
            endpoint: config.api_endpoint + 'expected_caseload',
            method: 'GET'
        });

        this.cacheEnabled = true;
    }
   
    getSelect(input) {
        return ModelDashboardExpectedCaseload.getSelect(input);
    }
}

export {ModelDashboardExpectedCaseload};
