import {config} from '../../config.js';
import {ModelXhr} from '../../helpers/model/xhr.js';
import {ModelAuthUserList} from './user-list.js';

class ModelAuthUser extends ModelXhr {

    static modelName = "ModelAuthUser";

    static getSelect(input) {
        return input;
    }

    static createItem(data, onSuccess=()=>{}, onError=()=>{}){
        // asign the right group to the user
        let type = 'team';
        if(data.role === '2'){
            type = 'region';
        }else if(data.role === '3'){
            type = 'ccg';
        }else if(data.role === '4'){
            type = 'provider';
        }
        
        // when role is administrator the group is 1: administrators
        const groupId = data.role === '1'? '1': data[type] || data.group;
        
        const userData = {
            name: data.name,
            email: data.email,
            password: data.password,
            role: data.role,
            group: groupId
        };
        if(data.status){
            userData.status = data.status;
        }
        this.saveData([ModelAuthUser, ModelAuthUserList], config.api_endpoint + 'user', 'POST', 
            userData, 
            (response) => {
                if(response.success){
                    const user = response.result? (response.result[0] || null): null;
                    onSuccess(user);
                }else{
                    onError(response.error || 'Unknown error');
                }
            }
        );
    }

    static updateItem(id, data, onSuccess=()=>{}, onError=()=>{}){
        this.saveData([ModelAuthUser, ModelAuthUserList], config.api_endpoint + 'user/' + id, 'PATCH', 
            data, 
            (response) => {
                if(response.success){
                    const user = response.result? (response.result[0] || null): null;
                    onSuccess(user);
                }else{
                    // todo: post status to global message channel
                    const error = response.error || "Unknow error";
                    console.error(error);
                    onError(error);
                }
            }
        );
    }

    static deleteItem(id, onSuccess=()=>{}){
        this.saveData([ModelAuthUser, ModelAuthUserList], config.api_endpoint + 'user/' + id, 'DELETE', 
            null, 
            (response) => {
                if(response.success){
                    const user = response.result? (response.result[0] || null): null;
                    onSuccess(user);
                }else{
                    // todo: post status to global message channel
                    console.error(response.error || "Unknow error");
                }
            }
        );
    }


    constructor(selectData) {
        super(selectData, {
            endpoint: config.api_endpoint + 'user',
            method: 'GET',
        });

        this.cacheEnabled = false;
    }

    getEndpoint(){
        return this.endpoint + (this.select.id? '/' + this.select.id: '');
    }

    getSelect(input) {
        return ModelAuthUser.getSelect(input);
    }

    getIsAdmin(){
        if(this.status === ModelAuthUser.Status.SUCCESS){
            const roles = this.data[0]?.roles || [];
            return {status: this.status, isAdmin: roles.includes(1)};
        }
        return {status: this.status, isAdmin: false};
    }
}

export {ModelAuthUser};