import React, {useState} from "react";
import {Card} from "../../../components/card/card.js";
import {CardHeader} from "../../../components/card/header.js";
import { useStateContext } from '../../../helpers/state-context';
import {ChartProgressCircle} from "../../../components/chart/progress-circle/progress-circle.js";
import {useModelFeedback} from "../../../helpers/model/use_model_feedback.js";
import {ModelDashboardAnswerDistribution} from "../../../model/dashboard/answer-distribution.js";
import {ChartLegend} from "../../../components/chart/legend.js";
import {getColorPalette} from "../../../helpers/color.js";
import {UIInputSelect } from "../../../components/ui/input/select";

function getQuestionOptions(){
    return [
        {
            question_ids: [5, 25, 26], 
            title: 'CBTp offered/delivered', 
            metricTitles:['FEP count','CBTp offered','2 sessions or more']
        },
        {
            question_ids: [5, 27, 28, 29], 
            title: 'Family interventions offered/delivered', 
            metricTitles:[
                'FEP count', 
                'In contact with their family',
                'Family interventions offered', 
                '2 or more sessions'
            ]
        },
        {
            question_ids: [5, 37], 
            title: 'Physical Health',
            metricTitles:['FEP count','All physical health checks']
        },
        {
            question_ids: [5, 34, 35, 36], 
            title: 'Vocation and Family Support', 
            metricTitles:[
                'FEP count', 
                'Not in employment/education', 
                '2 IPS meetings or more', 
                'Generic employment and educational support'
            ]
        }
    ];
};

const DashboardCardNiceInterventions = function (props) {
    const [state, ] = useStateContext(['page', 'section', 'period', 'group']);
    const group_id = (state.group && state.group !== 'undefined') ? state.group : '';
    const [questionOption, setQuestionOption] = useState('CBTp offered/delivered');
    const questionOptions = getQuestionOptions();
    const metricTitles = questionOptions.find((p)=>p.title === questionOption)?.metricTitles;
    const question_ids = questionOptions.find((p)=>p.title === questionOption)?.question_ids;
    let resultSet = useModelFeedback(ModelDashboardAnswerDistribution, {
        question_ids: question_ids, 
        period: state.period, 
        group_id : group_id,
        order: 'id asc'});
    if(resultSet.feedback){
        return resultSet.feedback;
    }
    
    const palette = [[226, 231, 252], ...getColorPalette(resultSet.data.length - 2), [232, 74, 80]];
    const total = resultSet.data.find(row => row.id === 5)?.value || 0;
    
    let data = resultSet.data.map((obj, key)=>{
        return {
            id: obj.id,
            title: metricTitles[question_ids.indexOf(obj.id)],
            value: obj.value,
            color: `rgb(${palette[key].join()})`
        }
    });
    
    const getQuestionSelect = () => {
        const options = questionOptions.map((o)=>o.title);
        return <UIInputSelect
            options={options}
            name={'period-select'}
            placeholder={props.placeholder}
            onChange={(value)=>{setQuestionOption(value)}}
            value={questionOption} />
    };

    const getTitle = function (){
        return <div style={{width: '100%'}}>
            {getQuestionSelect()}
        </div>
    }; 
    
    return <Card cardSize={props.cardSize}  id={props.id}>
        <CardHeader id={props.id} title={getTitle} style={{height: 35}}/>
        <div style={{
            display: 'flex',
            height: 'calc(100% - 55px)',
            padding: '10px 0'
        }}>
            <div style={{width: '75%', height: '100%', display: 'flex',  alignItems: 'center', position: 'relative'}}>
                <ChartProgressCircle data={data.slice(1)} hideTitles={true} total={total} palette={palette} />
            </div>
            <ChartLegend direction={'vertical'}
                    data={data.map((item)=>item.title)}
                    palette={palette}/>

        </div>
    </Card>
};

DashboardCardNiceInterventions.defaultProps = {
    id : 10,
    title: 'CBTp offered/delivered'
};

export {DashboardCardNiceInterventions}

